import React from 'react';
import { Button, Card, Table } from 'components';
import { Tooltip, OverlayTrigger, ProgressBar } from 'react-bootstrap';
import { FcInfo } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const ChargebackList = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const customer_id = dataObject.customer_id;
      const customer_name = dataObject.customer_name;
      const location_name = dataObject.location_name;
      const chargeback_created_on = dataObject.chargeback_created_on;
      const last_date_to_submit_evidence = dataObject.last_date_to_submit_evidence;

      const extractedData = {
        'Customer ID': customer_id,
        'Customer Name': customer_name,
        Location: location_name,
        'Chargeback Created On': chargeback_created_on,
        'Last Date To Submit Evidence': last_date_to_submit_evidence
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, _this.chargebackInfo?.name + fileExtension);
  };

  const columns = [
    {
      accessor: 'customer_id',
      Header: 'Customer ID',
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <div className="flex gap-2 px-2 justify-center items-center">
          <Link
            to={`/customer-info/${row.original.customer_mongo_id}`}
            className="text-blue-500 font-medium"
          >
            {row.original.customer_id}
          </Link>
        </div>
      )
    },
    {
      accessor: 'customer_name',
      Header: 'Customer Name'
    },
    {
      accessor: 'location_name',
      Header: 'Location Name'
    },
    {
      Header: 'Chargeback Created On',
      accessor: 'chargeback_created_on',
      //   sortable: false,
      Cell: (row) => (
        <div>
          {row.original.chargeback_created_on
            ? moment(row.original.chargeback_created_on).format('YYYY-MM-DD')
            : ''}
        </div>
      )
    },

    {
      Header: 'Last Date To Submit Evidence',
      accessor: 'last_date_to_submit_evidence',
      Cell: (row) => (
        <div>
          {row.original.last_date_to_submit_evidence
            ? moment(row.original.last_date_to_submit_evidence).format('YYYY-MM-DD')
            : ''}
        </div>
      )
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-col  sm:flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">
            {_this.chargebackInfo?.name}
            <span className="w-10 h-10 py-1 px-2 rounded-full bg-[#EFF8FF] text-[#175CD3] text-lg font-bold">
              {_this.tableData.length}
            </span>
          </h3>
          <input
            type="search"
            className="rounded-lg w-72"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
          <div className="flex gap-4">
            <Button
              onClick={() => exportReport()}
              className={`bg-button-primary mb-2`}
              variant="primary"
              id="export"
            >
              Export
            </Button>
          </div>
        </div>
      </Card>

      <Card className="flex mt-2" paddingClass="px-0 py-0">
        <div className="">
          <Table
            keyField="_id"
            columns={columns}
            data={_this.tableData}
            minRows={10}
            defaultPageSize={10}
          />
        </div>
      </Card>
    </>
  );
};

export default ChargebackList;
