import React from 'react';
import { Card, Button, Input, NewButton, Checkbox } from 'components';
import { PermissionWrapper, HasPermission } from 'components';
import { MdDelete, MdAccountBalance } from 'react-icons/md';
import { LiaShippingFastSolid } from 'react-icons/lia';
import { FaCcMastercard } from 'react-icons/fa6';
import { Accordion, Tooltip, OverlayTrigger } from 'react-bootstrap';

const PaymentMethod = ({ _this }) => {
  const customer = _this.customerDetails;
  return (
    <Card className="rounded-xl w-full md:w-[33%]" paddingClass="p-2">
      <div className="flex flex-row p-2 items-center justify-between">
        <h3 className="text-xl font-bold pb-2">
          Payment Methods{' '}
          <span className="w-8 h-8 py-1 px-2 rounded-full bg-[#EFF8FF] text-[#175CD3] text-xs font-bold">
            {customer.payment_method.length}
          </span>
        </h3>
        <PermissionWrapper permission={'add_payment_method'}>
          <NewButton
            onClick={() => {
              _this.setAddPaymentModalOpen(true);
            }}
          >
            {/* <BiPlus className="text-white" size={23} /> */}
            <span className="font-medium text-base ml-0.5 text-white">Add</span>
          </NewButton>
        </PermissionWrapper>
      </div>

      {customer?.payment_method ? (
        <>
          {customer?.payment_method?.map((item, index) => (
            <div
              onClick={() => {
                if (!_this.loggedInUser?.permissions?.includes('set_primary_payment_method'))
                  return false;
                if (item.is_primary == false) {
                  _this.setSelectPrimaryPaymentModalOpen(true);
                  item.payment_method == 'creditcard'
                    ? _this.setSelectedPaymentDetails((prev) => ({
                        ...prev,
                        payment_method_id: item._id,
                        customer_id: item.customer,
                        card_number: item.card_details.card_last_4_digit,
                        payment_method: item.payment_method
                      }))
                    : _this.setSelectedPaymentDetails((prev) => ({
                        ...prev,
                        payment_method_id: item._id,
                        customer_id: item.customer,
                        card_number: item.ach_details.account_last_4_digit,
                        payment_method: item.payment_method
                      }));
                }
              }}
              key={index}
              className={`p-3 m-2 flex flex-col justify-center rounded-lg items-start border-2 border-button-primary ${
                item.is_primary == false && 'border'
              } `}
            >
              <div className="w-full flex justify-between">
                {item.payment_method == 'creditcard' ? (
                  <FaCcMastercard size={40} className="text-button-primary" />
                ) : (
                  <MdAccountBalance size={40} className="text-button-primary" />
                )}
                {item.payment_method == 'creditcard' ? (
                  <>
                    <div className="flex flex-col gap-1 justify-center items-center mb-1">
                      <p className="text-body-color text-sm font-bold">
                        Card ending in{' '}
                        <span className="text-black text-base">
                          {item.card_details.card_last_4_digit}
                        </span>
                      </p>

                      <p p className="text-body-color text-sm font-bold">
                        Expiry{' '}
                        <span className="text-black text-sm">{item.card_details.expiry_date}</span>
                      </p>

                      <p className="text-black text-sm font-bold text-center">
                        {item.card_details.name}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col gap-1 justify-center items-center w-full mb-1">
                      <p className="text-body-color text-sm font-bold">
                        ACH ending in{' '}
                        <span className="text-black text-base">
                          {item.ach_details.account_last_4_digit}
                        </span>
                      </p>

                      <p p className="text-body-color text-sm font-bold">
                        Expiry <span className="text-black text-sm">N/A</span>
                      </p>

                      <p className="text-black text-sm font-bold">{item.ach_details.name}</p>
                    </div>
                  </>
                )}
                {item.is_primary === true ? (
                  <p className="text-button-primary font-bold text-sm">PRIMARY</p>
                ) : (
                  <div className="w-12"></div>
                )}
              </div>
              <div className={`flex items-center w-full mt-2 justify-center gap-2`}>
                <PermissionWrapper permission={'edit_billing_address'}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      _this.setEditPaymentModalOpen(true);
                    }}
                    className="bg-button-primary hover:bg-button-primaryHover px-2 py-1 rounded text-xs flex flex-row"
                  >
                    <span className="font-medium text-sm ml-0.5 text-white">
                      Edit Billing Details
                    </span>
                  </button>
                </PermissionWrapper>

                <div className="flex gap-2">
                  <PermissionWrapper permission={'delete_payment_method'}>
                    <MdDelete
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.is_primary == true)
                          return toast.error(
                            'Cannot delete this primary payment method. Select another payment method as primary or add one to delete this payment method'
                          );
                        _this.setDeletePaymentMethodModalOpen(true);

                        _this.setDeletePaymentMethodData((prev) => ({
                          ...prev,
                          _id: item._id,
                          last_4_digit:
                            item.payment_method == 'creditcard'
                              ? item.card_details.card_last_4_digit
                              : item.ach_details.account_last_4_digit
                        }));
                      }}
                      fill="red"
                      size={18}
                      className="cursor-pointer"
                    />
                  </PermissionWrapper>

                  {item.is_migrated_from_fusebill == true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-bottom ">
                          <p>--Migrated--</p>
                          <p>Fusebill ID: {item.fusebill_id}</p>
                        </Tooltip>
                      }
                    >
                      <span className="cursor-pointer">
                        <LiaShippingFastSolid size={18} className="cursor-pointer text-green-800" />
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="pb-1 mt-3 flex flex-row justify-between rounded-lg items-center border ">
          <div className="flex justify-center p-10">
            <div className="text-md text-slate-500 mt-1 w-[300px]">
              No Payment Method Available.
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default PaymentMethod;
