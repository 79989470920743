import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [locationList, setLocationList] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [selectedLocationInformation, setSelectedLocationInformation] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState({
    name: '',
    email: '',
    role_id: {}
  });

  useEffect(() => {
    loadLocationOptions();
  }, []);

  useEffect(() => {
    loadRoleOptions();
  }, []);

  const loadLocationOptions = async () => {
    let response = await API.getFranchiseLocation();
    if (response) {
      const list = response.map((item) => ({
        label: item.locationName,
        value: item
      }));
      setLocationList(list);
    }
  };

  const loadRoleOptions = async () => {
    let response = await API.getEmployeeRoleList();
    if (response) {
      const list = response.map((item) => ({
        label: item.role_name,
        value: {
          role_id: item._id,
          role_name: item.role_name
        }
      }));
      setRoleList(list);
    }
  };

  const onSelectingLocation = (locationName) => {
    let temp = [...locationName];
    setSelectedLocationInformation(temp);
  };

  const onEmployeeDetailsSubmit = async () => {
    const temp = {
      ...employeeDetails,
      locations: selectedLocationInformation,
      role_id: employeeDetails.role_id.value.role_id
    };
    dispatch(loadingStart());
    await API.createEmployee(temp)
      .then((response) => {
        if (response) {
          toast.success('Employee Created Successfully.');
          navigate('/employee-list');
          setEmployeeDetails((prev) => ({
            ...prev,
            name: '',
            email: '',
            role_id: ''
          }));
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          employeeDetails,
          setEmployeeDetails,
          onEmployeeDetailsSubmit,
          locationList,
          onSelectingLocation,
          roleList
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
