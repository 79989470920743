import { handleResponse, API, getMiracleAppURL, getToken } from './utils';
import states from '../helpers/states.json';

const employee = {
  generateEmailEmployee: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/employee/generate-email', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  fetchEmployeeEmailLogs: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/employee/employee-mail-logs', {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createEmployee: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/employee/create-employee', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  getEmployeeList: async (employeeName) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/employee/get-employee-list', {
        params: { employeeName },
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  getEmployeeDetailsById: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/employee/get-employee-details', {
        params: { id: id },
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  changePassword: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/employee/change-password', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  editProfile: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/employee/edit-profile', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  deleteEmployee: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/employee/delete-employee', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  editEmployeeData: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/employee/edit-employee-data', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  changeEmployeeStatus: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/employee/change-employee-status', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  sendPassword: async (data) => {
    const token = await getToken();
    let response = null;

    try {
      response = await API.post(
        '/v1/employee/send-employee-password',
        { data },
        {
          params: {},
          headers: {
            Authorization: 'Bearer: ' + token
          }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getEmployeeRoleList: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/employee/get-role-list', {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default employee;
