import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const [searchFromDate, setSearchFromDate] = useState('');
  const [searchToDate, setSearchToDate] = useState('');
  const [searchFieldOption, setSearchFieldOption] = useState({
    'HSPP ID': 'hspp_id',
    'Patient ID': 'customer_id',
    'First Name': 'first_name',
    'Last Name': 'last_name',
    'Created At': 'createdAt'
  });
  const [tableData, setTableData] = useState([]);
  const [searchTableData, setSearchTableData] = useState([]);
  const [search, setSearch] = useState({ searchField: '', searchInput: '' });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (search === '') {
      setSearchTableData(tableData);
    }
  }, [search]);

  const init = async () => {
    dispatch(loadingStart());
    await API.getDeletedCustomerList()
      .then((response) => {
        if (response) {
          setTableData(response);
          setSearchTableData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const searchData = () => {
    const payload = search;
    if (payload.searchField === 'createdAt') {
      const fromDate = moment(searchFromDate).format('YYYY-MM-DD');
      const toDate = moment(searchToDate).add(1, 'days').format('YYYY-MM-DD');
      payload.searchInput = `${fromDate}to${toDate}`;
    }
    if (payload.searchInput == '') {
      setSearchTableData(tableData);
    } else {
      API.getDeletedCustomerListBySearch(payload).then((response) => {
        if (response) {
          setSearchTableData(response);
        }
      });
    }
  };

  const RestoreCustomer = async (id) => {
    console.log(id);
    dispatch(loadingStart());
    await API.restoreCustomer({ id })
      .then((response) => {
        if (response) {
          toast.success('Customer restored successfully');
          init();
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          searchTableData,
          setSearch,
          search,
          searchData,
          searchFieldOption,
          setSearchFieldOption,
          searchFromDate,
          setSearchFromDate,
          searchToDate,
          setSearchToDate,
          RestoreCustomer
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
