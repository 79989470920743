import React from 'react';
import { Card, Button } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import Config from 'config';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';

const Section = ({ _this }) => {
  const columns = [
    {
      Header: 'Patient ID',
      fixed: 'left',
      accessor: 'customer_data.customer_id',
      Cell: (row) => (
        <Link
          to={`/customer-info/${row?.original?.customer}`}
          className="text-blue-500 font-medium"
        >
          {row?.original?.patient_id || ''}
        </Link>
      )
    },
    {
      accessor: '_id',
      Header: 'Invoice ID',
      Cell: (row) => <div className="font-medium">{parseHsppId(row.original._id)}</div>
    },
    {
      Header: 'Transaction ID',
      minWidth: 220,
      accessor: 'payment_gateway_transaction_id',
      Cell: (row) =>
        row.original.payment_gateway_transaction_id && (
          <div className="font-medium">{row.original.payment_gateway_transaction_id}</div>
        )
    },
    {
      Header: 'Customer Name',
      accessor: 'customer_data.first_name',
      Cell: (row) => <div>{row.original?.patient_name}</div>
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (row) => <div>${row.original.amount}</div>
    },
    {
      Header: 'Paid On',
      accessor: 'paid_on',
      Cell: (row) => (
        <div>{row.original.paid_on && moment(row.original.paid_on).format('YYYY-MM-DD')}</div>
      )
    },
    {
      Header: 'Due Date',
      accessor: 'due_date',
      Cell: (row) => <div>{moment(row.original.due_date).format('YYYY-MM-DD')}</div>
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: (row) => (
        <div
          className={`font-semibold px-3 py-1 rounded text-xs ${renderStatusColorClass(
            row.original.status === 'Paid'
              ? 'Active'
              : row.original.status === 'Failed'
              ? 'Expired'
              : row.original.status === 'Void'
              ? 'Cancelled'
              : row.original.status === 'Cancel'
              ? 'Cancelled'
              : row.original.status === 'Refunded'
              ? 'Cancelled'
              : 'OnHold'
          )}`}
        >
          {row.original.status === 'Paid'
            ? 'PAID'
            : row.original.status === 'Failed'
            ? 'FAILED'
            : row.original.status === 'Void'
            ? 'CANCELLED'
            : row.original.status === 'Cancel'
            ? 'CANCELLED'
            : row.original.status === 'Processing'
            ? 'PROCESSING'
            : row.original.status === 'Refunded'
            ? 'REFUNDED'
            : 'PENDING'}
        </div>
      )
    },
    {
      accessor: '_id',
      Header: 'Bill',
      sortable: false,
      Cell: (row) => (
        <center>
          <Link
            to={`/view-invoice/${row.original._id}`}
            target="_blank"
            className="bg-blue-500 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
          >
            View Bill
          </Link>
        </center>
      )
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-2xl">Invoice List</h3>
          <div className=""></div>
        </div>
      </Card>
      <Card className="flex mt-2" paddingClass="px-0 py-0">
        <div className="">
          <Table
            keyField="id"
            columns={columns}
            data={_this.tableData}
            headerComponent={null}
            pageSize={100}
            showPageSizeOptions={false}
            pages={_this.totalPage}
            onFetchData={(state, instance) => {
              _this.fetchData(
                state.page,
                state.pageSize,
                state?.sorted[0]?.id,
                state?.sorted[0]?.desc
              );
            }}
            // onSortedChange={(e) => {
            //   _this.setSortColumn(e[0].id);
            //   _this.setSortDirection(e[0].desc);
            //   _this.fetchData(_this.currentPage, _this.limitPerPage, e[0].id, e[0].desc);
            // }}
            manual={true}
          />
        </div>
      </Card>
    </>
  );
};

export default Section;
