import React from 'react';
import { Card, Dropdown, DatePicker, Table, Button } from 'components';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { currencyFormatter } from 'services/helpers';

const Section = ({ _this }) => {
  const totalRevenue = () => {
    let revenue = 0;
    _this.tableData0.forEach((element) => {
      revenue = revenue + element.revenue;
    });

    return currencyFormatter.format(revenue.toFixed(2));
  };
  return (
    <Card className="flex mr-2 mt-2">
      <div className="relative lg:flex flex-col items-center sm:flex-row gap-2 justify-between mb-2">
        <div>
          <h3 className="text-body-color text-xl font-semibold pb-2">Revenue Report</h3>
          <p className="text-body-color text-lg font-semibold pb-2">
            Total Revenue : {totalRevenue()}
          </p>
        </div>
        <DatePicker
          label="Start Month"
          isMonth={true}
          required
          value={_this.revenueParams?.start_date || ''}
          onChange={(e) => {
            _this.setRevenueParams((prev) => ({
              ...prev,
              start_date: moment(e).startOf('month').toDate()
            }));
          }}
        />

        <DatePicker
          label="End Month"
          isMonth={true}
          required
          value={_this.revenueParams?.end_date || ''}
          onChange={(e) => {
            _this.setRevenueParams((prev) => ({
              ...prev,
              end_date: moment(e).endOf('month').toDate()
            }));
          }}
        />
        <Dropdown
          className="w-48 z-50"
          label="Location"
          placeholder="@location"
          required
          type="text"
          options={_this.locationList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
          aria-label="Default select example"
          value={_this.revenueParams.locations}
          onChange={(e) => {
            _this.setRevenueParams((prev) => ({
              ...prev,
              locations: e
            }));
          }}
          isMulti
        />

        <button
          className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
          onClick={_this.init}
        >
          Generate Report
        </button>
      </div>
      <div className="flex justify-between my-2 gap-10 mt-2">
        <div className="flex items-center gap-2 text-lg">
          <div
            onClick={() => _this?.setTabNo(0)}
            className={`cursor-pointer px-2 hover:text-blue-500 border-blue-500 ${
              _this?.tabNo == 0 && 'text-blue-700 border-b-2'
            }`}
          >
            Revenue Summary
          </div>
          <div
            onClick={() => _this?.setTabNo(1)}
            className={`cursor-pointer px-2 hover:text-blue-500 border-blue-500 ${
              _this?.tabNo == 1 && 'text-blue-700 border-b-2'
            }`}
          >
            Revenue Journal Entry
          </div>
        </div>
        <button
          className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
          onClick={() => _this?.exportReport()}
          id="export"
        >
          Export
        </button>
      </div>
      {_this?.tabNo == 0 ? (
        <Table
          keyField="_id"
          columns={_this?.column0}
          data={_this.tableData0}
          headerComponent={null}
          minRows={20}
        />
      ) : (
        <Table
          keyField="_id"
          columns={_this?.column1}
          data={_this.tableData1}
          headerComponent={null}
          minRows={20}
        />
      )}
    </Card>
  );
};

export default Section;
