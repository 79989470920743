import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import { BiEditAlt } from 'react-icons/bi';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import LocationName from './LocationName';
import LocationGroup from './LocationGroup';
import FranchiseName from './FranchiseName';
import State from './State';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const hsppId = dataObject.hspp_id;
      const customerId = dataObject.customer_id;
      const customerName = `${dataObject.first_name} ${dataObject.last_name}`;
      const location = dataObject.location.toString();
      const franchise = dataObject.franchise.toString();
      const plan = dataObject.plan_name;
      const amount = `$${dataObject.plan_amount}`;
      const nextInvoiceDate = dataObject.next_invoice_date
        ? new Date(dataObject.next_invoice_date)
        : '';
      const lWarrantyExpiry = dataObject.l_warranty_expiry
        ? new Date(dataObject.l_warranty_expiry)
        : '';
      const rWarrantyExpiry = dataObject.r_warranty_expiry
        ? new Date(dataObject.r_warranty_expiry)
        : '';
      const expiryDate = dataObject.expiry_date ? new Date(dataObject.expiry_date) : '';

      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        'HSPP ID': hsppId,
        'Customer ID': customerId,
        'Customer Name': customerName,
        Location: location,
        Franchise: franchise,
        Plan: plan,
        Amount: amount,
        'Next Invoice Date': nextInvoiceDate,
        'L WarrantyExpiry': lWarrantyExpiry,
        'R WarrantyExpiry': rWarrantyExpiry,
        'Expiry Date': expiryDate

        // Address: formattedAddress
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'L&DReport' + fileExtension);
  };

  const columns = [
    {
      accessor: 'hspp_id',
      Header: 'HSPP ID',
      sortable: false,
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link to={`/customer-info/${row.original.customer}`} className="text-blue-500 font-medium">
          #{row.original.hspp_id}
        </Link>
      )
    },
    {
      accessor: 'customer_id',
      Header: 'Patient ID',
      Cell: (row) => <div>{row.original.customer_id}</div>
    },
    {
      accessor: 'first_name',
      Header: 'Name',
      Cell: (row) => <div>{row.original.first_name + ' ' + row.original.last_name}</div>
    },
    {
      accessor: 'location',
      Header: 'Location',
      Cell: (row) => <div>{row.original.location}</div>
    },
    {
      accessor: 'franchise',
      Header: 'Franchise',
      Cell: (row) => <div>{row.original.franchise}</div>
    },
    {
      accessor: 'plan_name',
      Header: 'Plan',
      Cell: (row) => <div>{row.original.plan_name}</div>
    },
    {
      accessor: 'plan_amount',
      Header: 'Amount',
      Cell: (row) => <div>${row.original.plan_amount}</div>
    },
    {
      accessor: 'next_invoice_date',
      Header: 'Next Invoice Date',
      Cell: (row) => (
        <div>
          {row.original.next_invoice_date
            ? moment(row.original.next_invoice_date).format('YYYY-MM-DD')
            : ''}
        </div>
      )
    },
    {
      accessor: 'l_warranty_expiry',
      Header: 'L Warranty Expiry',
      Cell: (row) => (
        <div>
          {row.original.l_warranty_expiry
            ? moment(row.original.l_warranty_expiry).format('YYYY-MM-DD')
            : ''}
        </div>
      )
    },
    {
      accessor: 'r_warranty_expiry',
      Header: 'R Warranty Expiry',
      Cell: (row) => (
        <div>
          {row.original.r_warranty_expiry
            ? moment(row.original.r_warranty_expiry).format('YYYY-MM-DD')
            : ''}
        </div>
      )
    },
    {
      accessor: 'expiry_date',
      Header: 'Expiry Date',
      width: 120,
      Cell: (row) => (
        <div className="flex gap-2">
          <p>
            {row.original.expiry_date ? moment(row.original.expiry_date).format('YYYY-MM-DD') : ''}
          </p>
          <span>
            <BiEditAlt
              size={20}
              className="cursor-pointer"
              onClick={() => {
                _this.handleExpiryDateEdit(row.original._id, row.original.expiry_date);
              }}
            />
          </span>
        </div>
      )
    }
  ];

  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card background={'bg-cardbg1'} className="">
        <div className="relative flex flex-wrap flex-col sm:flex-row gap-2 justify-between items-center">
          <h3 className="text-body-color text-xl md:text-2xl">HSPP Attachment Rate Report</h3>

          <DatePicker
            label="Start Date"
            required
            value={_this.hsppAttatchmentDetails?.start_date || ''}
            onChange={(e) => {
              _this.setHsppAttatchmentDetails((prev) => ({
                ...prev,
                start_date: e
              }));
            }}
          />

          <DatePicker
            label="End Date"
            required
            value={_this.hsppAttatchmentDetails?.end_date || ''}
            onChange={(e) => {
              _this.setHsppAttatchmentDetails((prev) => ({
                ...prev,
                end_date: e
              }));
            }}
          />
          <Dropdown
            label="Search By"
            placeholder="@location"
            className="z-10"
            required
            type="text"
            options={[
              { label: 'Location Name', value: 'location_name' },
              { label: 'Franchise Name', value: 'franchise_name' },
              { label: 'Location Group', value: 'location_group' },
              { label: 'State', value: 'state' }
            ]}
            aria-label="Default select example"
            value={_this.hsppAttatchmentDetails?.search_by}
            onChange={(e) => {
              _this.setHsppAttatchmentDetails((prev) => ({
                ...prev,
                search_by: e
              }));
            }}
          />
          <button
            className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
            onClick={_this.init}
          >
            Generate Report
          </button>
        </div>
      </Card>

      {_this.hsppAttatchmentDetails.search_by.value == 'location_name' && (
        <LocationName _this={_this} />
      )}
      {_this.hsppAttatchmentDetails.search_by.value == 'location_group' && (
        <LocationGroup _this={_this} />
      )}
      {_this.hsppAttatchmentDetails.search_by.value == 'franchise_name' && (
        <FranchiseName _this={_this} />
      )}
      {_this.hsppAttatchmentDetails.search_by.value == 'state' && <State _this={_this} />}
    </>
  );
};

export default Section;
