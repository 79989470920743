import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const column0 = [
  {
    Header: 'Location',
    accessor: 'location_name'
  },
  {
    Header: 'Class',
    accessor: 'class'
  },
  {
    Header: 'Total Revenue',
    accessor: 'revenue',
    Cell: (row) => {
      return <p>${Number(row?.original?.revenue)?.toFixed(2)}</p>;
    }
  }
];

const column1 = [
  {
    Header: 'Ref Number',
    accessor: 'refNo'
  },
  {
    Header: 'Transaction Date',
    accessor: 'trDate'
  },
  {
    Header: 'Account',
    accessor: 'account'
  },
  {
    Header: 'Debit',
    accessor: 'debit'
  },
  {
    Header: 'Credit',
    accessor: 'credit'
  },
  {
    Header: 'Memo',
    accessor: 'memo'
  },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Class',
    accessor: 'class',
    minWidth: 250
  }
];

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.session).userSession;
  const { state } = useLocation();
  const [locationList, setLocationList] = useState(null);
  const [revenueParams, setRevenueParams] = useState(
    state?.revenueParams || {
      start_date: moment().startOf('month').toDate(),
      end_date: moment().endOf('month').toDate(),
      locations: [
        {
          label: 'All',
          value: {
            locationName: 'All'
          }
        }
      ]
    }
  );

  const [tableData0, setTableData0] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tabNo, setTabNo] = useState(0);

  useEffect(() => {
    init();
    loadLocationOptions();
  }, []);

  useEffect(() => {
    setTableData1(
      tableData0?.map((item) => {
        return {
          refNo: 'REVRevenue',
          trDate: moment(revenueParams?.end_date).format('MM/DD/YYYY'),
          account: '4010',
          debit: '',
          credit: Number(item?.revenue)?.toFixed(2),
          memo: 'Sales Entry',
          name: '',
          class: item?.class
        };
      })
    );
  }, [tableData0]);

  const loadLocationOptions = async () => {
    // dispatch(loadingStart());
    await API.getFranchiseLocation().then((response) => {
      if (response) {
        const list = response.map((item) => ({
          label: item.locationName,
          value: item
        }));
        let userLocationMatch = [];

        loggedInUser.location.forEach((elem) => {
          userLocationMatch.push(...list.filter((item) => item.value.locationName == elem));
        });

        if (loggedInUser.role !== 'Employee') {
          setLocationList(list);
          setRevenueParams((prev) => ({
            ...prev,
            location: revenueParams?.location || userLocationMatch
          }));
        } else {
          setLocationList(userLocationMatch);
          setRevenueParams((prev) => ({
            ...prev,
            location: revenueParams?.location || userLocationMatch
          }));
        }
      }
    });
    // .finally(() => dispatch(loadingStop()));
  };

  const init = () => {
    if (revenueParams?.locations?.length == 0) {
      toast.error('Please enter locations !');
      return;
    }
    dispatch(loadingStart());
    const locations = revenueParams?.locations?.map((item) => item.value.locationId);
    API.fetchRevenueReport(locations, revenueParams.start_date, revenueParams.end_date)
      .then((response) => {
        if (response) {
          setTableData0(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const exportReport = () => {
    const revenueSummary = tableData0?.map((item) => {
      return {
        Location: item?.location_name,
        Class: item?.class,
        'Total Revenue': item?.revenue
      };
    });

    const journalEntry = tableData1?.map((item) => {
      return {
        'Ref Number': item?.refNo,
        'Transaction Date': item?.trDate,
        Account: item?.account,
        Debit: item?.debit,
        Credit: item?.credit,
        Memo: item?.memo,
        Name: item?.name,
        Class: item?.class
      };
    });

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Create first worksheet
    const ws1 = XLSX.utils.json_to_sheet(revenueSummary);
    // Create second worksheet
    const ws2 = XLSX.utils.json_to_sheet(journalEntry);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Append worksheets to workbook
    XLSX.utils.book_append_sheet(wb, ws1, 'Revenue Summary');
    XLSX.utils.book_append_sheet(wb, ws2, 'Revenue Journal Entry');

    // Generate Excel file as a binary array
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'RevenueReport' + fileExtension);
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData0,
          tableData1,
          revenueParams,
          setRevenueParams,
          locationList,
          init,
          column0,
          column1,
          tabNo,
          setTabNo,
          exportReport
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
