import React, { useEffect, useState } from 'react';
import { Card, Input, Dropdown, Checkbox, Row } from 'components';
import { Form, Alert } from 'react-bootstrap';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

const Section = ({ _this }) => {
  return (
    <>
      <div className="flex flex-col md:flex-row flex-wrap justify-between">
        <PaymentMethodCard _this={_this} isPrimary={true} />
        {_this?.showSecondaryPayment && <PaymentMethodCard _this={_this} isPrimary={false} />}
        <Card className="mb-3 flex md:w-[45%]">
          <div className="flex flex-row items-center justify-between">
            <h3 className="text-body-color text-xl">Billing Information</h3>
            <div
              className="p-1 bg-slate-600 text-white text-sm rounded-sm cursor-pointer hover:bg-slate-500"
              onClick={() => _this.copyShippingToBilling()}
            >
              Copy Shipping Address
            </div>
          </div>
          <div className="bg-slate-100 mt-2 p-2 rounded">
            <Form>
              <Input
                label="Address 1"
                placeholder=""
                type="text"
                required
                value={(_this.billingAddress && _this.billingAddress.address1) || ''}
                onChange={(e) => _this.updateBillingAddress('address1', e.target.value)}
              />
              <Input
                label="Address 2"
                placeholder=""
                type="text"
                value={(_this.billingAddress && _this.billingAddress.address2) || ''}
                onChange={(e) => _this.updateBillingAddress('address2', e.target.value)}
              />
              <Row>
                <Input
                  label="City"
                  placeholder=""
                  type="text"
                  required
                  value={(_this.billingAddress && _this.billingAddress.city) || ''}
                  onChange={(e) => _this.updateBillingAddress('city', e.target.value)}
                />
                <Dropdown
                  label="State"
                  placeholder="Select"
                  required
                  type="text"
                  options={_this.stateList}
                  value={
                    _this.billingAddress && _this.billingAddress.state
                      ? { label: _this.billingAddress.state, value: _this.billingAddress.state }
                      : ''
                  }
                  onChange={(e) => _this.updateBillingAddress('state', e.value)}
                />
              </Row>
              <Row>
                <Dropdown
                  label="Country"
                  placeholder="Select"
                  required
                  type="text"
                  isDisabled
                  defaultValue={(_this.billingAddress && _this.billingAddress.country) || ''}
                  value={(_this.billingAddress && _this.billingAddress.country) || ''}
                  onChange={(e) => _this.updateBillingAddress('country', e)}
                />
                <Input
                  label="Zipcode"
                  placeholder=""
                  required
                  type="text"
                  value={(_this.billingAddress && _this.billingAddress.zipcode) || ''}
                  onChange={(e) => _this.updateBillingAddress('zipcode', e.target.value)}
                />
              </Row>
            </Form>
          </div>
        </Card>
        <Card className="mb-3 md:w-[45%]">
          <h6 className="text-body-color font-medium text-2xl border-b-[2px] pb-1">
            Customer Details
          </h6>
          <div className="mt-3 flex text-xl flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-lg">Patient ID:</h5>
            <h5 className="text-slate-500 text-lg">{_this.customerDetails?.customer_id}</h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-lg">Name:</h5>
            <h5 className="text-slate-500 text-lg">
              {_this.customerDetails?.first_name + ' ' + _this.customerDetails?.last_name}
            </h5>
          </div>
          {_this.productInformation?.map((item, index) => (
            <div
              key={index}
              className="mt-3 flex text-xl flex-row justify-between items-center border-b-[1px] pb-2"
            >
              <h5 className="text-slate-500 text-lg">{`Plan${index + 1}:`}</h5>
              <h5 className="text-slate-500 text-lg">
                {item?.plan_override_amount
                  ? `${item.plan.value.name} | $${item?.plan_override_amount?.toFixed(2)}`
                  : `${item?.plan?.label}`}
              </h5>
            </div>
          ))}
        </Card>
      </div>
      <Card className="mb-2" paddingClass="px-4 py-2">
        <input
          id="tnc"
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked) _this.setIsTnCChecked(true);
            else _this.setIsTnCChecked(false);
          }}
        />
        <label htmlFor="tnc" className="p-2 ">
          By Clicking Submit you are Agreeing to our
          <a
            href="https://myhspp.s3.amazonaws.com/prod/1694758636980.docx"
            target="_blank"
            className="cursor-pointer text-blue-700"
            rel="noreferrer"
          >
            {' '}
            Term and Conditions
          </a>
        </label>
      </Card>
    </>
  );
};

const PaymentMethodCard = ({ _this, isPrimary }) => {
  const [cardExpiry, setCardExpiry] = useState('');
  useEffect(() => {
    let expiry = cardExpiry.toString();
    if (cardExpiry.includes('/') == false) {
      if (expiry.length == 2) expiry = expiry + '/';
    }
    _this.updateCardDetails('expiry', expiry, isPrimary);
  }, [cardExpiry]);

  const getVar = (name) => {
    return isPrimary ? name : name + '2';
  };

  return (
    <Card className="mb-3 flex md:w-[45%]">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h3 className="text-body-color text-xl">Select Payment Method</h3>
        <Row className="w-full md:w-6/12 grid grid-cols-3 mt-2">
          <Checkbox
            className="mt-2 text-sm"
            id="ach"
            label={<p className="text-base tracking-tight">ACH</p>}
            type="checkbox"
            checked={_this[getVar('paymentMethod')] == 'ACH'}
            onChange={(e) => {
              _this.updatePaymentMethod('ACH', isPrimary);
              if (e.target.checked) {
                _this.updateShowACHDetails(true, isPrimary);
                _this.updateCardDetails('cvc', '', isPrimary);
                _this.updateCardDetails('expiry', '', isPrimary);
                _this.updateCardDetails('focus', '', isPrimary);
                _this.updateCardDetails('number', '', isPrimary);
                _this.updateCardDetails('focused', '', isPrimary);
              }
            }}
          />
          <Checkbox
            className="mt-2 text-sm"
            id="cc"
            label={<p className="text-base tracking-tight">Credit Card</p>}
            type="checkbox"
            checked={_this[getVar('paymentMethod')] == 'CC'}
            onChange={(e) => {
              _this.updatePaymentMethod('CC', isPrimary);
              if (e.target.checked) {
                _this.updateShowACHDetails(false, isPrimary);
                _this.updateACHDetails('routing_number', '', isPrimary);
                _this.updateACHDetails('account_number', '', isPrimary);
              }
            }}
          />
          {isPrimary && (
            <Checkbox
              className="mt-2 text-sm"
              id="credit"
              label={<p className="text-base tracking-tight">Credit</p>}
              type="checkbox"
              checked={_this[getVar('paymentMethod')] == 'NA'}
              onChange={(e) => {
                _this.updatePaymentMethod('NA', isPrimary);
                if (e.target.checked) {
                  _this.updateCardDetails('cvc', '', isPrimary);
                  _this.updateCardDetails('expiry', '', isPrimary);
                  _this.updateCardDetails('focus', '', isPrimary);
                  _this.updateCardDetails('number', '', isPrimary);
                  _this.updateCardDetails('focused', '', isPrimary);
                  _this.updateACHDetails('routing_number', '', isPrimary);
                  _this.updateACHDetails('account_number', '', isPrimary);
                }
              }}
            />
          )}
        </Row>
      </div>

      <div
        className={`${
          _this[getVar('paymentMethod')] !== 'NA' ? 'bg-slate-100' : ''
        } mt-2 p-2 pt-3 rounded`}
      >
        {_this[getVar('paymentMethod')] === 'CC' ? (
          <>
            <Cards
              cvc={_this[getVar('cardDetails')] && _this[getVar('cardDetails')].cvc}
              expiry={_this[getVar('cardDetails')] && _this[getVar('cardDetails')].expiry}
              focused={_this[getVar('cardDetails')] && _this[getVar('cardDetails')].focused}
              name={_this[getVar('cardDetails')] && _this[getVar('cardDetails')].name}
              number={_this[getVar('cardDetails')] && _this[getVar('cardDetails')].number}
            />
            <Form className="mt-4">
              <Input
                label="Card Number"
                placeholder=""
                required
                type="number"
                value={(_this[getVar('cardDetails')] && _this[getVar('cardDetails')].number) || ''}
                onChange={(e) => _this.updateCardDetails('number', e.target.value, isPrimary)}
              />
              <Input
                label="Card Name"
                placeholder=""
                type="text"
                required
                value={(_this[getVar('cardDetails')] && _this[getVar('cardDetails')].name) || ''}
                onChange={(e) => _this.updateCardDetails('name', e.target.value, isPrimary)}
              />
              <Row>
                <Input
                  label="Card Expiry (MM/YYYY)"
                  placeholder=""
                  type="text"
                  required
                  value={
                    (_this[getVar('cardDetails')] && _this[getVar('cardDetails')].expiry) || ''
                  }
                  onChange={(e) => setCardExpiry(e.target.value)}
                />
                <Input
                  label="CVV"
                  placeholder=""
                  required
                  type="text"
                  value={(_this[getVar('cardDetails')] && _this[getVar('cardDetails')].cvc) || ''}
                  onChange={(e) => _this.updateCardDetails('cvc', e.target.value, isPrimary)}
                />
              </Row>
            </Form>
          </>
        ) : _this[getVar('paymentMethod')] === 'ACH' ? (
          <>
            <Form className="mt-4">
              <Input
                label="Account Number"
                placeholder=""
                required
                type="number"
                value={_this[getVar('achDetails')]?.account_number || ''}
                onChange={(e) =>
                  _this.updateACHDetails('account_number', e.target.value, isPrimary)
                }
              />
              <Input
                label="Bank Routing Number"
                placeholder=""
                type="text"
                required
                value={_this[getVar('achDetails')]?.routing_number || ''}
                onChange={(e) =>
                  _this.updateACHDetails('routing_number', e.target.value, isPrimary)
                }
              />
              <Input
                label="Name"
                placeholder=""
                type="text"
                required
                value={_this[getVar('achDetails')]?.name || ''}
                onChange={(e) => _this.updateACHDetails('name', e.target.value, isPrimary)}
              />
            </Form>
          </>
        ) : (
          <Alert variant="info">
            <Alert.Heading>Alert</Alert.Heading>
            <p>Please contact Admin after creating the customer account to add credit balance.</p>
          </Alert>
        )}
        {isPrimary && (
          <Checkbox
            label={<p className="text-lg tracking-tight">Add secondary payment</p>}
            className="mt-3 w-2/5 mx-auto text-base"
            type="checkbox"
            onChange={() => _this?.showHideSecondaryPayment()}
          />
        )}
      </div>
    </Card>
  );
};
export default Section;
