import React from 'react';
import { Card, Button, Input, Dropdown, Select, Row } from 'components';
import { Form, InputGroup } from 'react-bootstrap';

const Section = ({ _this }) => {
  return (
    <>
      <Card className="mb-2" background={'bg-cardbg1'} paddingClass="px-4 py-3">
        <div className="flex flex-row justify-between items-center">
          <h3 className="text-body-color text-2xl">New Employee</h3>
          <div className="w-44">
            <Button onClick={() => _this.onEmployeeDetailsSubmit()}>Submit</Button>
          </div>
        </div>
      </Card>
      <Card className="flex basis-1/2">
        <h3 className="text-body-color text-xl">Employee Information</h3>
        <Form className="bg-slate-100 mt-2 p-2 rounded">
          <Input
            label="Name"
            placeholder="@name"
            type="text"
            required
            value={_this.employeeDetails.name || ''}
            onChange={(e) =>
              _this.setEmployeeDetails((prev) => ({
                ...prev,
                name: e.target.value
              }))
            }
          />

          <Input
            label="Email"
            placeholder="@email"
            type="text"
            required
            value={_this.employeeDetails.email || ''}
            onChange={(e) =>
              _this.setEmployeeDetails((prev) => ({
                ...prev,
                email: e.target.value
              }))
            }
          />
          <Row>
            <Dropdown
              label="Role"
              placeholder="@role"
              required
              type="text"
              options={_this.roleList}
              value={_this.employeeDetails.role_id}
              onChange={(e) =>
                _this.setEmployeeDetails((prev) => ({
                  ...prev,
                  role_id: e
                }))
              }
            />
            <Dropdown
              label="Select Location"
              required
              type="text"
              options={_this.locationList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
              isClearable
              aria-label="Default select example"
              onChange={(e) => {
                _this.onSelectingLocation(e);
              }}
              value={_this.setSelectedLocationInformation}
              isMulti
            />
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default Section;
