import { Modal } from 'react-bootstrap';
import { Button, Input, DatePicker } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const StartStopModal = ({ _this }) => {
  return (
    <Modal
      show={_this.showStartStopModal}
      onHide={() => _this.setShowStartStopModal(false)}
      fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      className="mt-16 mb-28 flex justify-center items-center"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {_this.ongoingContest?.contest ? 'Stop' : 'Start'} Contest
        </Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setShowStartStopModal(false)} />
      </Modal.Header>
      <Modal.Body>
        {_this.ongoingContest?.contest == false ? (
          <>
            <Input
              type="text"
              placeholder="Enter contest name..."
              label="Contest Name"
              required
              value={_this.contestStartData?.contest_name}
              onChange={(e) =>
                _this.setContestStartData((prev) => ({
                  ...prev,
                  contest_name: e.target.value
                }))
              }
            />
            <DatePicker
              label="Contest Start Date"
              required
              value={_this.contestStartData?.contest_start_date}
              onChange={(e) =>
                _this.setContestStartData((prev) => ({
                  ...prev,
                  contest_start_date: e
                }))
              }
            />
            <DatePicker
              label="Contest End Date"
              required
              value={_this.contestStartData?.contest_end_date}
              onChange={(e) =>
                _this.setContestStartData((prev) => ({
                  ...prev,
                  contest_end_date: e
                }))
              }
            />
          </>
        ) : (
          <Input
            type="text"
            placeholder="Enter contest name..."
            label="Contest Name"
            required
            value={_this.contestName}
            onChange={(e) => _this.setContestName(e.target.value)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.ongoingContest?.contest == false
              ? _this.startContestInitial()
              : _this.stopContestByName();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StartStopModal;
