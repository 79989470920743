import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import {
  Card,
  Button,
  Input,
  Dropdown,
  DatePicker,
  Row,
  Checkbox,
  Table,
  ExpandableTable
} from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import { FaSyncAlt } from 'react-icons/fa';

const State = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const state = dataObject.state;
      const startSyclePatients = dataObject.startSyclePatients;
      const currentSyclePatients = dataObject.currentSyclePatients;
      const startActivePatients = dataObject.startActivePatients;
      const currentActivePatients = dataObject.currentActivePatients;
      const startActiveSubscriptions = dataObject.startActiveSubscriptions;
      const currentActiveSubscriptions = dataObject.currentActiveSubscriptions;
      const percHspp = dataObject.percHspp;
      const patientAdded = dataObject.patientAdded;
      const subscriptionAdded = dataObject.subscriptionAdded;

      const extractedData = {
        state: state,
        'Start Sycle Patients': startSyclePatients,
        'Current Sycle Patients': currentSyclePatients,
        'Start Active Patients': startActivePatients,
        'Current Active Patients': currentActivePatients,
        '% Hspp': percHspp,
        'Start Active Subscriptions': startActiveSubscriptions,
        'Current Active Subscriptions': currentActiveSubscriptions,
        'Patient Added': patientAdded,
        'Subscription Added': subscriptionAdded
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'ActiveHsppPatientReport' + fileExtension);
  };

  const columns = [
    {
      // accessor: 'state',
      // fixed: 'left',
      // headerStyle: { backgroundColor: '#474747', fontSize: '90%' },
      // style: { backgroundColor: '#C6C6C6' },
      name: 'State',
      cell: (row) => row?.state,
      selector: (row) => row['state'],
      sortable: true
    },

    {
      name: 'Start Sycle Patients',
      cell: (row) => row?.startSyclePatients,
      selector: (row) => row['startSyclePatients'],
      sortable: true
    },
    {
      name: 'Current Sycle Patinets',
      cell: (row) => <div>{row?.currentSyclePatients}</div>,
      selector: (row) => row['currentSyclePatients'],
      sortable: true
    },
    {
      name: 'Start Active Patients',
      cell: (row) => <div>{row?.startActivePatients}</div>,
      selector: (row) => row['startActivePatients'],
      sortable: true
    },
    {
      name: 'Current Active Patients',
      cell: (row) => <div>{row?.currentActivePatients}</div>,
      selector: (row) => row['currentActivePatients'],
      sortable: true
    },
    {
      name: '% HSPP',
      cell: (row) => <div>{row?.percHspp}</div>,
      selector: (row) => row['percHspp'],
      sortable: true
    },
    {
      name: 'Start Active Subscriptions',
      minWidth: 150,
      cell: (row) => <div>{row?.startActiveSubscriptions}</div>,
      selector: (row) => row['startActiveSubscriptions'],
      sortable: true
    },
    {
      name: 'Current Active Subscriptions',
      minWidth: 150,
      cell: (row) => <div>{row?.currentActiveSubscriptions}</div>,
      selector: (row) => row['currentActiveSubscriptions'],
      sortable: true
    },
    {
      name: 'Patient Added',
      cell: (row) => <div>{row?.patientAdded}</div>,
      selector: (row) => row['patientAdded'],
      sortable: true
    },
    {
      name: 'Subscription Added',
      selector: (row) => row['subscriptionAdded'],
      cell: (row) => <div>{row?.subscriptionAdded}</div>,
      width: '150px',
      sortable: true
    }
  ];
  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card className="flex mr-2 mt-2" paddingClass="px-1 py-2">
        <div className="flex justify-between items-center p-2">
          <div className="flex gap-2">
            <ButtonBTN
              onClick={() => exportReport()}
              className={`bg-button-primary  mb-2`}
              variant="primary"
              id="export"
            >
              Export
            </ButtonBTN>
            <ButtonBTN
              onClick={() => _this.syncReport()}
              className={`bg-button-primary mb-2 ml-2 flex justify-between items-center`}
              variant="primary"
              id="export"
              disabled={_this.inSync}
            >
              Sync <FaSyncAlt className={`ml-1 ${_this.inSync ? 'animate-spin' : ''}`} />
            </ButtonBTN>
          </div>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
        </div>
        <ExpandableTable
          keyField="_id"
          columnDefs={columns}
          rowData={_this.tableData}
          onRowExpanded={_this.onRowExpanded}
          // headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default State;
