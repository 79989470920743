import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop } from 'redux/action';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import { toast } from 'react-toastify';
import moment from 'moment';

const Index = () => {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [openRequestFormModal, setOpenRequestFormModal] = useState(false);
  const [isPatientIdLoading, setIsPatientIdLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [autoSuggestList, setAutosuggestList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [serialNumberOptions, setSerialNumberOptions] = useState([]);
  const [addSerialNumberModalVisibility, setAddSerialNumberModalVisibility] = useState(false);
  const [addSerialNumberData, setAddSerialNumberData] = useState({
    left: null,
    right: null
  });

  const [acceptOrDenyApprovalModal, setAcceptOrDenyApprovalModal] = useState(false);
  const [acceptOrDenyApprovalData, setAcceptOrDenyApprovalData] = useState(null);
  const [opsAndSycleData, setOpsAndSycleData] = useState(null);

  useEffect(() => {
    loadLocationOptions();
    init();
  }, []);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const updateCustomerDetails = (key, value) => {
    setCustomerDetails((state) => {
      return {
        ...state,
        // signature: `${customerDetails?.first_name} ${customerDetails?.last_name}`,
        [key]: value
      };
    });
  };

  const loadCustomerOptions = async (e) => {
    updateCustomerDetails('customer_id', e);
    if (e.length >= 3) {
      setIsPatientIdLoading(true);
      API.getCustomerById(e)
        .then((response) => {
          if (response.status) {
            const customerIdList = response.result.map(
              ({ patient_id, first_name, last_name, location, city }) => ({
                id: patient_id,
                name: patient_id,
                value: {
                  patient_id,
                  first_name,
                  last_name,
                  city,
                  location
                }
              })
            );
            setAutosuggestList(customerIdList);
          }
        })
        .finally(() => {
          setIsPatientIdLoading(false);
        });
    }
  };

  const loadLocationOptions = async () => {
    let response = await API.getFranchiseLocation();
    if (response) {
      const list = response.map((item) => ({
        label: item.locationName,
        value: item
      }));
      setLocationList(list);
    }
  };

  const loadSerialNumber = async (customerId) => {
    let response = await API.getLossAndDamageSerialById(customerId);
    if (response) {
      const list = response.serialNumbers.map((item) => ({
        label: `${item.ItemType}::${item.SerialNo} (${item.purchase_date})`,
        value: {
          item_type: item.ItemType,
          serial_number: item.SerialNo,
          purchase_date: item.purchase_date
        }
      }));
      setSerialNumberOptions(list);
    }
  };

  const updateCustomerDetailsBySelection = (e) => {
    if (e) {
      updateCustomerDetails('customer_id', e.value.patient_id);
      updateCustomerDetails('first_name', e.value.first_name);
      updateCustomerDetails('last_name', e.value.last_name);
      updateCustomerDetails('city', e.value.city);

      const location = locationList.filter((item) => item.value.locationId == e.value.location);
      if (location.length > 0) {
        updateCustomerDetails('location', location[0]);
      }

      setCustomerDetails((prev) => ({
        ...prev,
        serialNumber: null
      }));

      loadSerialNumber(e.value.patient_id);

      //updating location for patients location
    } else setCustomerDetails(null);
  };

  const onSelectingSerialNumber = (e) => {
    const temp = [...e];

    const temp_array = temp.map((item) => item.value.item_type);
    let left = 0;
    let right = 0;

    for (const item of temp_array) {
      if (item == 'Left') {
        left++;
      } else {
        right++;
      }
    }

    if (left < 2 && right < 2) {
      updateCustomerDetails('serialNumber', temp);
    } else {
      toast.error('Cannot add more than one left or more than one right serial number.');
    }
  };

  const submitAddSerialNumberData = () => {
    const temp = addSerialNumberData;

    let serial_number_array = [];

    if (temp.left) {
      const left_serial = {
        label: `Left::${temp.left.serial_number} (${moment(temp.left.purchase_date).format(
          'YYYY-MM-DD'
        )})`,
        value: {
          item_type: 'Left',
          serial_number: temp.left.serial_number,
          purchase_date: moment(temp.left.purchase_date).format('YYYY-MM-DD')
        }
      };

      serial_number_array.push(left_serial);
    }

    if (temp.right) {
      const right_serial = {
        label: `Right::${temp.right.serial_number} (${moment(temp.right.purchase_date).format(
          'YYYY-MM-DD'
        )})`,
        value: {
          item_type: 'Right',
          serial_number: temp.right.serial_number,
          purchase_date: moment(temp.right.purchase_date).format('YYYY-MM-DD')
        }
      };

      serial_number_array.push(right_serial);
    }

    setSerialNumberOptions([...serialNumberOptions, ...serial_number_array]);

    setAddSerialNumberModalVisibility(false);
    setAddSerialNumberData({
      left: null,
      right: null
    });
  };

  const init = async () => {
    dispatch(loadingStart());
    await API.getLossAndDamageApprovalReport()
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const submitRequestApprovalForm = () => {
    const temp = { ...customerDetails };
    let data = {};

    data.customer_id = temp.customer_id;
    (data.first_name = temp.first_name),
      (data.last_name = temp.last_name),
      (data.location_id = temp.location.value.locationId);
    data.type = temp.type.value;

    temp.serialNumber.map((item) => {
      if (item.value.item_type == 'Left') {
        data.left_serial_number = item.value.serial_number;
        data.left_purchase_date = item.value.purchase_date;
      }

      if (item.value.item_type == 'Right') {
        data.right_serial_number = item.value.serial_number;
        data.right_purchase_date = item.value.purchase_date;
      }
    });

    dispatch(loadingStart());
    API.requestApproval(data)
      .then((res) => {
        if (res) toast.success('Approval requested successfully ');
      })
      .finally(() => {
        dispatch(loadingStop());
        setOpenRequestFormModal(false), setCustomerDetails([]), setSerialNumberOptions([]);
        init();
      });
  };

  const getOpsAndSycleData = async (patientId) => {
    dispatch(loadingStart());
    API.getOPSAndSycleData(patientId)
      .then((res) => {
        if (res) setOpsAndSycleData(res);
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const submitAprroval = async () => {
    const data = {
      _id: acceptOrDenyApprovalData._id,
      notes: acceptOrDenyApprovalData.notes,
      status: acceptOrDenyApprovalData.accept ? 'approved' : 'denied'
    };

    dispatch(loadingStart());
    API.submitApproval(data)
      .then((res) => {
        if (res) toast.success(`${res.status} successfully.`);
      })
      .finally(() => {
        dispatch(loadingStop());
        setAcceptOrDenyApprovalModal(false),
          setAcceptOrDenyApprovalData(null),
          setOpsAndSycleData(null);
        init();
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          loadCustomerOptions,
          updateCustomerDetailsBySelection,
          tableData,
          openRequestFormModal,
          setOpenRequestFormModal,
          autoSuggestList,
          setAutosuggestList,
          isPatientIdLoading,
          customerDetails,
          serialNumberOptions,
          setSerialNumberOptions,
          updateCustomerDetails,
          setCustomerDetails,
          addSerialNumberModalVisibility,
          setAddSerialNumberModalVisibility,
          addSerialNumberData,
          setAddSerialNumberData,
          submitAddSerialNumberData,
          submitRequestApprovalForm,
          onSelectingSerialNumber,
          acceptOrDenyApprovalModal,
          setAcceptOrDenyApprovalModal,
          acceptOrDenyApprovalData,
          setAcceptOrDenyApprovalData,
          opsAndSycleData,
          setOpsAndSycleData,
          getOpsAndSycleData,
          submitAprroval,
          locationList,
          searchInput,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
