import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUploading: false
};

const uploadData = createSlice({
  name: 'uploadProgress',
  initialState: initialState,
  reducers: {
    setUploadProgress: (state, action) => {
      state.isUploading = action.payload;
    }
  }
});

export const uploadActions = uploadData.actions;
export default uploadData.reducer;
