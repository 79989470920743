import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Modal, Form, Alert } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { toast } from 'react-toastify';
import { AiFillCloseCircle } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr';
import moment from 'moment';
import { FaPlus, FaPlusCircle, FaPlusSquare } from 'react-icons/fa';
import { BiPlus, BiSend } from 'react-icons/bi';
const AddCredit = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      className="flex justify-center items-center min-h-screen"
      show={_this.creditModelOpen}
      onHide={() => {
        _this.setCreditModelOpen(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="flex justify-between">
        <Modal.Title id="contained-modal-title-vcenter" className="font-bold text-md">
          Credits
        </Modal.Title>
        <div className="flex gap-2 items-center">
          <h3 className="text-md">
            Patient Name :
            <strong>
              {_this.customerDetails?.first_name + ' ' + _this.customerDetails.last_name}
            </strong>
          </h3>
          <AiFillCloseCircle
            size={30}
            onClick={() => {
              _this.setCreditModelOpen(false);
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="m-0">
        <Card className=" ">
          <Alert variant="warning">
            {/* <Alert.Heading className="text-md">Alert</Alert.Heading> */}
            <p className="p-0">
              Insert positive value to Add credit and negative value to Deduct credit.
            </p>
          </Alert>
          <div className="">
            <Input
              label="Credit Balance "
              value={_this.creditBalance}
              onChange={(e) => _this.setCreditBalance(e.target.value)}
              type="number"
            />
            <Input
              label="Remarks"
              type="text"
              value={_this.creditRemarks}
              onChange={(e) => _this.setCreditRemarks(e.target.value)}
            />
            <Button onClick={_this.handelAddCredits}>Add</Button>
          </div>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default AddCredit;
