import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session).userSession;
  const [franchiseLocationData, setFranchiseLocationData] = useState();
  const [customersData, setCustomersData] = useState();
  const [revenueData, setRevenueData] = useState();
  const [subCategoriesFranchiseLocationOptions, setSubCategoriesFranchiseLocationOptions] =
    useState();
  const [selectedFieldsforCustomerInformation, setSelectedFieldsForCustomerInformation] = useState({
    key: '',
    value: ''
  });
  const [
    subCategoriesFranchiseLocationOptionsForRevenue,
    setSubCategoriesFranchiseLocationOptionsForRevenue
  ] = useState();

  const [selectedFieldsforRevenueInformation, setSelectedFieldsForRevenueInformation] = useState({
    key: '',
    value: ''
  });
  const [keysOfFranchiseLocationData, setKeysOfFranchiseLocationData] = useState({
    'Franchise Name': 'franchiseName',
    'Franchise Id': 'franchiseId',
    'Location Id': 'locationId',
    'Location Name': 'locationName',
    'Location Group': 'locationGroup',
    Total: 'total'
  });

  const [userLocationId, setUserLocationId] = useState([]);
  const [liveContestResult, setLiveContestResult] = useState([]);

  useEffect(() => {
    loadFranchiseLocationOptions();
  }, []);

  useEffect(() => {
    if (userLocationId.length > 0) {
      onFranchiseLocationOptionsForCustomerSubmit([]);
      onFranchiseLocationOptionsForRevenueSubmit([]);
    }
  }, [userLocationId]);

  useEffect(() => {
    fetchEmployeeContestRecord();
  }, []);
  const getSubCategoriesFnachiseLocationOptions = (selectedResult) => {
    if (selectedResult != 'total') {
      const data = franchiseLocationData?.map((item) => {
        return item[selectedResult];
      });
      const newData = [...new Set(data)];
      setSubCategoriesFranchiseLocationOptions(newData);
    } else {
      setSubCategoriesFranchiseLocationOptions();
    }
  };

  const getSubCategoriesFnachiseLocationOptionsForRevenue = (selectedResult) => {
    if (selectedResult != 'total') {
      const data = franchiseLocationData?.map((item) => {
        return item[selectedResult];
      });
      const newData = [...new Set(data)];
      setSubCategoriesFranchiseLocationOptionsForRevenue(newData);
    } else {
      setSubCategoriesFranchiseLocationOptionsForRevenue();
    }
  };

  const loadFranchiseLocationOptions = async () => {
    await API.getFranchiseLocation().then((res) => {
      if (res) {
        setFranchiseLocationData(res);
        const filteredIds = res
          ?.filter((loc) => user?.location?.includes(loc.locationName))
          .map((loc) => loc.locationId);

        setUserLocationId(filteredIds);
      }
    });
  };

  const onFranchiseLocationOptionsForCustomerSubmit = async (input) => {
    const location_ids = [];

    franchiseLocationData?.filter((item) => {
      if (
        input == 'Total' &&
        item[keysOfFranchiseLocationData[selectedFieldsforCustomerInformation.key]]
      ) {
        location_ids.push(item.locationId);
      } else if (
        item.franchiseName == input ||
        item.franchiseId == input ||
        item.locationId == input ||
        item.locationName == input ||
        item.locationGroup == input
      ) {
        location_ids.push(item.locationId);
      }
    });

    dispatch(loadingStart());
    API.getCustomerInformation(location_ids)
      .then((response) => {
        if (response) {
          setCustomersData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const onFranchiseLocationOptionsForRevenueSubmit = (input) => {
    const location_ids = [];

    franchiseLocationData?.filter((item) => {
      if (
        input == 'Total' &&
        item[keysOfFranchiseLocationData[selectedFieldsforRevenueInformation.key]]
      ) {
        location_ids.push(item.locationId);
      } else if (
        item.franchiseName == input ||
        item.franchiseId == input ||
        item.locationId == input ||
        item.locationName == input ||
        item.locationGroup == input
      ) {
        location_ids.push(item.locationId);
      }
    });

    dispatch(loadingStart());
    API.getRevenueInformation(location_ids.length > 0 ? location_ids : userLocationId)
      .then((response) => {
        if (response) {
          setRevenueData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const fetchEmployeeContestRecord = () => {
    API.fetchEmployeeDashboardContestRecord(user.location).then((response) => {
      if (response) {
        setLiveContestResult(response);
      }
    });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          keysOfFranchiseLocationData,
          getSubCategoriesFnachiseLocationOptions,
          subCategoriesFranchiseLocationOptions,
          onFranchiseLocationOptionsForCustomerSubmit,
          selectedFieldsforCustomerInformation,
          setSelectedFieldsForCustomerInformation,
          customersData,
          subCategoriesFranchiseLocationOptionsForRevenue,
          getSubCategoriesFnachiseLocationOptionsForRevenue,
          selectedFieldsforRevenueInformation,
          setSelectedFieldsForRevenueInformation,
          onFranchiseLocationOptionsForRevenueSubmit,
          revenueData,
          user,
          liveContestResult,
          setLiveContestResult
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
