import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';
import states from '../helpers/states.json';

const lossAndDamageApprovals = {
  getLossAndDamageSerialById: async (patientId) => {
    try {
      const response = await fetch(
        `${getMiracleAppURL()}v1/api/myhspp.com/hspp-approval.php?patientId=${patientId}`
      );
      const response_1 = await response.json();
      return response_1;
    } catch (err) {
      console.log('Error from server ' + err);
    }
  },

  getOPSAndSycleData: async (patientId) => {
    try {
      const response = await fetch(
        `${getMiracleAppURL()}v1/api/hspp/hspp-approval/ops_and_sycle_data.php?patient_id=${patientId}`
      );
      const response_1 = await response.json();
      return response_1;
    } catch (err) {
      console.log('Error from server ' + err);
    }
  },
  getLossAndDamageApprovalReport: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(
        '/v1/loss-and-damage-approvals/get-loss-and-damage-approval-report',

        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  requestApproval: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/loss-and-damage-approvals/request-approval', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  submitApproval: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/loss-and-damage-approvals/submit-approval', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default lossAndDamageApprovals;
