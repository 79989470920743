import React from 'react';
import { Card, Button } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import * as moment from 'moment';

const Section = ({ _this }) => {
  return <></>;
};

export default Section;
