import React from 'react';
import { Table } from 'components';
import moment from 'moment';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { Modal, Form } from 'react-bootstrap';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Body = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const active_invoice_date =
        dataObject.active_invoice_date &&
        moment(dataObject.active_invoice_date).format('YYYY-MM-DD');
      const amount = dataObject.amount;
      const expiry_date =
        dataObject.expiry_date && moment(dataObject.expiry_date).format('YYYY-MM-DD');
      const location = dataObject.location;
      const patient_id = dataObject.patient_id;
      const patient_name = dataObject.patient_name;
      const plan_name = dataObject.plan_name;
      const total_plan_frequency = dataObject.total_plan_frequency;
      const upgrade_to_plan_name = dataObject.upgrade_to_plan_name;

      const extractedData = {
        'Patient ID': patient_id,
        'Patient Name': patient_name,
        Location: location,
        'Plan Name': plan_name,
        Amount: amount,
        'Expiry Date': expiry_date,
        Frequency: total_plan_frequency,
        'Last Invoice Date': active_invoice_date,
        'Upgrade To Plan': upgrade_to_plan_name
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'ActiveHsppPatientReport' + fileExtension);
  };
  const columns = [
    {
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747', fontSize: '90%' },
      style: { backgroundColor: '#C6C6C6' },
      accessor: 'patient_id',
      Header: 'Patient Id',
      Cell: (row) => <div>{row.original.patient_id}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Patient Name',
      accessor: 'patient_name',
      Cell: (row) => <div>{row.original.patient_name}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Location',
      accessor: 'location',
      Cell: (row) => <div>{row.original.location}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Plan Name',
      accessor: 'plan_name',
      Cell: (row) => <div>{row.original.plan_name}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Amount',
      accessor: 'amount',
      Cell: (row) => <div>{row.original.amount}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Expiry Date',
      accessor: 'expiry_date',
      Cell: (row) => (
        <div>
          {row.original.expiry_date && moment(row.original.expiry_date).format('YYYY-MM-DD')}
        </div>
      )
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Frequency',
      accessor: 'total_plan_frequency',
      Cell: (row) => <div>{row.original.total_plan_frequency}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Last Invoice Date',
      accessor: 'active_invoice_date',
      Cell: (row) => (
        <div>
          {row.original.active_invoice_date &&
            moment(row.original.active_invoice_date).format('YYYY-MM-DD')}
        </div>
      )
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Upgrade To Plan',
      accessor: 'upgrade_to_plan_name',
      Cell: (row) => <div>{row.original.upgrade_to_plan_name}</div>
    }
  ];

  return (
    <div className="flex flex-col gap-1">
      <Card background={'bg-cardbg1'} className="">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between items-center">
          <div className="flex flex-col gap-2 sm:w-[30%]">
            <h3 className="text-body-color text-xl md:text-2xl">
              Active Hspp Patient History Report
            </h3>
            <h4 className="text-lg md:text-xl">Count: {_this.tableData.length}</h4>
            <input
              className="w-64 rounded-md"
              type="search"
              placeholder="Search.."
              value={_this.searchInput}
              onChange={(e) => _this.setSearchInput(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <DatePicker
              label="Start Date"
              required
              value={_this.activeHsppPatientHistoryDetails?.start_date || ''}
              onChange={(e) => {
                _this.setActiveHsppPatientHistoryDetails((prev) => ({
                  ...prev,
                  start_date: e
                }));
              }}
            />

            <DatePicker
              label="Current Date"
              required
              value={_this.activeHsppPatientHistoryDetails?.current_date || ''}
              onChange={(e) => {
                _this.setActiveHsppPatientHistoryDetails((prev) => ({
                  ...prev,
                  current_date: e
                }));
              }}
            />
          </div>
          <div className="flex flex-col">
            <Dropdown
              aria-label="Default select example"
              className="z-30 w-60"
              label="Search By"
              placeholder="@location"
              required
              type="text"
              value={_this?.activeHsppPatientHistoryDetails?.search_field}
              options={[
                { label: 'All', value: 'all' },
                { label: 'Location Name', value: 'location_name' },
                { label: 'Franchise Name', value: 'franchise_name' }
              ]}
              onChange={(e) => {
                _this.setActiveHsppPatientHistoryDetails((prev) => ({
                  ...prev,
                  search_field: e,
                  search_value: []
                }));
              }}
            />

            {_this.activeHsppPatientHistoryDetails.search_field.value === 'location_name' && (
              <Dropdown
                label="Search"
                className="z-20 w-60"
                placeholder="@select location name"
                type="text"
                options={_this.locationListForActiveHsppHistoryReport
                  ?.slice()
                  .sort((a, b) => a.label.localeCompare(b.label))}
                value={
                  _this.activeHsppPatientHistoryDetails.search_value.length > 0 &&
                  _this.activeHsppPatientHistoryDetails.search_value
                }
                onChange={(e) => {
                  _this.setActiveHsppPatientHistoryDetails((prev) => ({
                    ...prev,
                    search_value: e
                  }));
                }}
                aria-label="Location List"
                isMulti
              />
            )}
            {_this.activeHsppPatientHistoryDetails.search_field.value === 'franchise_name' && (
              <Dropdown
                label="Search"
                required
                className="z-20 w-60"
                placeholder="@select franchise name"
                type="text"
                options={_this.franchiseListForActiveHsppHistoryReport
                  ?.slice()
                  .sort((a, b) => a.label.localeCompare(b.label))}
                value={
                  _this.activeHsppPatientHistoryDetails.search_value.length > 0 &&
                  _this.activeHsppPatientHistoryDetails.search_value
                }
                onChange={(e) => {
                  _this.setActiveHsppPatientHistoryDetails((prev) => ({
                    ...prev,
                    search_value: e
                  }));
                }}
                aria-label="Franchise List"
                isMulti
              />
            )}
          </div>
          <div className="w-48 flex flex-col gap-4">
            <Button onClick={_this.init}>Generate Report</Button>
            <Button onClick={exportReport}>Export</Button>
          </div>
        </div>
      </Card>
      <Table
        keyField="_id"
        columns={columns}
        data={_this.tableData}
        headerComponent={null}
        minRows={20}
      />
    </div>
  );
};

export default Body;
