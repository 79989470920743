import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const [planList, setPlanList] = useState([]);
  const [deletePlanModalOpen, setDeletePlanModalOpen] = useState(false);
  const [deletePlanDetails, setDeletePlanDetails] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    dispatch(loadingStart());
    resetField();
    await API.getPlans()
      .then((response) => {
        if (response) {
          setPlanList(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const getHistory = async (id) => {
    dispatch(loadingStart());
    let res = [];
    await API.getPlanHistory(id)
      .then((response) => {
        if (response) {
          res = response;
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
    return res;
  };

  const resetField = () => {
    setDeletePlanDetails(null);
  };

  const goToDeletePlan = (plan) => {
    setDeletePlanDetails(plan);
    setDeletePlanModalOpen(true);
  };

  const deletePlan = async () => {
    dispatch(loadingStart());
    if (!deletePlanDetails._id) return toast.error('Error!');
    await API.deletePlan({ id: deletePlanDetails._id })
      .then((response) => {
        if (response) {
          init();
          setDeletePlanModalOpen(false);
          toast.success('Plan deleted Successfully!');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          planList,
          deletePlanModalOpen,
          setDeletePlanModalOpen,
          deletePlanDetails,
          goToDeletePlan,
          deletePlan,
          getHistory
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
