import React from 'react';
import { Card, Button, Table } from 'components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ButtonBTN from 'react-bootstrap/Button';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { IoEyeSharp } from 'react-icons/io5';
import { FaAngleLeft } from 'react-icons/fa';

import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import { FaSyncAlt } from 'react-icons/fa';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const hspp_id = dataObject.hspp_id;
      const customer_id = dataObject.customer_id;
      const message = dataObject.message;
      const name = dataObject.name;
      const location = dataObject.location;

      const extractedData = {
        'HSPP ID': hspp_id,
        'Customer ID': customer_id,
        Message: message,
        Name: name,
        Location: location
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'RetryPaymentsSummary' + fileExtension);
  };

  const columns = [
    {
      accessor: 'hspp_id',
      Header: 'HSPP ID',
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link
          to={`/customer-info/${row?.original?.customer_mongo_id}`}
          className="text-blue-500 font-medium"
        >
          #{row?.original?.hspp_id}
        </Link>
      )
    },
    {
      accessor: 'customer_id',
      Header: 'Patient ID',
      Cell: (row) => <div>{row?.original?.customer_id}</div>
    },
    {
      accessor: 'message',
      Header: 'Message',
      Cell: (row) => <div>{row?.original?.message}</div>
    },
    {
      accessor: 'name',
      Header: 'Patient Name',
      Cell: (row) => <div>{row?.original?.name}</div>
    },
    {
      accessor: 'location',
      Header: 'Location',
      Cell: (row) => <div>{row?.original?.location}</div>
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'} className="mb-3">
        <div className="flex flex-col lg:flex-row justify-between my-3">
          <h3 className="text-body-color text-2xl">Summary ({_this.tableData?.length})</h3>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />

          <ButtonBTN
            className={`bg-button-primary`}
            variant="primary"
            id="export"
            onClick={() => {
              exportReport();
            }}
          >
            Export
          </ButtonBTN>
          {/* {/* </div> */}
        </div>
      </Card>
      <div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this?.tableData}
          headerComponent={null}
          showPageJump={false}
          showPageSizeOptions={true}
        />
      </div>
    </>
  );
};

export default Section;
