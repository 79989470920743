import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import { BiEditAlt } from 'react-icons/bi';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const location_group = dataObject.location_group.toString();
      const category = dataObject.category;
      const contest_name = dataObject.contest_name;
      const contest_status = dataObject.contest_status;
      const patient_added = dataObject.patient_added;
      const hurdle_crossed = dataObject.hurdle_crossed;
      const prize_achieved = dataObject.prize_achieved;
      const achieved_on = moment(dataObject.achieved_on).format('YYYY-MM-DD');

      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        'Location Group': location_group,
        Category: category,
        'Contest Name': contest_name,
        'Contest Status': contest_status,
        'Patient Added': patient_added,
        'Hurdle Crossed': hurdle_crossed,
        'Prize Achieved': prize_achieved,
        'Achieved On': achieved_on
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'ContestAchievementReport' + fileExtension);
  };
  const columns = [
    {
      accessor: 'location_group',
      Header: 'Location Group',
      Cell: (row) => <div>{row?.original?.location_group}</div>
    },
    {
      accessor: 'category',
      Header: 'Category',
      Cell: (row) => <div>{row?.original?.category}</div>
    },
    {
      accessor: 'contest_name',
      Header: 'Contest Name',
      Cell: (row) => <div>{row?.original?.contest_name}</div>
    },
    {
      accessor: 'contest_status',
      Header: 'Contest Status',
      Cell: (row) => <div>{row?.original?.contest_status}</div>
    },
    {
      accessor: 'patient_added',
      Header: 'Patient Added',
      Cell: (row) => <div>{row?.original?.patient_added}</div>
    },
    {
      accessor: 'hurdle_crossed',
      Header: 'Hurdle Crossed',
      Cell: (row) => <div>{row?.original?.hurdle_crossed}</div>
    },
    {
      accessor: 'prize_achieved',
      Header: 'Prize Achieved',
      Cell: (row) => <div>{row?.original?.prize_achieved}</div>
    },
    {
      accessor: 'achieved_on',
      Header: 'Achieved On',
      Cell: (row) => <div>{moment(row?.original?.achieved_on).format('YYYY-MM-DD')}</div>
    }
  ];

  return (
    <>
      <Card className="flex mr-2 mt-2">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between mb-2">
          <h3 className="text-body-color text-xl font-semibold pb-2">Contest Achievement</h3>

          <input
            className="h-10"
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />

          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary h-10`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default Section;
