import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.session).userSession;
  const { state } = useLocation();
  const [locationList, setLocationList] = useState(null);
  const [droppedPatientsDetails, setDroppedPatientsDetails] = useState(
    state.droppedPatientsDetails
  );

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    init();
    loadLocationOptions();
  }, []);

  const loadLocationOptions = async () => {
    dispatch(loadingStart());
    await API.getFranchiseLocation()
      .then((response) => {
        if (response) {
          const list = response.map((item) => ({
            label: item.locationName,
            value: item
          }));
          let userLocationMatch = [];

          loggedInUser.location.forEach((elem) => {
            userLocationMatch.push(...list.filter((item) => item.value.locationName == elem));
          });

          if (loggedInUser.role !== 'Employee') {
            setLocationList(list);
            setDroppedPatientsDetails((prev) => ({
              ...prev,
              location: droppedPatientsDetails?.location || userLocationMatch
            }));
          } else {
            setLocationList(userLocationMatch);
            setDroppedPatientsDetails((prev) => ({
              ...prev,
              location: droppedPatientsDetails?.location || userLocationMatch
            }));
          }
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const init = () => {
    dispatch(loadingStart());
    API.getDroppedPatientsReport(droppedPatientsDetails)
      .then((response) => {
        if (response) {
          setTableData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData,
          droppedPatientsDetails,
          setDroppedPatientsDetails,
          locationList,
          init
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
