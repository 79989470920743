import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer, Table } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

const Index = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [activeHsppPatientReportBasedOnPlanName, setActiveHsppPatientReportBasedOnPlanName] =
    useState(state.activeHsppReportBasedOnPlanName);

  const [searchInput, setSearchInput] = useState('');

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const activeHsppPatientOptions = [
    { label: 'Location Name', value: 'location_name' },
    { label: 'Franchise Name', value: 'franchise_name' },
    { label: 'Location Group', value: 'location_group' },
    { label: 'State', value: 'state' },
    { label: 'Total', value: 'total' }
  ];

  useEffect(() => {
    init();
    // getActiveHsppStateLocationReport();
  }, [activeHsppPatientReportBasedOnPlanName.search_by]);
  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const init = () => {
    dispatch(loadingStart());
    API.getActiveHsppPatientReportBasedOnPLanName(activeHsppPatientReportBasedOnPlanName)
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          activeHsppPatientReportBasedOnPlanName,
          setActiveHsppPatientReportBasedOnPlanName,
          activeHsppPatientOptions,
          init,
          tableData,
          searchInput,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
