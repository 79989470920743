import React from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const DeleteSubscriptionModal = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this.deleteSubscriptionModalOpen}
      onHide={() => _this.setDeleteSubscriptionModalOpen(false)}
      // fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Alert</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setDeleteSubscriptionModalOpen(false)} />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          <h3 className="text-font-label text-lg">
            Are You sure you want to delete{' '}
            <span className="text-black">{_this.deleteSubscriptionData?.plan_name}</span>.
          </h3>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" onClick={() => _this.handleDeleteSubscription()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSubscriptionModal;
