import React from 'react';
import { Card, Button, Input, TextArea } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import { DatePicker } from 'components';
import { parseHsppId } from 'services/helpers';
import { BsSearch } from 'react-icons/bs';
import { Form } from 'react-bootstrap';

import InputGroup from 'react-bootstrap/InputGroup';
import ButtonBTN from 'react-bootstrap/Button';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Alert from 'react-bootstrap/Alert';
const Section = ({ _this }) => {
  return (
    <>
      {_this.patients?.length > 0 && (
        <Alert key={'danger'} variant={'primary'}>
          <strong className="mb-2">Email to:</strong>
          <div className="flex flex-wrap">
            {_this.patients?.map((item, index) => {
              return (
                <div key={index} className="flex px-3 py-1 gap-1">
                  <span>{index + 1}.</span>
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
        </Alert>
      )}

      <Card className="flex basis-1/2">
        <h3 className="text-body-color text-xl">Generate Email</h3>
        <Form className="bg-slate-100 mt-2 p-2 rounded">
          <Input
            label="Email Subject"
            placeholder="@subject"
            type="text"
            required
            value={_this.email.email_subject || ''}
            onChange={(e) =>
              _this.setEmail((prev) => ({
                ...prev,
                email_subject: e.target.value
              }))
            }
          />

          <TextArea
            label="Email Body"
            placeholder="@body"
            type="textArea"
            rows={20}
            required
            value={_this.email.email_body || ''}
            onChange={(e) =>
              _this.setEmail((prev) => ({
                ...prev,
                email_body: e.target.value
              }))
            }
          />
        </Form>
      </Card>
      <Card className="mt-2 mb-2" background={'bg-cardbg1'} paddingClass="px-4 py-3">
        <div className="flex flex-row justify-between items-center">
          <h3 className="text-body-color text-2xl"></h3>
          <div className="w-44">
            <Button onClick={() => _this.sendEmail()}>Submit</Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Section;
