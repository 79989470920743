import { Button, DashboardContainer } from 'components';
import React, { useState, useEffect } from 'react';
import Body from './Body';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import { FcShipped, FcInfo } from 'react-icons/fc';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import API from 'services/api';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import Config from 'config';
import { RiQuestionFill } from 'react-icons/ri';
const stripe_link =
  Config.ENVIRONMENT == 'DEV'
    ? 'https://dashboard.stripe.com/test/disputes/'
    : 'https://dashboard.stripe.com/disputes/';

const Index = () => {
  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const chargeback_table = [
    {
      Header: 'Patient ID',
      accessor: 'patient_id',
      minWidth: 120,
      Cell: (row) => (
        <Link
          to={`/customer-info/${row.original.hspp_id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 font-medium"
        >
          {row.original.patient_id}
        </Link>
      )
    },
    {
      Header: 'Dispute',
      accessor: 'dispute_id',
      minWidth: 100,
      Cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-top">
              <p>{row.original.dispute_id}</p>
            </Tooltip>
          }
        >
          <Link
            to={stripe_link + row.original.dispute_id}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 font-medium"
          >
            link
          </Link>
        </OverlayTrigger>
      )
    },
    {
      Header: 'Patient Name',
      accessor: 'patient_name',
      minWidth: 180,
      Cell: (row) => <div>{row?.original?.patient_name}</div>
    },
    {
      Header: 'Store Location',
      accessor: 'location_name',
      minWidth: 180,
      Cell: (row) => <div>{row?.original?.location_name}</div>
    },
    {
      Header: 'Dispute On',
      accessor: 'created',
      minWidth: 100,
      Cell: (row) => (
        <div>
          {row?.original?.data &&
            (JSON?.parse(row?.original?.data)?.created
              ? moment.unix(JSON?.parse(row?.original?.data)?.created)?.format('YYYY-MM-DD')
              : '--')}
        </div>
      )
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      minWidth: 150,
      Cell: (row) => <div className="capitalize">{row?.original?.reason}</div>
    },
    {
      Header: 'Status',
      minWidth: 200,
      accessor: 'status',
      Cell: (row) => (
        <div>
          {row?.original?.status && (
            <div className="capitalize">{row?.original?.status.replaceAll('_', ' ')}</div>
          )}
        </div>
      )
    },
    {
      Header: 'Document Uplodaded',
      accessor: 'has_document',
      Cell: (row) => <div className="capitalize">{row?.original?.has_document ? 'Yes' : 'No'}</div>
    },
    {
      Header: 'Send Email',
      accessor: '_id',
      minWidth: 150,
      Cell: (row) => (
        <button
          onClick={() => sendChargebackEmail(row?.original?._id)}
          className="bg-button-primary mt-3 hover:bg-button-primaryHover p-2 text-white rounded"
        >
          Send Email
        </button>
      )
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (row) => <div>$ {(row?.original?.amount / 100).toFixed(2)}</div>
    },
    {
      Header: 'Payment Method',
      minWidth: 180,
      sortable: false,
      Cell: (row) =>
        row.original?.payment_details?.payment_method && (
          <div>
            {row.original?.payment_details?.payment_method == 'check'
              ? 'Check : **' + row.original?.payment_details?.ach_details?.account_last_4_digit
              : 'Card : **' + row.original?.payment_details?.card_details?.card_last_4_digit}
          </div>
        )
    },
    {
      Header: 'Respond By',
      accessor: 'formattedDueDate',
      minWidth: 150,
      Cell: (row) => {
        const due_by = JSON.parse(row?.original?.data)?.evidence_details?.due_by;
        let formattedDueDate = '-',
          daysRemaining = -1;

        if (due_by) {
          let dueDate = moment.unix(due_by);
          const currentDate = moment();
          daysRemaining = dueDate.diff(currentDate, 'days');
          formattedDueDate = dueDate.format('MM-DD-YYYY');
        }
        return (
          <div className="flex justify-center items-center">
            {daysRemaining >= 0 ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-bottom">
                    <p>{daysRemaining} days to respond</p>
                  </Tooltip>
                }
              >
                <diV className="flex gap-2 justify-center items-center">
                  <span className="cursor-pointer">
                    <FcInfo size={20} />
                  </span>
                  <p>{formattedDueDate}</p>
                </diV>
              </OverlayTrigger>
            ) : (
              formattedDueDate
            )}
          </div>
        );
      }
    }
  ];
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [chargebackDetails, setChargebackDetails] = useState(state.chargebackDetails);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    init();
  }, [chargebackDetails.end_date]);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const init = () => {
    dispatch(loadingStart());
    API.getChargebackReport(chargebackDetails)
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const sendChargebackEmail = async (chargeback_id) => {
    dispatch(loadingStart());
    API.sendChargeBackEmail(chargeback_id)
      .then((response) => {
        if (response) {
          toast.success('Chargeback email sent.');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of tableData) {
      const patient_id = dataObject.patient_id;
      const patient_name = dataObject.patient_name;
      const location_name = dataObject?.location_name;
      const created = moment.unix(JSON?.parse(dataObject.data)?.created)?.format('YYYY-MM-DD');
      const dispute_id = dataObject.dispute_id;
      const reason = dataObject.reason;
      const status = dataObject.status;
      const amount = '$' + (dataObject.amount / 100).toFixed(2);
      const payment_method =
        dataObject?.payment_details?.payment_method == 'check'
          ? 'Check : **' + dataObject?.payment_details?.ach_details?.account_last_4_digit
          : 'Card : **' + dataObject?.payment_details?.card_details?.card_last_4_digit;
      const due_by = moment
        .unix(JSON?.parse(dataObject.data)?.evidence_details?.due_by)
        ?.format('YYYY-MM-DD');

      const extractedData = {
        'Patient ID': patient_id,
        'Patient Name': patient_name,
        'Store Location': location_name,
        'Dispute On': created,
        'Dispute ID': dispute_id,
        Reason: reason,
        Status: status,
        Amount: amount,
        'Payment Method': payment_method,
        'Respond By': due_by
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'Chargeback Report' + fileExtension);
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          chargebackDetails,
          setChargebackDetails,
          chargeback_table,
          init,
          tableData,
          searchInput,
          setSearchInput,
          exportReport
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
