import React, { useState } from 'react';
import { Modal, Button, Card, Alert } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';

const ExportCustomers = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      className="mt-4"
      show={_this.exportModalVisible}
      onHide={() => {
        _this.setExportModalVisible(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="flex justify-between">
        <Modal.Title id="contained-modal-title-vcenter" className="font-bold text-md">
          Export
        </Modal.Title>
        <div className="flex gap-2 items-center">
          <AiFillCloseCircle
            size={30}
            onClick={() => {
              _this.setExportModalVisible(false);
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Card className="p-3">
          <div className="grid-cols-1 grid md:grid-cols-3">
            {_this.exportDataFields.map((field) => (
              <div key={field.name}>
                <div className="flex items-center gap-2 mb-2">
                  <input
                    type="checkbox"
                    id={field.name}
                    checked={_this.checkedFields[field.name]}
                    onChange={() => _this.toggleCheckbox(field.name)}
                  />
                  <label htmlFor={field.name}>{field.displayName}</label>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => _this.handelExportClick()} className="bg-primary">
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportCustomers;
