import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
const Index = () => {
  const dispatch = useDispatch();
  const [selectCategory, setSelectCategory] = useState({ label: 'Tier 1', value: 'tier_1' });
  const [locationList, setLocationList] = useState([]);
  const [selectLocationGroup, setSelectLocationGroup] = useState({ label: 'All', value: 'All' });
  const [tableData, setTableData] = useState([]);
  const [addContestPrizeModal, setAddContestPrizeModal] = useState(false);
  const [addContestPrizeData, setAddContestPrizeData] = useState(null);
  const [editContestPrizeModal, setEditContestPrizeModal] = useState(false);
  const [editContestPrizeData, setEditContestPrizeData] = useState(null);

  useEffect(() => {
    init();
    loadLocationGroup();
  }, [selectCategory, selectLocationGroup]);

  const loadLocationGroup = async () => {
    // dispatch(loadingStart());
    await API.fetchLocationGroup().then((response) => {
      if (response) {
        const list = response.map((item) => ({
          label: item.location_group,
          value: item.location_group
        }));
        setLocationList(list);
      }
    });
  };

  const init = async () => {
    const category = selectCategory.value;
    const location_group = selectLocationGroup.value;
    dispatch(loadingStart());
    await API.fetchContestPrizes(category, location_group)
      .then((response) => {
        if (response) {
          setTableData(response);
          // setEmployeeDetails(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const addContestPrize = async () => {
    console.log(addContestPrizeData);

    dispatch(loadingStart());
    await API.addContestPrize(addContestPrizeData)
      .then((response) => {
        if (response) {
          toast.success('Prize added successfully');
          init();
          setAddContestPrizeModal(false);
          setAddContestPrizeData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const editContestPrize = async () => {
    console.log(editContestPrizeData);
    dispatch(loadingStart());
    await API.editContestPrize(editContestPrizeData)
      .then((response) => {
        if (response) {
          toast.success('Prize edited successfully');
          init();
          setEditContestPrizeModal(false);
          setEditContestPrizeData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };
  return (
    <DashboardContainer>
      <Body
        _this={{
          selectCategory,
          setSelectCategory,
          tableData,
          setTableData,
          selectLocationGroup,
          setSelectLocationGroup,
          locationList,
          addContestPrizeModal,
          setAddContestPrizeModal,
          addContestPrizeData,
          setAddContestPrizeData,
          addContestPrize,
          editContestPrizeModal,
          setEditContestPrizeModal,
          editContestPrizeData,
          setEditContestPrizeData,
          editContestPrize
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
