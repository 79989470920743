import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import customer from 'services/api/customer';

const Index = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session).userSession;
  // console.log('user', user);
  const customer_id = user?._id;
  const [customerDetails, setCustomerDetails] = useState(null);
  const [subscriptionDetailsModalOpen, setSubscriptionDetailsModalOpen] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [achPaymentVisibility, setACHPaymentVisibility] = useState(true);
  const [selectPrimaryPaymentModalOpen, setSelectPrimaryPaymentModalOpen] = useState(false);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState({
    payment_method_id: '',
    customer_id: '',
    card_number: '',
    payment_method: ''
  });
  const [cardDetails, setCardDetails] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  });
  const [achDetails, setACHDetails] = useState({
    name: '',
    routing_number: '',
    account_number: ''
  });

  const updateCardDetails = (key, value) => {
    setCardDetails((state) => {
      return {
        ...state,
        [key]: value
      };
    });
    setCardDetails((state) => {
      return {
        ...state,
        focused: key
      };
    });
  };

  const updateACHDetails = (key, value) => {
    setACHDetails((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };
  const [documentModalOpen, setDocumentModelOpen] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [fileAttachment, setFileAttachment] = useState(null);
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (customerDetails) {
      updateCardDetails('name', customerDetails?.first_name + ' ' + customerDetails?.last_name);

      updateACHDetails('name', customerDetails?.first_name + ' ' + customerDetails?.last_name);
    }
  }, [customerDetails]);

  const init = async () => {
    dispatch(loadingStart());
    await API.getMyDetailsById()
      .then((response) => {
        if (response) {
          setCustomerDetails(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const validateValues = (fields = [], arr) => {
    for (const field of fields) {
      if (!arr[field]) return false;
    }
    return true;
  };

  //payload for Add New Payment
  const finalDataSubmitforAddNewPayment = () => {
    const card_details = { ...cardDetails, number: cardDetails?.number.toString() };
    const ach_details = { ...achDetails };

    if (
      !validateValues(['cvc', 'expiry', 'name', 'number'], card_details) &&
      !validateValues(['name', 'account_number', 'routing_number'], ach_details)
    )
      return toast.error('Required payment detail fields cannot be blank!');

    const card_temp = cardDetails.expiry.split('/');

    const finalPaymentData = {
      customer_id: customerDetails?._id,

      card_details: {
        card_last_4_digit: card_details.number.substring(card_details.number.length - 4),
        expiry_month: card_temp[0],
        expiry_year: card_temp[1],
        expiry_date: cardDetails.expiry,
        name: card_details.name,
        cvv: cardDetails.cvc,
        card_number: cardDetails.number
      },
      ach_details: {
        account_last_4_digit: ach_details.account_number.substring(
          ach_details.account_number.length - 4
        ),
        account_number: ach_details.account_number,
        name: ach_details.name,
        routing_number: ach_details.routing_number
      }
    };

    return addPaymentMethod(finalPaymentData);
  };
  //add Payment method api call
  const addPaymentMethod = async (paymentData) => {
    dispatch(loadingStart());
    await API.addNewPayment(paymentData)
      .then((response) => {
        if (response) {
          toast.success('New Payment Method Added!');
          setAddPaymentModalOpen(false);
          init();
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  //Select Primary Payment Method

  const selectPrimaryPaymentMethod = async () => {
    const data = {
      customer_id: selectedPaymentDetails.customer_id,
      payment_method_id: selectedPaymentDetails.payment_method_id
    };
    dispatch(loadingStart());
    await API.selectPrimaryPaymentMethod(data)
      .then((response) => {
        if (response) {
          setSelectPrimaryPaymentModalOpen(false);
          init();
          toast.success('Payment method updated successfully');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };
  const getDocuments = async () => {
    const data = {
      id: customerDetails._id
    };
    console.log(data);
    await API.GetDocuments(data).then((response) => {
      setDocuments(response);
    });
  };

  const handelChatMessageSend = async () => {
    if (!fileAttachment || userMessage == '')
      toast.error('Please Select Attachment and Enter Message !');
    else {
      dispatch(loadingStart());
      const data = {
        customer_id: customerDetails?._id,
        message: userMessage,
        file: fileAttachment
      };
      await API.SendDocuments(data)
        .then(() => {
          setUserMessage('');
          setFileAttachment(null);
          setUserMessage('');
        })
        .finally(() => {
          init();
          dispatch(loadingStop());
        });
    }
  };
  return (
    <DashboardContainer>
      <Body
        _this={{
          user,
          customerDetails,
          subscriptionDetailsModalOpen,
          setSubscriptionDetailsModalOpen,
          subscriptionDetails,
          setSubscriptionDetails,
          addPaymentModalOpen,
          setAddPaymentModalOpen,
          achPaymentVisibility,
          setACHPaymentVisibility,
          updateCardDetails,
          updateACHDetails,
          cardDetails,
          achDetails,
          finalDataSubmitforAddNewPayment,
          selectPrimaryPaymentModalOpen,
          setSelectPrimaryPaymentModalOpen,
          selectedPaymentDetails,
          setSelectedPaymentDetails,
          selectPrimaryPaymentMethod,
          documentModalOpen,
          setDocumentModelOpen,
          userMessage,
          setUserMessage,
          fileAttachment,
          setFileAttachment,
          handelChatMessageSend,
          // getDocuments,
          documents
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
