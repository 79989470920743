import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import ButtonBTN from 'react-bootstrap/Button';

const Body = ({ _this }) => {
  return (
    <div>
      <Card background={'bg-cardbg1'} className="">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between items-center">
          <h3 className="text-body-color text-xl md:text-2xl">Chargeback Report</h3>

          <DatePicker
            label="Start Date"
            required
            value={_this.chargebackDetails?.start_date || ''}
            onChange={(e) => {
              _this.setChargebackDetails((prev) => ({
                ...prev,
                start_date: e
              }));
            }}
          />

          <DatePicker
            label="End Date"
            required
            value={_this.chargebackDetails?.end_date || ''}
            onChange={(e) => {
              _this.setChargebackDetails((prev) => ({
                ...prev,
                end_date: e
              }));
            }}
          />
          <button
            className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
            onClick={_this.init}
          >
            Generate Report
          </button>
        </div>
      </Card>
      <Card className="mt-2" paddingClass="p-1">
        <div className="flex justify-between items-center p-1">
          <ButtonBTN
            onClick={() => _this.exportReport()}
            className={`bg-button-primary  mb-2`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
        </div>
        <Table
          keyField="id"
          columns={_this.chargeback_table}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
          defaultPageSize={20}
        />
      </Card>
    </div>
  );
};

export default Body;
