import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import './style.scss';
/**
 * ExpandableTable is a component created on top of DataTable
 * {object} props Other Component props for DataTable
 * {object} columnDefs Column defination for the table
 * {object} rowData Data array for the table
 * {function} onRowExpanded Callback on row toggled and expanded
 */
const ExpandableTable = ({ columnDefs, rowData, onRowExpanded, onRowClicked, ...props }) => {
  const renderer = ({ data }) => {
    const ChildComponent = data.child_component
      ? data.child_component
      : () => {
          return <p>No rows found</p>;
        };
    return (
      <div style={{ backgroundColor: '#F2F9FD', padding: 10, overflow: 'auto' }}>
        <ChildComponent tableData={data.expanded_data} />
      </div>
    );
  };

  return (
    <DataTable
      data={rowData}
      columns={columnDefs}
      expandableRows={true}
      subHeaderWrap={true}
      //expandableRowExpanded={(row) => row.expanded}
      expandableRowsComponent={renderer}
      // expandableRowsComponent={ExpandedComponent}
      onRowExpandToggled={(isExpanded, row) => {
        if (isExpanded) onRowExpanded(row);
      }}
      onRowClicked={onRowClicked}
      className="react_table_custom_component"
    />
  );
};

export default ExpandableTable;
