import React from 'react';
import { Card, Button } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import { DatePicker } from 'components';
import { parseHsppId } from 'services/helpers';
import { BsSearch } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonBTN from 'react-bootstrap/Button';
import { PermissionWrapper, HasPermission } from 'components';

const Section = ({ _this }) => {
  const columns = [
    {
      accessor: '_id',
      Header: 'HSPP ID',
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link to={`/customer-info/${row.original._id}`} className="text-blue-500 font-medium">
          #{row.original.hspp_id}
        </Link>
      )
    },
    {
      accessor: 'customer_id',
      Header: 'Patient ID'
    },
    {
      accessor: '',
      Header: 'Patient Name',
      Cell: (row) => <div>{row.original.first_name + ' ' + row.original.last_name}</div>
    },
    {
      accessor: 'active_plans',
      Header: 'Active Plans'
    },
    {
      Header: 'Number Of Subscription',
      accessor: 'subscriptions'
    },
    {
      Cell: (row) => <div>{moment(row.original.createdAt).format('YYYY-MM-DD')}</div>,
      Header: 'Created At'
    }
  ];
  if (HasPermission({ permission: ['restore_customer'], require_all: false }))
    columns.push({
      Cell: (row) => (
        <div className="p-1 w-full flex justify-center items-center">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-2 py-1.5 rounded text-xs flex flex-row self-center w-24 justify-center items-center"
            onClick={() => _this.RestoreCustomer(row.original._id)}
          >
            Restore
          </button>
        </div>
      ),
      Header: 'Action'
    });

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-2xl">Deleted Customer List</h3>
        </div>
        <div className="flex justify-center items-center mt-2">
          <InputGroup
            className={` rounded-md ${
              _this.search.searchField === 'createdAt' ? 'w-6/12' : 'w-96'
            } border-2 focus:border-button-primary`}
          >
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                _this.setSearch((prev) => ({
                  ...prev,
                  searchField: _this.searchFieldOption[e.target.value]
                }));
                if (_this.searchFieldOption[e.target.value] != 'Created At') {
                  _this.setSearchFromDate('');
                  _this.setSearchToDate('');
                  _this.setSearch((prev) => ({
                    ...prev,
                    searchInput: ''
                  }));
                }
              }}
            >
              <option>Select Parameters</option>
              {Object.keys(_this.searchFieldOption).map((item, index) => (
                <option key={index} className="text-lg">
                  {item}
                </option>
              ))}
            </Form.Select>
            {_this.search.searchField === 'createdAt' ? (
              <>
                <DatePicker
                  // label="From"
                  value={_this.searchFromDate || ''}
                  onChange={(e) => _this.setSearchFromDate(e)}
                  onCalendarOpen={null}
                />
                <DatePicker
                  // label="To"
                  value={_this.searchToDate || ''}
                  onChange={(e) => _this.setSearchToDate(e)}
                  onCalendarOpen={null}
                />
              </>
            ) : (
              <Form.Control
                placeholder="...Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                type="search"
                className="rounded-md"
                value={_this.search.searchInput}
                onChange={(e) =>
                  _this.setSearch((prev) => ({
                    ...prev,
                    searchInput: e.target.value
                  }))
                }
              />
            )}
            <ButtonBTN
              onClick={() => _this.searchData()}
              className={`bg-button-primary`}
              variant="primary"
              id="button-addon3"
            >
              <BsSearch size={24} />
            </ButtonBTN>
          </InputGroup>
        </div>
      </Card>
      <Card className="flex mt-2" paddingClass="p-2">
        <div className="rounded">
          <Table
            keyField="id"
            columns={columns}
            data={_this.searchTableData}
            headerComponent={null}
          />
        </div>
      </Card>
    </>
  );
};

export default Section;
