import React from 'react';
import { Table } from 'components';
import moment from 'moment';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { Modal, Form } from 'react-bootstrap';
import FranchiseName from './FranchiseName';
import LocationName from './LocationName';
import LocationGroup from './LocationGroup';
import Total from './Total';
import State from './State';
const Body = ({ _this }) => {
  return (
    <div className="flex flex-col gap-1">
      <Card background={'bg-cardbg1'} className="">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between items-center">
          <h3 className="text-body-color text-xl md:text-2xl">Active Hspp Patient Report</h3>

          <DatePicker
            label="Start Date"
            required
            value={_this.activeHsppPatientDetails?.start_date || ''}
            onChange={(e) => {
              _this.setActiveHsppPatientDetails((prev) => ({
                ...prev,
                start_date: e
              }));
            }}
          />

          <DatePicker
            label="Current Date"
            required
            value={_this.activeHsppPatientDetails?.current_date || ''}
            onChange={(e) => {
              _this.setActiveHsppPatientDetails((prev) => ({
                ...prev,
                current_date: e
              }));
            }}
          />

          <Dropdown
            label="Search By"
            className="w-48 z-10"
            placeholder="@location"
            required
            type="text"
            options={_this.activeHsppPatientOptions}
            aria-label="Default select example"
            value={_this.activeHsppPatientDetails.search_by}
            onChange={(e) => {
              _this.setActiveHsppPatientDetails((prev) => ({
                ...prev,
                search_by: e
              }));
            }}
          />

          <button
            className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
            onClick={_this.init}
          >
            Generate Report
          </button>
        </div>
      </Card>
      {_this.activeHsppPatientDetails.search_by.value == 'location_name' && (
        <LocationName _this={_this} />
      )}
      {_this.activeHsppPatientDetails.search_by.value == 'location_group' && (
        <LocationGroup _this={_this} />
      )}
      {_this.activeHsppPatientDetails.search_by.value == 'franchise_name' && (
        <FranchiseName _this={_this} />
      )}
      {_this.activeHsppPatientDetails.search_by.value == 'state' && <State _this={_this} />}
      {_this.activeHsppPatientDetails.search_by.value == 'total' && <Total _this={_this} />}
    </div>
  );
};

export default Body;
