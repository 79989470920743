import React from 'react';
import { Pie } from 'react-chartjs-2';

import { Chart as ChartJs, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJs.register(Tooltip, Legend, ArcElement);
const PieChart = ({ data, onClick }) => {
  const options = {
    maintainAspectRatio: false,
    onClick: onClick
  };

  return <Pie options={options} data={data} />;
};

export default PieChart;
