import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [operateStartStopContestModal, setOperateStartStopContestModal] = useState(false);
  const [selectCategory, setSelectCategory] = useState({ label: 'Tier 1', value: 'tier1' });
  const [tableData, setTableData] = useState([]);
  const [ongoingContest, setOngoingContest] = useState(null);
  const [showStartStopModal, setShowStartStopModal] = useState(false);
  const [contestName, setContestName] = useState('');
  const [contestDate, setContestDate] = useState(moment().toDate());
  const [contestStartData, setContestStartData] = useState({
    contest_name: '',
    contest_start_date: moment().toDate(),
    contest_end_date: ''
  });

  useEffect(() => {
    fetchIsThereAnyOngoingContest();
  }, []);

  const fetchIsThereAnyOngoingContest = () => {
    dispatch(loadingStart());
    API.fetchIsThereAnyOngoingContest()
      .then((result) => {
        if (result) setOngoingContest(result);
        setContestName(result.contest_name);
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const startContestInitial = async () => {
    if (
      !contestStartData.contest_name.trim() ||
      !contestStartData.contest_start_date ||
      !contestStartData.contest_start_date
    ) {
      return toast.error('Please enter the required value');
    }

    dispatch(loadingStart());
    await API.startInitialContest(contestStartData)
      .then((result) => {
        if (result) toast.success('Contest has been stared.');
        setContestStartData(null);
      })
      .finally(() => {
        dispatch(loadingStop());
      });

    setContestName('');
    setContestDate(moment().toDate());
    setShowStartStopModal(false);
    fetchIsThereAnyOngoingContest();
  };

  const stopContestByName = async () => {
    if (!contestName.trim()) {
      return toast.error('Please enter the name of the contest');
    }

    dispatch(loadingStart());
    await API.stopContestByName(contestName)
      .then((result) => {
        if (result) {
          toast.success('Contest has been stopped.');
          setOngoingContest(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });

    setContestName('');
    setContestDate(moment().toDate());
    setShowStartStopModal(false);
    fetchIsThereAnyOngoingContest();
  };

  const handleContestPrizeList = () => {
    navigate('/contest-prize');
  };

  const handleLocationGroupContestReport = () => {
    navigate('/location-group-contest');
  };

  const handleOngoingContestRecord = () => {
    navigate('/ongoing-contest-record');
  };

  const handleAchievementContest = () => {
    navigate('/contest-achievement');
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          handleLocationGroupContestReport,
          handleContestPrizeList,
          handleOngoingContestRecord,
          operateStartStopContestModal,
          setOperateStartStopContestModal,
          ongoingContest,
          startContestInitial,
          stopContestByName,
          showStartStopModal,
          setShowStartStopModal,
          setContestDate,
          setContestName,
          contestDate,
          contestName,
          handleAchievementContest,
          contestStartData,
          setContestStartData
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
