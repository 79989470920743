import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer, Table } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

const Index = () => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    init();
    // getActiveHsppStateLocationReport();
  }, []);
  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const init = () => {
    dispatch(loadingStart());
    API.getSubscriptionPlanChangeReport()
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          // init,
          tableData,
          searchInput,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
