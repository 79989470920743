import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const Component = ({ label = '', controlId = '', type = '', ...props }) => {
  return (
    <Form.Group className="mb-2" controlId={controlId}>
      <Form.Check
        className="text-sm text-font-inputText tracking-wide"
        type={type}
        label={label}
        {...props}
      />
    </Form.Group>
  );
};

Component.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  type: PropTypes.string
};

export default Component;
