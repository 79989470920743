import { Button, Card, Dropdown, Input, TextArea } from 'components';
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const Body = ({ _this }) => {
  useEffect(() => {
    console.log(_this.selectedLocations);
  }, [_this.selectedLocations]);
  useEffect(() => {
    console.log(_this.selectedFranchise);
  }, [_this.selectedFranchise]);

  return (
    <>
      <Card background="">
        <h3 className="text-body-color text-2xl">Employees based on Location and Franchise</h3>
        <div className="text-center my-3 flex justify-center gap-10">
          <Dropdown
            className="w-64"
            label="Franchise"
            placeholder="@franchise"
            required
            type="text"
            options={_this.franchiseList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
            aria-label="Default select example"
            value={_this?.selectedFranchise}
            onChange={(e) => _this.setSelectedFranchise(e)}
            isMulti
          />

          <Dropdown
            className="w-64"
            label="Location Name"
            placeholder="@location"
            required
            type="text"
            options={_this.locationList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
            aria-label="Default select example"
            value={_this?.selectedLocations}
            onChange={(e) => _this.setSelectedLocations(e)}
            isMulti
          />
        </div>

        <Form className="bg-slate-100 mt-2 p-2 rounded">
          <Input
            label="Email Subject"
            placeholder="@subject"
            type="text"
            required
            value={_this?.mailData?.subject || ''}
            onChange={(e) =>
              _this?.setMailData((prev) => ({
                ...prev,
                subject: e.target.value
              }))
            }
          />

          <TextArea
            label="Email Body"
            placeholder="@body"
            type="textArea"
            rows={20}
            required
            value={_this?.mailData?.body || ''}
            onChange={(e) =>
              _this?.setMailData((prev) => ({
                ...prev,
                body: e.target.value
              }))
            }
          />

          <div className="w-52 my-2 mx-auto">
            <Button onClick={() => _this.sendEmail()}>Submit</Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default Body;
