import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { logout, toggleMenu } from 'redux/action';
import classNames from 'classnames';

/**
 * Receives 3 params named permission, children, require_all
 * permission can be string / array of string
 * if we need to maatch multiple permission then params will be permmission={['edit_role','manage_role']}
 * if we need to match atleast 1 permission then params will be permmission={['edit_role','manage_role']} and require_all={false}
 */
const Component = ({ permission, children, require_all = true, ...props }) => {
  const { userSession } = useSelector((state) => state.session);
  const checker = require_all
    ? (arr, target) => target.every((v) => arr.includes(v))
    : (arr, target) => {
        return arr.filter((value) => target.includes(value)).length > 0 ? true : false;
      };

  if (checker(userSession?.permissions, typeof permission === 'string' ? [permission] : permission))
    return children;
  else return <div></div>;
};

export const HasPermission = ({ permission, require_all = true }) => {
  const { userSession } = useSelector((state) => state.session);
  const checker = require_all
    ? (arr, target) => target.every((v) => arr.includes(v))
    : (arr, target) => {
        return arr.filter((value) => target.includes(value)).length > 0 ? true : false;
      };

  if (checker(userSession?.permissions, typeof permission === 'string' ? [permission] : permission))
    return true;
  else return false;
};

export default Component;
