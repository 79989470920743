import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();

  const [employeeList, setEmployeeList] = useState([]);
  const [locationList, setLocationList] = useState();
  const [roleList, setRoleList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [editEmployeeModalVisibility, setEditEmployeeModalVisibility] = useState(false);
  const [editEmployeeData, setEditEmployeeData] = useState({
    name: '',
    email: '',
    location: [],
    user_id: '',
    role_id: {}
  });
  const [deleteEmployeeModalVisibility, setDeleteEmployeeModalVisibility] = useState(false);
  const [deleteEmployeeData, setDeleteEmployeeData] = useState({
    user_id: '',
    name: ''
  });

  useEffect(() => {
    loadLocationOptions();
  }, []);

  useEffect(() => {
    getEmployeeData();
  }, []);

  useEffect(() => {
    loadRoleOptions();
  }, []);

  useEffect(() => {
    if (employeeList) {
      setDuplicateEmployeeData();
    }
  }, [employeeList]);

  const loadLocationOptions = async () => {
    let response = await API.getFranchiseLocation();
    if (response) {
      const list = response.map((item) => ({
        label: item.locationName,
        value: item
      }));
      setLocationList(list);
    }
  };

  const loadRoleOptions = async () => {
    let response = await API.getEmployeeRoleList();
    if (response) {
      const list = response.map((item) => ({
        label: item.role_name,
        value: {
          role_id: item._id,
          role_name: item.role_name
        }
      }));
      setRoleList(list);
    }
  };

  const setDuplicateEmployeeData = () => {
    // Create a map to group employees by  name.
    const employeeMap = new Map();

    for (const employee of employeeList) {
      if (!employeeMap.has(employee.name)) {
        employeeMap.set(employee.name, []);
      }
      employeeMap.get(employee.name).push(employee);
    }

    // Generate the final output
    const output = [];
    for (const [name, employeeData] of employeeMap.entries()) {
      const _ids = employeeData.map((employee) => employee._id).join(', ');
      const locations = employeeData.map((employee) => employee.location).join(', ');
      const user_id = employeeData
        .map((employee) => employee.user_id)
        .join(', ')
        .split(',');
      const roles = [...new Set(employeeData.map((employee) => employee.role))].join(', ');
      const emails = [...new Set(employeeData.map((employee) => employee.email))].join(', ');
      const statuses = [...new Set(employeeData.map((employee) => employee.status))].join(', ');
      const createdAt = [...new Set(employeeData.map((employee) => employee.createdAt))].join(', ');

      output.push({
        _id: _ids,
        name: name,
        location: locations,
        role: roles,
        status: statuses,
        createdAt: createdAt,
        email: emails,
        user_id: user_id[0]
      });
    }

    setTableData(output);
  };

  const onSelectingLocation = (locationName) => {
    let temp = [...locationName];
    setEditEmployeeData((prev) => ({
      ...prev,
      location: temp
    }));
  };

  const editLocationField = (location) => {
    let temp = [];

    location.forEach((element) => {
      const filteredValues = locationList.filter((item) =>
        element.includes(item.value.locationName)
      );

      temp.push(filteredValues[0]);
    });

    setEditEmployeeData((prev) => ({
      ...prev,
      location: temp
    }));
  };

  const handelSearchInput = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value == '') {
      getEmployeeData('');
    }
    const filteredResults = tableData.filter((employee) =>
      Object.values(employee).join(', ').toLowerCase().includes(e.target.value.toLowerCase())
    );

    setTableData(filteredResults);
  };

  const handelSearch = (e) => {
    e.preventDefault();
    getEmployeeData(searchInput);
  };

  const handelEmailSend = async (row) => {
    dispatch(loadingStart());
    await API.sendPassword(row.original.user_id)
      .then((response) => {
        if (response) {
          toast.success('Credentials Sent Successfully.');
        } else {
          toast.error('Something Went Wrong ! ');
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const getEmployeeData = async (employeeName) => {
    dispatch(loadingStart());
    await API.getEmployeeList(employeeName)
      .then((response) => {
        if (response) {
          setEmployeeList(response);
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const changeEmployeeStatus = async ({ status, user_id }) => {
    status == true ? (status = 'Enabled') : (status = 'Disabled');
    await API.changeEmployeeStatus({ status: status, user_id: user_id }).then((response) => {
      if (response) {
        getEmployeeData();
      }
    });
  };

  const editDataSubmit = async () => {
    const temp = { ...editEmployeeData };
    temp.role_id = editEmployeeData.role_id.value.role_id;
    dispatch(loadingStart());
    await API.editEmployeeData(temp)
      .then((response) => {
        if (response) {
          toast.success('Employee updated successfully');
          setEditEmployeeModalVisibility(false);
          getEmployeeData();
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const deleteEmployeeSubmit = async () => {
    dispatch(loadingStart());
    await API.deleteEmployee(deleteEmployeeData)
      .then((response) => {
        if (response) {
          toast.success('Employee deleted successfully');
          setDeleteEmployeeModalVisibility(false);
          getEmployeeData();
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          locationList,
          setLocationList,
          editEmployeeData,
          setEditEmployeeData,
          tableData,
          changeEmployeeStatus,
          searchInput,
          handelSearchInput,
          handelSearch,
          handelEmailSend,
          deleteEmployeeModalVisibility,
          setDeleteEmployeeModalVisibility,
          editEmployeeModalVisibility,
          setEditEmployeeModalVisibility,
          editLocationField,
          onSelectingLocation,
          editDataSubmit,
          deleteEmployeeData,
          setDeleteEmployeeData,
          deleteEmployeeSubmit,
          roleList
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
