import React from 'react';
import { Table, Button, Card, Input, Dropdown, Select } from 'components';
import ButtonBTN from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FcShipped } from 'react-icons/fc';
import { BiSearch } from 'react-icons/bi';
const Body = ({ _this }) => {
  const columns = [
    {
      accessor: 'customer_id',
      Header: 'Patient ID',
      Cell: (row) => (
        <div className="flex justify-center items-center gap-2">
          {row.original.is_migrated_from_fusebill &&
            row.original.is_migrated_from_fusebill == true && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-bottom ">
                    <p>--Migrated--</p>
                    <p>Fusebill ID: {row.original.fusebill_id}</p>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">
                  <FcShipped size={20} />
                </span>
              </OverlayTrigger>
            )}
          <Link
            to={`/customer-info/${row.original.customer_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 font-medium"
          >
            {row.original.patient_id}
          </Link>
        </div>
      )
    },
    {
      accessor: 'patient_name',
      minWidth: 150,
      Header: 'Patient Name',
      Cell: (row) => <div>{row?.original?.patient_name}</div>
    },
    {
      accessor: 'email',
      minWidth: 200,
      Header: 'Email',
      Cell: (row) => <div>{row?.original?.email}</div>
    },
    {
      accessor: 'phone',
      minWidth: 100,
      Header: 'Phone',
      Cell: (row) => <div>{row?.original?.phone}</div>
    },
    {
      accessor: 'location_name',
      minWidth: 150,
      Header: 'Location Name',
      Cell: (row) => <div>{row?.original?.location_name}</div>
    },
    {
      accessor: 'l_battery_size',
      Header: 'L Battery Size',
      minWidth: 200,
      Cell: (row) => (
        <div className="px-2">
          {/* <Dropdown
            placeholder="Select"
            required
            type="text"
            options={_this.batterySizeOptions}
            value={row.original.l_battery_size}
            onChange={(e) => {
              _this.setUpdatedData((prev) => [...prev, row.index]);
              const updatedData = [..._this.data];
              updatedData[row.index] = {
                ...updatedData[row.index],
                l_battery_size: e,
                r_battery_size: e
              };
              _this.setData(updatedData);
            }}
          /> */}

          <Form.Select
            className="md:w-48 rounded-md border-2 focus:border-button-primary"
            aria-label="Default select example"
            value={row.original.l_battery_size}
            onChange={(e) => {
              _this.setUpdatedData((prev) => [...prev, row.index]);
              const updatedData = [..._this.data];
              updatedData[row.index] = {
                ...updatedData[row.index],
                l_battery_size: e.target.value,
                r_battery_size: e.target.value
              };
              _this.setData(updatedData);
            }}
          >
            <option>Select</option>
            {_this.batterySizeOptions?.map((item, index) => (
              <option key={index} className="text-lg">
                {item}
              </option>
            ))}
          </Form.Select>
        </div>
      )
    },
    {
      accessor: 'r_battery_size',
      Header: 'R Battery Size',
      minWidth: 200,
      Cell: (row) => (
        <div className="px-2">
          {/* <Dropdown
            placeholder="Select"
            required
            type="text"
            options={_this.batterySizeOptions}
            value={row.original.r_battery_size}
            onChange={(e) => {
              _this.setUpdatedData((prev) => [...prev, row.index]);
              const updatedData = [..._this.data];
              updatedData[row.index] = { ...updatedData[row.index], r_battery_size: e };
              _this.setData(updatedData);
            }}
          /> */}
          <Form.Select
            className="md:w-48 rounded-md border-2 focus:border-button-primary"
            aria-label="Default select example"
            value={row.original.r_battery_size}
            onChange={(e) => {
              _this.setUpdatedData((prev) => [...prev, row.index]);
              const updatedData = [..._this.data];
              updatedData[row.index] = {
                ...updatedData[row.index],
                r_battery_size: e.target.value
              };
              _this.setData(updatedData);
            }}
          >
            <option>Select</option>
            {_this.batterySizeOptions?.map((item, index) => (
              <option key={index} className="text-lg">
                {item}
              </option>
            ))}
          </Form.Select>
        </div>
      )
    },
    {
      accessor: 'do_not_mail',
      minWidth: 120,
      Header: 'Do Not Mail',
      Cell: (row) => <div>{row?.original?.do_not_mail ? 'Yes' : 'No'}</div>
    }
    // {
    //   accessor: 'createdAt',
    //   // sortable: false,
    //   minWidth: 150,
    //   Header: 'Created At',
    //   Cell: (row) => <div>{moment(row.original.createdAt).format('YYYY-MM-DD')}</div>
    // }
  ];
  return (
    <div className="flex flex-col gap-1">
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-col lg:flex-row lg:gap-3 justify-between items-center">
          <div className="flex gap-4 mb-2 items-center">
            <h3 className="text-body-color text-xl md:text-2xl">
              Invalid Battery List | {_this?.totalData}
            </h3>
          </div>

          <div className="flex justify-center items-center">
            <Form.Select
              aria-label="Default select example"
              // className="!w-full md:!w-2/12 mb-2"
              className="w-48 z-20 mb-2"
              value={_this.search?.searchField}
              onChange={(e) => {
                _this.setSearch((prev) => ({
                  ...prev,
                  searchField: e.target.value
                }));
                _this.setSelectedLocations([]);
                _this.setSelectedLocationsArray([]);
              }}
            >
              {Object.keys(_this.searchFieldOption).map((item, index) => (
                <option key={index} value={_this.searchFieldOption[item]} className="text-lg">
                  {item}
                </option>
              ))}
            </Form.Select>
            {_this.search.searchField === 'Location Name' && (
              <div className="flex justify-center items-center">
                <Dropdown
                  className="w-48 z-20"
                  placeholder="@location"
                  type="text"
                  options={_this.locationList
                    ?.slice()
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  value={_this.selectedLocations.location}
                  onChange={(e) => {
                    _this.setSelectedLocations((prev) => ({
                      ...prev,
                      location: e
                    }));
                  }}
                  aria-label="Location List"
                  isMulti
                />
              </div>
            )}
            {_this.search.searchField === 'Franchise  Name' && (
              <Dropdown
                className="w-48 z-20 !mb-0"
                placeholder="@location"
                type="text"
                options={_this.franchiseList}
                onChange={(e) => {
                  _this.setSelectedLocations((prev) => ({
                    ...prev,
                    location: e
                  }));
                }}
                aria-label="Franchise List"
              />
            )}
            <ButtonBTN
              onClick={() => _this.searchData()}
              className={`bg-button-primary mb-2 md:!mt-0`}
              variant="primary"
              id="button-addon3"
            >
              <BiSearch size={24} />
            </ButtonBTN>
          </div>

          <div>
            <Button onClick={_this.handelExport}>Export</Button>
            <div className="w-48 mt-2">
              <Button onClick={_this.submitEditBatteriesData}>Save</Button>
            </div>
          </div>
        </div>
      </Card>
      <Card paddingClass="p-1">
        <Table
          keyField="id"
          columns={columns}
          data={_this.data}
          headerComponent={null}
          showPageJump={false}
          showPageSizeOptions={true}
          pages={_this.totalPage}
          page={_this.currentPage}
          onPageChange={(e) => {
            // if (_this.updatedData != '') {
            //   return alert('Please save this page then go to next.');
            // }
            _this.setCurrentPage(e);
            _this.init(e, _this.limitPerPage);
          }}
          onPageSizeChange={(e) => {
            _this.setCurrentPage(0);
            _this.setLimitPerPage(e);
            _this.init(_this.currentPage, e);
          }}
          onSortedChange={(e) => {
            _this.setSortColumn(e[0].id);
            _this.setSortDirection(e[0].desc);
            _this.init(_this.currentPage, _this.limitPerPage, e[0].id, e[0].desc);
          }}
          manual={true}
        />
      </Card>
      <Loading _this={_this} />
    </div>
  );
};

export default Body;
