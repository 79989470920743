import moment from 'moment';
import React from 'react';
import doctorImg from '../../../assets/images/doctor_img1.png';
import MyProfile from './MyProfile';

function GoodDayHero({ _this }) {
  return (
    <div className="w-full flex flex-col sm:flex-row bg-theme-dashboard-blue rounded-xl text-theme-dull-white py-3">
      <div className="w-full sm:w-[70%] h-full py-3 sm:py-0 sm:px-5 px-3 flex flex-row justify-start items-center gap-10 border-dashed border-white border-b-2 sm:border-r-2 sm:border-b-0">
        <div className="w-3/6 sm:w-1/6 flex justify-center items-center">
          <img src={doctorImg} className="object-cover" alt="hspp-logo" />
        </div>
        <div className="flex flex-col justify-end gap-2">
          <h2 className="text-lg sm:text-3xl font-extrabold">Good Day, {_this.user?.name}</h2>
          <p className="text-sm sm:text-lg">Have a Nice {moment().format('dddd')}!</p>
        </div>
      </div>
      <div className="w-full sm:w-[30%] h-full px-3 sm:px-5 flex flex-row justify-between">
        <MyProfile _this={_this} />
      </div>
    </div>
  );
}

export default GoodDayHero;
