import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, Select } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const EditPermissionModal = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this.editPermissionModalVisibility}
      onHide={() => _this.setEditPermissionModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Edit Permission</Modal.Title>

        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setEditPermissionModalVisibility(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-col">
        <div className="alert alert-danger" role="alert">
          Editing permission should be handled by developers only. It can break site.
        </div>
        <Card className="mb-3 flex mr-2">
          <Input
            label="Permission Name"
            placeholder="@Enter Permission Nmae"
            type="text"
            required
            value={_this.editPermissionData?.permission_name || ''}
            onChange={(e) => _this.updateEditPermissionData('permission_name', e.target.value)}
          />

          <Dropdown
            label="Permission Group"
            required
            type="text"
            options={_this.permissionGroupOption}
            // isClearable
            aria-label="Default select example"
            onChange={(e) => {
              _this.updateEditPermissionData('permission_group', e);
            }}
            value={_this.editPermissionData?.permission_group}
            // isMulti
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.submitEditPermissionData();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPermissionModal;
