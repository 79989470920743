import React, { useEffect, useState } from 'react';
import { Button, Card, Table } from 'components';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import RequestFormModal from './RequestFormModal';
import AddSerialNumberModal from './AddSerialNumberModal';
import moment from 'moment';
import { PermissionWrapper, HasPermission } from 'components';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { ImCancelCircle } from 'react-icons/im';
import { MdDoneAll } from 'react-icons/md';
import AcceptOrDenyModal from './AcceptOrDenyModal';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import customer from 'services/api/customer';
import ButtonBTN from 'react-bootstrap/Button';

const Body = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const status = dataObject.status;
      const customer_status = dataObject.customer_status;
      const subscription_status = dataObject.subscription_status;
      const customer_id = dataObject.customer_id;
      const customer_name = `${dataObject.first_name} ${dataObject.last_name}`;
      const location = dataObject.location;
      const left_serial_number = dataObject.left_serial_number;
      const left_purchase_date = dataObject.left_purchase_date;
      const right_serial_number = dataObject.right_serial_number;
      const right_purchase_date = dataObject.right_purchase_date;
      const type = dataObject.type;
      const notes = dataObject.notes;
      const requested_by = dataObject.requested_by;
      const entry_date = dataObject.entry_date;

      const extractedData = {
        Status: status,
        'Patient ID': customer_id,
        'Patient Name': customer_name,
        Location: location,
        'Left Serial Number': left_serial_number,
        'Left Purchase Date': left_purchase_date,
        'Right Serial Number': right_serial_number,
        'Right Purchase Date': right_purchase_date,
        'Customer Status': customer_status,
        'Subscription Status': subscription_status,
        Type: type,
        Notes: notes,
        'Requested By': requested_by,
        'Entry Date': entry_date
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'L&DApprovalsReport' + fileExtension);
  };

  const columns = [
    {
      Header: 'Patient ID',
      accessor: 'customer_id',
      Cell: (row) => row?.original?.customer_id || ''
    },
    {
      Header: 'Customer Name',
      accessor: 'customer_name',
      Cell: (row) => <div>{`${row?.original?.first_name} ${row?.original?.last_name}`}</div>
    },
    {
      Header: 'Location',
      accessor: 'location',
      Cell: (row) => <div>{row?.original?.location}</div>
    },
    {
      Header: 'Left Serial Number',
      accessor: 'left_serial_number',
      Cell: (row) => <div className="font-medium">{row?.original?.left_serial_number}</div>
    },
    {
      Header: 'Left Purchase Date',
      accessor: 'left_purchase_date',
      Cell: (row) => (
        <div>
          {row?.original?.left_purchase_date &&
            moment(row?.original?.left_purchase_date).format('YYYY-MM-DD')}
        </div>
      )
    },
    {
      Header: 'Right Serial Number',
      accessor: 'right_serial_number',
      Cell: (row) => <div className="font-medium">{row?.original?.right_serial_number}</div>
    },
    {
      Header: 'Right Purchase Date',
      accessor: 'right_purchase_date',
      Cell: (row) => (
        <div>
          {row?.original?.right_purchase_date &&
            moment(row?.original?.right_purchase_date).format('YYYY-MM-DD')}
        </div>
      )
    },
    {
      Header: 'Customer Status',
      accessor: 'customer_status',
      Cell: (row) => row?.original?.customer_status || ''
    },
    {
      Header: 'Subscription Status',
      accessor: 'subscription_status',
      minWidth: 110,
      Cell: (row) => row?.original?.subscription_status || ''
    },

    {
      Header: 'Type',
      accessor: 'type',
      minWidth: 120,
      Cell: (row) => <div>{row?.original?.type}</div>
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      width: 500,
      Cell: (row) => <div>{row?.original?.notes}</div>
    },
    {
      Header: 'Requested By',
      accessor: 'requested_by',
      minWidth: 250,
      Cell: (row) => <div className="px-1">{row?.original?.requested_by}</div>
    },
    {
      Header: 'Entry Date',
      accessor: 'entry_date',
      Cell: (row) => <div>{moment(row?.original?.entry_date).format('YYYY-MM-DD')}</div>
    }
  ];

  if (HasPermission({ permission: ['approval_status_employee'], require_all: false }))
    columns.unshift({
      Header: 'Status',
      accessor: 'status',
      minWidth: 130,
      Cell: (row) => (
        <div className="flex justify-center items-center w-full h-full p-2">
          <div
            className={
              (row.original.status == 'pending'
                ? 'bg-amber-200 text-amber-700'
                : row.original.status == 'approved'
                ? 'bg-green-200 text-green-700'
                : 'bg-red-200 text-red-700') +
              ' py-1 rounded px-2 text-sm font-bold tracking-wider my-auto'
            }
          >
            {row.original.status == 'pending'
              ? 'PENDING'
              : row.original.status == 'approved'
              ? 'APPROVED'
              : 'REJECTED'}
          </div>
        </div>
      )
    });

  if (HasPermission({ permission: ['approval_action'], require_all: false }))
    columns.unshift({
      Header: 'Action',
      accessor: '_id',
      sortable: false,
      width: 120,
      Cell: (row) => (
        <div className="flex justify-center items-center w-full h-full">
          {row.original.status == 'pending' ? (
            <p>
              <span
                className="text-green-700 hover:underline cursor-pointer"
                onClick={() => {
                  _this.getOpsAndSycleData(row.original.customer_id);
                  _this.setAcceptOrDenyApprovalModal(true),
                    _this.setAcceptOrDenyApprovalData({
                      accept: true,
                      _id: row.original._id,
                      notes: ''
                    });
                }}
              >
                Accept
              </span>{' '}
              |{' '}
              <span
                className="text-red-700 hover:underline cursor-pointer"
                onClick={() => {
                  _this.getOpsAndSycleData(row.original.customer_id);
                  _this.setAcceptOrDenyApprovalModal(true),
                    _this.setAcceptOrDenyApprovalData({
                      accept: false,
                      _id: row.original._id,
                      notes: ''
                    });
                }}
              >
                Reject
              </span>
            </p>
          ) : row.original.status == 'approved' ? (
            <MdDoneAll size={40} fill="green" />
          ) : (
            <ImCancelCircle size={40} fill="red" />
          )}
        </div>
      )
    });

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-col  sm:flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">L&D Approvals</h3>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
          <div className="flex gap-4">
            {' '}
            <Button
              style="w-20"
              onClick={() => {
                // _this.setModalTicket(row?.original);
                _this.setOpenRequestFormModal(true);
              }}
              className="font-medium"
            >
              Form
            </Button>
            <Button
              onClick={() => exportReport()}
              className={`bg-button-primary  mb-2`}
              variant="primary"
              id="export"
            >
              Export
            </Button>
          </div>
        </div>
      </Card>

      <Card className="flex mt-2" paddingClass="px-0 py-0">
        <div className="">
          <Table
            keyField="_id"
            columns={columns}
            data={_this.tableData}
            minRows={6}
            defaultPageSize={6}
          />
        </div>
      </Card>

      <RequestFormModal _this={_this} />
      <AddSerialNumberModal _this={_this} />
      <AcceptOrDenyModal _this={_this} />
    </>
  );
};

export default Body;
