import React from 'react';
import { Card, Input, Button } from 'components';
import { Form, Modal } from 'react-bootstrap';

const CheckPasswordChangeModal = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      className="mt-10"
      show={_this.setPaswordModalOpen}
      backdrop={'static'}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Set New Password</Modal.Title>
      </Modal.Header>

      <Modal.Body className="flex flex-col justify-center items-center">
        <Card className="mb-3 mt-2">
          <div className="text-md text-slate-500 mt-1">
            <Form className="bg-slate-100 mt-2 p-3 rounded">
              <Input
                label="Password"
                placeholder="@Password"
                type="password"
                required
                value={_this.password_details.password}
                onChange={(e) =>
                  _this.setPassword_details((prev) => ({
                    ...prev,
                    password: e.target.value
                  }))
                }
              />
              <Input
                label="Confirm Password"
                placeholder="@Confirm Password"
                type="password"
                required
                value={_this.password_details.confirm_password}
                onChange={(e) =>
                  _this.setPassword_details((prev) => ({
                    ...prev,
                    confirm_password: e.target.value
                  }))
                }
              />
            </Form>
          </div>
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => _this.changePassword()}>Set Password</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckPasswordChangeModal;
