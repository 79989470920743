import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import { BiEditAlt } from 'react-icons/bi';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import EditExpiryDateModal from './EditExpiryDateModal';
import { PermissionWrapper, HasPermission } from 'components';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const hsppId = dataObject.hspp_id;
      const customerId = dataObject.customer_id;
      const customerName = `${dataObject.first_name} ${dataObject.last_name}`;
      const location = dataObject.location.toString();
      const franchise = dataObject.franchise.toString();
      const plan = dataObject.plan_name;
      const amount = `$${dataObject.plan_amount}`;
      const nextInvoiceDate = dataObject.next_invoice_date
        ? new Date(dataObject.next_invoice_date)
        : '';
      const lWarrantyExpiry = dataObject.l_warranty_expiry
        ? new Date(dataObject.l_warranty_expiry)
        : '';
      const rWarrantyExpiry = dataObject.r_warranty_expiry
        ? new Date(dataObject.r_warranty_expiry)
        : '';
      const expiryDate = dataObject.expiry_date ? new Date(dataObject.expiry_date) : '';

      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        'HSPP ID': hsppId,
        'Customer ID': customerId,
        'Customer Name': customerName,
        Location: location,
        Franchise: franchise,
        Plan: plan,
        Amount: amount,
        'Next Invoice Date': nextInvoiceDate,
        'L WarrantyExpiry': lWarrantyExpiry,
        'R WarrantyExpiry': rWarrantyExpiry,
        'Expiry Date': expiryDate

        // Address: formattedAddress
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'L&DReport' + fileExtension);
  };

  const columns = [
    {
      accessor: 'hspp_id',
      Header: 'HSPP ID',
      sortable: false,
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link to={`/customer-info/${row.original.customer}`} className="text-blue-500 font-medium">
          #{row.original.hspp_id}
        </Link>
      )
    },
    {
      accessor: 'customer_id',
      Header: 'Patient ID',
      Cell: (row) => <div>{row.original.customer_id}</div>
    },
    {
      accessor: 'first_name',
      Header: 'Name',
      Cell: (row) => <div>{row.original.first_name + ' ' + row.original.last_name}</div>
    },
    {
      accessor: 'location',
      Header: 'Location',
      Cell: (row) => <div>{row.original.location}</div>
    },
    {
      accessor: 'franchise',
      Header: 'Franchise',
      Cell: (row) => <div>{row.original.franchise}</div>
    },
    {
      accessor: 'plan_name',
      Header: 'Plan',
      Cell: (row) => <div>{row.original.plan_name}</div>
    },
    {
      accessor: 'plan_amount',
      Header: 'Amount',
      maxWidth: 80,
      Cell: (row) => <div>${row.original.plan_amount}</div>
    },
    {
      accessor: 'next_invoice_date',
      Header: 'Next Invoice Date',
      Cell: (row) => (
        <div>
          {row.original.next_invoice_date
            ? moment(row.original.next_invoice_date).format('YYYY-MM-DD')
            : ''}
        </div>
      )
    },
    {
      accessor: 'l_warranty_expiry',
      Header: 'L Warranty Expiry',
      width: 120,
      Cell: (row) => (
        <div className="flex gap-2">
          <p>
            {row.original.l_warranty_expiry
              ? moment(row.original.l_warranty_expiry).format('YYYY-MM-DD')
              : ''}
          </p>
          <span>
            <span>
              <PermissionWrapper permission={'edit_l&r_warranty_date'}>
                <BiEditAlt
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    _this.handleExpiryDateEdit(
                      row.original._id,
                      row.original.l_warranty_expiry,
                      row.original.r_warranty_expiry,
                      row.original.expiry_date
                    );
                  }}
                />
              </PermissionWrapper>
            </span>
          </span>
        </div>
      )
    },
    {
      accessor: 'r_warranty_expiry',
      Header: 'R Warranty Expiry',
      width: 120,
      Cell: (row) => (
        <div className="flex gap-2">
          <p>
            {row.original.r_warranty_expiry
              ? moment(row.original.r_warranty_expiry).format('YYYY-MM-DD')
              : ''}
          </p>
          <span>
            <span>
              <PermissionWrapper permission={'edit_l&r_warranty_date'}>
                <BiEditAlt
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    _this.handleExpiryDateEdit(
                      row.original._id,
                      row.original.l_warranty_expiry,
                      row.original.r_warranty_expiry,
                      row.original.expiry_date
                    );
                  }}
                />
              </PermissionWrapper>
            </span>
          </span>
        </div>
      )
    },
    {
      accessor: 'expiry_date',
      Header: 'Expiry Date',
      width: 120,
      Cell: (row) => (
        <div className="flex gap-2">
          <p>
            {row.original.expiry_date ? moment(row.original.expiry_date).format('YYYY-MM-DD') : ''}
          </p>
          <span>
            <PermissionWrapper permission={'edit_expiry_date'}>
              <BiEditAlt
                size={20}
                className="cursor-pointer"
                onClick={() => {
                  _this.handleExpiryDateEdit(
                    row.original._id,
                    row.original.l_warranty_expiry,
                    row.original.r_warranty_expiry,
                    row.original.expiry_date
                  );
                }}
              />
            </PermissionWrapper>
          </span>
        </div>
      )
    }
  ];

  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card className="flex mr-2 mt-2">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between mb-2">
          <h3 className="text-body-color text-xl font-semibold pb-2">
            L&D Report {`(${_this.tableData?.length > 0 ? _this.tableData?.length : 0})`}
          </h3>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
          <div>
            <Dropdown
              // label="Plan Search"
              className="w-48 z-10"
              placeholder="@plans"
              required
              type="text"
              options={[
                { label: 'Loss & Damage', value: 'lossanddamage' },
                { label: 'Secure Plan', value: 'secureplan' },
                { label: 'Battery Plan', value: 'batteryplan' },
                { label: '1 Month Free HSPP', value: '1monthfreehspp' }
              ]}
              aria-label="Default select example"
              value={_this.planCode}
              onChange={(e) => {
                _this.setPlanCode(e);
              }}
            />
          </div>
          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
      <EditExpiryDateModal _this={_this} />
    </>
  );
};

export default Section;
