import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';
import states from '../helpers/states.json';

const document = {
  //  this two method will be used by customer itself. without loging in

  //   GetDocuments: async (data) => {
  //     const token = await getToken();
  //     let response = null;
  //     try {
  //       response = await API.post('/v1/documents/get-documents', data, {
  //         headers: { Authorization: 'Bearer: ' + token }
  //       });
  //     } catch (e) {
  //       response = e;
  //     }
  //     return handleResponse(response);
  //   },
  //   SendDocuments: async (data, onUploadProgress) => {
  //     const token = await getToken();
  //     let response = null;
  //     const finalData = processFormData(data);
  //     try {
  //       response = await APIFORMDATA.post('/v1/documents/insert-document', finalData, {
  //         headers: { Authorization: 'Bearer: ' + token },
  //         onUploadProgress: onUploadProgress
  //       });
  //     } catch (e) {
  //       response = e;
  //     }
  //     return handleResponse(response);
  //   },
  DeleteDocument: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/documents/delete-document?delete_id=${id}`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default document;
