import React, { useEffect, useState } from 'react';
import { Card, Button, Input } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Alert, Form, InputGroup, ToggleButton } from 'react-bootstrap';
import { MdToggleOn, MdToggleOff, MdAddBox } from 'react-icons/md';
import Editor from 'jodit-react';
import htmlParser from 'html-react-parser';
import { AiFillEdit } from 'react-icons/ai';
import ButtonBTN from 'react-bootstrap/Button';

const Section = ({ _this }) => {
  const columns = [
    {
      Header: 'HSPP ID',
      sortable: false,
      accessor: '_id',
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link
          to={`/customer-info/${row.original._id}`}
          state={{ hspp_id: row.original._id }}
          className="text-blue-500 font-medium"
        >
          #{row.original.hspp_id}
        </Link>
      )
    },
    {
      accessor: 'customer_id',
      Header: 'Patient ID'
    },
    {
      accessor: 'first_name',
      Header: 'Patient Name',
      Cell: (row) => <div>{row.original.first_name + ' ' + row.original.last_name}</div>
    },
    {
      accessor: 'email',
      Header: 'Patient Email',
      Cell: (row) => <div>{row.original.email}</div>
    },
    {
      accessor: 'createdAt',
      Cell: (row) => <div>{moment(row.original.createdAt).format('YYYY-MM-DD')}</div>,
      Header: 'Created On'
    },
    {
      Cell: (row) => (
        <BootstrapSwitchButton
          size="xs"
          width={75}
          onstyle="secondary"
          offstyle="success"
          checked={
            _this.showNameForEmail.filter((item) => item.email == row.original.email).length > 0
              ? true
              : false
          }
          onlabel="Remove"
          offlabel="Add"
          onChange={(e) => {
            _this.AddPeopleForEmail({
              status: e,
              email: row.original.email,
              name: row.original.first_name + ' ' + row.original.last_name
            });
          }}
        />
      ),
      Header: 'Action',
      maxWidth: 120
    }
  ];

  const employeeColumns = [
    {
      accessor: 'employee_name',
      Header: 'Sent By',
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => <div>{row?.original?.employee_name}</div>
    },
    {
      Header: 'Franchise names',
      Cell: (row) => (
        <p>
          {JSON.parse(row?.original?.data)?.franchiseList?.map((fran) => (
            <span key={fran?.value?.franchiseId}>{fran?.value?.franchiseName + ', '}</span>
          ))}
        </p>
      )
    },
    {
      Header: 'Email Subject',
      Cell: (row) => <div>{JSON.parse(row?.original?.data)?.mailData?.subject}</div>
    },
    {
      accessor: 'createdAt',
      Header: 'Email sent on',
      Cell: (row) => <div>{moment(row?.original?.createdAt).format('YYYY-MM-DD')}</div>
    },
    {
      Header: 'No of employee',
      Cell: (row) => <div>{JSON.parse(row?.original?.data)?.count}</div>
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-2xl">
            {_this?.mailTemplates ? 'Email Templates' : 'Employee Mail History'}
          </h3>
          <div>
            {_this?.mailTemplates ? null : (
              <Button onClick={() => _this.navigateMailPage()}>Generate Employee Mail</Button>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center mt-3">
          <div className="flex justify-between text-lg items-center text-body-color gap-2">
            Mail Templates
            {_this?.mailTemplates ? (
              <MdToggleOff
                onClick={() => _this.setMailTemplates(false)}
                size={45}
                className="cursor-pointer text-blue-500"
              />
            ) : (
              <MdToggleOn
                onClick={() => _this.setMailTemplates(true)}
                size={45}
                className="cursor-pointer text-blue-500"
              />
            )}
            Employees
          </div>
          {_this.mailTemplates ? (
            <InputGroup className="rounded-md w-1/3">
              <Form.Select
                value={_this?.selectedTemplate}
                onChange={(e) => _this?.setSelectedTemplate(e.target.value)}
                disabled={_this.isNew}
              >
                <option value={''} className="text-md">
                  Select a Template
                </option>
                {_this?.templateList?.length
                  ? _this?.templateList?.map((item, index) => (
                      <option key={index} value={item.email_type} className="text-md">
                        {item?.email_type}
                      </option>
                    ))
                  : null}
              </Form.Select>
              <ButtonBTN
                className={`bg-button-primary mt-2 md:!mt-0 flex text-lg gap-2 items-center`}
                variant="primary"
                id="button-addon3"
                disabled
              >
                Edit
                <AiFillEdit size={24} />
              </ButtonBTN>
            </InputGroup>
          ) : null}

          {_this.mailTemplates ? (
            <ButtonBTN
              onClick={() => _this.setIsNew(true)}
              className={`bg-button-primary mt-2 md:!mt-0 flex gap-2 items-center text-lg`}
              variant="primary"
              id="button-addon3"
              disabled={_this.selectedTemplate}
            >
              Create
              <MdAddBox size={24} />
            </ButtonBTN>
          ) : null}
        </div>
      </Card>
      {_this.showNameForEmail?.length > 0 && (
        <Alert key={'danger'} className="my-2" variant={'primary'}>
          <strong className="mb-2">Email to:</strong>
          <div className="flex flex-wrap">
            {_this.showNameForEmail?.map((item, index) => {
              return (
                <div key={index} className="flex px-3 py-1 gap-1">
                  <span>{index + 1}.</span>
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
        </Alert>
      )}
      <Card className="flex mt-2" paddingClass="p-2">
        {_this?.mailTemplates ? (
          <div>
            {_this.isNew == false && _this.selectedTemplate == '' ? (
              <h3 className="text-2xl text-center p-5">
                Select a template to edit or create a new one
              </h3>
            ) : (
              <div>
                {_this.isNew ? (
                  <div>
                    <h3 className="text-2xl text-center bg-gray-200 rounded-full">
                      New Email Template
                    </h3>
                    <Input
                      label="Email Type"
                      placeholder="@new email type"
                      type="text"
                      required
                      value={_this.newMail?.email_type}
                      onChange={(e) =>
                        _this.setNewMail({ ..._this.newMail, email_type: e.target.value })
                      }
                    />
                  </div>
                ) : null}
                <Input
                  label="Email Subject"
                  className="mb-3 text-lg"
                  placeholder="@subject"
                  type="text"
                  required
                  value={_this.newMail?.subject}
                  onChange={(e) => _this.setNewMail({ ..._this.newMail, subject: e.target.value })}
                />
                <h3 className="text-lg">
                  Email Body <span className="text-red-600">*</span>
                </h3>
                <Editor
                  value={_this.newMail?.body}
                  onChange={(value) => _this?.setNewMail({ ..._this.newMail, body: value })}
                  tabIndex={1}
                />

                <div className="flex justify-between w-2/3 mx-auto py-3">
                  <ButtonBTN
                    onClick={() => _this.handleCancel()}
                    className={`mt-2 md:!mt-0 w-1/4 text-xl`}
                    variant="outline-secondary"
                    id="button-addon3"
                  >
                    Cancel
                  </ButtonBTN>

                  <ButtonBTN
                    onClick={() => _this.removeTemplate()}
                    className={`mt-2 md:!mt-0 w-1/4 text-xl`}
                    variant="outline-danger"
                    id="button-addon3"
                  >
                    Remove
                  </ButtonBTN>

                  <ButtonBTN
                    onClick={() => _this.handleSubmit()}
                    className={`mt-2 md:!mt-0 w-1/4 text-xl`}
                    variant="outline-primary"
                    id="button-addon3"
                  >
                    Save
                  </ButtonBTN>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Table
            keyField="_id"
            columns={employeeColumns}
            data={_this?.employeeTableData}
            headerComponent={null}
          />
        )}
      </Card>
    </>
  );
};

export default Section;
