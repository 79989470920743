import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { PermissionWrapper, HasPermission } from 'components';
import { TbLivePhoto } from 'react-icons/tb';
import StartStopModal from './StartStopModal';

const Section = ({ _this }) => {
  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Contest</h3>
          {_this.ongoingContest?.contest ? (
            <div className="flex text-xl items-center mb-3">
              Ongoing Contest: {_this.ongoingContest?.contest_name}
            </div>
          ) : (
            <div className="flex text-xl items-center mb-3">No ongoing contest.</div>
          )}
          {_this.ongoingContest?.contest ? (
            <div>
              <div className="flex text-xl items-center mb-3">
                <TbLivePhoto size={25} className="mr-4 text-red-600 animate-bounce" />
                Contest is live
              </div>
              <Button onClick={() => _this.setShowStartStopModal(true)}>Stop Contest</Button>
            </div>
          ) : (
            <div className="text-xl">
              <Button onClick={() => _this.setShowStartStopModal(true)}>Start a Contest</Button>
            </div>
          )}
        </div>
      </Card>
      <div className="mt-3 mb-3 flex flex-col lg:flex-row flex-wrap gap-3 justify-between">
        <PermissionWrapper permission="view_contest">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">Ongoing Contest Record</h3>
            <Button onClick={_this.handleOngoingContestRecord}>See List</Button>
          </Card>
        </PermissionWrapper>
        {/* see contest prize list */}
        <PermissionWrapper permission="view_contest">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">Contest Prize</h3>
            <Button onClick={_this.handleContestPrizeList}>See List</Button>
          </Card>
        </PermissionWrapper>

        <PermissionWrapper permission="view_contest">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">Achievements</h3>
            <Button onClick={_this.handleAchievementContest}>See List</Button>
          </Card>
        </PermissionWrapper>
      </div>

      <StartStopModal _this={_this} />
    </>
  );
};

export default Section;
