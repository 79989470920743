import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import './style.scss';

const Component = ({
  label = '',
  controlId = '',
  type = '',
  placeholder = '',
  required = false,
  hint = '',
  isMonth = false,
  ...props
}) => {
  return (
    <Form.Group className="" controlId={controlId}>
      <Form.Label className="text-sm text-slate-600 font-bold tracking-wide mb-0">
        {label}
        {required && <span className="text-red-600"> *</span>}
      </Form.Label>
      <DatePicker
        format={isMonth ? 'MM/yyyy' : 'yyyy-MM-dd'}
        locale="en-US"
        clearIcon={null}
        showNavigation={true}
        minDetail={isMonth ? 'year' : 'month'} // Show only year and month views
        maxDetail={isMonth ? 'year' : 'month'}
        className="w-full rounded inputShadow border-border-primary focus:border-border-focus inputBox bg-white"
        {...props}
      />
      {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
    </Form.Group>
  );
};

Component.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  type: PropTypes.string
};

export default Component;
