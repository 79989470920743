import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const location = dataObject.location_name;
      const amplifon_patients = dataObject.amplifon_patients;
      const active_patients = dataObject.active_patients;
      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        Location: location,
        'Amplifon patients': amplifon_patients,
        'Active Hspp Patients': active_patients
        // Address: formattedAddress
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'AmplifonPatientsReport' + fileExtension);
  };

  const columns = [
    {
      accessor: 'location_name',
      Header: 'Location',
      Cell: (row) => <div>{row.original.location_name}</div>
    },
    {
      accessor: 'amplifon_patients',
      Header: 'Amplifon Patients',
      Cell: (row) => <div>{row.original.amplifon_patients}</div>
    },
    {
      accessor: 'active_patients',
      Header: 'Active Hspp Patients',
      Cell: (row) => <div>{row.original.active_patients}</div>
    }
  ];

  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card className="flex mr-2 mt-2">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between mb-2">
          <h3 className="text-body-color text-xl font-semibold pb-2">Amplifon Patients Report</h3>
          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default Section;
