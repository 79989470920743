import React from 'react';
import { Card, Table } from 'components';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Section = ({ _this }) => {
  const exportReport = () => {
    const tableArray = [];

    for (const data of _this.tableData) {
      const dataObject = {
        'Patient Id': data?.patient_id,
        'HSPP Id': data?.customer_id,
        'Patient Name': data?.patient_name,
        'Current last4': data?.curr_last4,
        'Current expiry date': data?.curr_expiry,
        'Previous last4': data?.prev_last4,
        'Previous expiry date': data?.prev_expiry,
        'Update Date': data?.eventDate,
        'Stripe Event Id': data?.event_id
      };

      tableArray.push(dataObject);
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(tableArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'CardUpdateReport' + fileExtension);
  };

  const columns = [
    {
      accessor: 'patient_id',
      Header: 'Patient Id',
      Cell: (row) => (
        <Link
          to={`/customer-info/${row.original?.customer_id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 font-medium"
        >
          {row.original?.patient_id}
        </Link>
      )
    },
    {
      accessor: 'patient_name',
      Header: 'Patient Name',
      width: 200
    },
    {
      accessor: 'curr_last4',
      Header: 'Current last 4 digits',
      width: 200
    },
    {
      accessor: 'curr_expiry',
      Header: 'Current Expiry date',
      width: 200
    },
    {
      accessor: 'prev_last4',
      Header: 'Previous last 4 digits',
      width: 200
    },
    {
      accessor: 'prev_expiry',
      Header: 'Previous Expiry date',
      width: 200
    },
    {
      accessor: 'eventDate',
      Header: 'Update date',
      width: 200
    },
    {
      accessor: 'event_id',
      Header: 'Stripe Event Link',
      width: 300,
      Cell: (row) => (
        <a
          href={`https://dashboard.stripe.com/events/${row?.original?.event_id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 font-medium"
        >
          {row.original?.event_id}
        </a>
      )
    }
  ];

  return (
    <>
      <Card className="flex mr-2 mt-2">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between mb-4">
          <h3 className="text-body-color text-xl font-semibold pb-2">
            Cards Updated by Stripe | {_this?.tableData?.length}
          </h3>
          <input
            type="search"
            placeholder="Search..."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary`}
            variant="primary"
            id="export"
            disabled={_this?.tableData?.length == 0}
          >
            Export
          </ButtonBTN>
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default Section;
