import { configureStore } from '@reduxjs/toolkit';
import session from '../reducer/session';
import uploadProgress from '../reducer/uploadProgress';

export default configureStore({
  reducer: {
    session,
    uploadProgress
  }
});
