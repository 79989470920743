import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import tabs from 'navigation/tabs';

const Index = () => {
  const dispatch = useDispatch();

  const [permissionTableData, setPermissionTableData] = useState([]);
  const [roleTableData, setRoleTableData] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [createRoleModalVisibility, setCreateRoleModalVisibility] = useState(false);
  const [createRoleData, setCreateRoleData] = useState(null);
  const [editRoleModalVisibility, setEditRoleModalVisibility] = useState(false);
  const [editRoleData, setEditRoleData] = useState(null);
  const [deleteRoleModalVisibility, setDeleteRoleModalVisibility] = useState(false);
  const [deleteRoleData, setDeleteRoleData] = useState(null);
  const [createPermissionModalVisibility, setCreatePermissionModalVisibility] = useState(false);
  const [editPermissionModalVisibility, setEditPermissionModalVisibility] = useState(false);
  const [deletePermissionModalVisibility, setDeletePermissionModalVisibility] = useState(false);
  const [createPermissionData, setCreatePermissionData] = useState(null);
  const [editPermissionData, setEditPermissionData] = useState(null);
  const [deletePermissionData, setDeletePermissionData] = useState(null);
  const [permissionGroupOption, setPermissionGroupOption] = useState();

  useEffect(() => {
    const permissionGroups = tabs.map((item) => ({ label: item.name, value: item.name }));
    setPermissionGroupOption(permissionGroups);
  }, []);

  useEffect(() => {
    permissionInit();
  }, []);

  useEffect(() => {
    roleInit();
  }, []);

  const updateCreatePermissionData = (key, value) => {
    setCreatePermissionData((prev) => {
      return {
        ...prev,
        [key]: value
      };
    });
  };

  const updateCreateRoleData = (key, value) => {
    setCreateRoleData((prev) => {
      return {
        ...prev,
        [key]: value
      };
    });
  };

  const updateEditPermissionData = (key, value) => {
    setEditPermissionData((prev) => {
      return {
        ...prev,
        [key]: value
      };
    });
  };

  const updateEditRoleData = (key, value) => {
    setEditRoleData((prev) => {
      return {
        ...prev,
        [key]: value
      };
    });
  };

  const loadCreateRoleOptions = (permissionList) => {
    const groupedOptions = {};

    permissionList.forEach((item) => {
      const permissionGroup = item.value.permission_group;

      if (!groupedOptions[permissionGroup]) {
        groupedOptions[permissionGroup] = {
          label: permissionGroup,
          options: []
        };
      }

      groupedOptions[permissionGroup].options.push({
        label: item.label,
        value: item.value
      });
    });

    const options = Object.values(groupedOptions);

    setPermissionList(options);
  };

  const filterPermissionGroup = (permission_group) => {
    const filteredData = permissionGroupOption.filter((item) => item.value == permission_group);
    return filteredData[0];
  };

  const fixPermissionsWithCommas = (permissions) => {
    const mappedPermissions = permissions.map((item) => item.permission_name);
    const combinedPermissionNames = mappedPermissions.join(', ');
    return combinedPermissionNames;
  };

  const filterRolePermissions = (permissions) => {
    const matchingPermissions = permissionTableData
      .filter((permission1) =>
        permissions.some((permission2) => permission2._id === permission1._id)
      )
      .map((matchedPermission) => ({
        label: matchedPermission.permission_name,
        value: matchedPermission
      }));

    updateEditRoleData('permissions', matchingPermissions);
  };

  const permissionInit = async () => {
    dispatch(loadingStart());
    await API.getPermissionList()
      .then((response) => {
        if (response) {
          setPermissionTableData(response);
          const list = response.map((item) => ({
            label: item.permission_name,
            value: item
          }));
          loadCreateRoleOptions(list);
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const roleInit = async () => {
    dispatch(loadingStart());
    await API.getRoleList()
      .then((response) => {
        if (response) {
          setRoleTableData(response);
          // const list = response.map((item) => ({
          //   label: item.permission_name,
          //   value: item
          // }));
          // loadCreateRoleOptions(list);
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const submitCreatePermissionData = async () => {
    if (createPermissionData.permission_name == '' || createPermissionData.permission_group == '')
      return toast.error('All fields are mandatory.');
    const temp = { ...createPermissionData };
    temp.permission_group = createPermissionData.permission_group.value;
    dispatch(loadingStart());
    await API.createPermission(temp)
      .then((response) => {
        if (response) {
          setCreatePermissionModalVisibility(false);
          toast.success('Permission Added Successfully');
          permissionInit();
          setCreatePermissionData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const submitEditPermissionData = async () => {
    const temp = { ...editPermissionData };
    temp.permission_group = editPermissionData?.permission_group?.value;
    dispatch(loadingStart());
    await API.editPermission(temp)
      .then((response) => {
        if (response) {
          setEditPermissionModalVisibility(false);
          toast.success('Permission Updated Successfully');
          permissionInit();
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const submitDeletePermissionData = async () => {
    dispatch(loadingStart());
    await API.deletePermission(deletePermissionData.permission_id)
      .then((response) => {
        if (response) {
          toast.success('Permission Deleted Successfully');
          permissionInit();
        }
      })
      .finally(() => {
        setDeletePermissionModalVisibility(false);
        dispatch(loadingStop());
      });
  };

  const submitCreateRoleData = async () => {
    if (createRoleData.permission_name == '' || createRoleData.permissions.length < 1)
      return toast.error('All fields are mandatory.');
    const temp = { ...createRoleData };
    temp.permissions = createRoleData.permissions.map((item) => item.value._id);
    dispatch(loadingStart());
    await API.createRole(temp)
      .then((response) => {
        if (response) {
          setCreateRoleModalVisibility(false);
          toast.success('Role Added Successfully');
          roleInit();
          setCreateRoleData(null);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const submitEditRoleData = async () => {
    const temp = { ...editRoleData };
    temp.permissions = editRoleData.permissions.map((item) => item.value._id);
    dispatch(loadingStart());
    await API.editRole(temp)
      .then((response) => {
        if (response) {
          setEditRoleModalVisibility(false);
          toast.success('Role Updated Successfully');
          roleInit();
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const submitDeleteRoleData = async () => {
    dispatch(loadingStart());
    await API.deleteRole(deleteRoleData.role_id)
      .then((response) => {
        if (response) {
          toast.success('Role Deleted Successfully');
          roleInit();
        }
      })
      .finally(() => {
        setDeleteRoleModalVisibility(false);
        dispatch(loadingStop());
      });
  };
  return (
    <DashboardContainer>
      <Body
        _this={{
          createRoleModalVisibility,
          setCreateRoleModalVisibility,
          createRoleData,
          setCreateRoleData,
          createPermissionData,
          setCreatePermissionData,
          createPermissionModalVisibility,
          setCreatePermissionModalVisibility,
          permissionGroupOption,
          updateCreatePermissionData,
          submitCreatePermissionData,
          permissionTableData,
          editPermissionModalVisibility,
          setEditPermissionModalVisibility,
          deletePermissionModalVisibility,
          setDeletePermissionModalVisibility,
          editPermissionData,
          setEditPermissionData,
          deletePermissionData,
          setDeletePermissionData,
          updateEditPermissionData,
          submitEditPermissionData,
          submitDeletePermissionData,
          filterPermissionGroup,
          permissionList,
          setPermissionList,
          updateCreateRoleData,
          submitCreateRoleData,
          roleTableData,
          fixPermissionsWithCommas,
          editRoleModalVisibility,
          setEditRoleModalVisibility,
          editRoleData,
          setEditRoleData,
          updateEditRoleData,
          filterRolePermissions,
          submitEditRoleData,
          deleteRoleModalVisibility,
          setDeleteRoleModalVisibility,
          deleteRoleData,
          setDeleteRoleData,
          submitDeleteRoleData
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
