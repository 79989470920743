import { handleResponse, API, getToken } from './utils';

const invoice = {
  retryFailedInvoice: async (invoice_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        `/v1/invoice/pay-multiple-failed-invoices`,
        { invoice_id },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    if (response?.status == 200) return { status: true };
    else return { status: false, message: response?.data?.error || 'Server error occured.' };
  },
  getInvoiceList: async (page, limit, sortingField, sortingDirection) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(
        `/v1/invoice/get-invoices?page=${page || 0}&limit=${limit || 100}&sortingField=${
          sortingField || 'due_date'
        }&sortingDirection=${sortingDirection ? 1 : -1}`,
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getInvoiceData: async (invoice_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/invoice/get-invoice-data/${invoice_id}`, {
        // params: { invoice_id },
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  payInvoice: async (ids) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/invoice/pay-invoice',
        { invoice_ids: ids },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  retryPayment: async (invoice_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/invoice/pay-failed-invoices',
        { invoice_id: invoice_id },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
      return handleResponse(response);
    } catch (error) {
      response = error;
    }
  },
  initiateDuplicateInvoiceRefund: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/invoice/refund-duplicate-invoices', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  deleteInvoice: async (_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/invoice/delete-invoice?invoice_id=${_id}`, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  editInvoiceDueDate: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(`/v1/invoice/edit-invoice-due-date`, data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  payAdvanceInvoice: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/invoice/pay-advance-invoice', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default invoice;
