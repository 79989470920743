import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [originalData, setOriginalData] = useState(
    JSON.parse(localStorage.getItem('retryPayments'))
  );
  const [tableData, setTableData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  return (
    <DashboardContainer>
      <Body _this={{ tableData, searchInput, setSearchInput }} />
    </DashboardContainer>
  );
};

export default Index;
