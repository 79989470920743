import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, loadSessionFromLocal } from 'redux/action';
import * as Pages from '../pages';
import * as PortalDashboard from '../pages/PortalDashboard';
import Config from 'config';
import loader from 'assets/images/loader.gif';
import { toast } from 'react-toastify';

const Navigation = () => {
  const { isLoading } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    // Handle logout action on unauthorized api call
    if (Config.UNAUTHORIZED_EXCEPTION == true) {
      dispatch(logout());
      Config.UNAUTHORIZED_EXCEPTION = false;
      window.location = '/';
    }
  }, [Config.UNAUTHORIZED_EXCEPTION]);

  useEffect(() => {
    // This use Effect is only used to load localstorage data into redux on page reload.
    dispatch(
      loadSessionFromLocal(
        localStorage.getItem('userSession') ? JSON.parse(localStorage.getItem('userSession')) : ''
      )
    );
  }, []);

  return (
    <BrowserRouter>
      {isLoading && (
        <div className="preloader">
          <img src={loader} className="w-60" />
        </div>
      )}
      <Routes>
        <Route path="/">
          <Route index element={<Pages.Login />} />
          <Route path="/update-payment/:userId" element={<Pages.UpdatePayment />} />
          <Route path="/patient-info/:patient_id" element={<Pages.PatientDashboardRedirect />} />
          <Route
            path="/email-confirmation/:allow/:customer_id"
            element={<Pages.EmailConfirmation />}
          />
          {/* ALL RESTRICTED ROUTES */}
          <Route
            path="/dashboard"
            element={
              <RequireAuth permission={'view_dashboard'}>
                <PortalDashboard.Dashboard />
              </RequireAuth>
            }
          />

          <Route
            path="/dashboard/retried-summary-data"
            element={
              <RequireAuth permission={'view_dashboard'}>
                <PortalDashboard.RetriedSummaryData />
              </RequireAuth>
            }
          />

          <Route
            path="/customer-dashboard"
            element={
              <RequireAuth permission={'view_customer_dashboard'}>
                <PortalDashboard.CustomerDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/manage-roles"
            element={
              <RequireAuth permission={'view_manage_roles'}>
                <PortalDashboard.ManageRoles />
              </RequireAuth>
            }
          />
          <Route
            path="/mail"
            element={
              <RequireAuth permission={'view_mail'}>
                <PortalDashboard.Mail />
              </RequireAuth>
            }
          />
          <Route
            path="/mail/mail-customers"
            element={
              <RequireAuth permission={'generate_mail'}>
                <PortalDashboard.GenerateMail />
              </RequireAuth>
            }
          />
          <Route
            path="/mail/mail-employees"
            element={
              <RequireAuth permission={'generate_mail'}>
                <PortalDashboard.EmployeeMail />
              </RequireAuth>
            }
          />
          <Route
            path="/email-error-log"
            element={
              <RequireAuth permission={'view_email_error_log'}>
                <PortalDashboard.EmailErrorLog />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard-invoices"
            element={
              <RequireAuth permission={'view_dashboard_invoices'}>
                <PortalDashboard.DashboardInvoices />
              </RequireAuth>
            }
          />
          <Route
            path="/plan-list"
            element={
              <RequireAuth permission={'view_plan_list'}>
                <PortalDashboard.PlanList />
              </RequireAuth>
            }
          />
          <Route
            path="/create-plan"
            element={
              <RequireAuth permission={'create_plan'}>
                <PortalDashboard.CreatePlan />
              </RequireAuth>
            }
          />
          <Route
            path="/edit-plan"
            element={
              <RequireAuth permission={'edit_plan'}>
                <PortalDashboard.EditPlan />
              </RequireAuth>
            }
          />

          <Route
            path="/customer-list"
            element={
              <RequireAuth permission={'view_customer_list'}>
                <PortalDashboard.CustomerList />
              </RequireAuth>
            }
          />
          <Route
            path="/create-customer"
            element={
              <RequireAuth permission={'create_customer'}>
                <PortalDashboard.CreateCustomer />
              </RequireAuth>
            }
          />
          <Route
            path="/customer-info/:hspp_id"
            element={
              <RequireAuth permission={'view_customer_info'}>
                <PortalDashboard.CustomerInfo />
              </RequireAuth>
            }
          />

          <Route
            path="/non-hspp-patients"
            element={
              <RequireAuth permission={'view_non_hspp_patients'}>
                <PortalDashboard.NonHsppPatients />
              </RequireAuth>
            }
          />
          <Route
            path="/employee-list"
            element={
              <RequireAuth permission={'view_employee_list'}>
                <PortalDashboard.EmployeeList />
              </RequireAuth>
            }
          />
          <Route
            path="/create-employee"
            element={
              <RequireAuth permission={'create_employee'}>
                <PortalDashboard.CreateEmployee />
              </RequireAuth>
            }
          />
          <Route
            path="/subscription-list"
            element={
              <RequireAuth permission={'view_subscription_list'}>
                <PortalDashboard.SubscriptionList />
              </RequireAuth>
            }
          />
          <Route
            path="/invoice-list"
            element={
              <RequireAuth permission={'view_invoice_list'}>
                <PortalDashboard.InvoiceList />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth permission={'view_profile'}>
                <PortalDashboard.Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/customer-profile"
            element={
              <RequireAuth permission={'view_customer_profile'}>
                <PortalDashboard.CustomerProfile />
              </RequireAuth>
            }
          />
          <Route
            path="tickets"
            element={
              <RequireAuth permission={'view_tickets'}>
                <PortalDashboard.Ticket />
              </RequireAuth>
            }
          />
          <Route
            path="chargeback-dashboard"
            element={
              <RequireAuth permission={'view_chargeback_dashboard'}>
                <PortalDashboard.Chargeback />
              </RequireAuth>
            }
          />
          <Route
            path="chargeback-list"
            element={
              <RequireAuth permission={'view_chargeback_list'}>
                <PortalDashboard.ChargebackList />
              </RequireAuth>
            }
          />
          <Route
            path="/deleted-customer-list"
            element={
              <RequireAuth permission={'view_recycle_bin'}>
                <PortalDashboard.RecycleBin />
              </RequireAuth>
            }
          />
          <Route
            path="/reports"
            element={
              <RequireAuth permission={'view_reports'}>
                <PortalDashboard.Reports />
              </RequireAuth>
            }
          />
          <Route
            path="/dropped-patients-report"
            element={
              <RequireAuth permission={'view_dropped_patients_report'}>
                <PortalDashboard.DroppedPatientsCountReports />
              </RequireAuth>
            }
          />
          <Route
            path="/dropped-patients-invoices-report"
            element={
              <RequireAuth permission={'view_dropped_patients_invoice_report'}>
                <PortalDashboard.DroppedPatientsInvoicesReports />
              </RequireAuth>
            }
          />
          <Route
            path="/protection-plan-battery-report"
            element={
              <RequireAuth permission={'view_protection_plan_battery_report'}>
                <PortalDashboard.ProtectionPlanBatteryMailer />
              </RequireAuth>
            }
          />
          <Route
            path="/invalid-battery-report"
            element={
              <RequireAuth permission={'view_protection_plan_battery_report'}>
                <PortalDashboard.InvalidBatteryReport />
              </RequireAuth>
            }
          />
          <Route
            path="/active-hspp-patient-report"
            element={
              <RequireAuth permission={'view_active_hspp_patient_report'}>
                <PortalDashboard.ActiveHsppPatientReport />
              </RequireAuth>
            }
          />
          <Route
            path="/active-hspp-report-based-on-plan-name"
            element={
              <RequireAuth permission={'view_active_hspp_patient_report_based_on_plan_name'}>
                <PortalDashboard.ActiveHsppReportBasedOnPlanName />
              </RequireAuth>
            }
          />
          <Route
            path="/active-hspp-patient-history-report"
            element={
              <RequireAuth permission={'view_active_hspp_patient_history_report'}>
                <PortalDashboard.ActiveHsppPatientHistoryReport />
              </RequireAuth>
            }
          />
          <Route
            path="/amplifon-patient-report"
            element={
              <RequireAuth permission={'view_amplifon_patients_report'}>
                <PortalDashboard.AmplifonPatientReport />
              </RequireAuth>
            }
          />
          <Route
            path="/chargeback-report"
            element={
              <RequireAuth permission={'view_chargeback_report'}>
                <PortalDashboard.ChargebackReport />
              </RequireAuth>
            }
          />
          <Route
            path="/newsletter-report"
            element={
              <RequireAuth permission={'view_newsletter_report'}>
                <PortalDashboard.newsLetterReport />
              </RequireAuth>
            }
          />
          <Route
            path="/hspp-attatchment-report"
            element={
              <RequireAuth permission={'view_hspp_attatchment_report'}>
                <PortalDashboard.HsppAttatchmentReport />
              </RequireAuth>
            }
          />
          <Route
            path="/future-plans-report"
            element={
              <RequireAuth permission={'view_future_plans_report'}>
                <PortalDashboard.FuturePlansReport />
              </RequireAuth>
            }
          />
          <Route
            path="/loss-and-damage-report"
            element={
              <RequireAuth permission={'view_loss_and_damage_report'}>
                <PortalDashboard.LossAndDamageReport />
              </RequireAuth>
            }
          />
          <Route
            path="/card-update-report"
            element={
              <RequireAuth permission={'view_card_update_report'}>
                <PortalDashboard.CardUpdateReport />
              </RequireAuth>
            }
          />
          <Route
            path="/subscription-plan-change-report"
            element={
              <RequireAuth permission={'subscription_plan_change_report'}>
                <PortalDashboard.SubscriptionPlanChangeReport />
              </RequireAuth>
            }
          />
          <Route
            path="/contest"
            element={
              <RequireAuth permission={'view_contest'}>
                <PortalDashboard.Contest />
              </RequireAuth>
            }
          />
          <Route
            path="/contest-prize"
            element={
              <RequireAuth permission={'view_contest'}>
                <PortalDashboard.ContestPrize />
              </RequireAuth>
            }
          />
          <Route
            path="/ongoing-contest-record"
            element={
              <RequireAuth permission={'view_contest'}>
                <PortalDashboard.OngoingContestRecord />
              </RequireAuth>
            }
          />
          <Route
            path="/contest-achievement"
            element={
              <RequireAuth permission={'view_contest'}>
                <PortalDashboard.ContestAchievement />
              </RequireAuth>
            }
          />
          <Route path="/view-invoice/:id" element={<PortalDashboard.InvoicePdf />} />
          <Route path="/subscription-details" element={<PortalDashboard.SubscriptionDetails />} />
          <Route
            path="/loss-and-damage-approvals"
            element={
              <RequireAuth permission={'view_loss_and_damage_approvals'}>
                <PortalDashboard.LossAndDamageApprovals />
              </RequireAuth>
            }
          />
          <Route
            path="/accounting"
            element={
              <RequireAuth permission={'view_accounting'}>
                <PortalDashboard.Accouning />
              </RequireAuth>
            }
          />
          <Route
            path="/accounting/revenue-report"
            element={
              <RequireAuth permission={'view_revenue_report'}>
                <PortalDashboard.RevenueReport />
              </RequireAuth>
            }
          />
          <Route
            path="/accounting/revenue-patients-report"
            element={
              <RequireAuth permission={'view_revenue_patients_report'}>
                <PortalDashboard.RevenuePatientsReport />
              </RequireAuth>
            }
          />
          <Route
            path="/chargeback"
            element={
              <RequireAuth permission={'view_chargeback_dashboard'}>
                <PortalDashboard.Chargeback />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
          {/* <Route element={<RequireUserAuth />}>
            <Route path="/customer/dashboard" element={<Customers.Dashboard />} />
            <Route path="/customer/profile" element={<Customers.Profile />} />
            <Route path="/customer/view-invoice/:id" element={<Customers.InvoicePdf />} />
            <Route path="*" element={<NotFound />} />
          </Route> */}
          {/* <Route element={<RequireEmployeeAuth />}>
            <Route path="/dashboard" element={<Employees.Dashboard />} />
            <Route path="/dashboard/failed-invoices" element={<Employees.FailedInvoices />} />
            <Route path="/customer-list" element={<Employees.CustomerList />} />
            <Route path="/customer-info" element={<Employees.CustomerInfo />} />
            <Route path="/create-customer" element={<Employees.CreateCustomer />} />
            <Route path="/employee/invoice-list" element={<Employees.InvoiceList />} />
            <Route path="/employee/profile" element={<Employees.Profile />} />
            <Route path="/employee/subscription-list" element={<Employees.SubscriptionList />} />
            <Route path="/view-invoice/:id" element={<Employees.InvoicePdf />} />
            <Route path="*" element={<NotFound />} />
          </Route> */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const NotFound = () => {
  return <h1>404 Not Found</h1>;
};

const Forbidden = () => {
  return <h1>403 Forbidden. You dont have access to this page.</h1>;
};

const RequireAuth = ({ permission, ...props }) => {
  const location = useLocation();
  const { userSession } = useSelector((state) => state.session);

  if (userSession == null)
    // Still loading data from localstorage
    return (
      <div className="preloader">
        <img src={loader} className="w-60" />
      </div>
    );
  else if (userSession == '')
    // Localstorage loaded but no user session found. Redirect to login page.
    return <Navigate to="/" state={{ from: location }} />;
  else if (!userSession?.permissions.includes(permission))
    // User don't have access
    return <Forbidden />;
  else return props.children; // Session present and Localstorage loaded
};

export default Navigation;
