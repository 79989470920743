import React from 'react';
import { Card, Dropdown, DatePicker, Table, Button } from 'components';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { currencyFormatter } from 'services/helpers';

const Section = ({ _this }) => {
  const totalRevenue = () => {
    let revenue = 0;
    _this.tableData.length > 0 &&
      _this.tableData.forEach((element) => {
        revenue = revenue + element.amount;
      });

    return currencyFormatter.format(revenue.toFixed(2));
  };
  return (
    <Card className="flex mr-2 mt-2">
      <div className="relative lg:flex flex-col items-center sm:flex-row gap-2 justify-between mb-2">
        <div>
          <h3 className="text-body-color text-xl font-semibold pb-2">Revenue Patients Report</h3>
          <p className="text-body-color text-lg font-semibold pb-2">
            Total Revenue : {totalRevenue()}
          </p>
          <input
            className="w-64 rounded-md"
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
        </div>
        <DatePicker
          label="Start Month"
          isMonth={true}
          required
          value={_this.revenuePatientsReportParams?.start_date || ''}
          onChange={(e) => {
            _this.setRevenuePatientsReportParams((prev) => ({
              ...prev,
              start_date: moment(e).startOf('month').toDate()
            }));
          }}
        />

        <DatePicker
          label="End Month"
          isMonth={true}
          required
          value={_this.revenuePatientsReportParams?.end_date || ''}
          onChange={(e) => {
            _this.setRevenuePatientsReportParams((prev) => ({
              ...prev,
              end_date: moment(e).endOf('month').toDate()
            }));
          }}
        />
        <Dropdown
          className="w-48 z-50"
          label="Location"
          placeholder="@location"
          required
          type="text"
          options={_this.locationList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
          aria-label="Default select example"
          value={_this.revenuePatientsReportParams.locations}
          onChange={(e) => {
            _this.setRevenuePatientsReportParams((prev) => ({
              ...prev,
              locations: e
            }));
          }}
          isMulti
        />

        <div>
          <button
            className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
            onClick={_this.init}
          >
            Generate Report
          </button>
          <button
            className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
            onClick={_this.exportReport}
          >
            Export
          </button>
        </div>
      </div>

      <Table
        keyField="_id"
        columns={_this?.column}
        data={_this.tableData}
        headerComponent={null}
        minRows={20}
      />
    </Card>
  );
};

export default Section;
