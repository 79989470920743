import {
  MdDashboard,
  MdArticle,
  MdPeople,
  MdDelete,
  MdManageAccounts,
  MdAccountBalanceWallet
} from 'react-icons/md';
import { FaFileInvoice, FaTicketAlt } from 'react-icons/fa';
import { BsJournalBookmarkFill } from 'react-icons/bs';
import { RiLogoutBoxFill } from 'react-icons/ri';
import { HiUserCircle } from 'react-icons/hi';
import { BsBriefcaseFill } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr';
import { TbReportAnalytics } from 'react-icons/tb';
import { MdPersonAddDisabled } from 'react-icons/md';
import { RiMailForbidFill } from 'react-icons/ri';
import { FaAward } from 'react-icons/fa';
import { BsStickiesFill } from 'react-icons/bs';
import { FaMoneyBillTransfer } from 'react-icons/fa6';

const tabs = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: MdDashboard,
    permission: 'view_dashboard'
  },
  {
    name: 'Chargeback',
    to: '/chargeback',
    icon: FaMoneyBillTransfer,
    permission: 'view_chargeback_dashboard'
  },
  {
    name: 'Patient Dashboard',
    to: '/customer-dashboard',
    icon: MdDashboard,
    permission: 'view_customer_dashboard'
  },
  {
    name: 'Manage Roles',
    to: '/manage-roles',
    icon: MdManageAccounts,
    permission: 'view_manage_roles'
  },
  { name: 'Mail', to: '/mail', icon: GrMail, permission: 'view_mail' },
  {
    name: 'Email Error Log',
    to: '/email-error-log',
    icon: RiMailForbidFill,
    permission: 'view_mail'
  },
  { name: 'Customers', to: '/customer-list', icon: MdPeople, permission: 'view_customer_list' },
  {
    name: 'Non HSPP Patients',
    to: '/non-hspp-patients',
    icon: MdPersonAddDisabled,
    permission: 'view_non_hspp_patients'
  },
  {
    name: 'Employees',
    to: '/employee-list',
    icon: BsBriefcaseFill,
    permission: 'view_employee_list'
  },
  { name: 'Plans', to: '/plan-list', icon: MdArticle, permission: 'view_plan_list' },
  {
    name: 'Subscriptions',
    to: '/subscription-list',
    icon: BsJournalBookmarkFill,
    permission: 'view_subscription_list'
  },
  { name: 'Invoices', to: '/invoice-list', icon: FaFileInvoice, permission: 'view_invoice_list' },
  { name: 'Profile', to: '/profile', icon: HiUserCircle, permission: 'view_profile' },
  {
    name: 'Patient Profile',
    to: '/customer-profile',
    icon: HiUserCircle,
    permission: 'view_customer_profile'
  },
  { name: 'Tickets', to: '/tickets', icon: FaTicketAlt, permission: 'view_tickets' },
  {
    name: 'L&D Approvals',
    to: '/loss-and-damage-approvals',
    icon: BsStickiesFill,
    permission: 'view_loss_and_damage_approvals'
  },
  {
    name: 'Reports',
    to: '/reports',
    icon: TbReportAnalytics,
    permission: 'view_reports'
  },
  {
    name: 'Accounting',
    to: '/accounting',
    icon: MdAccountBalanceWallet,
    permission: 'view_accounting'
  },
  {
    name: 'Contest',
    to: '/contest',
    icon: FaAward,
    permission: 'view_contest'
  },
  {
    name: 'Recycle Bin',
    to: '/deleted-customer-list',
    icon: MdDelete,
    permission: 'view_recycle_bin'
  },
  { name: 'Logout', to: '/', icon: RiLogoutBoxFill, permission: 'logout' }
];

export default tabs;
