import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
const Index = () => {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    init();
    // loadLocationGroup();
  }, []);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  // const loadLocationGroup = async () => {
  //   dispatch(loadingStart());
  //   await API.fetchLocationGroup()
  //     .then((response) => {
  //       if (response) {
  //         const list = response.map((item) => ({
  //           label: item.location_group,
  //           value: item.location_group
  //         }));
  //         setLocationList(list);
  //       }
  //     })
  //     .finally(() => {
  //       dispatch(loadingStop());
  //     });
  // };

  const init = async () => {
    dispatch(loadingStart());
    await API.fetchContestAchievementReocrd()
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData,
          setTableData,
          searchInput,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
