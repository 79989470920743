import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [inSync, setInSync] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const reloadData = async () => {
    try {
      const response = await API.getAmplifonPatientReport();
      if (response) setTableData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const init = () => {
    toast.promise(reloadData, {
      pending: 'Generating patients report...',
      success: 'Report is generated',
      error: 'Failed to generate report'
    });
  };

  // not used for now...
  const syncReport = () => {
    setInSync(true);
    toast
      .promise(
        async () => {
          await API.SyncAmplifonPatientReport();
          await reloadData();
        },
        {
          pending: 'Report sync is in progress',
          success: 'Report is synced now',
          error: 'Failed to sync report'
        }
      )
      .then(() => setInSync(false));
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
