import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Form, Table } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdPlaylistAddCircle, MdFormatListBulletedAdd, MdLibraryAdd } from 'react-icons/md';
import { IoMdAdd, IoIosAddCircleOutline } from 'react-icons/io';
import ButtonBTN from 'react-bootstrap/Button';

import {
  Card,
  Button,
  Input,
  Dropdown,
  DatePicker,
  Row,
  Checkbox,
  AutSuggestInput,
  TextArea
} from 'components';

const SummaryModal = ({ _this }) => {
  const navigate = useNavigate();
  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this?.summaryModalOpen}
      centered
      onHide={() => _this?.setSummaryModalOpen(false)}
      size="lg"
      scrollable
      // className="z-50"
      className="my-20 h-[80%]"
    >
      <Modal.Header>
        <Modal.Title>Summary</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => {
            _this?.setSummaryModalOpen(false);
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="w-full h-[90%] table-responsive">
            {/* <p className="text-lg font-semibold">OPS</p> */}
            <Table bordered hover size="sm">
              <thead className="text-sm">
                <tr>
                  <th>Summary</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {_this.summaryData &&
                  Object.entries(_this.summaryData).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td
                        className="text-button-primary cursor-pointer"
                        onClick={() => {
                          const data = localStorage.getItem('retryPayments');
                          if (data) {
                            localStorage.removeItem('retryPayments');
                          }

                          localStorage.setItem('retryPayments', JSON.stringify(value.data));
                        }}
                      >
                        <Link
                          to={{
                            pathname: '/dashboard/retried-summary-data'
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          // target="_blank"
                          // to={{
                          //   pathname: '/dashboard/retried-summary-data',
                          //   state: {
                          //     data: value.data
                          //   }
                          // }}
                        >
                          {value.count}
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.setSummaryModalOpen(false);
          }}
          // color={_this.acceptOrDenyApprovalData?.accept ? 'primary' : 'danger'}
        >
          Go Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SummaryModal;
