import React from 'react';
import { SuperAdminProfile } from 'components';

const Section = ({ _this }) => {
  return (
    <SuperAdminProfile
      admin={_this.employeeDetails}
      is_password_changed={_this.employeeDetails?.is_password_changed}
      init={_this.init}
    />
  );
};

export default Section;
