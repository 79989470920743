import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import { BiEditAlt } from 'react-icons/bi';
import { FaSyncAlt } from 'react-icons/fa';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const location_group = dataObject.location_group.toString();
      const category = dataObject.category;
      const current_active_patient = dataObject.current_active_patients;
      const patients_added = dataObject.patients_added;
      const ongoing_hurdle_name = dataObject.ongoing_hurdle;
      const ongoing_hurdle_value = dataObject.ongoing_hurdle_value;
      const crossed_hurdle = dataObject.crossed_hurdle;
      const prize_achieved = dataObject.prize_achieved;

      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        'Location Group': location_group,
        Category: category,
        'Current Active Patient': current_active_patient,
        'Patient Added': patients_added,
        'Ongoing Hurdle Name': ongoing_hurdle_name,
        'Ongoing Hurdle': ongoing_hurdle_value,
        'Crossed Hurdle': crossed_hurdle,
        'Prize Achieved': prize_achieved
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'OngoingHurdleReport' + fileExtension);
  };

  const columns = [
    {
      accessor: 'location_group',
      Header: 'Location Group',
      Cell: (row) => <div>{row?.original?.location_group}</div>
    },
    {
      accessor: 'category',
      Header: 'Category',
      Cell: (row) => <div>{row?.original?.category}</div>
    },
    {
      accessor: 'current_active_patients',
      Header: 'Current Active Patient',
      Cell: (row) => <div>{row?.original?.current_active_patients}</div>
    },
    {
      accessor: 'patients_added',
      Header: 'Patient Added',
      Cell: (row) => <div>{row?.original?.patients_added}</div>
    },
    {
      accessor: 'ongoing_hurdle',
      Header: 'Ongoing Hurdle Name',
      Cell: (row) => <div>{row?.original?.ongoing_hurdle}</div>
    },
    {
      accessor: 'ongoing_hurdle_value',
      Header: 'Ongoing Hurdle',
      Cell: (row) => <div>{row?.original?.ongoing_hurdle_value}</div>
    },
    {
      accessor: 'crossed_hurdle',
      Header: 'Crossed Hurdle',
      Cell: (row) => <div>{row?.original?.crossed_hurdle}</div>
    },
    {
      accessor: 'prize_achieved',
      Header: 'Prize Achieved',
      Cell: (row) => <div>{row.original.prize_achieved}</div>
    }
  ];

  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card className="flex mr-2 mt-2">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between mb-2">
          <h3 className="text-body-color text-xl font-semibold pb-2">Ongoing Contest Records</h3>
          {/* <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          /> */}
          <ButtonBTN
            onClick={() => _this.syncReport()}
            className={`bg-button-primary mb-2 ml-2 flex justify-between items-center h-10`}
            variant="primary"
            id="export"
            disabled={_this.inSync}
          >
            Sync <FaSyncAlt className={`ml-1 ${_this.inSync ? 'animate-spin' : ''}`} />
          </ButtonBTN>
          {/* <div>
            <h3 className="text-gray-500 text-lg font-semibold pb-1">Tier 1 : 0 - 199 Patients</h3>
            <h3 className="text-gray-500 text-lg font-semibold pb-1">Tier 2 : 200 + Patients</h3>
          </div> */}
          <input
            className="h-10"
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />

          <ButtonBTN
            onClick={() => _this.addOngoingContestRecord()}
            className={`bg-button-primary h-10`}
            variant="primary"
            id="export"
          >
            Update Record
          </ButtonBTN>

          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary h-10`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default Section;
