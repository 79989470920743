import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import { idahoLocations } from 'services/helpers';

const Index = () => {
  const dispatch = useDispatch();
  const [searchFromDate, setSearchFromDate] = useState('');
  const [searchToDate, setSearchToDate] = useState('');
  const [searchFieldOption, setSearchFieldOption] = useState({
    'HSPP ID': 'hspp_id',
    'Patient ID': 'customer_id',
    'First Name': 'first_name',
    'Last Name': 'last_name',
    Email: 'email',
    'Created On': 'createdAt',
    Location: 'location_name',
    'Active Plan': 'active_plan',
    'Last Four': 'last_four',
    Status: 'status',
    'Transaction ID': 'transaction_id'
  });
  const [tableData, setTableData] = useState([]);
  const [initialData, setInitialData] = useState({ total_pages: 0, data: [], total_items: 0 });
  const [count, setCount] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [payload, setPayload] = useState(null);
  const [search, setSearch] = useState({ searchField: 'customer_id', searchInput: '' });
  const [countFieldOption, setCountFieldOption] = useState({
    Today: 'today',
    Yesterday: 'yesterday',
    ThisWeek: 'week',
    ThisMonth: 'month',
    ThisYear: 'ytd'
  });
  const [currentSelectedCountOption, setCurrentSelectedCountOption] = useState(null);
  const [pageDataLimit, setPageDataLimit] = useState(20);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const exportDataFields = [
    { name: 'patient_id', displayName: 'Patient ID', defaultChecked: true },
    { name: 'hspp_id', displayName: 'HSPP ID', defaultChecked: true },
    { name: 'patient_name', displayName: 'Patient Name', defaultChecked: true },
    { name: 'location_name', displayName: 'Location Name', defaultChecked: true },
    { name: 'createdAt', displayName: 'Created On', defaultChecked: true },
    { name: 'email', displayName: 'Email', defaultChecked: false },
    { name: 'phone', displayName: 'Phone', defaultChecked: false },
    { name: 'shipping_address', displayName: 'Shipping Address', defaultChecked: false },
    { name: 'billing_address', displayName: 'Billing Address', defaultChecked: false },
    { name: 'r_battery_size', displayName: 'R Battery Size', defaultChecked: false },
    { name: 'l_battery_size', displayName: 'L Battery Size', defaultChecked: false },
    { name: 'r_serial_number', displayName: 'R Serial Number', defaultChecked: false },
    { name: 'l_serial_number', displayName: 'L Serial Number', defaultChecked: false },
    { name: 'r_warranty_date', displayName: 'R Warranty Date', defaultChecked: false },
    { name: 'l_warranty_date', displayName: 'L Warranty Date', defaultChecked: false },
    { name: 'last_invoice_date', displayName: 'Last Invoice Date', defaultChecked: false },
    { name: 'next_invoice_date', displayName: 'Next Invoice Date', defaultChecked: false },
    { name: 'plan_name', displayName: 'Plan Name', defaultChecked: false },
    { name: 'amount', displayName: 'Amount', defaultChecked: false },
    // { name: 'active_plan', displayName: 'Active Plan', defaultChecked: false },
    { name: 'status', displayName: 'Status', defaultChecked: false },
    { name: 'expiry_date', displayName: 'Expiry Date', defaultChecked: false }
  ];
  const [checkedFields, setCheckedFields] = useState(() => {
    const initialCheckedFields = {};
    exportDataFields.forEach((field) => {
      initialCheckedFields[field.name] = field.defaultChecked;
    });
    return initialCheckedFields;
  });
  const [isLoading, setIsLoading] = useState(false);

  const toggleCheckbox = (fieldName) => {
    setCheckedFields((prevCheckedFields) => ({
      ...prevCheckedFields,
      [fieldName]: !prevCheckedFields[fieldName]
    }));
  };

  useEffect(() => {
    fetchData(0, limitPerPage, null, true);
  }, []);
  useEffect(() => {
    if (search === '') {
      setTableData(initialData);
    }
  }, [search]);

  const fixMultipleLocations = (store_location) => {
    const uniqueLocation = new Set();

    // Loop through the data array and add unique names to the Set
    store_location && store_location?.forEach((item) => uniqueLocation.add(item));

    // Convert the Set back to an array
    const uniqueLocationsArray = Array.from(uniqueLocation);

    const newValue = uniqueLocationsArray.join(', ');

    return newValue;
  };

  ////////////    CHECK IF EMPLOYEE IS FROM IDAHO STATE
  const { userSession: user } = useSelector((state) => state.session);
  const [isIdaho, setIsIdaho] = useState(false);
  useEffect(() => {
    if (idahoLocations.includes(user?.location[0])) setIsIdaho(true);
  }, [user]);

  const fetchData = async (
    page,
    limit,
    payload,
    initial = false,
    sortingField = 'createdAt',
    sortingDirection = true
  ) => {
    dispatch(loadingStart());
    await API.getCustomerList(page, limit, payload, sortingField, sortingDirection)
      .then((response) => {
        if (response) {
          setTableData(response.data);
          if (initial) {
            setInitialData(response);
          }
          if (response.total_pages >= 0) setTotalPage(response.total_pages);
          if (response.total_items >= 0) setCount(response.total_items);
        }
        if (page < 1) setCurrentPage(0);
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const searchData = (e) => {
    if (e) e.preventDefault();
    const payload = search;
    if (payload.searchField && (payload.searchInput || (searchFromDate && searchToDate))) {
      if (payload.searchField === 'createdAt') {
        const fromDate = moment(searchFromDate).format('YYYY-MM-DD');
        const toDate = moment(searchToDate).add(1, 'days').format('YYYY-MM-DD');
        payload.searchInput = `${fromDate}to${toDate}`;
      }
      fetchData(0, limitPerPage, payload);
      setCurrentSelectedCountOption('');
      setPayload(payload);
    } else {
      setTableData(initialData.data);
      setTotalPage(initialData.total_pages);
      setCount(initialData.total_items);
      setCurrentPage(0);
    }
  };

  const deleteCustomer = async (id) => {
    dispatch(loadingStart());
    await API.deleteCustomer({ id })
      .then((response) => {
        if (response) {
          toast.success('Customer Deleted Successfully');
          fetchData(currentPage, limitPerPage, payload);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const handelActiveCount = (e) => {
    setCurrentSelectedCountOption(e.target.value);
    setSearch({ searchField: '', searchInput: '' });
    let payload = {
      searchField: 'createdAt'
    };
    if (e.target.value === 'Today') {
      const todayStart = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const todayEnd = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
      payload['searchInput'] = `${todayStart}to${todayEnd}`;
    } else if (e.target.value === 'Yesterday') {
      const yesterdayStart = moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      const yesterdayEnd = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
      payload['searchInput'] = `${yesterdayStart}to${yesterdayEnd}`;
    } else if (e.target.value === 'ThisWeek') {
      const startOfWeek = moment().startOf('week').format('YYYY-MM-DD HH:mm:ss');
      const endOfWeek = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
      payload['searchInput'] = `${startOfWeek}to${endOfWeek}`;
    } else if (e.target.value === 'ThisMonth') {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
      const endOfMonth = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
      payload['searchInput'] = `${startOfMonth}to${endOfMonth}`;
    } else if (e.target.value === 'ThisYear') {
      const oneYearAgo = moment().subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss');
      const todayEnd = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
      payload['searchInput'] = `${oneYearAgo}to${todayEnd}`;
    } else {
      payload = null;
    }
    setPayload(payload);
    if (payload) {
      fetchData(0, limitPerPage, payload);
    } else {
      setTableData(initialData.data);
      setTotalPage(initialData.total_pages);
      setCount(initialData.total_items);
      setCurrentPage(0);
    }
  };

  const exportCustomer = () => {
    const extractedDataArray = [];
    for (const dataObject of tableData) {
      const customerId = dataObject.customer_id;
      const customerName = `${dataObject.first_name} ${dataObject.last_name}`;
      const storeLocations = dataObject.store_location.toString();
      const createdAt = new Date(dataObject.createdAt);
      const activePlans = dataObject.active_plans;
      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        CustomerID: customerId,
        CustomerName: customerName,
        StoreLocations: storeLocations,
        CreatedAt: createdAt,
        ActivePlans: activePlans
        // Address: formattedAddress
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'CustomerList' + fileExtension);
  };

  const handelExportClick = async () => {
    // console.log(checkedFields);
    setIsLoading(true);
    const res = await API.ExportCustomer({ fields: checkedFields, filter: payload });
    if (res) {
      const desiredOrder = [
        'patient_id',
        'hspp_id',
        'patient_name',
        'location_name',
        'email',
        'phone',
        'shipping_address',
        'billing_address',
        'r_battery_size',
        'l_battery_size',
        'r_serial_number',
        'l_serial_number',
        'r_warranty_date',
        'l_warranty_date',
        'last_invoice_date',
        'next_invoice_date',
        'plan_name',
        'amount',
        'status',
        'expiry_date',
        'createdAt'
      ];

      const orderedDataArray = res.map((item) => {
        const orderedItem = {};

        desiredOrder.forEach((key) => {
          if (item[key] !== undefined) {
            orderedItem[key] = item[key];
          }
        });

        return orderedItem;
      });

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(orderedDataArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'data');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      // Create a Blob and save the Excel file
      const data = new Blob([excelBuffer], { type: fileType });
      FileServer.saveAs(data, 'CustomerList' + fileExtension);
      // console.log(res);
      setExportModalVisible(false);
    }
    setIsLoading(false);
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          setSearch,
          search,
          searchData,
          searchFieldOption,
          setSearchFieldOption,
          searchFromDate,
          setSearchFromDate,
          searchToDate,
          setSearchToDate,
          deleteCustomer,
          fixMultipleLocations,
          exportCustomer,
          countFieldOption,
          handelActiveCount,
          pageDataLimit,
          setPageDataLimit,
          currentSelectedCountOption,
          totalCustomerCount,
          currentPage,
          setCurrentPage,
          fetchData,
          tableData,
          count,
          totalPage,
          limitPerPage,
          setLimitPerPage,
          payload,
          exportModalVisible,
          setExportModalVisible,
          checkedFields,
          setCheckedFields,
          toggleCheckbox,
          exportDataFields,
          handelExportClick,
          isLoading,
          isIdaho
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
