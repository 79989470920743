import React from 'react';
import { Table } from 'components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { Modal, Form } from 'react-bootstrap';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Body = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const extractedData = {
        'Patient ID': dataObject.patient_id,
        'Patient Name': dataObject.patient_name,
        Location: dataObject.location,
        'Current Plan Name': dataObject.current_plan_name,
        'Current Plan Amount': dataObject.current_plan_amount,
        'Expiry Date': moment(dataObject.expiry_date).format('YYYY-MM-DD'),
        'Plan Interval': dataObject.plan_interval,
        'New Plan Name': dataObject.new_plan_name,
        'New Plan Amount': dataObject.new_plan_amount,
        'Plan Renewal Date': moment(dataObject.plan_renewal_date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'SubscriptionPlanChangeReport' + fileExtension);
  };

  const columns = [
    {
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747', fontSize: '90%' },
      accessor: 'patient_id',
      style: { backgroundColor: '#C6C6C6' },
      Header: 'Patient ID',
      Cell: (row) => (
        <Link
          to={`/customer-info/${row.original.customer_mongo_id}`}
          className="text-blue-500 font-medium"
        >
          {row.original.patient_id}
        </Link>
      )
    },
    {
      accessor: 'patient_name',
      headerStyle: { fontSize: '90%' },
      Header: 'Patient Name',
      Cell: (row) => <div>{row.original.patient_name}</div>
    },
    {
      accessor: 'location',
      headerStyle: { fontSize: '90%' },
      Header: 'Location',
      Cell: (row) => <div>{row.original.location}</div>
    },
    {
      accessor: 'current_plan_name',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Plan Name',
      minWidth: 200,
      Cell: (row) => <div>{row.original.current_plan_name}</div>
    },
    {
      accessor: 'old_plan_amount',
      headerStyle: { fontSize: '90%' },
      Header: 'Old Plan Amount',
      Cell: (row) => <div>{row.original.old_plan_amount}</div>
    },
    {
      accessor: 'expiry_date',
      headerStyle: { fontSize: '90%' },
      Header: 'Expiry Date',
      Cell: (row) => <div>{moment(row.original.expiry_date).format('YYYY-MM-DD')}</div>
    },
    {
      accessor: 'plan_interval',
      headerStyle: { fontSize: '90%' },
      Header: 'Plan Interval',
      Cell: (row) => <div>{row.original.plan_interval}</div>
    },
    {
      accessor: 'new_plan_name',
      headerStyle: { fontSize: '90%' },
      Header: 'New Plan Name',
      minWidth: 200,
      Cell: (row) => <div>{row.original.new_plan_name}</div>
    },
    {
      accessor: 'new_plan_amount',
      headerStyle: { fontSize: '90%' },
      Header: 'New Plan Amount',
      Cell: (row) => <div>{row.original.new_plan_amount}</div>
    },
    {
      accessor: 'plan_renewal_date',
      headerStyle: { fontSize: '90%' },
      Header: 'Plan Renewal Date',
      Cell: (row) => (
        <div>{moment(row.original.expiry_date).add(1, 'day').format('YYYY-MM-DD')}</div>
      )
    }
  ];
  return (
    <Card className="flex mr-2 mt-2" paddingClass="px-1 py-2">
      <div className="flex justify-between items-center p-2">
        <div className="flex gap-2">
          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary  mb-2`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
        </div>
        <h3 className="text-body-color text-xl font-semibold">
          Subscription Plan Change Report({_this.tableData?.length})
        </h3>
        <input
          type="search"
          placeholder="Search.."
          value={_this.searchInput}
          onChange={(e) => _this.setSearchInput(e.target.value)}
        />
      </div>

      <Table
        keyField="_id"
        columns={columns}
        data={_this.tableData}
        headerComponent={null}
        minRows={20}
      />
    </Card>
  );
};

export default Body;
