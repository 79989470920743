import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import { FaSyncAlt } from 'react-icons/fa';

const LocationGroup = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const locationGroup = dataObject.location_group;
      const sycle_patient = dataObject.sycle_patient;
      const hspp_patient = dataObject.hspp_patient;
      const hspp_attatchment_rate = dataObject.hspp_attatchment_rate;

      const extractedData = {
        'Location Group': locationGroup,
        'Sycle Patients': sycle_patient,
        'HSPP Patients': hspp_patient,
        'HSPP Attatchment Rate': hspp_attatchment_rate
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'HSPPAttatchmentRateReport' + fileExtension);
  };

  const columns = [
    {
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      accessor: 'location_group',
      Header: 'Location Group',
      Cell: (row) => <div>{row.original.location_group}</div>,
      width: 250
    },
    {
      Header: 'Sycle Patients',
      accessor: 'sycle_patient',
      Cell: (row) => <div>{row.original.sycle_patient}</div>
    },
    {
      Header: 'HSPP Patients',
      accessor: 'hspp_patient',
      Cell: (row) => <div>{row.original.hspp_patient}</div>
    },
    {
      Header: 'HSPP Attachment Rate',
      accessor: 'hspp_attatchment_rate',
      Cell: (row) => <div>{row.original.hspp_attatchment_rate}</div>
    }
  ];
  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card className="flex mr-2 mt-2" paddingClass="px-1 py-2">
        <div className="flex justify-between items-center p-2">
          <div className="flex gap-2">
            <ButtonBTN
              onClick={() => exportReport()}
              className={`bg-button-primary  mb-2`}
              variant="primary"
              id="export"
            >
              Export
            </ButtonBTN>
          </div>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default LocationGroup;
