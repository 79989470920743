import Config from 'config';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import { toast } from 'react-toastify';

export const API = axios.create({
  baseURL: Config.API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
export const APIFORMDATA = axios.create({
  baseURL: Config.API_URL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

export const processFormData = (object) => {
  const formData = new FormData();
  for (const key in object) {
    const value = object[key];
    formData.append(key, value);
  }
  return formData;
};

export const handleResponse = (response) => {
  if (response?.status === 202) toast.error(response.data.error);
  else if (response?.response?.status === 500) toast.error(response?.message);
  else if (response?.response?.status === 401) {
    Config.UNAUTHORIZED_EXCEPTION = true;
    toast.error('You are not authorized for the action.');
  } else if (response?.status === 200) return response?.data;
  else toast.error('Something went wrong. Please contact server admin.');
  return false;
};

export const getToken = async () => {
  const session = localStorage.getItem('userSession')
    ? JSON.parse(localStorage.getItem('userSession'))
    : null;

  let response = session?.access_token;
  if (session && session?.token_expiry - new Date().getTime() < 1) {
    //call refresh token and update client token
    try {
      const apiresponse = await API.get(
        '/v1/auth/refresh-token/' + session._id + '/' + session.active_session_refresh_token
      );
      session.access_token = apiresponse.data;
      session.token_expiry = new Date().getTime() + 23 * 60 * 60 * 1000;
      response = session.access_token;
      localStorage.setItem('userSession', JSON.stringify(session));
    } catch (e) {
      //
    }
  }
  return response;
};

export const SOCKET = async (namespace = '') => {
  /*const token = await getToken();
  const socket = socketIOClient(Config.API_URL + namespace, {
    query: {
      token: token
    },
    forceNew: true
  });
  return socket;*/
};

export const isDataURL = async (s) => {
  const res = (await s.includes('file://')) || s.includes('/private');
  return res;
};

export const getMiracleAppURL = () => {
  return 'https://phpstack-426242-1347501.cloudwaysapps.com/';
};
