import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { loadingStart, loadingStop } from 'redux/action';
import API from 'services/api';
import Body from './Body';
import { useParams } from 'react-router-dom';

const Index = () => {
  let { userId } = useParams();
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState(null);
  const [workDone, setWorkDone] = useState(false);
  const [achPaymentVisibility, setACHPaymentVisibility] = useState(true);
  const [cardDetails, setCardDetails] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  });
  const [achDetails, setACHDetails] = useState({
    name: '',
    routing_number: '',
    account_number: ''
  });

  const validateValues = (fields = [], arr) => {
    for (const field of fields) {
      if (!arr[field]) return false;
    }
    return true;
  };

  //payload for Add New Payment
  const finalDataSubmitforAddNewPayment = () => {
    const card_details = { ...cardDetails, number: cardDetails?.number.toString() };
    const ach_details = { ...achDetails };

    if (
      !validateValues(['cvc', 'expiry', 'name', 'number'], card_details) &&
      !validateValues(['name', 'account_number', 'routing_number'], ach_details)
    )
      return toast.error('Required payment detail fields cannot be blank!');

    const card_temp = cardDetails.expiry.split('/');
    const finalPaymentData = {
      customer_id: userId,
      card_details: {
        card_last_4_digit: card_details.number.substring(card_details.number.length - 4),
        expiry_month: card_temp[0],
        expiry_year: card_temp[1],
        expiry_date: cardDetails.expiry,
        name: card_details.name,
        cvv: cardDetails.cvc,
        card_number: cardDetails.number
      },
      ach_details: {
        account_last_4_digit: ach_details.account_number.substring(
          ach_details.account_number.length - 4
        ),
        account_number: ach_details.account_number,
        name: ach_details.name,
        routing_number: ach_details.routing_number
      }
    };

    addPaymentMethod(finalPaymentData);
  };

  const addPaymentMethod = async (paymentData) => {
    dispatch(loadingStart());
    await API.addNewPaymentByCustomer(paymentData)
      .then((response) => {
        if (response) {
          toast.success('New Payment Method Added, Thank You !');
          setWorkDone(true);
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const updateCardDetails = (key, value) => {
    setCardDetails((state) => {
      return {
        ...state,
        [key]: value
      };
    });
    setCardDetails((state) => {
      return {
        ...state,
        focused: key
      };
    });
  };

  const updateACHDetails = (key, value) => {
    setACHDetails((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };

  const init = async () => {
    dispatch(loadingStart());
    await API.getCustomerDetailsById(userId)
      .then((response) => {
        if (response) {
          setCustomer(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Body
      _this={{
        customer,
        setACHPaymentVisibility,
        cardDetails,
        achDetails,
        achPaymentVisibility,
        updateACHDetails,
        updateCardDetails,
        finalDataSubmitforAddNewPayment,
        workDone
      }}
    />
  );
};

export default Index;
