import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer, Table } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

const Index = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const loggedInUser = useSelector((state) => state.session).userSession;
  const [activeHsppPatientHistoryDetails, setActiveHsppPatientHistoryDetails] = useState(
    state.activeHsppPatientHistoryDetails
  );
  const [locationListForActiveHsppHistoryReport, setLocationListForActiveHsppHistoryReport] =
    useState(null);
  const [franchiseListForActiveHsppHistoryReport, setFranchiseListForActiveHsppHistoryReport] =
    useState(null);
  const [searchInput, setSearchInput] = useState('');

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    loadLocationOptions();
    init();
  }, []);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const loadLocationOptions = async () => {
    await API.getFranchiseLocation()
      .then((response) => {
        if (response) {
          const locationMap = {};

          response.forEach((item) => {
            const { franchiseName, locationId, locationName } = item;
            if (!locationMap[franchiseName]) {
              locationMap[franchiseName] = {
                label: franchiseName,
                value: [{ location_id: locationId, location_name: locationName }]
              };
            } else {
              locationMap[franchiseName].value.push({
                location_id: locationId,
                location_name: locationName
              });
            }
          });

          const locationListForActiveHsppHistory = response.map((item) => ({
            label: item.locationName,
            value: item.locationId
          }));

          const franchiseListForActiveHsppHistory = Object.values(locationMap);

          let userLocationMatchForActiveHSppHistory = [];

          loggedInUser.location.forEach((elem) => {
            userLocationMatchForActiveHSppHistory.push(
              ...locationListForActiveHsppHistory.filter((item) => item.label == elem)
            );
          });

          const userFranchiseMatchForActiveHSppHistory = franchiseListForActiveHsppHistory
            .map((company) => {
              const matchingLocations = company.value.filter((location) =>
                loggedInUser.location.includes(location.location_name)
              );
              return matchingLocations.length > 0
                ? { label: company.label, value: matchingLocations }
                : null;
            })
            .filter((company) => company !== null);

          if (loggedInUser.role !== 'Employee') {
            setFranchiseListForActiveHsppHistoryReport(franchiseListForActiveHsppHistory);
            setLocationListForActiveHsppHistoryReport(locationListForActiveHsppHistory);
          } else {
            setFranchiseListForActiveHsppHistoryReport(userFranchiseMatchForActiveHSppHistory);
            setLocationListForActiveHsppHistoryReport(userLocationMatchForActiveHSppHistory);
          }
        }
      })
      .finally();
  };

  const init = () => {
    dispatch(loadingStart());
    API.getActiveHsppPatientHistoryReport(activeHsppPatientHistoryDetails)
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          init,
          setTableData,
          tableData,
          locationListForActiveHsppHistoryReport,
          franchiseListForActiveHsppHistoryReport,
          activeHsppPatientHistoryDetails,
          setActiveHsppPatientHistoryDetails,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;

const HelloWorld = () => {
  return <div>Hello World</div>;
};

const ChildComponent = ({ tableData = [] }) => {
  const columns = [
    {
      accessor: 'location_name',
      Header: 'Location Name',
      headerStyle: { fontSize: '90%' },
      Cell: (row) => <div>{row.original.location_name}</div>
      // width: 200
    },
    {
      accessor: 'startSyclePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Sycle Patients',
      Cell: (row) => <div>{row.original.startSyclePatients}</div>
    },
    {
      accessor: 'currentSyclePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Sycle Patinets',
      Cell: (row) => <div>{row.original.currentSyclePatients}</div>
    },
    {
      accessor: 'startActivePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Active Patients',
      Cell: (row) => <div>{row.original.startActivePatients}</div>
    },
    {
      accessor: 'currentActivePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Active Patients',
      Cell: (row) => <div>{row.original.currentActivePatients}</div>
    },
    {
      accessor: 'percHspp',
      headerStyle: { fontSize: '90%' },
      Header: '% HSPP',
      Cell: (row) => <div>{row.original.percHspp}</div>
    },
    {
      accessor: 'startActiveSubscriptions',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Active Subscriptions',
      Cell: (row) => <div>{row.original.startActiveSubscriptions}</div>
    },
    {
      accessor: 'currentActiveSubscriptions',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Active Subscriptions',
      Cell: (row) => <div>{row.original.currentActiveSubscriptions}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Patient Added',
      accessor: 'patientAdded',
      Cell: (row) => <div>{row.original.patientAdded}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Subscription Added',
      accessor: 'subscriptionAdded',
      Cell: (row) => <div>{row.original.subscriptionAdded}</div>
    }
  ];
  return (
    <Table
      keyField="_id"
      columns={columns}
      data={tableData}
      headerComponent={null}
      maxRows={20}
      minRows={1}
    />
  );
};
