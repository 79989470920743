import React from 'react';
import { Card, Button, Table } from 'components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ButtonBTN from 'react-bootstrap/Button';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { IoEyeSharp } from 'react-icons/io5';
import { FaAngleLeft } from 'react-icons/fa';
import SummaryModal from './SummaryModal';

const RetryPayments = ({ _this }) => {
  const columns = [
    {
      accessor: 'hspp_id',
      Header: 'HSPP ID',
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link
          to={`/customer-info/${row?.original?.customer_mongo_id}`}
          className="text-blue-500 font-medium"
        >
          #{row?.original?.hspp_id}
        </Link>
      )
    },
    {
      accessor: 'customer_id',
      Header: 'Patient ID',
      Cell: (row) => <div>{row?.original.customer_id}</div>
    },
    {
      accessor: 'retry_status',
      Header: 'Retry Status',
      Cell: (row) => {
        const stat = row?.original?.retry_status;
        const className =
          stat == 'processing'
            ? 'text-amber-700 bg-amber-200'
            : stat == 'succeeded'
            ? 'text-green-700 bg-green-200'
            : 'text-red-700 bg-red-200';
        return (
          <div className={`${className} p-1 rounded-md flex items-center justify-around`}>
            <p>{row?.original?.retry_status} </p>
            {row?.original?.message && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    <p>{row?.original?.message}</p>
                  </Tooltip>
                }
              >
                <div className="cursor-pointer">
                  <IoEyeSharp size={20} />
                </div>
              </OverlayTrigger>
            )}
          </div>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Patient Name',
      Cell: (row) => <div>{row?.original?.name}</div>
    },
    {
      accessor: 'location',
      Header: 'Location',
      Cell: (row) => <div>{row?.original?.location}</div>
    },
    {
      accessor: 'franchise',
      Header: 'Franchise',
      Cell: (row) => <div>{row?.original?.franchise}</div>
    },
    {
      accessor: 'email',
      Header: 'Email',
      Cell: (row) => <div className="break-words">{row?.original?.email}</div>
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      Cell: (row) => <div className="break-words">{row?.original?.phone}</div>
    },
    {
      accessor: 'updatedAt',
      Header: 'Date',
      Cell: (row) => <div>{moment(row?.original?.updatedAt).format('YYYY-MM-DD')}</div>
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      Cell: (row) => <div>{row?.original?.amount}</div>
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'} className="mb-3">
        {_this?.isRetryInProgress == false && (
          <div
            className="text-blue-700 flex gap-2 cursor-pointer items-center text-lg"
            onClick={() => _this?.goBack()}
          >
            <FaAngleLeft />
            <p>Go back</p>
          </div>
        )}
        <div className="flex flex-col lg:flex-row justify-between my-3">
          <h3 className="text-body-color text-2xl">
            Retry Invoices ({_this.retryTableDataToShow?.length})
          </h3>
          <input
            type="search"
            placeholder="Search.."
            value={_this.retrySearchInput}
            onChange={(e) => _this.setRetrySearchInput(e.target.value)}
          />

          <ButtonBTN
            className={`bg-button-primary`}
            variant="primary"
            id="export"
            onClick={() => {
              _this.setSummaryModalOpen(true);
              _this.onSummaryOpen();
            }}
          >
            Summary
          </ButtonBTN>
        </div>
        <div className="w-2/3 md:w-1/3 mx-auto text-lg flex flex-col md:flex-row items-center justify-around">
          <p>Successfully retried : {_this?.retryCounts?.succeded}</p>
          <p>Retry failed : {_this?.retryCounts?.failed}</p>
        </div>
        <ProgressBar className="h-6">
          <ProgressBar
            variant="success"
            striped
            now={(_this?.retryCounts?.succeded / _this?.retryCounts?.total) * 100}
            label={
              <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {`Retry done : ${_this?.retryCounts?.succeded}`}
              </p>
            }
            key={1}
            className="text-lg"
          />
          <ProgressBar
            variant="warning"
            animated
            now={(_this?.retryCounts?.processing / _this?.retryCounts?.total) * 100}
            label={
              <p className="whitespace-nowrap overflow-hidden text-ellipsis">{`Waiting to process : ${_this?.retryCounts?.processing}`}</p>
            }
            key={2}
            className="text-lg"
          />
          <ProgressBar
            striped
            variant="danger"
            now={(_this?.retryCounts?.failed / _this?.retryCounts?.total) * 100}
            label={
              <p className="whitespace-nowrap overflow-hidden text-ellipsis">{`Failed attempt : ${_this?.retryCounts?.failed}`}</p>
            }
            key={3}
            className="text-lg"
          />
        </ProgressBar>
      </Card>
      <div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.retryTableDataToShow}
          headerComponent={null}
          showPageJump={false}
          showPageSizeOptions={true}
        />
      </div>
      <SummaryModal _this={_this} />
    </>
  );
};

export default RetryPayments;
