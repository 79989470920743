import React, { useState } from 'react';
import { Card, Button } from 'components';
import DeletePlan from './deletePlan';
import { MdDeleteForever } from 'react-icons/md';
import { AiOutlineHistory } from 'react-icons/ai';
import { TbEdit } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import ReactCardFlip from 'react-card-flip';

const Section = ({ _this }) => {
  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Plans</h3>
          <Link to="/create-plan" className="text-body-color">
            <Button onClick={() => {}}>Create New Plan</Button>
          </Link>
        </div>
      </Card>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
        {_this.planList.map((e, i) => (
          <Plan key={i} e={e} i={i} _this={_this} />
        ))}
        <DeletePlan _this={_this} />
      </div>
    </>
  );
};

export default Section;

const Plan = ({ e, i, _this }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [histories, setHistories] = useState([]);
  const formatDate = function (date) {
    const newDate = new Date(date);

    let formattedDate = `${`0${newDate.getMonth() + 1}`.slice(-2)}-`;
    formattedDate += `${`0${newDate.getDate()}`.slice(-2)}-`;
    formattedDate += `${newDate.getFullYear()}`;

    return formattedDate;
  };

  const getOldData = async () => {
    let res = await _this.getHistory(e._id);
    res = [{ effective_date: e.effective_date, monthly: e.monthly, annually: e.annually }, ...res];
    setHistories(res);
    setIsFlipped(!isFlipped);
  };

  return (
    <>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        containerClassName="flex flex-1 basis-1/3 p-2 w-full h-[500px] flex-shrink-0"
      >
        <Card className="flex flex-1 h-full">
          <div className="flex flex-row items-center justify-between">
            <h3 className="text-xl text-body-color">Plan {i + 1}</h3>
            <div className="flex flex-row items-center">
              <span className="bg-green-200 text-green-700 font-semibold px-3 py-1 rounded-full text-xs">
                <span className="font-normal">Effective: </span>
                <span className="ml-0.5">{formatDate(e.effective_date)}</span>
              </span>
              <AiOutlineHistory
                onClick={() => getOldData()}
                className="ml-2 text-sky-400"
                size={25}
              />
              <Link to="/edit-plan" state={e}>
                <TbEdit className="ml-1 text-sky-600" size={25} />
              </Link>
              <MdDeleteForever
                onClick={() => _this.goToDeletePlan(e)}
                className="ml-0.5 text-red-500"
                size={25}
              />
            </div>
          </div>
          <div className="h-1 bg-slate-200 rounded mt-2" />
          <div className="text-center text-lg font-semibold text-slate-600 mt-3 h-12">{e.name}</div>
          <div className="flex flex-row items-center justify-between bg-slate-200 rounded-sm pr-2 mt-5">
            <div className="bg-slate-400 rounded-r-full rounded-l-sm text-slate-100 px-2 py-1.5 w-36 text-base">
              Product ID
            </div>
            <div className="text-xl flex items-center justify-center">
              <span className="font-semibold text-base text-slate-500">{e.product_id}</span>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between bg-slate-200 rounded-sm pr-2 mt-3">
            <div className="bg-slate-400 rounded-r-full rounded-l-sm text-slate-100 px-2 py-1.5 w-36">
              Monthly Cost
            </div>
            <div className="text-xl flex items-center justify-center">
              <span className="font-semibold text-slate-500 text-base">${e.monthly}</span>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between bg-slate-200 rounded-sm pr-2 mt-3">
            <div className="bg-slate-400 rounded-r-full rounded-l-sm text-slate-100 px-2 py-1.5 w-36 text-base">
              Annually Cost
            </div>
            <div className="text-xl flex items-center justify-center">
              <span className="font-semibold text-slate-500 text-base">${e.annually}</span>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between bg-slate-200 rounded-sm pr-2 mt-3">
            <div className="bg-slate-400 rounded-r-full rounded-l-sm text-slate-100 px-2 py-1.5 w-36 text-base">
              Frequency
            </div>
            <div className="text-xl flex items-center justify-center">
              <span className="font-semibold text-slate-500 text-base">{e.frequency}</span>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between bg-slate-200 rounded-sm pr-2 mt-3">
            <div className="bg-slate-400 rounded-r-full rounded-l-sm text-slate-100 px-2 py-1.5 w-36 text-base">
              Quantity
            </div>
            <div className="text-xl flex items-center justify-center">
              <span className="font-semibold text-slate-500 text-base">{e.quantity}</span>
            </div>
          </div>
          <div className="flex items-center justify-center mt-3 rounded bg-slate-200">
            <span className="text-xs text-center text-slate-500 break-words w-5/6">
              #
              {e.plan_code.length > 30
                ? e.plan_code.substring(0, 30) +
                  '\n' +
                  e.plan_code.substring(30, e.plan_code.length)
                : e.plan_code}
              #
            </span>
          </div>
        </Card>
        <Card className="flex flex-1 h-full">
          <div className="flex flex-row items-center justify-between">
            <h3 className="text-xl text-body-color">Plan {i + 1} History</h3>
            <div className="flex flex-row items-center">
              <AiOutlineHistory
                onClick={() => setIsFlipped(!isFlipped)}
                className="ml-2 text-sky-400"
                size={25}
              />
            </div>
          </div>
          <div className="h-1 bg-slate-200 rounded mt-2" />
          <div className="mt-3 flex flex-row justify-between items-center border-b-[2px] pb-2">
            <h5 className="text-slate-500 text-sm font-medium">Effective Date</h5>
            <h5 className="text-slate-500 text-sm font-medium">Monthly</h5>
            <h5 className="text-slate-500 text-sm font-medium">Annually</h5>
          </div>
          {histories.length > 0 &&
            histories.map((e, i) => (
              <div
                key={i}
                className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2"
              >
                <h5 className="text-slate-500 text-sm">{formatDate(e.effective_date)}</h5>
                <h5 className="text-slate-500 text-sm">${e.monthly}</h5>
                <h5 className="text-slate-500 text-sm">${e.annually}</h5>
              </div>
            ))}
          {histories.length < 1 && (
            <center>
              <h5 className="text-slate-500 text-sm mt-3">No Data</h5>
            </center>
          )}
        </Card>
      </ReactCardFlip>
    </>
  );
};
