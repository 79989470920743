import { handleResponse, API, getMiracleAppURL, getToken } from './utils';
import states from '../helpers/states.json';

const dashboard = {
  getFranchiseLocation: async () => {
    const token = await getToken();
    try {
      const response = await API.get(`/v1/location/fetch-franchise-location`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
      return response.data;
    } catch (err) {
      console.log('Error from server ' + err);
    }
  },

  getCustomerInformation: async (location_ids) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/dashboard/get-customer-information', location_ids, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  getRevenueInformation: async (location_ids) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/dashboard/get-revenue-information', location_ids, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  getRevenueInvoicesData: async (invoices_ids) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/dashboard/get-revenue-invoices-data', invoices_ids, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  fetchEmployeeDashboardContestRecord: async (employee_location) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/dashboard/fetch-employee-dashboard-contest-record?current_date=2024-01-31',
        { employee_location: employee_location },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default dashboard;
