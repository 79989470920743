import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const patients = params.state;

  const [email, setEmail] = useState({
    email_subject: '',
    email_body: ''
  });

  const sendEmail = async () => {
    if (patients.length <= 0)
      return toast.error('Please select patients on Mail tab and try again.');
    if (email.email_body.length <= 0 || email.email_subject.length <= 0)
      return toast.error('Email subject and email body cannot be empty.');

    const data = { patients, email };

    dispatch(loadingStart());
    await API.generateEmailCustomer(data)
      .then((response) => {
        if (response) {
          toast.success('Email sent successfully');
          navigate('/mail');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };
  return (
    <DashboardContainer>
      <Body _this={{ patients, email, setEmail, sendEmail }} />
    </DashboardContainer>
  );
};

export default Index;
