import React from 'react';
import { Card, Button, Input, NewButton, Checkbox } from 'components';
import { PermissionWrapper, HasPermission } from 'components';

const Credits = ({ _this }) => {
  const customer = _this.customerDetails;
  return (
    <Card className="rounded-xl w-full md:w-[33%]" paddingClass="p-2">
      <div className="p-2 flex flex-row  items-center justify-between">
        <h3 className="text-xl font-bold  pb-2">Credits</h3>
        <PermissionWrapper permission={'add_credits'}>
          <NewButton
            onClick={() => {
              _this.setCreditModelOpen(true);
            }}
          >
            {/* <BiPlus className="text-white" size={23} /> */}
            <span className="font-medium text-base ml-0.5 text-white">Add / Remove</span>
          </NewButton>
        </PermissionWrapper>
      </div>
      <div className="mt-3 p-2">
        <h5 className="text-xl font-bold">
          $ {customer?.credit?.credit_amount?.toFixed(2) || '0.00'}
        </h5>
        <h5 className="text-slate-500 text-sm">Credit Amount</h5>
      </div>
    </Card>
  );
};

export default Credits;
