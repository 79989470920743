import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';
import states from '../helpers/states.json';

const ticket = {
  getTickets: async () => {
    let response;
    const token = await getToken();
    try {
      response = await API.get(`/v1/ticket/get-tickets`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },
  createTicket: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/ticket/create-ticket',
        {
          customer_id: data.customer_id,
          subscription_id: data.subscription_id,
          action: data.action
        },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  resolveTicket: async (data) => {
    let response;
    const token = await getToken();
    try {
      response = await API.put(`/v1/ticket/resolve-ticket`, data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  }
};

export default ticket;
