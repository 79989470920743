import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, Select } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const DeleteRoleModal = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this.deleteRoleModalVisibility}
      onHide={() => _this.setDeleteRoleModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Delete Role</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setDeleteRoleModalVisibility(false)} />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 flex mr-2">
          <h3 className="text-xl text-gray-600">
            Are you sure you want to delete{' '}
            <span className="text-black">{_this.deleteRoleData?.role_name}</span>?
          </h3>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => _this.submitDeleteRoleData()}
          className="bg-rose-700 hover:bg-rose-800 text-white text-xl rounded flex flex-row self-center w-full h-10 justify-center items-center"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteRoleModal;
