import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
const Index = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session).userSession;
  const hspp_id = user?._id;
  const [customerDetails, setCustomerDetails] = useState(null);

  useEffect(() => {
    if (hspp_id) {
      init();
    }
  }, [hspp_id]);

  const init = async () => {
    dispatch(loadingStart());
    await API.getMyDetailsById()
      .then((response) => {
        if (response) {
          setCustomerDetails(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          customerDetails,
          user,
          init
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
