import auth from './auth';
import plan from './plan';
import customer from './customer';
import invoice from './invoice';
import subscription from './subscription';
import { SOCKET } from './utils';
import dashboard from './dashboard';
import employee from './employee';
import manageRoles from './manageRoles';
import report from './report';
import ticket from './ticket';
import nonHsppPatients from './nonHsppPatients';
import emailTemplates from './emailTemplates';
import emailErrorLog from './emailErrorLog';
import document from './document';
import contest from './contest';
import publicRoutes from './public';
import lossAndDamageApprovals from './lossAnDamageApprovals';
import chargebackDashboard from './chargebackDashboard';
import accounting from './accounting';

const api = {
  ...auth,
  ...plan,
  ...customer,
  ...document,
  ...invoice,
  ...subscription,
  ...dashboard,
  ...employee,
  ...manageRoles,
  ...report,
  ...ticket,
  ...nonHsppPatients,
  ...emailTemplates,
  ...emailErrorLog,
  ...contest,
  ...publicRoutes,
  ...lossAndDamageApprovals,
  ...chargebackDashboard,
  ...accounting,
  SOCKET
};
export default api;
