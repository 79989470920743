import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadingStart, loadingStop } from 'redux/action';
import API from 'services/api';
import Body from './Body';
import { DashboardContainer } from 'components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [locationList, setLocationList] = useState([]);
  const [franchiseList, setFranchiseList] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState([]);
  const [mailData, setMailData] = useState({
    subject: '',
    body: ''
  });

  const sendEmail = async () => {
    if (selectedLocations.length == 0)
      return toast.error('No location was selected to send mail !');
    if (mailData.body.length <= 0 || mailData.subject.length <= 0)
      return toast.error('Email subject and email body cannot be empty.');

    const data = { locationList: selectedLocations, mailData, franchiseList: selectedFranchise };
    dispatch(loadingStart());
    await API.generateEmailEmployee(data)
      .then((response) => {
        if (response) {
          toast.success(response);
          navigate('/mail');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const loadLocationOptions = async () => {
    //  fetching locations
    dispatch(loadingStart());
    let list;
    await API.getFranchiseLocation()
      .then((response) => {
        if (response) {
          list = response.map((item) => ({
            label: item.locationName,
            value: item
          }));

          setLocationList(list);
        }
      })
      .finally(() => dispatch(loadingStop()));

    //  storing franhises
    let map = new Map();
    for (let loc of list) {
      if (map.get(loc.value.franchiseName)) continue;
      map.set(loc.value.franchiseName, true);
      setFranchiseList((prev) => [...prev, { label: loc.value.franchiseName, value: loc.value }]);
    }

    // setting default all value
    let defaultValue = list.find((loc) => loc.label == 'All');
    setSelectedLocations([defaultValue]);
    setSelectedFranchise([{ label: 'All', value: defaultValue.value }]);
  };

  useEffect(() => {
    let map = new Map();
    for (let fr of selectedFranchise) {
      map.set(fr.label, true);
    }
    let newLocations = [];
    for (let loc of locationList) {
      // if (selectedLocations.find((selected) => selected.label == loc.label)) continue;
      if (map.get(loc.value.franchiseName)) {
        // setSelectedLocations((prev) => [...prev, loc]);
        newLocations.push(loc);
      }
    }
    setSelectedLocations(newLocations);
  }, [selectedFranchise]);

  useEffect(() => {
    loadLocationOptions();
  }, []);

  return (
    <DashboardContainer>
      <Body
        _this={{
          locationList,
          selectedLocations,
          setSelectedLocations,
          selectedFranchise,
          setSelectedFranchise,
          setFranchiseList,
          franchiseList,
          mailData,
          setMailData,
          sendEmail
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
