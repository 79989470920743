import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';

const emailTemplates = {
  createTemplate: async (data) => {
    let response;
    const token = await getToken();
    try {
      response = await API.post(`/v1/template/create-template`, data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  editTemplate: async (data) => {
    let response;
    const token = await getToken();
    try {
      response = await API.put(`/v1/template/edit-template`, data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  getTemplates: async () => {
    let response;
    const token = await getToken();
    try {
      response = await API.get(`/v1/template/get-all-template`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  },

  removeTemplate: async (email_type) => {
    let response;
    const token = await getToken();
    try {
      response = await API.put(
        `/v1/template/remove-template`,
        { email_type },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  }
};

export default emailTemplates;
