import { handleResponse, API, getToken } from './utils';

const plan = {
  getPlans: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/plan/get-plans', {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getPlanHistory: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/plan/get-history-by-plan',
        { plan_id: id },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createPlan: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/plan/create', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  editPlan: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/plan/update', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  deletePlan: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/plan/delete', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default plan;
