import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DashboardContainer } from 'components';
import { MdArrowBack } from 'react-icons/md';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const DroppedPatientsReport = ({ _this }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const patients_report = state.patients_report;

  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of patients_report) {
      const customerId = dataObject.customer_id;
      const customerName = `${dataObject.first_name} ${dataObject.last_name}`;
      const location = dataObject.location.toString();
      const droppedDate = new Date(dataObject.dropped_date);
      const dueDate = new Date(dataObject.due_date);
      const status = dataObject.invoice_status;
      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        CustomerID: customerId,
        CustomerName: customerName,
        Location: location,
        DroppedDate: droppedDate,
        DueDate: dueDate,
        Status: status
        // Address: formattedAddress
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'DroppePatientsReport' + fileExtension);
  };

  const columns = [
    {
      accessor: '_id',
      Header: 'HSPP ID',
      sortable: false,
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link to={`/customer-info/${row.original._id}`} className="text-blue-500 font-medium">
          #{row.original.hspp_id}
        </Link>
      )
    },
    {
      Header: 'Patient ID',
      Cell: (row) => <div>{row.original.customer_id}</div>
    },
    {
      Header: 'Name',
      Cell: (row) => <div>{row.original.first_name + ' ' + row.original.last_name}</div>
    },
    {
      accessor: 'location',
      Header: 'Location',
      Cell: (row) => <div>{row.original.location}</div>
    },
    {
      Header: 'Dropped Date',
      Cell: (row) => <div>{moment(row.original.dropped_date).format('YYYY-MM-DD')}</div>
    },
    {
      Header: 'Invoice Due Date',
      Cell: (row) => <div>{moment(row.original.due_date).format('YYYY-MM-DD')}</div>
    },
    {
      accessor: 'invoice_status',
      Header: 'Invoice Status',
      Cell: (row) => (
        <div
          className={`font-semibold px-3 py-1 rounded text-xs ${renderStatusColorClass('Expired')}`}
        >
          {row.original.invoice_status === 'Failed' && 'FAILED'}
        </div>
      )
    }
  ];

  return (
    <DashboardContainer>
      <>
        {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
        <Card className="flex mr-2 mt-2">
          <div className="flex justify-between items-center">
            <div className="flex gap-4 mb-2">
              <div
                className="w-10 h-10 rounded-full border-2 border-gray-500 flex justify-center items-center text-gray-500 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <MdArrowBack size={40} />
              </div>

              <h3 className="text-body-color text-xl font-semibold pb-2">
                Dropped Patients Report
              </h3>
            </div>
            <ButtonBTN
              onClick={() => exportReport()}
              className={`bg-button-primary`}
              variant="primary"
              id="export"
            >
              Export
            </ButtonBTN>
          </div>
          <Table
            keyField="_id"
            columns={columns}
            data={patients_report}
            headerComponent={null}
            minRows={20}
          />
        </Card>
      </>
    </DashboardContainer>
  );
};

export default DroppedPatientsReport;
