import React from 'react';
import { Card, Button } from 'components';
import { parseHsppId } from 'services/helpers';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import ButtonBTN from 'react-bootstrap/Button';
import SummaryModal from './SummaryModal';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const hspp_id = dataObject.hspp_id;
      const customer_id = dataObject.customer_id;
      const name = dataObject.name;
      const location = dataObject.location;
      const franchise = dataObject.franchise;
      const email = dataObject.email;
      const phone = dataObject.phone;
      const updatedtAt = dataObject.updatedAt;
      const status = dataObject.status;
      const amount = dataObject.amount;

      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        'HSPP ID': hspp_id,
        'Patient ID': customer_id,
        'Patient Name': name,
        Location: location,
        Franchise: franchise,
        Email: email,
        Phone: phone,
        Date: updatedtAt,
        Status: status,
        Amount: amount
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, `Invoices ${_this.invoicesData.date}` + fileExtension);
  };

  const columns = [
    {
      accessor: '_id',
      Header: 'HSPP ID',
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link
          to={`/customer-info/${row?.original?.customer_mongo_id}`}
          className="text-blue-500 font-medium"
        >
          #{row?.original?.hspp_id}
        </Link>
      )
    },
    {
      accessor: 'customer_id',
      Header: 'Patient ID',
      Cell: (row) => <div>{row?.original.customer_id}</div>
    },
    {
      accessor: 'name',
      Header: 'Patient Name',
      Cell: (row) => <div>{row?.original?.name}</div>
    },
    {
      accessor: 'location',
      Header: 'Location',
      Cell: (row) => <div>{row?.original?.location}</div>
    },
    {
      accessor: 'franchise',
      Header: 'Franchise',
      Cell: (row) => <div>{row?.original?.franchise}</div>
    },
    {
      accessor: 'email',
      Header: 'Email',
      Cell: (row) => <div className="break-words">{row?.original?.email}</div>
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      Cell: (row) => <div className="break-words">{row?.original?.phone}</div>
    },
    // {
    //   accessor: '_id',
    //   Header: 'Invoice ID',
    //   Cell: (row) => <div>{row?.original?._id}</div>
    // },
    {
      accessor: 'updatedAt',
      Header: 'Date',
      Cell: (row) => <div>{moment(row?.original?.updatedAt).format('YYYY-MM-DD')}</div>
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: (row) => <div>{row?.original?.status}</div>
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      Cell: (row) => <div>{row?.original?.amount}</div>
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'} className="mb-3">
        <div className="flex flex-col lg:flex-row justify-between gap-3 items-center">
          <h3 className="text-body-color text-2xl">
            Invoices {_this.invoicesData?.date} ({_this.tableData?.length})
          </h3>

          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
          <div className="flex gap-5">
            \
            <ButtonBTN
              onClick={_this?.retryPayments}
              className={`bg-button-primary`}
              variant="primary"
              id="export"
            >
              Retry All
            </ButtonBTN>
            <ButtonBTN
              onClick={() => exportReport()}
              className={`bg-button-primary`}
              variant="primary"
              id="export"
            >
              Export
            </ButtonBTN>
          </div>
        </div>
      </Card>
      <div className="flex flex-wrap gap-3 mb-3">
        {_this.customerData?.length <= 0 ? (
          <Card>
            <div className="flex justify-center items-center h-52">
              <h3 className="text-body-color text-3xl">No invoices found</h3>
            </div>
          </Card>
        ) : (
          // _this.customerData?.map((item, i) => (
          //   <Card className="flex w-[32%]" key={i}>
          //     <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
          //       <h3 className="text-body-color text-xl font-semibold">
          //         Customer | {item?.customer[0]?.first_name + ' ' + item?.customer[0]?.last_name}
          //       </h3>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">HSPP ID:</h5>
          //       <h5 className="text-slate-500 text-sm">
          //         {' '}
          //         <Link
          //           to={`/customer-info/${item?.customer[0]?._id}`}
          //           className="text-blue-500 font-medium"
          //         >
          //           {parseHsppId(item?.customer[0]?._id)}
          //         </Link>
          //       </h5>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">Patient ID:</h5>
          //       <h5 className="text-slate-500 text-sm">{item?.customer[0]?.customer_id}</h5>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">Invoice ID:</h5>
          //       <h5 className="text-slate-500 text-sm">{item?._id}</h5>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">Name:</h5>
          //       <h5 className="text-slate-500 text-sm">
          //         {item?.customer[0]?.first_name + ' ' + item?.customer[0]?.last_name}
          //       </h5>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">Phone:</h5>
          //       <h5 className="text-slate-500 text-sm">{item?.customer[0]?.phone}</h5>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">Email:</h5>
          //       <h5 className="text-slate-500 text-sm">{item?.customer[0]?.email}</h5>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">Status:</h5>
          //       <h5 className="text-slate-500 text-sm">{item?.status}</h5>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">Amount:</h5>
          //       <h5 className="text-slate-500 text-sm">{item?.amount}</h5>
          //     </div>
          //     <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
          //       <h5 className="text-slate-500 text-sm">Transaction ID:</h5>
          //       <h5 className="text-slate-500 text-sm">
          //         {item?.status == 'Paid' ? item?.transaction_id : 'N/A'}
          //       </h5>
          //       {item?.status != 'Paid'}
          //     </div>
          //   </Card>
          // ))
          <Card className="flex mt-2" paddingClass="px-0 py-0">
            <div>
              <Table
                keyField="id"
                columns={columns}
                data={_this.tableData}
                headerComponent={null}
                showPageJump={false}
                showPageSizeOptions={true}
              />
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default Section;
