import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop } from 'redux/action';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

const Index = () => {
  const location = useLocation();
  const chargebackInfo = location.state;
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    chargebackList();
  }, []);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const chargebackList = () => {
    dispatch(loadingStart());
    API.getChargebackInformationByIds(chargebackInfo._ids)
      .then((result) => {
        setTableData(result);
        setOriginalData(result);
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };
  return (
    <DashboardContainer>
      <Body _this={{ chargebackInfo, tableData, searchInput, setSearchInput }} />
    </DashboardContainer>
  );
};

export default Index;
