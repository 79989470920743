import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
const Index = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session).userSession;
  const _id = user?._id;

  const [employeeDetails, setEmployeeDetails] = useState(null);

  useEffect(() => {
    if (_id) {
      init();
    }
  }, [_id]);

  const init = async () => {
    dispatch(loadingStart());
    await API.getEmployeeDetailsById(_id)
      .then((response) => {
        if (response) {
          setEmployeeDetails(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          employeeDetails,
          setEmployeeDetails,
          init
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
