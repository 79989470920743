import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mailTemplates, setMailTemplates] = useState(true);
  const [searchFromDate, setSearchFromDate] = useState('');
  const [searchToDate, setSearchToDate] = useState('');
  const [searchFieldOption, setSearchFieldOption] = useState({
    'HSPP ID': 'hspp_id',
    'Patient ID': 'customer_id',
    'First Name': 'first_name',
    'Last Name': 'last_name',
    'Created On': 'createdAt',
    Email: 'email'
  });
  const [templateList, setTemplateList] = useState([]);
  const [currentSelectedCountOption, setCurrentSelectedCountOption] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [initialData, setInitialData] = useState({ total_pages: 0, data: [], total_items: 0 });
  const [count, setCount] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [payload, setPayload] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [search, setSearch] = useState({ searchField: 'customer_id', searchInput: '' });

  const [employeeTableData, setEmployeeTableData] = useState([]);
  const [showNameForEmail, setShowNameForEmail] = useState([]);
  const [newMail, setNewMail] = useState({ subject: '', body: '', email_type: '' });
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (search === '') {
      setTableData(initialData);
    }
  }, [search]);

  useEffect(() => {
    if (selectedTemplate == '') setNewMail({ body: '', subject: '', email_type: '' });
    else {
      let template = templateList.find((t) => t.email_type == selectedTemplate);
      setNewMail(template);
    }
  }, [selectedTemplate]);

  const init = () => {
    fetchData(0, limitPerPage, null, true);
    fetchEmployeeEmailLogs();
    fetchTemplates();
    setNewMail({ body: '', subject: '', email_type: '' });
    setIsNew(false);
    setSelectedTemplate('');
  };

  const handleCancel = () => {
    setIsNew(false);
    setNewMail({ body: '', subject: '', email_type: '' });
    setSelectedTemplate('');
  };

  const handleSubmit = () => {
    if (!newMail.body || !newMail.body || !newMail.subject) {
      toast.error('please enter all the details for the email');
      return;
    }

    if (isNew) {
      dispatch(loadingStart());
      API.createTemplate(newMail)
        .then((res) => {
          if (res) toast.success('new email template created.');
        })
        .finally(() => {
          dispatch(loadingStop());
          init();
        });
    } else {
      API.editTemplate(newMail)
        .then((res) => {
          if (res) toast.success('email template edited');
        })
        .finally(() => {
          dispatch(loadingStop());
          init();
        });
    }
  };

  const removeTemplate = () => {
    if (window.confirm('Are you sure to delete the template ' + selectedTemplate) == false) return;
    dispatch(loadingStart());
    API.removeTemplate(selectedTemplate)
      .then((res) => {
        if (res) toast.success('Email template removed successfully.');
      })
      .finally(() => {
        dispatch(loadingStop());
        init();
      });
  };

  const fetchTemplates = async () => {
    dispatch(loadingStart());
    API.getTemplates()
      .then((res) => {
        setTemplateList(res);
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const navigateMailPage = () => {
    if (mailTemplates) {
      navigate('/mail/mail-customers', {
        state: showNameForEmail
      });
    } else {
      navigate('/mail/mail-employees');
    }
  };

  const fetchData = async (
    page,
    limit,
    payload,
    initial = false,
    sortingField = 'createdAt',
    sortingDirection = true
  ) => {
    dispatch(loadingStart());
    await API.getCustomerList(page, limit, payload, sortingField, sortingDirection)
      .then((response) => {
        if (response) {
          setTableData(response.data);
          if (initial) {
            setInitialData(response);
          }
          if (response.total_pages >= 0) setTotalPage(response.total_pages);
          if (response.total_items >= 0) setCount(response.total_items);
        }
        if (page < 1) setCurrentPage(0);
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const searchData = (e) => {
    if (e) e.preventDefault();
    const payload = search;
    if (payload.searchField && (payload.searchInput || (searchFromDate && searchToDate))) {
      if (payload.searchField === 'createdAt') {
        const fromDate = moment(searchFromDate).format('YYYY-MM-DD');
        const toDate = moment(searchToDate).add(1, 'days').format('YYYY-MM-DD');
        payload.searchInput = `${fromDate}to${toDate}`;
      }
      fetchData(0, limitPerPage, payload);
      setCurrentSelectedCountOption('');
      setPayload(payload);
    } else {
      console.log(payload);
      setTableData(initialData.data);
      setTotalPage(initialData.total_pages);
      setCount(initialData.total_items);
      setCurrentPage(0);
    }
  };

  const fetchEmployeeEmailLogs = async () => {
    dispatch(loadingStart());
    await API.fetchEmployeeEmailLogs()
      .then((response) => {
        if (response) {
          setEmployeeTableData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const AddPeopleForEmail = ({ status, email, name }) => {
    let temp = [...showNameForEmail];
    if (status == true) {
      temp.push({ email, name });
    } else {
      temp = temp.filter((item) => item.email != email);
    }

    setShowNameForEmail(temp);
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          setSearch,
          fetchData,
          search,
          searchData,
          searchFieldOption,
          setSearchFieldOption,
          searchFromDate,
          setSearchFromDate,
          searchToDate,
          setSearchToDate,
          AddPeopleForEmail,
          showNameForEmail,
          mailTemplates,
          setMailTemplates,
          navigateMailPage,
          templateList,
          employeeTableData,
          currentSelectedCountOption,
          tableData,
          count,
          totalPage,
          currentPage,
          payload,
          setPayload,
          setCurrentPage,
          setCount,
          setLimitPerPage,
          limitPerPage,
          selectedTemplate,
          setSelectedTemplate,
          newMail,
          setNewMail,
          isNew,
          setIsNew,
          handleCancel,
          handleSubmit,
          removeTemplate
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
