import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../services/api';

const index = () => {
  const { patient_id } = useParams();
  const navigate = useNavigate();

  const init = async () => {
    const hspp_id = await API.getHSPPIdOfPatient(patient_id);
    navigate('/customer-info/' + (hspp_id || 'not_found'));
  };

  useEffect(() => {
    init();
  }, []);

  return <div></div>;
};

export default index;
