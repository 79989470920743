import React from 'react';
import { Card, Button, Input, Dropdown, DatePicker, Stepper } from 'components';
import { Form, InputGroup, Modal } from 'react-bootstrap';

const Section = ({ _this }) => {
  return (
    <>
      <Card className="mb-3" background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-2xl">New Plan</h3>
          <div className="">
            <Button onClick={() => _this.createPlan()}>Create Plan</Button>
          </div>
        </div>
      </Card>
      <div className="flex flex-col md:flex-row gap-3">
        <Card className="mb-3 flex basis-1/2">
          <div className="bg-slate-100 mt-2 p-2 rounded">
            <Form>
              <Input
                label="Plan Name"
                placeholder="@Enter plan name"
                type="text"
                required
                value={(_this.planDetails && _this.planDetails.name) || ''}
                onChange={(e) => _this.updatePlanDetails('name', e.target.value)}
              />
              <Input
                label="Product Id"
                placeholder="@Enter ID"
                type="text"
                required
                value={(_this.planDetails && _this.planDetails.product_id) || ''}
                onChange={(e) => _this.updatePlanDetails('product_id', e.target.value)}
              />
              <Input
                label="Plan Code"
                placeholder="@Enter plan code"
                type="text"
                required
                value={(_this.planDetails && _this.planDetails.plan_code) || ''}
                onChange={(e) => _this.updatePlanDetails('plan_code', e.target.value)}
              />
              <Input
                label="Plan Quantity"
                placeholder="@Enter quantity"
                type="text"
                required
                value={(_this.planDetails && _this.planDetails.quantity) || ''}
                onChange={(e) => _this.updatePlanDetails('quantity', e.target.value)}
              />
            </Form>
          </div>
        </Card>
        <Card className="mb-3 flex basis-1/2">
          <div className="bg-slate-100 mt-2 p-2 rounded">
            <Form>
              <Input
                label="Plan Monthly Cost ($)"
                placeholder="@Enter cost"
                type="text"
                required
                value={(_this.planDetails && _this.planDetails.monthly) || ''}
                onChange={(e) => _this.updatePlanDetails('monthly', e.target.value)}
              />
              <Input
                label="Plan Annually Cost ($)"
                placeholder="@Enter cost"
                type="text"
                required
                value={(_this.planDetails && _this.planDetails.annually) || ''}
                onChange={(e) => _this.updatePlanDetails('annually', e.target.value)}
              />
              <DatePicker
                label="Plan Effective Date"
                required
                value={(_this.planDetails && _this.planDetails.effective_date) || ''}
                onChange={(e) => _this.updatePlanDetails('effective_date', e)}
              />
              <Input
                label="Plan Frequency"
                required
                placeholder="@Enter frequency"
                type="text"
                value={(_this.planDetails && _this.planDetails.frequency) || ''}
                onChange={(e) => _this.updatePlanDetails('frequency', e.target.value)}
              />
            </Form>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Section;
