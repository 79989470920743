import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
const Index = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [hsppAttatchmentDetails, setHsppAttatchmentDetails] = useState(
    state.hsppAttatchmentDetails
  );
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    init();
  }, [hsppAttatchmentDetails.search_by]);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(customerData);
    } else {
      const filteredResults = customerData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const init = () => {
    dispatch(loadingStart());
    API.getHsppAttatchmentRateReport(hsppAttatchmentDetails)
      .then((response) => {
        if (response) {
          setTableData(response);
          setCustomerData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          init,
          tableData,
          searchInput,
          hsppAttatchmentDetails,
          setHsppAttatchmentDetails,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
