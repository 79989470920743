import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tableData, setTableDate] = useState([]);
  const [seeLogDataModalOpen, setSeeLogDataModalOpen] = useState(false);
  const [logDataToShow, setLogDataToShow] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    dispatch(loadingStart());
    API.fetchEmailErrorLog()
      .then((res) => {
        setTableDate(res);
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData,
          seeLogDataModalOpen,
          setSeeLogDataModalOpen,
          logDataToShow,
          setLogDataToShow
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
