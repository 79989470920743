import React from 'react';
import logo from 'assets/images/logo copy.png';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { parseHsppId } from 'services/helpers';
import moment from 'moment';

const Component = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.ribbonContainer}>
          {data?.status === 'Paid' || data?.status === 'Refunded' ? (
            <>
              <View style={styles.paidRibbon}>
                <Text style={styles.ribbonText}>PAID</Text>
              </View>
            </>
          ) : (
            <>
              <View style={styles.unpaidRibbon}>
                <Text style={styles.ribbonText}>UNPAID</Text>
              </View>
            </>
          )}

          <View style={styles.ribbonTriangle}></View>
        </View>
        company description
        <Image style={styles.logo} src={logo} />
        <View style={styles.company}>
          <Text style={styles.companyName}>Health Services Protection Plan</Text>
          <Text style={styles.companyAdress}>
            10557 W Carlton Bay Dr #201 Garden City, ID 83714
          </Text>
        </View>
        {/* {/* Invoice date and invoice to */}
        <View style={styles.invoices}>
          <View style={styles.invoiceTo}>
            <Text style={styles.customerDetails}>Invoiced To</Text>
            <Text style={styles.nameText}>
              {data?.customer?.first_name} {data?.customer?.last_name}
            </Text>
            <Text style={styles.invoiceBody}>
              <Text style={styles.boldText}>Patient Id: </Text>
              {data?.customer?.customer_id}
            </Text>
            <Text style={styles.invoiceBody}>
              <Text style={styles.boldText}>Phone: </Text>
              {data?.customer?.phone}
            </Text>
            <Text style={styles.invoiceBody}>
              <Text style={styles.boldText}>E-mail:</Text> {data?.customer?.email}
            </Text>

            <Text style={styles.invoiceBody}>
              <Text style={styles.boldText}>Billing Address:</Text>
            </Text>
            <Text style={styles.invoiceBody}>
              {data?.customer?.billing_address?.address1},{' '}
              {data?.customer?.billing_address?.address2}, {data?.customer?.billing_address?.city},{' '}
              {data?.customer?.billing_address?.state} {data?.customer?.billing_address?.zipcode},{' '}
              {data?.customer?.billing_address?.country}
            </Text>
          </View>
          <View style={styles.invoiceCred}>
            <Text style={styles.invoiceId}>Invoice #{parseHsppId(data?._id)}</Text>
            <Text style={styles.transactionId}>
              <Text style={styles.boldText}>
                Transaction ID :{' '}
                <Text style={styles.invoiceBody}>{data?.payment_gateway_transaction_id}</Text>
              </Text>
            </Text>
            <View>
              <Text style={styles.invoiceDate}>
                <Text style={styles.boldText}>Payment method: </Text>
                {data?.payment_method?.payment_method == 'creditcard'
                  ? `Card ***${data?.payment_method?.card_details?.card_last_4_digit}`
                  : `ACH ***${data?.payment_method?.ach_details?.account_last_4_digit}`}
              </Text>
            </View>
            <Text style={styles.invoiceDate}>
              <Text style={styles.boldText}>Date:</Text>{' '}
              {moment(data?.createdAt).format('YYYY-MM-DD')}
            </Text>
            <Text style={styles.invoiceDate}>
              <Text style={styles.boldText}>Due Date: </Text>
              {moment(data?.due_date).format('YYYY-MM-DD')}
            </Text>
          </View>
        </View>
        {/* billing section */}
        {/* billheader */}
        <View style={styles.billHeader}>
          <Text style={styles.assetDescription}> Description </Text>

          <Text style={styles.totalAmount}> Amount </Text>
        </View>
        {/* billbody */}
        <View style={styles.billTable}>
          <View style={styles.assetDescription}>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Plan:</Text> {data?.subscription?.plan_name}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Store:</Text>{' '}
              {data?.subscription?.product_information?.location_name}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Left Serial:</Text>{' '}
              {data?.subscription?.product_information?.left_serial}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Right Serial:</Text>{' '}
              {data?.subscription?.product_information?.right_serial}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>L Battery Size:</Text>
              {data?.subscription?.product_information?.l_battery_size}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>R Battery Size:</Text>
              {data?.subscription?.product_information?.r_battery_size}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Chargable:</Text>{' '}
              {data?.subscription?.product_information?.chargable}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Payment Interval:</Text>{' '}
              {data?.subscription?.plan_interval}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}> Is Active:</Text>{' '}
              {data?.subscription?.status == 'Active' ? 'Yes' : 'No'}
            </Text>
          </View>

          <View style={styles.totalAmount}>
            <Text style={styles.textAmount}>$ {data?.amount}</Text>
          </View>
        </View>
        {/* billFooter */}
        <View style={styles.BillFooter}>
          <View style={styles.notesSection}>
            <Text style={styles.notesLetter}> Shipping Address</Text>
            <Text style={styles.notesText}>
              {data?.customer?.first_name} {data?.customer?.last_name},
              {data?.customer?.shipping_address?.address1},{' '}
              {data?.customer?.shipping_address?.address2}, {data?.customer?.shipping_address?.city}
              , {data?.customer?.shipping_address?.state},
              {data?.customer?.shipping_address?.zipcode},
              {data?.customer?.shipping_address?.country}
            </Text>
          </View>
          <View style={styles.totalAmountSection}>
            <Text style={styles.totalText}> TOTAL</Text>
            <Text style={styles.totalAmountText}> $ {data?.amount}</Text>
          </View>
        </View>
        <View style={styles.signatureSection}>
          <Text style={styles.signature}>{data?.customer?.signature?.signature}</Text>
          <Text style={styles.signatureText}>SIGNATURE</Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default Component;

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  body: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    padding: 20
  },

  ribbonContainer: {
    top: -50,
    right: 100,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignSelf: 'flex-end'
  },

  unpaidRibbon: {
    height: 100,
    width: 50,
    display: 'flex',
    padding: '2',
    paddingTop: 10,
    alignItems: 'center',
    justifyContent: 'flex-start',

    backgroundColor: '#BA0021',
    // in case of unpaaid use '#BA0021',
    position: 'absolute'
  },
  paidRibbon: {
    height: 100,
    width: 50,
    display: 'flex',
    padding: '2',
    paddingTop: 10,
    alignItems: 'center',
    justifyContent: 'flex-start',

    backgroundColor: '#00563B',
    // in case of unpaaid use '#BA0021',
    position: 'absolute'
  },

  ribbonText: {
    fontFamily: 'Times-BoldItalic',
    fontSize: 12,
    color: 'white',
    marginTop: '20'
  },

  ribbonTriangle: {
    height: 50,
    width: 50,
    backgroundColor: 'white',
    top: 80,
    transform: 'rotate(45deg)',

    position: 'absolute'
  },

  logo: {
    marginTop: 30,
    marginLeft: 30,
    width: '50%',
    height: '8%',
    alignSelf: 'flex-start'
  },
  company: {
    display: 'flex',
    width: '28%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    lineHeight: 'normal',
    marginRight: 20
  },
  companyName: {
    fontFamily: 'Times-Bold',
    fontSize: 11,
    textAlign: 'right'
  },
  companyAdress: {
    fontSize: 8,
    textAlign: 'justify',
    marginBottom: 30,
    fontFamily: 'Times-Roman',
    letterSpacing: 1.5,
    marginRight: 4
    // fontFamily: 'Oswald'
  },

  invoices: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20
  },

  invoiceTo: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    paddingRight: 100,
    lineHeight: 1.2
  },

  customerDetails: {
    fontSize: 15,
    fontFamily: 'Times-Bold',
    fontWeight: 'bold',
    marginBottom: 10
  },
  invoiceBody: {
    fontSize: 10,
    alignItems: 'justify',
    fontFamily: 'Helvetica'
  },
  invoiceCred: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: 5,
    alignItems: 'flex-end'
  },
  invoiceId: {
    fontSize: 26,
    fontFamily: 'Times-Bold',
    marginBottom: 2
  },
  transactionId: {
    fontSize: 10,
    fontFamily: 'Helvetica',
    marginBottom: 10
  },
  invoiceDate: {
    fontSize: 15,
    alignItems: 'inherit',
    alignSelf: 'flex-end',
    fontFamily: 'Times-Roman'
  },

  billHeader: {
    margin: '0 20px 0 20px',

    display: 'flex',
    flexDirection: 'row',

    backgroundColor: '#1C75BC',
    // background:
    //   'linear-gradient(90deg, rgba(28,117,188,1) 0%, rgba(32,141,227,1) 35%, rgba(78,118,244,0.9168709720216212) 100%)',
    fontSize: 17,
    color: 'white',
    fontFamily: 'Times-Bold'
  },

  assetDescription: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid grey',
    width: '70%',
    textAlign: 'left',
    padding: 3
  },

  totalAmount: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    textAlign: 'center',
    padding: 3,
    fontFamily: 'Times-Bold'
  },
  billTable: {
    margin: '0 20px 0 20px',
    borderLeft: '1px solid grey',
    borderRight: '1px solid grey',

    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Oswald',
    fontSize: 13
  },

  BillFooter: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 20,

    height: 100
  },
  notesSection: {
    width: '70%',
    padding: 15,
    backgroundColor: '#0B5382',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },

  notesLetter: {
    fontSize: 14,
    fontFamily: 'Times-Bold',
    marginBottom: 4
  },

  notesText: {
    fontSize: 11,
    textAlign: 'inherit',
    paddingLeft: 2
  },

  totalAmountSection: {
    width: '30%',
    backgroundColor: '#F1BA1B',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    lineHeight: 'normal'
  },

  totalText: {
    fontSize: 14,
    fontFamily: 'Times-Bold'
  },
  totalAmountText: {
    fontSize: 30,
    fontFamily: 'Times-Bold'
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },

  textAmount: {
    textAlign: 'center'
  },

  signatureSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    margin: 20,
    marginTop: 45
  },

  signatureText: {
    borderTop: '1px solid grey',
    width: 128,
    fontFamily: 'Times-Roman',
    color: 'grey',
    letterSpacing: 3,
    textAlign: 'center'
  },
  signature: {
    textAlign: 'right'
  },

  text: {
    margin: 5,
    textAlign: 'left',
    fontFamily: 'Times-Roman',
    lineHeight: 'normal'
  },
  boldText: {
    fontFamily: 'Times-Bold'
  },
  nameText: {
    fontSize: 15,
    fontFamily: 'Times-BoldItalic',
    marginBottom: 3
  }
});
