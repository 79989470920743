import React from 'react';
import { Profile } from 'components';

const Section = ({ _this }) => {
  const customer = _this.customerDetails;
  return (
    <Profile
      customer={customer}
      is_password_changed={_this.user.is_password_changed}
      init={_this.init}
    />
  );
};

export default Section;
