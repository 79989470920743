const { VITE_APP_ENVIRONMENT, VITE_APP_URL, VITE_APP_STORAGE_URL } = import.meta.env;

// Constant Values

const Config = {
  API_URL: VITE_APP_URL,
  STORAGE_URL: VITE_APP_STORAGE_URL,
  UNAUTHORIZED_EXCEPTION: false,
  ENVIRONMENT: VITE_APP_ENVIRONMENT
};

export default Config;
