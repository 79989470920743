import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Card, Input, Button } from 'components';
import { Form, Modal } from 'react-bootstrap';
import { parseHsppId } from 'services/helpers';
import { useDispatch } from 'react-redux';
import { loadingStart, loadingStop, logout } from 'redux/action';
import API from 'services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Body from './Body';

const Component = ({ admin, is_password_changed, init }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [setPaswordModalOpen, setSetPaswordModalOpen] = useState(false);
  const [password_details, setPassword_details] = useState({
    password: '',
    confirm_password: ''
  });
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [edit_profile, setEdit_profile] = useState({
    name: '',
    mail: ''
  });

  useEffect(() => {
    is_password_changed == false && setSetPaswordModalOpen(true);
    if (admin) {
      setEdit_profile((prev) => ({
        ...prev,
        name: admin?.name,
        mail: admin?.email
      }));
    }
  }, [editProfileModalOpen, admin]);

  const changePassword = async () => {
    dispatch(loadingStart());
    await API.changePassword(password_details)
      .then((response) => {
        if (response) {
          toast.success('Password updated successfully. Login again with your new password');
          setSetPaswordModalOpen(false);
          dispatch(logout());
          navigate('/');
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const UpdateProfile = async () => {
    if (!edit_profile.name || !edit_profile.mail) return toast.error('All fields are mandatory');
    dispatch(loadingStart());
    await API.editProfile(edit_profile)
      .then((response) => {
        if (response) {
          toast.success('Profile has been updated successfully');
          setEditProfileModalOpen(false);
          init();
        }
      })
      .finally(() => dispatch(loadingStop()));
  };
  return (
    <>
      <Body
        _this={{
          admin,
          is_password_changed,
          setPaswordModalOpen,
          setSetPaswordModalOpen,
          password_details,
          setPassword_details,
          changePassword,
          editProfileModalOpen,
          setEditProfileModalOpen,
          edit_profile,
          setEdit_profile,
          UpdateProfile
        }}
      />
    </>
  );
};

export default Component;
