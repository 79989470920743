import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const column = [
  {
    Header: 'Patient ID',
    accessor: 'patient_id'
  },
  {
    Header: 'Patient Name',
    accessor: 'patient_name'
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Plan Name',
    accessor: 'plan_name'
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: (row) => {
      return <p>${Number(row?.original?.amount)?.toFixed(2)}</p>;
    }
  },
  {
    Header: 'Interval',
    accessor: 'interval'
  }
];

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.session).userSession;
  const { state } = useLocation();
  const [locationList, setLocationList] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [revenuePatientsReportParams, setRevenuePatientsReportParams] = useState(
    state?.revenuePatientsReportParams || {
      start_date: moment().startOf('month').toDate(),
      end_date: moment().endOf('month').toDate(),
      locations: [
        {
          label: 'All',
          value: {
            locationName: 'All'
          }
        }
      ]
    }
  );

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    init();
    loadLocationOptions();
  }, []);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const loadLocationOptions = async () => {
    // dispatch(loadingStart());
    await API.getFranchiseLocation().then((response) => {
      if (response) {
        const list = response.map((item) => ({
          label: item.locationName,
          value: item
        }));
        let userLocationMatch = [];

        loggedInUser.location.forEach((elem) => {
          userLocationMatch.push(...list.filter((item) => item.value.locationName == elem));
        });

        if (loggedInUser.role !== 'Employee') {
          setLocationList(list);
          setRevenuePatientsReportParams((prev) => ({
            ...prev,
            location: revenuePatientsReportParams?.location || userLocationMatch
          }));
        } else {
          setLocationList(userLocationMatch);
          setRevenuePatientsReportParams((prev) => ({
            ...prev,
            location: revenuePatientsReportParams?.location || userLocationMatch
          }));
        }
      }
    });
    // .finally(() => dispatch(loadingStop()));
  };

  const init = () => {
    if (revenuePatientsReportParams?.locations?.length == 0) {
      toast.error('Please enter locations !');
      return;
    }
    dispatch(loadingStart());
    const locations = revenuePatientsReportParams?.locations?.map((item) => item.label);
    API.fetchRevenuePatientsReport(
      locations,
      revenuePatientsReportParams.start_date,
      revenuePatientsReportParams.end_date
    )
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const exportReport = () => {
    const report = tableData?.map((item) => {
      return {
        'Patient ID': item.patient_id,
        'Patient Name': item.patient_name,
        Location: item?.location,
        'Plan Name': item?.plan_name,
        Amount: item?.amount,
        Interval: item?.interval
      };
    });

    // const journalEntry = tableData1?.map((item) => {
    //   return {
    //     'Ref Number': item?.refNo,
    //     'Transaction Date': item?.trDate,
    //     Account: item?.account,
    //     Debit: item?.debit,
    //     Credit: item?.credit,
    //     Memo: item?.memo,
    //     Name: item?.name,
    //     Class: item?.class
    //   };
    // });

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Create first worksheet
    const ws1 = XLSX.utils.json_to_sheet(report);
    // Create second worksheet
    // const ws2 = XLSX.utils.json_to_sheet(journalEntry);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Append worksheets to workbook
    XLSX.utils.book_append_sheet(wb, ws1, 'Revenue Patients Report');
    // XLSX.utils.book_append_sheet(wb, ws2, 'Revenue Journal Entry');

    // Generate Excel file as a binary array
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'RevenuePatientsReport' + fileExtension);
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData,
          revenuePatientsReportParams,
          setRevenuePatientsReportParams,
          locationList,
          init,
          exportReport,
          column,
          searchInput,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
