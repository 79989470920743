import React, { useEffect } from 'react';

import EditCustomer from './Modals/EditCustomer';
import AddNote from './Modals/AddNote';
import SubscriptionDetails from './Modals/SubscriptionDetails';
import AddNewPlan from './Modals/AddNewPlan';
import AddNewSubscription from './Modals/AddNewSubscription';
import Config from 'config';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import EditPaymentDetails from './Modals/EditPaymentDetails';
import AddNewPayment from './Modals/AddNewPayment';
import SetPrimaryPaymentModal from './Modals/SetPrimaryPaymentModal';
import Alert from 'react-bootstrap/Alert';
import RefundModal from './Modals/RefundModal';
import EditSubscription from './Modals/EditSubscription';
import DocumentModel from './Modals/DocumentModel';
import AddCredit from './Modals/AddCredit';
import { PermissionWrapper, HasPermission } from 'components';
import RefundDuplicateInvoiceModal from './Modals/RefundDuplicateInvoiceModal';
import TemporaryEditSubscription from './Modals/TemporaryEditSubscription';
import DeleteSubscriptionModal from './Modals/DeleteSubscriptionModal';
import DeleteInvoiceModal from './Modals/DeleteInvoiceModal';
import EditDueDateModal from './Modals/EditDueDateModal';
import PayExtra from './Modals/PayExtra';
import SeeLogEmailDataModal from './Modals/SeeLogEmailDataModal';
import { MdArrowBack } from 'react-icons/md';
import DeletePaymentMethodModal from './Modals/DeletePaymentMethodModal';
import { toast } from 'react-toastify';
import LogDataModal from './Modals/LogDataModel';
import { IoMdTime } from 'react-icons/io';
import DeleteDocumentModal from './Modals/DeleteDocumentModal';
import Spinner from 'react-bootstrap/Spinner';
const stripe_link =
  Config?.ENVIRONMENT == 'DEV'
    ? 'https://dashboard.stripe.com/test/disputes/'
    : 'https://dashboard.stripe.com/disputes/';

import {
  UserDetails,
  Credits,
  Subscription,
  PaymentMethod,
  Notes,
  Invoice,
  Chargeback,
  Log
} from './Sections';
const Section = ({ _this }) => {
  return (
    <>
      <div className="flex flex-col gap-3 mb-3">
        <div className="w-full flex flex-col md:flex-row gap-3">
          <PermissionWrapper permission="view_customer_details">
            <UserDetails _this={_this} />
          </PermissionWrapper>

          <PermissionWrapper permission={'view_subscription'}>
            <Subscription _this={_this} />
          </PermissionWrapper>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-3">
          <PermissionWrapper permission={'view_notes'}>
            <Notes _this={_this} />
          </PermissionWrapper>

          <PermissionWrapper permission={'view_invoices'} className="w-full md:w-[65%]">
            <Invoice _this={_this} />
          </PermissionWrapper>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-3">
          <PermissionWrapper permission={'view_payment_method'}>
            <PaymentMethod _this={_this} />
          </PermissionWrapper>

          <PermissionWrapper permission={'view_chargeback'}>
            <Chargeback _this={_this} />
          </PermissionWrapper>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-3">
          <PermissionWrapper permission={'view_credits'}>
            <Credits _this={_this} />
          </PermissionWrapper>

          <PermissionWrapper permission={'view_logs'}>
            <Log _this={_this} />
          </PermissionWrapper>
        </div>
      </div>

      <EditCustomer _this={_this} />
      <AddNewPayment _this={_this} />
      <EditPaymentDetails _this={_this} />
      <EditSubscription _this={_this} />
      <AddNote _this={_this} />
      <SubscriptionDetails _this={_this} />
      <AddNewPlan _this={_this} />
      <AddNewSubscription _this={_this} />
      <SetPrimaryPaymentModal _this={_this} />
      <RefundModal _this={_this} />
      <DocumentModel _this={_this} />
      <AddCredit _this={_this} />
      <RefundDuplicateInvoiceModal _this={_this} />
      <TemporaryEditSubscription _this={_this} />
      <DeleteSubscriptionModal _this={_this} />
      <DeleteInvoiceModal _this={_this} />
      <EditDueDateModal _this={_this} />
      <PayExtra _this={_this} />
      <SeeLogEmailDataModal _this={_this} />
      <DeletePaymentMethodModal _this={_this} />
      <LogDataModal _this={_this} />
      <DeleteDocumentModal _this={_this} />
    </>
  );
};

export default Section;
