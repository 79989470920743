import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toggleSideNav } from 'redux/action';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { PermissionWrapper, HasPermission, AutSuggestInput } from 'components';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { MdOutlineSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import API from 'services/api';

const Component = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isExpanded = useSelector((state) => state.session).sidenavIsExpanded;
  const user = useSelector((state) => state.session).userSession.email;
  const [userInput, setUserInput] = useState('');
  const [isSuggestionShowing, setIsSuggestionShowing] = useState(false);
  const [autoSuggestList, setAutoSuggestList] = useState([]);
  const inputRef = useRef(null);

  const getPatientDetails = async () => {
    const response = await API.getCustomerIDs(userInput);
    if (response) {
      setAutoSuggestList(response);
    }
  };

  useEffect(() => {
    if (userInput.length == 0) setAutoSuggestList([]);
    else {
      const timer = setTimeout(() => {
        if (inputRef.current.value == userInput) {
          getPatientDetails();
        }

        clearTimeout(timer);
      }, 300);
    }
  }, [userInput]);

  return (
    <>
      <nav
        style={{ zIndex: 9995 }}
        className="flex flex-row justify-between items-center fixed w-full h-[50px] sm:h-[70px] bg-body-backgroudColor px-12 sm:px-0"
      >
        <div className="flex flex-row items-center gap-2">
          <img src="/logo-favicon.png" className="w-6 h-6 sm:w-8 sm:h-8" alt="hspp-logo" />
          <div className="hidden sm:block font-sans-serif text-theme-dashboard-blue sm:text-2xl text-sm md:text-lg font-semibold">
            HSPP Dashboard
          </div>
        </div>
        <div className="flex gap-2 sm:gap-4 justify-center items-center mx-3 fixed right-0">
          <PermissionWrapper permission={'search_patient'}>
            <div className="w-32 sm:w-60 relative">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                🔍
              </span>
              <input
                type="search"
                className="w-full h-6 sm:h-10 pl-10 rounded-xl border bg-white text-xs sm:text-base"
                placeholder="Patient Id/Name"
                value={userInput}
                ref={inputRef}
                onChange={(w) => {
                  if (w.target.value == '') setIsSuggestionShowing(false);
                  else setIsSuggestionShowing(true);
                  setUserInput(w.target.value);
                }}
              />
              <div
                className={`absolute bg-white w-full rounded max-h-72 ${
                  autoSuggestList?.length ? 'border-[1px] ' : ' '
                } border-gray-500 overflow-y-auto`}
              >
                {isSuggestionShowing &&
                  autoSuggestList?.map((item, i) => {
                    return (
                      <div
                        className="p-2 border-b border-gray-300"
                        key={i}
                        onClick={() => {
                          setIsSuggestionShowing(false);
                          setUserInput('');
                          navigate(`/customer-info/${item.id}`);
                        }}
                      >
                        <span>Name : {item.full_name}</span>
                        <br />
                        <span>Customer ID : {item.customer_id}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </PermissionWrapper>
          {/* <div className="flex justify-center items-center mr-2 text-3xl w-16 h-16 rounded-full border-3 border-gray-400 text-button-primary">
            {user[0].toString().toUpperCase()}
          </div> */}

          <Link to={'/profile'}>
            <div className="p-2 text-body-color">
              <MdOutlineSettings className=" cursor-pointer" size={22} />
            </div>
          </Link>
        </div>
      </nav>
      <div className="h-[70px]"></div>
    </>
  );
};

Component.propTypes = {};

export default Component;
