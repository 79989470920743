import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form, Row, Col } from 'react-bootstrap';

const Component = ({ children, className = '', padding = 0 }) => {
  return (
    <Row className={classNames('', className)}>
      {children && children instanceof Array ? (
        children.map((e, i) => <Col key={i}>{e}</Col>)
      ) : (
        <Col>{children}</Col>
      )}
    </Row>
  );
};

Component.propTypes = {
  children: PropTypes.node,
  background: PropTypes.string,
  className: PropTypes.any
};

export default Component;
