import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

const Component = ({
  children,
  background = null,
  className = '',
  paddingClass = 'px-4 py-4',
  onClick
}) => {
  return (
    <Container
      fluid
      className={classNames(
        'bg-white rounded-md border border-["#EBEAED"] relative p-0',
        className
      )}
      onClick={onClick}
    >
      {background && (
        <div
          className={classNames(
            'absolute top-0 left-0 bg-card-bg-1 w-full h-full bg-contain bg-no-repeat rounded-tr-md rounded-br-md z-0',
            background
          )}
        ></div>
      )}
      <div className={'relative w-full ' + paddingClass}>{children}</div>
    </Container>
  );
};

Component.propTypes = {
  children: PropTypes.node,
  background: PropTypes.string,
  className: PropTypes.any
};

export default Component;
