import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login, setUploadProgress } from 'redux/action';
import { DashboardContainer } from 'components';
import { toast } from 'react-toastify';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { idahoLocations } from 'services/helpers';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const nonHsppPatients = state?.patient_id;
  const loggedInUser = useSelector((state) => state.session).userSession;
  const [activeStep, setActiveStep] = useState(0);
  const [planList, setPlanList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [productInformation, setProductInformation] = useState([]);
  const [checkCustomerExistModalVisibility, setCheckCustomerExistModalVisibility] = useState(false);
  const [checkcustomerExistHspp_id, setCheckcustomerExistHspp_id] = useState({
    customer_id: '',
    hspp_id: ''
  });

  const [billingAddress, setBillingAddress] = useState({
    country: { label: 'United States', value: 'United States' }
  });
  const [shippingAddress, setShippingAddress] = useState({
    country: { label: 'United States', value: 'United States' }
  });

  //  Payment options for primary method
  const [achPaymentVisibility, setACHPaymentVisibility] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('ACH');
  const [cardDetails, setCardDetails] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  });
  const [achDetails, setACHDetails] = useState({
    name: '',
    routing_number: '',
    account_number: ''
  });

  //  Payment options for secondary method (same name with a '2')
  const [showSecondaryPayment, setShowSecondaryPayment] = useState(false); //  to show the optional payment method
  const [achPaymentVisibility2, setACHPaymentVisibility2] = useState(false);
  const [paymentMethod2, setPaymentMethod2] = useState('ACH');
  const [cardDetails2, setCardDetails2] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  });
  const [achDetails2, setACHDetails2] = useState({
    name: '',
    routing_number: '',
    account_number: ''
  });

  const batterySizeOptions = [
    { label: 'None', value: 'None' },
    { label: '675', value: '675' },
    { label: '312', value: '312' },
    { label: '13', value: '13' },
    { label: '10', value: '10' },
    { label: '312 - Rechargable', value: '312 - Rechargable' },
    { label: '13 - Rechargable', value: '13 - Rechargable' },
    { label: 'Lithium Batteries', value: 'Lithium Batteries' }
  ];
  const [totalCustomerData, setTotalCustomerData] = useState({});
  const [noOfPlans, setNoOfPlans] = useState({ value: 1, label: 1 });
  const [autoSuggestList, setAutosuggestList] = useState([]);
  const [onNextClickModalVisibility, setOnNextClickModalVisibility] = useState(false);
  const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
  const [isTnCChecked, setIsTnCChecked] = useState(false);
  const [isPatientIdLoading, setIsPatientIdLoading] = useState(false);
  const [fileAttachment, setFileAttachment] = useState(null);

  useEffect(() => {
    loadPlanListOptions();
    loadLocationOptions();
    loadStateOptions();
  }, []);

  //For non hspp patient autofill
  useEffect(() => {
    if (nonHsppPatients) dispatch(loadingStart());
    if (locationList.length > 0 && planList.length > 0 && stateList.length > 0 && nonHsppPatients) {
      createNonHsppPatient(nonHsppPatients);
    }
  }, [locationList, planList, stateList]);

  const createNonHsppPatient = async (e) => {
    let customerIdList = [];

    dispatch(loadingStart());
    await API.getCustomerById(e)
      .then((response) => {
        if (response.status) {
          customerIdList = response.result.map(
            ({
              patient_id,
              first_name,
              last_name,
              email,
              phone,
              address,
              city,
              state,
              zipcode,
              location,
              l_warranty,
              r_warranty,
              battery_type
            }) => ({
              id: patient_id,
              name: patient_id,
              value: {
                patient_id,
                first_name,
                last_name,
                email,
                phone,
                address,
                city,
                state,
                zipcode,
                location,
                l_warranty,
                r_warranty,
                battery_type
              }
            })
          );
        }
      })
      .finally(() => dispatch(loadingStop()));
    dispatch(loadingStart());
    await API.getSerialNumberById(e)
      .then((response) => {
        if (response.status) {
          let left_serial = '',
            right_serial = '';
          if (response?.result[0]?.left) {
            left_serial = response?.result[0]?.left;
            right_serial = response?.result[1]?.right;
          } else right_serial = response?.result[0]?.right;
          customerIdList[0] = { ...customerIdList[0], left_serial, right_serial };
        }
      })
      .finally(() => dispatch(loadingStop()));
    if (customerIdList.length > 0) updateCustomerDetailsBySelection(customerIdList[0]);
  };

  const loadStateOptions = async () => {
    await API.getCountryStates()
      .then(async (response) => {
        if (response) {
          const list = response.states.map((item) => ({
            label: item.subdivisionIsoCode,
            value: item.subdivisionIsoCode
          }));
          setStateList(list);
        }
      })
      .finally(() => {});
  };

  const loadPlanListOptions = async () => {
    await API.getPlansData()
      .then((response) => {
        if (response) {
          const list = response.map((item) => ({
            label: item.name,
            value: item
          }));
          setPlanList(list);
        }
      })
      .finally(() => {});
  };

  const loadLocationOptions = async () => {
    let response = await API.getFranchiseLocation();
    if (response) {
      console.log(response);
      const list = response
        .filter((item) => {
          return idahoLocations.includes(item?.locationName) == false;
        })
        .map((item) => ({
          label: item.locationName,
          value: item
        }));
      setLocationList(list);
    }
  };

  const loadCustomerOptions = async (e) => {
    updateCustomerDetails('customer_id', e);
    if (e.length >= 3) {
      setIsPatientIdLoading(true);
      API.getCustomerById(e)
        .then((response) => {
          if (response.status) {
            const customerIdList = response.result.map(
              ({
                patient_id,
                first_name,
                last_name,
                email,
                phone,
                address,
                city,
                state,
                zipcode,
                location,
                l_warranty,
                r_warranty,
                battery_type
              }) => ({
                id: patient_id,
                name: patient_id,
                value: {
                  patient_id,
                  first_name,
                  last_name,
                  email,
                  phone,
                  address,
                  city,
                  state,
                  zipcode,
                  location,
                  l_warranty,
                  r_warranty,
                  battery_type
                }
              })
            );
            setAutosuggestList(customerIdList);
          }
        })
        .finally(() => {
          setIsPatientIdLoading(false);
        });
    }
  };

  const calculateNumberOfMonthsOrYears = (productInfo) => {
    if (
      productInfo &&
      [
        '2022protectionsupplementallossdamage',
        'protectionsupplementallossdamagesingleaid'
      ].includes(productInfo?.plan?.value?.plan_code) &&
      (productInfo.l_warranty_expiry || productInfo.r_warranty_expiry) &&
      productInfo.plan_interval
    ) {
      // Calculate frequency based on warrenty date
      const frequencyInMonth = moment(
        moment(productInfo.l_warranty_expiry || productInfo.r_warranty_expiry)
      ).diff(moment(), 'months');
      if (productInfo.plan_interval?.value == 'Monthly') return frequencyInMonth;
      else if (productInfo.plan_interval?.value == 'Annually')
        return Math.ceil(frequencyInMonth / 12);
      else if (productInfo.plan_interval?.value == 'Full Amount') return '0';
    } else if (
      productInfo &&
      ['2022secureplan', '2022secureplansupplementalrepairwarrantysingleaid'].includes(
        productInfo?.plan?.value?.plan_code
      ) &&
      productInfo.plan_interval
    ) {
      // Calculate frequency based on db frequency set
      const frequencyInMonth = productInfo?.plan?.value?.frequency * 12;
      if (productInfo.plan_interval?.value == 'Monthly') return frequencyInMonth;
      else if (productInfo.plan_interval?.value == 'Annually')
        return Math.ceil(frequencyInMonth / 12);
      else if (productInfo.plan_interval?.value == 'Full Amount') return '0';
    }
    return '';
  };

  const calculateTotalAmount = () => {
    let amount = 0;
    for (const subscription of productInformation) {
      if (subscription?.plan_interval?.value && subscription?.plan?.value) {
        const interval = subscription?.plan_interval?.value;
        const warranty = subscription.l_warranty_expiry
          ? moment(subscription.l_warranty_expiry)
          : subscription.r_warranty_expiry
          ? moment(subscription.r_warranty_expiry)
          : null;
        const activationDate = subscription?.payment_date
          ? moment(subscription?.payment_date)
          : moment();

        //console.log(subscription?.plan?.value?.frequency);
        if (interval == 'Monthly') {
          if (
            warranty &&
            activationDate &&
            warranty.diff(activationDate, 'days') < 30 &&
            warranty.diff(activationDate, 'days') > 0
          ) {
            amount += (
              (subscription?.plan?.value.monthly / 30) *
              warranty.diff(activationDate, 'days')
            ).toFixed(2);
          } else amount += subscription?.plan?.value.monthly;
        } else if (interval == 'Annually') {
          if (
            warranty &&
            activationDate &&
            warranty.diff(activationDate, 'days') < 365 &&
            warranty.diff(activationDate, 'days') > 0
          ) {
            // Calculate the different in month and remaining days
            const months = moment(warranty.format('YYYY-MM-') + activationDate.format('DD'))
              .add(1, 'day')
              .diff(activationDate, 'months');
            const days = warranty.diff(
              moment(warranty.format('YYYY-MM-') + activationDate.format('DD')),
              'days'
            );
            amount += (
              parseFloat(months * subscription?.plan?.value.monthly) +
              parseFloat((subscription?.plan?.value.monthly / 30) * days)
            ).toFixed(2);
          } else amount += subscription?.plan?.value.annually;
        } else if (interval == 'Full Amount') {
          if (warranty && activationDate && warranty.diff(activationDate, 'days') > 0) {
            // Calculate the different in month and remaining days
            const months = moment(warranty.format('YYYY-MM-') + activationDate.format('DD'))
              .add(1, 'day')
              .diff(activationDate, 'months');
            const days = warranty.diff(
              moment(warranty.format('YYYY-MM-') + activationDate.format('DD')),
              'days'
            );
            amount += +(
              parseFloat(months * subscription?.plan?.value.monthly) +
              parseFloat((subscription?.plan?.value.monthly / 30) * days)
            ).toFixed(2);

            // console.log('2nd', parseFloat((subscription?.plan?.value.monthly / 30) * days));
          } else
            amount += Number(
              subscription?.plan?.value?.frequency * subscription?.plan?.value?.annually
            );
        }
      }
      amount = amount - (subscription?.discount || 0);
    }
    return Math.abs(amount);
  };

  const updateCustomerDetails = (key, value) => {
    setCustomerDetails((state) => {
      return {
        ...state,
        // signature: `${customerDetails?.first_name} ${customerDetails?.last_name}`,
        [key]: value
      };
    });
  };

  const updateProductInformation = (key, value, index) => {
    let c = [...productInformation];
    if (key == 'payment_date' && moment(value) < moment().startOf('day')) return;

    c[index] = { ...c[index], [key]: value };
    // Add same date to r_warranty
    if (key === 'l_warranty_expiry' && value != '')
      c[index] = { ...c[index], r_warranty_expiry: value };
    // Add same battery size to r_battery_size
    if (key === 'l_battery_size') c[index] = { ...c[index], r_battery_size: value };

    if (key === 'l_battery_size' || key === 'r_battery_size') {
      let chargableOptions = new Set(['675', '312', '13', '10']);
      let left = c[index].l_battery_size.value,
        right = c[index].r_battery_size.value;

      if (chargableOptions.has(left) && chargableOptions.has(right)) {
        c[index] = { ...c[index], chargable: { label: 'No', value: 'No' } };
      } else {
        c[index] = { ...c[index], chargable: { label: 'Yes', value: 'Yes' } };
      }
    }

    if (key == 'l_warranty_expiry' || key == 'r_warranty_expiry') {
      // Reset selected plan list if warranty date is changed
      c[index] = { ...c[index], plan: '' };
    }

    if (
      key == 'l_warranty_expiry' ||
      key == 'r_warranty_expiry' ||
      key == 'plan' ||
      key == 'plan_interval'
    ) {
      // Recalculate frequency
      c[index] = { ...c[index], plan_frequency: calculateNumberOfMonthsOrYears(c[index]) };
    }

    // Change newsletter accordingly
    if (key === 'l_battery_size' || key === 'r_battery_size') {
      if (
        ['Lithium Batteries', 'None'].includes(c[index].l_battery_size.value) ||
        ['Lithium Batteries', 'None'].includes(c[index].r_battery_size.value)
      )
        c[index] = { ...c[index], newsletter: true };
      else c[index] = { ...c[index], newsletter: false };
    }

    setProductInformation(c);
    // console.log(c);
  };

  const updateShippingAddress = (key, value) => {
    setShippingAddress((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };

  const updateBillingAddress = (key, value) => {
    setBillingAddress((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };

  const updateCardDetails = (key, value, isPrimary) => {
    if (isPrimary) {
      setCardDetails((state) => {
        return {
          ...state,
          [key]: value?.toString()
        };
      });
      setCardDetails((state) => {
        return {
          ...state,
          focused: key
        };
      });
    } else {
      setCardDetails2((state) => {
        return {
          ...state,
          [key]: value?.toString()
        };
      });
      setCardDetails2((state) => {
        return {
          ...state,
          focused: key
        };
      });
    }
  };

  const updateACHDetails = (key, value, isPrimary) => {
    if (isPrimary) {
      setACHDetails((state) => {
        return {
          ...state,
          [key]: value?.toString()
        };
      });
    } else {
      setACHDetails2((state) => {
        return {
          ...state,
          [key]: value?.toString()
        };
      });
    }
  };

  const updatePaymentMethod = (method, isPrimary) => {
    isPrimary ? setPaymentMethod(method) : setPaymentMethod2(method);
  };

  const updateShowACHDetails = (flag, isPrimary) => {
    isPrimary ? setACHPaymentVisibility(flag) : setACHPaymentVisibility2(flag);
  };

  const showHideSecondaryPayment = () => {
    if (showSecondaryPayment) {
      setPaymentMethod2('ACH');
      setCardDetails2({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: ''
      });
      setACHDetails2({ name: '', routing_number: '', account_number: '' });
    }
    setShowSecondaryPayment(!showSecondaryPayment);
  };

  const updateCustomerDetailsBySelection = (e) => {
    if (e) {
      const email = e.value.email == '-' ? '' : e.value.email;
      updateCustomerDetails('customer_id', e.value.patient_id);
      updateCustomerDetails('first_name', e.value.first_name);
      updateCustomerDetails('last_name', e.value.last_name);
      updateCustomerDetails('email', email);
      updateCustomerDetails('phone', e.value.phone);
      updateCustomerDetails('signature', {
        signature: `${e.value.first_name} ${e.value.last_name}`,
        font: 'default'
      });
      updateShippingAddress('address1', e.value.address);
      updateShippingAddress('city', e.value.city);
      updateShippingAddress('state', e.value.state);
      updateShippingAddress('zipcode', e.value.zipcode);
      updateShippingAddress('contact', e.value.first_name + ' ' + e.value.last_name);
      updateACHDetails('name', `${e.value.first_name} ${e.value.last_name}`, true);
      updateACHDetails('name', `${e.value.first_name} ${e.value.last_name}`, false);
      updateCardDetails('name', `${e.value.first_name} ${e.value.last_name}`, true);
      updateCardDetails('name', `${e.value.first_name} ${e.value.last_name}`, false);

      // Update location for all subscription
      const temp = [...productInformation];

      //updating location for patients location
      const location = locationList.filter((item) => item.value.locationId == e.value.location);
      if (location.length > 0) {
        for (let index = 0; index < noOfPlans.value; index++) {
          temp[index].location = location[0];
        }
      }

      // Set default payment date

      for (let index = 0; index < noOfPlans.value; index++) {
        temp[index].payment_date = moment().toDate();
      }

      //updating location for patients location
      const created_at_location = locationList.filter(
        (item) => item.value.locationName == loggedInUser.location[0]
      );
      if (
        created_at_location.length > 0 ||
        !loggedInUser.location ||
        loggedInUser.location == !'All'
      )
        for (let index = 0; index < noOfPlans.value; index++) {
          temp[index].created_at_location = created_at_location[0];
        }

      if (temp.length > 0 && e?.value?.l_warranty && e?.value?.r_warranty) {
        try {
          let left = moment(e?.value?.l_warranty).toDate(),
            right = moment(e?.value?.r_warranty).toDate();
          if (left >= new Date()) temp[0].l_warranty_expiry = left;
          if (right >= new Date()) temp[0].r_warranty_expiry = right;
        } catch (error) {
          console.log(error.message);
        }
      }

      if (e?.value?.battery_type) {
        let battery_sizes = e?.value?.battery_type.split(`/`);
        let leftFind = batterySizeOptions.find((battery) => battery.value == battery_sizes[0]);
        if (leftFind) {
          temp[0].l_battery_size = leftFind;
        } else if (battery_sizes[0] == 'BUI') temp[0].l_battery_size = batterySizeOptions[7];
        let rightFind = batterySizeOptions.find((battery) => battery.value == battery_sizes[1]);
        if (rightFind) {
          temp[0].r_battery_size = rightFind;
        } else if (battery_sizes[1] == 'BUI') temp[0].r_battery_size = batterySizeOptions[7];

        // chargable value automation
        let chargableOptions = new Set(['675', '312', '13', '10']);
        let left = temp[0].l_battery_size.value,
          right = temp[0].r_battery_size.value;

        if (chargableOptions.has(left) && chargableOptions.has(right)) {
          temp[0] = { ...temp[0], chargable: { label: 'No', value: 'No' } };
        } else {
          temp[0] = { ...temp[0], chargable: { label: 'Yes', value: 'Yes' } };
        }
      }
      if (e?.left_serial) {
        temp[0].left_serial = e?.left_serial;
      }
      if (e?.right_serial) {
        temp[0].right_serial = e?.right_serial;
      }

      setProductInformation(temp);
    } else setCustomerDetails(null);
  };

  const onNoOfPlanChange = (e) => {
    const newNoOfPlan = e.value;
    const array = new Array(newNoOfPlan).fill(productInformation[0]);
    setProductInformation(array);
    // Update the location on all the subscriptions
    // const location = productInformation[0]?.location;
    // if (location) {
    //   for (let index = 0; index < newNoOfPlan; index++) {
    //     updateProductInformation('location', location, index);
    //   }
    // }
    setNoOfPlans(e);
  };

  const copyShippingToBilling = () => {
    const billing_address = { ...shippingAddress };
    delete billing_address['contact'];
    setBillingAddress(billing_address);
  };

  const validateValues = (fields = [], arr) => {
    for (const field of fields) {
      if (!arr[field]) {
        return false;
      }
    }
    return true;
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  function isValidUSPhoneNumber(phoneNumber) {
    // const phonePattern = /^(\(\d{3}\) ?|\d{3}-)\d{3}-\d{4}$/;
    return phoneNumber.length >= 10 ? true : false;
  }

  const submitNext = () => {
    if (activeStep === 0) {
      if (customerDetails.email && customerDetails.email != '' && customerDetails.email != '-') {
        if (!isValidEmail(customerDetails.email)) {
          return toast.error('Enter a valid email address');
        }
      }
      if (!isValidUSPhoneNumber(customerDetails.phone)) {
        return toast.error('Enter a valid Phone Number');
      }
      copyShippingToBilling(shippingAddress);
      const customer_details = customerDetails;
      const shipping_address = { ...shippingAddress, country: shippingAddress.country?.value };

      if (
        !validateValues(
          ['customer_id', 'first_name', 'last_name', 'phone', 'signature'],
          customer_details
        )
      )
        return toast.error('Required Customer detail fields cannot be blank!');
      if (
        !validateValues(
          ['address1', 'city', 'contact', 'country', 'state', 'zipcode'],
          shipping_address
        )
      )
        return toast.error('Required shipping detail fields cannot be blank!');

      if (customerDetails.email && customerDetails.email.includes('miracle-earnw.com')) {
        return toast.error(
          "Looks like you did not put in the patient's email address, failure to enter an accurate email address could result in the patient's going without coverage due to not receiving communication."
        );
      }

      if (!fileAttachment) {
        return toast.error('HSPP authorization form is mandatory, cannot be blank.');
      }

      const subscriptions = [];
      for (const item of productInformation) {
        const product_information = {
          ...item,
          chargable: item.chargable?.value,
          location: item.location?.value,
          created_at_location: item.created_at_location?.value,
          location_id: item.location?.value?.code,
          plan_interval: item.plan_interval?.value,
          plan: item.plan?.value,
          l_warranty_expiry: item.l_warranty_expiry,
          r_warranty_expiry: item.r_warranty_expiry,
          l_battery_size: item.l_battery_size?.value,
          r_battery_size: item.r_battery_size?.value,
          payment_date: item.payment_date,
          plan_override_amount: item.plan_override_amount || null,
          plan_override_date: item.plan_override_date || null
        };

        if (
          !validateValues(
            [
              product_information.l_warranty_expiry === ''
                ? `r_warranty_expiry`
                : 'l_warranty_expiry',
              'plan',
              'plan_interval',
              'chargable',
              'location',
              'created_at_location',
              'l_battery_size',
              'r_battery_size',
              'payment_date'
            ],
            product_information
          )
        )
          return toast.error('Required subscription detail fields cannot be blank!');

        subscriptions.push(product_information);
      }

      setTotalCustomerData((prev) => {
        return { ...prev, customer_details, shipping_address, subscriptions };
      });

      return setOnNextClickModalVisibility(true);
    }

    if (activeStep === 1) {
      const billing_address = { ...billingAddress, country: billingAddress.country?.value };
      // const card_details = { ...cardDetails, number: cardDetails?.number.toString() };
      // const ach_details = { ...achDetails };
      if (!validateValues(['address1', 'city', 'country', 'state', 'zipcode'], billing_address))
        return toast.error('Required billing detail fields cannot be blank!');
      if (paymentMethod !== 'NA') {
        if (
          !validateValues(['cvc', 'expiry', 'name', 'number'], cardDetails) &&
          !validateValues(['name', 'account_number', 'routing_number'], achDetails)
        )
          return toast.error('Required payment detail fields cannot be blank!');
      }
      let secondary_payment_method = {
        method: showSecondaryPayment ? paymentMethod2 : ''
      };
      if (secondary_payment_method?.method != '') {
        if (
          !validateValues(['cvc', 'expiry', 'name', 'number'], cardDetails2) &&
          !validateValues(['name', 'account_number', 'routing_number'], achDetails2)
        )
          return toast.error('Required payment detail fields cannot be blank!');

        let card_temp = cardDetails2.expiry.split('/');
        secondary_payment_method = {
          ...secondary_payment_method,
          card_details: {
            card_last_4_digit: cardDetails2.number.substring(cardDetails2.number.length - 4),
            expiry_month: card_temp[0],
            expiry_year: card_temp[1],
            expiry_date: cardDetails2.expiry,
            name: cardDetails2.name,
            cvv: cardDetails2.cvc,
            card_number: cardDetails2.number
          },
          ach_details: {
            account_last_4_digit: achDetails2.account_number.substring(
              achDetails2.account_number.length - 4
            ),
            account_number: achDetails2.account_number,
            name: achDetails2.name,
            routing_number: achDetails2.routing_number
          }
        };
      }

      let card_temp = cardDetails.expiry.split('/');
      let finalCustomerData = {};
      if (paymentMethod !== 'NA') {
        finalCustomerData = {
          ...totalCustomerData,
          billing_address,
          card_details: {
            card_last_4_digit: cardDetails.number.substring(cardDetails.number.length - 4),
            expiry_month: card_temp[0],
            expiry_year: card_temp[1],
            expiry_date: cardDetails.expiry,
            name: cardDetails.name,
            cvv: cardDetails.cvc,
            card_number: cardDetails.number
          },
          ach_details: {
            account_last_4_digit: achDetails.account_number.substring(
              achDetails.account_number.length - 4
            ),
            account_number: achDetails.account_number,
            name: achDetails.name,
            routing_number: achDetails.routing_number
          },
          payment_method: paymentMethod,
          secondary_payment_method
        };
      } else {
        finalCustomerData = {
          ...totalCustomerData,
          billing_address,
          payment_method: paymentMethod
        };
      }
      setTotalCustomerData(finalCustomerData);

      return createCustomer(finalCustomerData);
    }
  };
  const CheckCustomerExists = async () => {
    const temp = { ...customerDetails };
    const customer_id = temp.customer_id;
    const email = temp.email;
    const phone = temp.phone;
    const response = await API.checkCustomerExists({ customer_id, email, phone }).then(
      (response) => {
        if (response) {
          if (response.email) {
            setCheckcustomerExistHspp_id((prev) => ({
              ...prev,
              hspp_id: response.hspp_id,
              customer_id: response.customer_id,
              email: response.email
            }));
          } else if (response.phone) {
            setCheckcustomerExistHspp_id((prev) => ({
              ...prev,
              hspp_id: response.hspp_id,
              customer_id: response.customer_id,
              phone: response.phone,
              email: ''
            }));
          } else {
            response.hspp_id &&
              setCheckcustomerExistHspp_id((prev) => ({
                ...prev,
                hspp_id: response.hspp_id,
                customer_id: response.customer_id
              }));
          }
          return response;
        }
      }
    );

    if (response.hspp_id) {
      setCheckCustomerExistModalVisibility(true);
      return;
    }
  };

  const onUploadProgress = (event) => {
    const calc = Math.round((event.loaded * 100) / event.total);
    dispatch(setUploadProgress(true));
  };

  const createCustomer = async (finalCustomerData) => {
    dispatch(loadingStart());
    await API.createCustomer(finalCustomerData)
      .then((response) => {
        if (response) {
          if (fileAttachment) {
            const data = {
              customer_id: response.hspp_id,
              message: 'HSPP Authorization Form',
              file: fileAttachment
            };
            toast.info('Document upload is in progress..');
            API.SendDocuments(data, onUploadProgress).then((res) => {
              if (!res) {
                toast.error(
                  'Failed To Upload HSPP Authorization Form , Please Upload from Customer Info!'
                );
              }
              // show toast message and reset the redux state
              toast.success('Document upload completed..!');
              dispatch(setUploadProgress(false));
            });
          }

          toast.success('Customer Successfully Created!');

          navigate(`/customer-info/${response.hspp_id}`);
          // payInvoice(response.invoice_ids, response.hspp_id);
        }
      })
      .catch((e) => {
        dispatch(loadingStop());
      })
      .finally(() => dispatch(loadingStop()));
  };

  // const payInvoice = async (ids, hspp_id) => {
  //   dispatch(loadingStart());
  //   await API.payInvoice(ids)
  //     .then((response) => {
  //       if (response) {
  //         toast.success('Subscription Started!');
  //       }
  //     })
  //     .finally(() => {f
  //       dispatch(loadingStop());
  //       navigate('/admin/customer-info', { state: { hspp_id: hspp_id } });
  //     });
  // };

  return (
    <DashboardContainer>
      <Body
        _this={{
          activeStep,
          customerDetails,
          updateCustomerDetails,
          billingAddress,
          updateBillingAddress,
          shippingAddress,
          updateShippingAddress,
          productInformation,
          updateProductInformation,
          cardDetails,
          achDetails2,
          cardDetails2,
          updateACHDetails,
          updateCardDetails,
          paymentMethod,
          paymentMethod2,
          updatePaymentMethod,
          achPaymentVisibility,
          achDetails,
          showSecondaryPayment,
          showHideSecondaryPayment,
          achPaymentVisibility2,
          updateShowACHDetails,
          loadCustomerOptions,
          autoSuggestList,
          updateCustomerDetailsBySelection,
          batterySizeOptions,
          copyShippingToBilling,
          submitNext,
          locationList,
          stateList,
          noOfPlans,
          setNoOfPlans,
          calculateNumberOfMonthsOrYears,
          calculateTotalAmount,
          onNoOfPlanChange,
          onNextClickModalVisibility,
          setOnNextClickModalVisibility,
          setActiveStep,
          CheckCustomerExists,
          checkCustomerExistModalVisibility,
          setCheckCustomerExistModalVisibility,
          checkcustomerExistHspp_id,
          planList,
          loggedInUser,
          isBackButtonPressed,
          setIsBackButtonPressed,
          isTnCChecked,
          setIsTnCChecked,
          isPatientIdLoading,
          setFileAttachment
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
