import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../services/api';
import { useDispatch } from 'react-redux';
import { loadingStart, loadingStop } from '../../redux/action';
import { toast } from 'react-toastify';
import { Card } from 'components';

const Index = () => {
  const [status, setStatus] = useState('loading');

  const params = useParams();
  const { customer_id, allow } = params;
  const dispatch = useDispatch();

  const init = async () => {
    dispatch(loadingStart());
    await API.emailConfirmation(customer_id, allow == 'yes')
      .then((res) => {
        if (res) {
          setStatus('success');
          let msg =
            allow == 'yes' ? 'Allowed email updates for you.' : 'Email updates stopped from now.';
          toast.success(msg);
        } else setStatus('failure');
      })
      .finally(() => dispatch(loadingStop()));
  };

  useEffect(() => {
    if (customer_id) init();
  }, [customer_id, allow]);

  return (
    <Card background={'bg-cardbg1'} className="w-4/5 md:w-1/2 mx-auto mt-20 text-center">
      <div className="font-semibold text-2xl">Email confirmation</div>
      <hr className="my-3" />
      <div className="text-xl text-gray-600">
        {status == 'loading' ? (
          <p>Please wait while your request is processing</p>
        ) : status == 'success' ? (
          <div>
            {allow == 'yes' ? (
              <p>You will receive text messages from HSPP now. Thank You !</p>
            ) : (
              <p>You will not receive any text messages from from now. Thank You !</p>
            )}
            <p>You can close this window now.</p>
          </div>
        ) : (
          <p>Some error occured. Please try again</p>
        )}
      </div>
    </Card>
  );
};

export default Index;
