import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const Component = ({
  type = 'button',
  color = '#1F288F',
  onClick = () => {},
  block = true,
  disabled = false,
  style = '',
  ...props
}) => {
  let shade =
    'text-white bg-button-primary border-button-primary hover:bg-button-primaryHover hover:border-button-primaryHover';
  if (color == 'danger')
    shade =
      'text-white bg-button-danger border-button-danger hover:bg-button-dangerHover hover:border-button-dangerHover';
  let className =
    shade +
    'text-white font-medium text-base rounded px-3 py-2 buttonShadow tracking-wide cursor-pointer';
  // if (block) className += ' w-full';
  if (disabled) className += ' buttonDisabled';
  if (style) className += ' ' + style;
  return (
    <button className={className} type={type} onClick={onClick} disabled={disabled}>
      {props.children}
    </button>
  );
};

Component.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  block: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Component;
