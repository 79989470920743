import { handleResponse, API, getMiracleAppURL, getToken } from './utils';
import states from '../helpers/states.json';

const contest = {
  fetchLocationGroup: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/contest/fetch-location-group`, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  fetchContestPrizes: async (category, location_group) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(
        `/v1/contest/fetch-contest-prize?category=${category}&location_group=${location_group}`,
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  fetchOngoingContestRecords: async (category, location_group) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/contest/fetch-contest-record`, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  fetchIsThereAnyOngoingContest: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/contest/fetch-is-there-any-ongoing-contest`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  fetchContestAchievementReocrd: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/contest/fetch-achievement-report`, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  startInitialContest: async (contest) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(`/v1/contest/add-contest-initial-record`, contest, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  stopContestByName: async (contest_name) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        `/v1/contest/stop-contest-initials`,
        { contest_name },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  addContestPrize: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(`/v1/contest/add-contest-prize`, data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  editContestPrize: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(`/v1/contest/edit-contest-prize`, data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  addContesAchievementRecord: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/cronjob/add-contest-achievement-record`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default contest;
