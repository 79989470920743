import { useEffect } from 'react';

export const parseHsppId = (id) => {
  if (!id) return '';
  return parseInt(`${id.substring(0, 4)}${id.substring(20)}`, 16).toString();
};

export const renderStatusColorClass = (status) => {
  let colors = 'text-green-700';
  if (status == 'Cancelled' || status == 'Expired' || status == 'Suspended')
    colors = 'text-red-700';
  if (status == 'OnHold') colors = 'text-amber-700';
  return colors;
};

export const renderInvoiceStatusColorClass = (status) => {
  let colors = 'text-green-900 bg-green-200';
  if (status == 'Failed') colors = 'text-red-900 bg-red-200';
  if (status == 'Processing') colors = 'text-blue-800 bg-blue-200';
  if (status == 'Refunded') colors = 'text-gray-900 bg-gray-200';
  return colors;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const useOnKeyPress = (callback, targetKey) => {
  useEffect(() => {
    const keyPressHandler = (event) => {
      if (event.key === targetKey) {
        callback();
      }
    };
    window.addEventListener('keydown', keyPressHandler);
    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, [callback, targetKey]);
};

//  temporary for Idaho locations
// don't allow to create customers...
export const idahoLocations = [
  "Coeur D'alene",
  'Nampa',
  'Boise',
  'Eagle',
  'Sandpoint',
  'Meridian',
  'Emmett',
  'Boise ParkCenter',
  'Lewiston',
  'Mccall',
  'SC-Moscow'
];
