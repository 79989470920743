import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop } from 'redux/action';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.session).userSession;
  const [locationList, setLocationList] = useState(null);
  const [nonHsppPatientsDetails, setNonHsppPatientsDetails] = useState({
    start_date: moment().clone().startOf('month').toDate(),
    end_date: moment().clone().endOf('month').toDate(),
    location: []
  });
  const [originalData, setOriginalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sort, setSort] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    loadLocationOptions();
  }, []);

  useEffect(() => {
    if (nonHsppPatientsDetails.location.length > 0) init();
  }, [nonHsppPatientsDetails.location]);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const loadLocationOptions = async () => {
    dispatch(loadingStart());
    await API.getFranchiseLocation()
      .then((response) => {
        if (response) {
          const list = response.map((item) => ({
            label: item.locationName,
            value: item
          }));
          let userLocationMatch = [];

          loggedInUser.location.forEach((elem) => {
            userLocationMatch.push(...list.filter((item) => item.value.locationName == elem));
          });

          if (loggedInUser.role !== 'Employee') {
            setLocationList(list);
            setNonHsppPatientsDetails((prev) => ({
              ...prev,
              location: userLocationMatch
            }));
          } else {
            setLocationList(userLocationMatch);
            setNonHsppPatientsDetails((prev) => ({
              ...prev,
              location: userLocationMatch
            }));
          }
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const init = () => {
    dispatch(loadingStart());

    API.fetchNonHsppPatients(nonHsppPatientsDetails)
      .then((response) => {
        if (response) {
          //arranging the data in descending order
          response.sort(
            (a, b) => new Date(b.InvoiceCreationDate) - new Date(a.InvoiceCreationDate)
          );
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const navigateToCreateCustomer = (patient_id) => {
    navigate('/create-customer', {
      state: {
        patient_id: patient_id
      }
    });
  };

  //  not needed cz its table now !
  // const sortData = () => {
  //   const temp = [...tableData];
  //   if (sort) {
  //     // Sorting the data based on InvoiceCreationDate in descending order
  //     temp.sort((a, b) => new Date(b.InvoiceCreationDate) - new Date(a.InvoiceCreationDate));
  //     setTableData(temp);
  //     setSort(!sort);
  //   } else {
  //     // Sorting the data based on InvoiceCreationDate in ascending order
  //     temp.sort((a, b) => new Date(a.InvoiceCreationDate) - new Date(b.InvoiceCreationDate));
  //     setTableData(temp);
  //     setSort(!sort);
  //   }
  // };

  return (
    <DashboardContainer>
      <Body
        _this={{
          nonHsppPatientsDetails,
          setNonHsppPatientsDetails,
          locationList,
          init,
          tableData,
          navigateToCreateCustomer,
          // sortData,
          searchInput,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
