import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchData = async (page, limit, sortingField, sortingDirection) => {
    dispatch(loadingStart());
    setCurrentPage(page);
    await API.getInvoiceList(page, limit, sortingField, sortingDirection)
      .then((response) => {
        if (response) {
          setTableData(response.data);
          if (response.total > 0) setTotalPage(response.total);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body _this={{ tableData, totalPage, fetchData, currentPage, setCurrentPage }} />
    </DashboardContainer>
  );
};

export default Index;
