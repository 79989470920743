import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import Navigation from './navigation';
import Config from './config';
import { ToastContainer } from 'react-toastify';
import { MaintenancePage } from './components';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const maintenanceMode = false;

  return (
    <div className="bg-body-backgroudColor min-h-screen max-w-full overflow-x-hidden relative">
      <Provider store={store}>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          style={{ zIndex: 99999 }}
        />
        {maintenanceMode ? <MaintenancePage /> : <Navigation />}
      </Provider>
    </div>
  );
};

export default App;
