import React from 'react';
import { Card, Button, PermissionWrapper, LineGraph, BarGraph } from 'components';
import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs';
import { GoArrowUpRight, GoArrowDownLeft } from 'react-icons/go';
import Form from 'react-bootstrap/Form';
import { BsThreeDots } from 'react-icons/bs';

const CustomerInformation = ({ _this }) => {
  const customer = _this.customersData;

  const calculatePercentage = (currentNumber, lastMonth) => {
    const difference = currentNumber - lastMonth;
    const result = lastMonth != 0 ? (difference / lastMonth) * 100 : 0;

    if (result < 0) {
      return (
        <div className="flex items-end font-semibold text-rose-600">
          <GoArrowDownLeft size={20} />{' '}
          <span className="ml-1 text-sm text-body-color">{result.toFixed(2)}%</span>
        </div>
      );
    } else if (isNaN(result)) {
      return '';
    } else {
      return (
        <div className="flex font-semibold text-green-600">
          <GoArrowUpRight size={20} />{' '}
          <span className="ml-1 text-sm text-body-color">{result.toFixed(2)}%</span>
        </div>
      );
    }
  };
  const graphData = {
    labels: [`current`, `1 Month Ago`, `3 Months Ago`, `6 Months Ago`, `12 Months Ago`],
    datasets: [
      {
        label: 'All Customers',
        data: [
          customer?.total_customers,
          customer?.last_month_customers,
          customer?.last_3_month_customers,
          customer?.last_6_month_customers,
          customer?.last_12_month_customers
        ],
        borderRadius: 10,
        borderWidth: 2,
        borderSkipped: false,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgb(75, 192, 192, 0.5)'
      },
      {
        label: 'Active Customers',
        data: [
          customer?.total_active_customers,
          customer?.last_month_active_customers,
          customer?.last_3_month_active_customers,
          customer?.last_6_month_active_customers,
          customer?.last_12_month_active_customers
        ],
        borderRadius: 10,
        borderWidth: 2,
        borderSkipped: false,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgb(54, 162, 235, 0.5)'
      },
      {
        label: 'Active Subscriptions',
        data: [
          customer?.total_active_subscriptions,
          customer?.last_month_active_subscriptions,
          customer?.last_3_month_active_subscriptions,
          customer?.last_6_month_active_subscriptions,
          customer?.last_12_month_active_subscriptions
        ],
        borderRadius: 10,
        borderWidth: 2,
        borderSkipped: false,
        borderColor: 'rgb(201, 203, 207)',
        backgroundColor: 'rgb(201, 203, 207, 0.5)'
      }
    ]
  };

  return (
    <div className="flex flex-col justify-between gap-4">
      {/* Heading*/}
      <Card className="rounded-xl">
        <div className="flex justify-between pb-3 border-b">
          <h1 className="font-bold text-lg sm:text-2xl">Customer Information</h1>

          <PermissionWrapper permission={'select_customer_information_location'}>
            <div className="flex flex-col sm:flex-row gap-2 justify-center items-center">
              <Form.Select
                className="md:w-48 rounded-md bg-transparent "
                aria-label="Default select example"
                onChange={(e) => {
                  _this.getSubCategoriesFnachiseLocationOptions &&
                    _this.getSubCategoriesFnachiseLocationOptions(
                      _this.keysOfFranchiseLocationData[e.target.value]
                    );
                  _this.setSelectedFieldsForCustomerInformation((prev) => ({
                    ...prev,
                    key: e.target.value
                  }));
                }}
              >
                <option>Select Parameters</option>
                {Object.keys(_this.keysOfFranchiseLocationData)?.map((item, index) => (
                  <option key={index} className="text-lg">
                    {item}
                  </option>
                ))}
              </Form.Select>

              <Form.Select
                className="w-58 rounded-md"
                aria-label="Default select example"
                onChange={(e) => {
                  _this.onFranchiseLocationOptionsForCustomerSubmit(e.target.value);
                  _this.setSelectedFieldsForCustomerInformation((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              >
                <option>Select Options</option>
                <option>Total</option>
                {_this.subCategoriesFranchiseLocationOptions?.map((item, index) => (
                  <option key={index} className="text-lg">
                    {item}
                  </option>
                ))}
              </Form.Select>
            </div>
          </PermissionWrapper>
        </div>

        <div className="w-full my-4 pb-6 flex justify-between flex-wrap border-b">
          <div className="p-2 border-b sm:border-b-0 flex flex-col justify-start gap-2">
            <p className="">Current</p>
            <p className="font-bold text-2xl">{customer?.total_customers}</p>

            <p className="font-bold text-xl">
              {customer?.total_active_customers}
              <span className="font-thin text-sm text-body-color ml-1">Active Customer</span>
            </p>

            <p className="font-bold text-xl">
              {customer?.total_active_subscriptions}
              <span className="font-thin text-sm text-body-color ml-1">Active Subscription</span>
            </p>
          </div>
          <div className="border-b sm:border-b-0 sm:border-l p-2 flex flex-col justify-start gap-2">
            <p className="">1 Month Ago</p>
            <div className="flex gap-2 items-end">
              <p className="font-bold text-2xl">{customer?.last_month_customers}</p>
              <p className="font-thin text-sm">
                {calculatePercentage(customer?.total_customers, customer?.last_month_customers)}
              </p>
            </div>

            <p className="font-bold text-xl">
              {customer?.last_month_active_customers}
              <span className="font-thin text-sm text-body-color ml-1">Active Customer</span>
            </p>

            <p className="font-bold text-xl">
              {customer?.last_month_active_subscriptions}
              <span className="font-thin text-sm text-body-color ml-1">Active Subscription</span>
            </p>
          </div>

          <div className="border-b sm:border-b-0 sm:border-l p-2 flex flex-col justify-start gap-2">
            <p className="">3 Month Ago</p>
            <div className="flex gap-2 items-end">
              <p className="font-bold text-2xl">{customer?.last_3_month_customers}</p>
              <p className="font-thin text-sm">
                {calculatePercentage(customer?.total_customers, customer?.last_3_month_customers)}
              </p>
            </div>

            <p className="font-bold text-xl">
              {customer?.last_3_month_active_customers}
              <span className="font-thin text-sm text-body-color ml-1">Active Customer</span>
            </p>

            <p className="font-bold text-xl">
              {customer?.last_3_month_active_subscriptions}
              <span className="font-thin text-sm text-body-color ml-1">Active Subscription</span>
            </p>
          </div>

          <div className="border-b sm:border-b-0 sm:border-l p-2 flex flex-col justify-start gap-2">
            <p className="">6 Month Ago</p>
            <div className="flex gap-2 items-end">
              <p className="font-bold text-2xl">{customer?.last_6_month_customers}</p>
              <p className="font-thin text-sm">
                {calculatePercentage(customer?.total_customers, customer?.last_6_month_customers)}
              </p>
            </div>

            <p className="font-bold text-xl">
              {customer?.last_6_month_active_customers}
              <span className="font-thin text-sm text-body-color ml-1">Active Customer</span>
            </p>

            <p className="font-bold text-xl">
              {customer?.last_6_month_active_subscriptions}
              <span className="font-thin text-sm text-body-color ml-1">Active Subscription</span>
            </p>
          </div>

          <div className="border-b sm:border-b-0 sm:border-l p-2 flex flex-col justify-start gap-2">
            <p className="">12 Month Ago</p>
            <div className="flex gap-2 items-end">
              <p className="font-bold text-2xl">{customer?.last_12_month_customers}</p>
              <p className="font-thin text-sm">
                {calculatePercentage(customer?.total_customers, customer?.last_12_month_customers)}
              </p>
            </div>

            <p className="font-bold text-xl">
              {customer?.last_12_month_active_customers}
              <span className="font-thin text-sm text-body-color ml-1">Active Customer</span>
            </p>

            <p className="font-bold text-xl">
              {customer?.last_12_month_active_subscriptions}
              <span className="font-thin text-sm text-body-color ml-1">Active Subscription</span>
            </p>
          </div>
        </div>

        <div className="flex justify-between pb-3">
          <h1 className="font-bold text-lg sm:text-2xl">Chart View</h1>
        </div>
        <div className="h-80">
          <BarGraph data={graphData} />
        </div>
      </Card>

      {/* <Card
        bordered={false}
        title="ONLINE WORK"
        className="flex-1 rounded-xl bg-theme-dull-white"
        extra={<BsThreeDots className="text-theme-text-color" />}
      >
        <div className="flex flex-row gap-2">
          <p className="flex basis-1/3">Graph</p>
          <div className="flex basis-2/3 flex-col gap-2">
            <span className="font-extrabold text-2xl text-theme-dull-white">9</span>
            <span className="text-theme-text-secondary-color text-sm">Online Consultations</span>
            <span className="text-sm p-1 bg-green-200 text-green-400 rounded">
              +12% than average
            </span>
          </div>
        </div>
      </Card>
      <Card
        bordered={false}
        title="LABORATORY WORK"
        className="flex-1 rounded-xl bg-theme-dull-white"
        extra={<BsThreeDots className="text-theme-text-color" />}
      >
        <div className="flex flex-row gap-2">
          <p className="flex basis-1/3">Graph</p>
          <div className="flex basis-2/3 flex-col gap-2">
            <span className="font-extrabold text-2xl text-theme-dull-white">19</span>
            <span className="text-theme-text-secondary-color text-sm">Laboratory Analysis</span>
            <span className="text-sm p-1 bg-cyan-200 text-cyan-400 rounded">+0% than average</span>
          </div>
        </div>
      </Card> */}
    </div>
    // <Card className="flex">
    //   <div className="flex flex-col sm:flex-row justify-between border-b-[3px] pb-1 gap-3">
    //     <h3 className="text-body-color text-xl md:text-2xl">Customer Information</h3>
    //     <PermissionWrapper permission={'select_customer_information_location'}>
    //       <div className="flex flex-col sm:flex-row gap-2 justify-center items-center mb-3">
    //         <Form.Select
    //           className="md:w-48 rounded-md border-2 focus:border-button-primary"
    //           aria-label="Default select example"
    //           onChange={(e) => {
    //             _this.getSubCategoriesFnachiseLocationOptions &&
    //               _this.getSubCategoriesFnachiseLocationOptions(
    //                 _this.keysOfFranchiseLocationData[e.target.value]
    //               );
    //             _this.setSelectedFieldsForCustomerInformation((prev) => ({
    //               ...prev,
    //               key: e.target.value
    //             }));
    //           }}
    //         >
    //           <option>Select Parameters</option>
    //           {Object.keys(_this.keysOfFranchiseLocationData)?.map((item, index) => (
    //             <option key={index} className="text-lg">
    //               {item}
    //             </option>
    //           ))}
    //         </Form.Select>

    //         <Form.Select
    //           className="w-58 rounded-md border-2 focus:border-button-primary"
    //           aria-label="Default select example"
    //           onChange={(e) => {
    //             _this.onFranchiseLocationOptionsForCustomerSubmit(e.target.value);
    //             _this.setSelectedFieldsForCustomerInformation((prev) => ({
    //               ...prev,
    //               value: e.target.value
    //             }));
    //           }}
    //         >
    //           <option>Select Options</option>
    //           <option>Total</option>
    //           {_this.subCategoriesFranchiseLocationOptions?.map((item, index) => (
    //             <option key={index} className="text-lg">
    //               {item}
    //             </option>
    //           ))}
    //         </Form.Select>
    //       </div>
    //     </PermissionWrapper>
    //   </div>

    //   <div className="flex justify-between my-2">
    //     <h6 className="text-body-color text-lg font-semibold pb-1 mt-2">All Customers</h6>
    //     <h6 className="text-body-color mt-2 pb-1 text-lg">
    //       As per{' '}
    //       <span className="text-black">
    //         {_this.selectedFieldsforCustomerInformation?.value != '' &&
    //         _this.selectedFieldsforCustomerInformation?.key != 'Total'
    //           ? `${_this.selectedFieldsforCustomerInformation?.key}: ${_this.selectedFieldsforCustomerInformation?.value}`
    //           : 'Total'}
    //       </span>
    //     </h6>
    //   </div>
    //   <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         Current
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1 h-14">
    //         <p className="text-4xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.total_customers}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         1 Month Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_month_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_customers,
    //           _this.customersData?.last_month_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         3 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_3_month_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_customers,
    //           _this.customersData?.last_3_month_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         6 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_6_month_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_customers,
    //           _this.customersData?.last_6_month_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         12 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_12_month_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_customers,
    //           _this.customersData?.last_12_month_customers
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   <h6 className="text-body-color font-semibold pb-1 mt-4 my-2">Active Customers</h6>
    //   <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         Current
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1 h-14">
    //         <p className="text-4xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.total_active_customers}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         1 Month Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_month_active_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_customers,
    //           _this.customersData?.last_month_active_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         3 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_3_month_active_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_customers,
    //           _this.customersData?.last_3_month_active_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         6 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_6_month_active_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_customers,
    //           _this.customersData?.last_6_month_active_customers
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         12 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_12_month_active_customers}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_customers,
    //           _this.customersData?.last_12_month_active_customers
    //         )}
    //       </div>
    //     </div>
    //   </div>

    //   <h6 className="text-body-color font-semibold pb-1 mt-4 my-2">Active Subscriptions</h6>
    //   <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         Current
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1 h-14">
    //         <p className="text-4xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.total_active_subscriptions}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         1 Month Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_month_active_subscriptions}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_subscriptions,
    //           _this.customersData?.last_month_active_subscriptions
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         3 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_3_month_active_subscriptions}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_subscriptions,
    //           _this.customersData?.last_3_month_active_subscriptions
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         6 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_6_month_active_subscriptions}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_subscriptions,
    //           _this.customersData?.last_6_month_active_subscriptions
    //         )}
    //       </div>
    //     </div>
    //     <div className="bg-body-backgroudColor rounded-lg w-36 py-1 pb-2">
    //       <h6 className="text-slate-900 font-medium tracking-wider text-center border-body-color border-b-[1px] py-1 shadow-b-sm">
    //         12 Months Ago
    //       </h6>
    //       <div className="mt-1 flex flex-col justify-center items-center gap-1">
    //         <p className="text-2xl text-yellow-700 font-semibold tracking-wide">
    //           {_this.customersData?.last_12_month_active_subscriptions}
    //         </p>
    //         {calculatePercentage(
    //           _this.customersData?.total_active_subscriptions,
    //           _this.customersData?.last_12_month_active_subscriptions
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </Card>
  );
};

export default CustomerInformation;
