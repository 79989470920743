import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Index = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [franchiseList, setFranchiseList] = useState([]);
  const [searchFieldOption, setSearchFieldOption] = useState({
    All: 'all',
    'Location Name': 'Location Name',
    'Franchise  Name': 'Franchise  Name'
  });
  const [search, setSearch] = useState({ searchField: 'all' });
  const [selectedLocations, setSelectedLocations] = useState({
    location: []
  });
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  const [shippingAddressIndicator, setShippingAddressIndicator] = useState(true);
  useEffect(() => {
    init();
    loadLocationOptions();
  }, [shippingAddressIndicator]);

  const loadLocationOptions = async () => {
    let response = await API.getFranchiseLocation();

    if (response) {
      const locationMap = {};

      response.forEach((item) => {
        const { franchiseName, locationId } = item;

        if (!locationMap[franchiseName]) {
          locationMap[franchiseName] = {
            label: franchiseName,
            value: [locationId]
          };
        } else {
          locationMap[franchiseName].value.push(locationId);
        }
      });

      const list = response.map((item) => ({
        label: item.locationName,
        value: item.locationId
      }));

      setLocationList(list);

      const franchiseList = Object.values(locationMap);
      setFranchiseList(franchiseList);
    }

    // if (response) {
    //   const list = response.map((item) => ({
    //     label: item.locationName,
    //     value: item.locationName
    //   }));
    //   setLocationList(list);
    // }
  };

  const init = async (current_page = 0, limit_per_page = 20, sort_col = null, sort_dir = null) => {
    dispatch(loadingStart());
    let data;
    if (sort_col) {
      data = {
        page: current_page,
        limit: limit_per_page,
        column: sort_col,
        direction: sort_dir ? 1 : -1,
        locations: selectedLocationsArray,
        shipping_address: shippingAddressIndicator
      };
    } else {
      data = {
        page: current_page,
        limit: limit_per_page,
        column: sortColumn,
        direction: sortDirection ? 1 : -1,
        locations: selectedLocationsArray,
        shipping_address: shippingAddressIndicator
      };
    }
    API.getProtectionPlanBatteryReport(data)
      .then((result) => {
        setData(result.data);
        if (current_page < 1) {
          setTotalPage(result.total_pages);
          setTotalData(result?.total_data);
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const handelExport = async () => {
    setIsLoading(true);
    let data;

    data = {
      page: 0,
      limit: totalPage * limitPerPage,
      column: sortColumn,
      direction: sortDirection ? 1 : -1,
      locations: selectedLocationsArray,
      shipping_address: shippingAddressIndicator
    };

    API.getProtectionPlanBatteryReport(data).then((result) => {
      const desiredOrder = [
        'patient_id',
        'patient_name',
        'location_name',
        'chargeable',
        'do_not_mail',
        'l_battery_size',
        'r_battery_size',
        'shipping_address_1',
        'shipping_address_2',
        'city',
        'state',
        'country',
        'zipcode',
        'createdAt'
      ];

      const orderedDataArray = result?.data?.map((item) => {
        const orderedItem = {};

        for (const key of desiredOrder) {
          if (key === 'do_not_mail') {
            orderedItem[key] = item[key] ? 'Yes' : 'No';
          } else if (key === 'createdAt') {
            const momentCreatedAt = moment(item[key]);
            let diff = moment().diff(momentCreatedAt);
            diff = diff / (24 * 3600 * 1000);

            const l_battery_size = item['l_battery_size'];
            const r_battery_size = item['r_battery_size'];
            let batterySize;
            let displayText = '';
            if (l_battery_size && l_battery_size !== 'None') {
              batterySize = l_battery_size;
            } else if (r_battery_size && r_battery_size !== 'None') {
              batterySize = r_battery_size;
            } else {
              batterySize = 'None';
            }

            switch (batterySize) {
              case '675':
                displayText = '675-NB';
                break;
              case '312':
                displayText = '312-NB';
                break;
              case '13':
                displayText = '13-NB';
                break;
              case '10':
                displayText = '10-NB';
                break;
              case '312 - Rechargable':
                displayText = '312R-NB';
                break;
              case '13 - Rechargable':
                displayText = '13R-NB';
                break;
              default:
                displayText = 'N-N';
                break;
            }
            if (diff < 31) displayText += 'W';

            // const momentCreatedAt = moment(item[key]);
            // const isPreviousMonth = momentCreatedAt.isSame(
            //   moment().subtract(1, 'month'),
            //   'month'
            // );
            // const l_battery_size = item['l_battery_size'];
            // const r_battery_size = item['r_battery_size'];
            // if (l_battery_size && l_battery_size !== 'None') {
            //   if (isPreviousMonth) {
            //     orderedItem['Fulltech_Mailer'] = l_battery_size + ' - NBW';
            //   } else {
            //     orderedItem['Fulltech_Mailer'] = l_battery_size + ' - NB';
            //   }
            // } else if (r_battery_size && r_battery_size !== 'None') {
            //   if (isPreviousMonth) {
            //     orderedItem['Fulltech_Mailer'] = r_battery_size + ' - NBW';
            //   } else {
            //     orderedItem['Fulltech_Mailer'] = r_battery_size + ' - NB';
            //   }
            // } else if (
            //   l_battery_size &&
            //   l_battery_size == 'None' &&
            //   r_battery_size &&
            //   r_battery_size == 'None'
            // ) {
            //   if (isPreviousMonth) {
            //     orderedItem['Fulltech_Mailer'] = 'No - NBW';
            //   } else {
            //     orderedItem['Fulltech_Mailer'] = 'NO - NB';
            //   }
            // } else {
            // }
            orderedItem['Fulltech_Mailer'] = displayText;
            orderedItem[key] = moment(momentCreatedAt).format('YYYY-MM-DD');
          } else if (item[key] !== undefined) {
            orderedItem[key] = item[key];
          }
        }

        return orderedItem;
      });

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(orderedDataArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'data');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileServer.saveAs(data, 'Protection Plan Battery Mailer List' + fileExtension);

      setIsLoading(false);
    });
  };
  const searchData = async () => {
    setCurrentPage(0);
    let locations = [];
    if (search.searchField == 'Franchise  Name') {
      locations = selectedLocations?.location?.value;
    } else if (search.searchField == 'Location Name') {
      locations = selectedLocations?.location?.map((item) => item.value);
    }
    setSelectedLocationsArray(locations);
    dispatch(loadingStart());
    API.getProtectionPlanBatteryReport({
      page: 0,
      limit: limitPerPage,
      column: sortColumn,
      direction: sortDirection ? 1 : -1,
      locations: locations,
      shipping_address: shippingAddressIndicator
    })
      .then((result) => {
        setData(result.data);
        setTotalPage(result.total_pages);
        setTotalData(result?.total_data);
      })
      .finally(() => dispatch(loadingStop()));
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          data,
          totalPage,
          init,
          currentPage,
          setCurrentPage,
          limitPerPage,
          setLimitPerPage,
          handelExport,
          isLoading,
          totalData,
          sortColumn,
          setSortColumn,
          sortDirection,
          setSortDirection,
          locationList,
          searchFieldOption,
          setSearch,
          search,
          selectedLocations,
          setSelectedLocations,
          searchData,
          setLocationList,
          franchiseList,
          setSelectedLocationsArray,
          shippingAddressIndicator,
          setShippingAddressIndicator
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
