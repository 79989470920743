import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { Card, Input, Button } from 'components';
import { Form } from 'react-bootstrap';
import { MdEdit } from 'react-icons/md';
import ButtonBTN from 'react-bootstrap/Button';
import { parseHsppId } from 'services/helpers';
import EditProfileModal from './EditProfileModal';
import CheckPasswordChangeModal from './CheckPasswordChageModal';

const Body = ({ _this }) => {
  return (
    <>
      {_this.is_password_changed == false && (
        <Alert key={'danger'} variant={'danger'}>
          Welcome <strong>{_this.admin?.name}. Please set your password and login again</strong> to
          access the dashboard.
        </Alert>
      )}

      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-2xl">Profile</h3>
          {/* <div className="mr-10">
            <div className="flex flex-row items-center justify-between">
              <span className="bg-green-200 text-green-700 font-semibold px-3 py-1 rounded text-xs">
                <span className="font-semibold text-base">{admin?.role}</span>
              </span>
            </div>
          </div> */}
        </div>
      </Card>
      <div className="flex flex-col md:flex-row">
        <Card className="mb-3 flex basis-1/2 mr-2 mt-2">
          <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
            <h3 className="text-body-color text-xl font-semibold">
              {/* {_this.admin?.role} |  */}
              {_this.admin?.name}
            </h3>
            <ButtonBTN
              onClick={() => _this.setEditProfileModalOpen(true)}
              className={`bg-button-primary`}
              variant="primary"
              id="button-addon3"
            >
              <MdEdit size={24} fill="white" />
            </ButtonBTN>{' '}
          </div>
          {/* <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">Contact Details</h6> */}
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">ID:</h5>
            <h5 className="text-slate-500 text-sm">{_this.admin?._id}</h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">Name:</h5>
            <h5 className="text-slate-500 text-sm">{_this.admin?.name}</h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">Email:</h5>
            <h5 className="text-slate-500 text-sm">{_this.admin?.email}</h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">Role:</h5>
            <h5 className="text-slate-500 text-sm">{_this.admin?.role}</h5>
          </div>
        </Card>
        <Card className="mb-3 flex basis-1/2 mt-2">
          <h3 className="text-body-color text-xl font-semibold border-b-[3px] pb-2">
            Change Password
          </h3>
          {/* <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">Change Password</h6> */}
          <div className="text-md text-slate-500 mt-1">
            <Form className="bg-slate-100 mt-2 p-3 rounded">
              <Input
                label="Password"
                placeholder="@Password"
                type="password"
                required
                value={_this.password_details.password}
                onChange={(e) =>
                  _this.setPassword_details((prev) => ({
                    ...prev,
                    password: e.target.value
                  }))
                }
              />
              <Input
                label="Confirm Password"
                placeholder="@Confirm Password"
                type="password"
                required
                value={_this.password_details.confirm_password}
                onChange={(e) =>
                  _this.setPassword_details((prev) => ({
                    ...prev,
                    confirm_password: e.target.value
                  }))
                }
              />
            </Form>
            <Button style="mt-2" onClick={() => _this.changePassword()}>
              Update Password
            </Button>
          </div>
        </Card>
      </div>
      <CheckPasswordChangeModal _this={_this} />
      <EditProfileModal _this={_this} />
    </>
  );
};

export default Body;
