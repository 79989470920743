import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const location = dataObject.location.toString();
      const patients = dataObject.patients;
      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        Location: location,
        patients: patients
        // Address: formattedAddress
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'DroppePatientsReport' + fileExtension);
  };

  const exportDetailedReport = () => {
    const extractedDataArray = [];
    const patients_report_array = _this.tableData.map((item) => {
      return item.patients_report;
    });

    const patients_report = patients_report_array.flat();
    for (const dataObject of patients_report) {
      const customerId = dataObject.customer_id;
      const customerName = `${dataObject.first_name} ${dataObject.last_name}`;
      const location = dataObject.location.toString();
      const droppedDate = new Date(dataObject.dropped_date);
      const dueDate = new Date(dataObject.due_date);
      const status = dataObject.invoice_status;
      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        CustomerID: customerId,
        CustomerName: customerName,
        Location: location,
        DroppedDate: droppedDate,
        DueDate: dueDate,
        Status: status
        // Address: formattedAddress
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'DroppePatientsDetailedReport' + fileExtension);
  };

  const columns = [
    {
      Header: 'Location',
      Cell: (row) => <div>{row.original.location}</div>
    },
    {
      Header: 'Patients',
      Cell: (row) => (
        <Link
          to={'/dropped-patients-invoices-report'}
          state={{
            patients_report: row.original.patients_report
          }}
          className="text-blue-500 font-medium"
        >
          <div>{row.original.patients}</div>
        </Link>
      )
    }
  ];

  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card className="flex mr-2 mt-2">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between mb-2">
          <h3 className="text-body-color text-xl font-semibold pb-2">Dropped Patients Report</h3>
          <DatePicker
            label="Start Date"
            required
            value={_this.droppedPatientsDetails?.start_due_date || ''}
            onChange={(e) => {
              _this.setDroppedPatientsDetails((prev) => ({
                ...prev,
                start_due_date: e
              }));
            }}
          />

          <DatePicker
            label="End Date"
            required
            value={_this.droppedPatientsDetails?.end_due_date || ''}
            onChange={(e) => {
              _this.setDroppedPatientsDetails((prev) => ({
                ...prev,
                end_due_date: e
              }));
            }}
          />
          <Dropdown
            className="w-48 z-50"
            label="Location"
            placeholder="@location"
            required
            type="text"
            options={_this.locationList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
            aria-label="Default select example"
            value={_this.droppedPatientsDetails.location}
            onChange={(e) => {
              _this.setDroppedPatientsDetails((prev) => ({
                ...prev,
                location: e
              }));
            }}
            isMulti
          />

          <button
            className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
            onClick={_this.init}
          >
            Generate Report
          </button>
        </div>
        <div className="flex justify-between my-2 gap-2 mt-2">
          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
          <ButtonBTN
            onClick={() => exportDetailedReport()}
            className={`bg-button-primary`}
            variant="primary"
            id="export"
          >
            Export Detailed Report
          </ButtonBTN>
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default Section;
