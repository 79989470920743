import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input, Dropdown } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const EditEmployeeModal = ({ _this }) => {
  const [note, setNote] = useState('');

  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this.editEmployeeModalVisibility}
      onHide={() => _this.setEditEmployeeModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Edit Employee</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setEditEmployeeModalVisibility(false)} />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 flex mr-2">
          <Input
            label="Name"
            type="text"
            required
            value={_this.editEmployeeData.name}
            onChange={(e) =>
              _this.setEditEmployeeData((prev) => ({
                ...prev,
                name: e.target.value
              }))
            }
          />
          <Input
            label="email"
            type="text"
            required
            value={_this.editEmployeeData.email}
            onChange={(e) =>
              _this.setEditEmployeeData((prev) => ({
                ...prev,
                email: e.target.value
              }))
            }
          />
          <Dropdown
            label="Select Location"
            required
            type="text"
            options={_this.locationList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
            isClearable
            aria-label="Default select example"
            onChange={(e) => {
              _this.onSelectingLocation(e);
            }}
            value={_this.editEmployeeData.location}
            isMulti
          />
          <Dropdown
            label="Select Role"
            required
            type="text"
            options={_this.roleList}
            isClearable
            aria-label="Default select example"
            onChange={(e) =>
              _this.setEditEmployeeData((prev) => ({
                ...prev,
                role_id: e
              }))
            }
            value={_this.editEmployeeData.role_id}
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.editDataSubmit();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditEmployeeModal;
