import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const Component = ({
  label = '',
  controlId = '',
  type = '',
  placeholder = '',
  hint = '',
  required = false,
  className = '',
  ...props
}) => {
  return (
    <Form.Group className="mb-2" controlId={controlId}>
      <Form.Label className="text-sm text-slate-600 font-bold tracking-wide mb-0">
        {label}
        {required && <span className="text-red-600"> *</span>}
      </Form.Label>
      <Form.Control
        className={
          'rounded inputShadow border-border-primary focus:border-border-focus inputBox ' +
          className
        }
        type={type}
        placeholder={placeholder}
        autoComplete="off"
        {...props}
      />
      {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
    </Form.Group>
  );
};

Component.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  type: PropTypes.string
};

export default Component;
