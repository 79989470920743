import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';
import states from '../helpers/states.json';

const NonHsppPatients = {
  fetchNonHsppPatients: async (data) => {
    let response;
    const token = await getToken();
    try {
      response = await API.post(`/v1/non-hspp-patients/fetch-patients`, data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  }
};

export default NonHsppPatients;
