import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import './style.scss';

const Component = ({
  label = '',
  controlId = '',
  hint = '',
  required = false,
  placeholder = '',
  maxResults = 8,
  items = [],
  inputSearchString = '',
  onSearch = () => {},
  onSelect = () => {},
  isLoading = false,
  onChange = () => {},
  onBlur = () => {},
  ...props
}) => {
  // const previousItemLength = useRef();
  // const [searchString, setSearchString] = useState('');

  // useEffect(() => {
  //   if (inputSearchString != '') {
  //     setSearchString(inputSearchString);
  //   }
  // }, [inputSearchString]);

  // useEffect(() => {
  //   if (items.length == 0) previousItemLength.current = 0;

  //   if (previousItemLength.current == 0 && items.length > 0) {
  //     previousItemLength.current = items.length;
  //     // Rerender to show list after api call
  //     const temp = searchString;
  //     setSearchString(temp);
  //     setTimeout(() => {
  //       setSearchString(temp);
  //     }, 100);
  //   }
  // }, [items]);

  return (
    <Form.Group className="mb-2 relative z-50" controlId={controlId}>
      <Form.Label className="text-sm text-slate-600 font-bold tracking-wide mb-0">
        {label}
        {required && <span className="text-red-600"> *</span>}
      </Form.Label>
      <AsyncTypeahead
        filterBy={() => true}
        isLoading={isLoading}
        labelKey="name"
        minLength={3}
        maxResults={maxResults}
        id="test"
        onSearch={onSearch}
        onBlur={onBlur}
        options={items}
        placeholder={placeholder}
        renderMenuItemChildren={(item) => {
          return (
            <span
              onClick={() => {
                onSelect(item);
              }}
              style={{ display: 'block', textAlign: 'left' }}
            >
              {item.name}
            </span>
          );
        }}
      />
      {/* <ReactSearchAutocomplete
        items={items}
        onSearch={(e) => {
          setSearchString(e);
          onSearch(e);
        }}
        inputSearchString={searchString}
        onHover={() => {}}
        onSelect={onSelect}
        onFocus={() => {}}
        showIcon={false}
        maxResults={maxResults}
        showNoResults={false}
        showItemsOnFocus={true}
        styling={{
          borderWidth: '1px',
          borderRadius: '4px',
          borderColor: 'hsl(0, 0%, 80%)',
          boxShadow: 'none',
          height: '38px'
        }}
        placeholder={placeholder}
        formatResult={(item) => {
          return <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>;
        }}
      /> */}
      {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
    </Form.Group>
  );
};

Component.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string
};

export default Component;
