import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJs.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const LineGraph = ({ data, onClick }) => {
  const options = {
    maintainAspectRatio: false,
    onClick: onClick
  };

  return <Line options={options} data={data} />;
};

export default LineGraph;
