import React from 'react';
import { IoMdSettings } from 'react-icons/io';
import { FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdOutlineMailOutline } from 'react-icons/md';
import { FiMapPin } from 'react-icons/fi';

const MyProfile = ({ _this }) => {
  const getShortName = (name) => {
    const parts = name.split(' ');
    const firstLetter = parts[0].charAt(0); // First letter of the first part
    const secondLetter = parts.length > 1 ? parts[1].charAt(0) : ''; // Check if there's a second part

    return firstLetter + secondLetter; // Concatenate letters
  };
  return (
    <div className="w-full text-theme-dull-white h-full flex justify-center items-center mr-2 flex-col sm:flex-row md:flex-col">
      <div className="flex justify-start items-center p-2 py-8 px-2 gap-4">
        {/* {_this.user?.role !== 'Employee' && ( */}
        <div className="w-14 h-14 rounded-full bg-theme-dull-white flex justify-center items-center">
          <p className=" text-2xl text-body-color">{getShortName(_this.user?.name)}</p>
        </div>
        {/* )} */}

        <div className="flex flex-col pr-3">
          <p className="text-base sm:text-lg font-bold whitespace-nowrap">{_this.user?.name}</p>
          <p className="text-sm sm:text-base">{_this.user?.role}</p>
        </div>
      </div>
      <div className="p-2 px-4 flex flex-col justify-start gap-4 rounded-b-xl text-center text-xs">
        <div className="flex gap-4">
          <MdOutlineMailOutline size={25} />
          <p className="text-sm sm:text-base whitespace-nowrap">{_this.user.email}</p>
        </div>

        <div className="border-theme-dull-white pr-4 flex gap-4">
          <FiMapPin size={25} />
          <p className="text-sm sm:text-base">
            {_this.user?.location
              ?.map((item, index) => {
                if (index === location.length - 1) {
                  return item;
                }
                return item + ',';
              })
              .join('')}
          </p>
        </div>
      </div>
    </div>

    // </div>
  );
};

export default MyProfile;
