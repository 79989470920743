import React from 'react';
import { Card, Button, Input, NewButton, Checkbox } from 'components';
import { PermissionWrapper, HasPermission } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import { Accordion, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { LiaShippingFastSolid, LiaPaypal } from 'react-icons/lia';
import { RiDeleteBin6Line } from 'react-icons/ri';

const Subscription = ({ _this }) => {
  const customer = _this.customerDetails;
  return (
    <Card className="rounded-xl w-full md:w-[68%]" paddingClass="p-3">
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-xl font-bold  pb-2">
          Subscriptions{' '}
          <span className="w-8 h-8 py-1 px-2 rounded-full bg-[#EFF8FF] text-[#175CD3] text-xs font-bold">
            {customer.subscription_groups.length}
          </span>
        </h3>
        <PermissionWrapper permission={'add_subscription'}>
          <NewButton
            onClick={() => {
              _this.setAddNewSubscriptionModalOpen(true);
            }}
          >
            <span className="font-medium text-base ml-0.5 text-white">Add New Subscription</span>
          </NewButton>
        </PermissionWrapper>
      </div>
      <div className="flex flex-col gap-2 mt-1">
        {customer.subscription_groups.map((subscription_group, index) => {
          let firstSubscription = subscription_group?.subscriptions?.find(
            (sub) => sub.status == 'Active'
          );
          if (!firstSubscription) firstSubscription = subscription_group?.subscriptions[0];
          return (
            <Accordion key={index} defaultActiveKey="0" className="w-full mt-2 rounded-xl">
              <Accordion.Item eventKey={'0'}>
                <Accordion.Header>
                  <div className="w-full flex flex-col sm:flex-row justify-between items-start pr-4">
                    <h4 className="text-body-color font-bold sm:text-sm text-xs whitespace-nowrap">
                      Subscription - #{index + 1}
                      <span
                        className={`${renderStatusColorClass(
                          firstSubscription?.status === 'Active'
                            ? 'Active'
                            : firstSubscription?.status === 'Expired'
                            ? 'Expired'
                            : firstSubscription?.status === 'Cancelled'
                            ? 'Cancelled'
                            : 'OnHold'
                        )} ml-2 uppercase text-xs font-bold`}
                      >
                        {firstSubscription.status}
                      </span>
                    </h4>

                    <div className="flex flex-row justify-end items-end gap-4">
                      <div>
                        <p className="text-xs">Expiry Date</p>
                        <p className="font-medium sm:font-bold text-xs sm:text-sm text-amber-700 whitespace-nowrap">
                          {moment(firstSubscription?.expiry_date).format('YYYY-MM-DD')}
                        </p>
                      </div>

                      <div>
                        <p className="text-xs">Next Invoice</p>
                        <p className="font-medium sm:font-bold text-xs sm:text-sm text-teal-700 whitespace-nowrap">
                          {moment(firstSubscription?.next_invoice_date).format('YYYY-MM-DD')}
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body
                  key={firstSubscription?._id}
                  className="pb-2 p-0 bg-body-backgroudColor"
                >
                  <div className="w-full flex flex-wrap">
                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">Store</p>
                      <p className="font-bold text-sm">
                        {firstSubscription?.product_information.location_name}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">Chargeable</p>
                      <p className="font-bold text-sm">
                        {firstSubscription?.product_information.chargable}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">L Warranty</p>
                      <p className="font-bold text-sm">
                        {moment(firstSubscription?.l_warranty_expiry).format('YYYY-MM-DD')}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">Left Serial</p>
                      <p className="font-bold text-sm">
                        {firstSubscription?.product_information.left_serial || 'N/A'}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">Right Serial</p>
                      <p className="font-bold text-sm">
                        {firstSubscription?.product_information.right_serial || 'N/A'}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">R Warranty</p>
                      <p className="font-bold text-sm">
                        {moment(firstSubscription?.r_warranty_expiry).format('YYYY-MM-DD')}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">L Battery Size</p>
                      <p className="font-bold text-sm">
                        {firstSubscription?.product_information.l_battery_size || 'N/A'}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">R Battery Size</p>
                      <p className="font-bold text-sm">
                        {firstSubscription?.product_information.r_battery_size || 'N/A'}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">Last Invoice Date</p>
                      <p className="font-bold text-sm">
                        {moment(firstSubscription?.active_invoice_date).format('YYYY-MM-DD')}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">Do Not Mail</p>
                      <p className="font-bold text-sm">
                        {customer?.shipping_address?.do_not_mail ? 'Yes' : 'No'}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      <p className="text-xs">Newsletter</p>
                      <p className="font-bold text-sm">
                        {firstSubscription?.newsletter ? 'Yes' : 'No'}{' '}
                      </p>
                    </div>

                    <div className="w-[100px] sm:w-1/6 h-[60px] border p-2">
                      {firstSubscription?.plan_override_amount &&
                      moment(firstSubscription?.plan_override_validity) > moment() ? (
                        <>
                          <p className="text-xs">Amount after discount</p>
                          <p className="font-bold text-sm">
                            ${firstSubscription?.plan_override_amount}
                          </p>
                        </>
                      ) : null}
                    </div>
                  </div>

                  {subscription_group?.subscriptions.map((subscription, subIndex) => {
                    return (
                      <div className="p-3 w-full mt-3 flex gap-2">
                        <div className="w-[60%] flex flex-col gap-2">
                          <div className="flex gap-2 justify-start items-start">
                            <h3 className="text-xs font-bold">{subscription?.plan_name}</h3>
                            <span
                              className={`${renderStatusColorClass(
                                subscription?.status === 'Active'
                                  ? 'Active'
                                  : subscription?.status === 'Expired'
                                  ? 'Expired'
                                  : subscription?.status === 'Cancelled'
                                  ? 'Cancelled'
                                  : 'OnHold'
                              )} ml-2 uppercase text-xs font-bold`}
                            >
                              {subscription.status}
                            </span>
                          </div>
                          <p className="text-xl font-bold"> ${subscription?.price}</p>
                        </div>
                        <div className="w-[40%] flex justify-end items-end">
                          <div className="px-2 border-gray-400 border-r">
                            <h6
                              onClick={() => {
                                _this.setSubscriptionDetails(subscription);
                                _this.setSubscriptionDetailsModalOpen(true);
                              }}
                              className="text-gray-700 hover:text-gray-800 text-xs cursor-pointer"
                            >
                              View Details
                            </h6>
                          </div>
                          {/* <PermissionWrapper permission={'edit_subscription'}>
                            <button
                              onClick={() => {
                               
                              }}
                              className="bg-button-primary hover:bg-button-primaryHover px-2 py-1 rounded text-xs flex flex-row"
                            >
                              
                              <span className="font-medium text-xs sm:text-sm ml-0.5 text-white">
                                Edit
                              </span>
                            </button>
                          </PermissionWrapper> */}

                          <div className="px-2 border-gray-400 border-r">
                            <h6
                              onClick={() => {
                                _this.setEditSubscriptionModalOpen(true);
                                const tempData =
                                  _this.customerDetails.subscription_groups[index].subscriptions[0]
                                    .product_information;

                                const chargable =
                                  tempData.chargable == 'No'
                                    ? { label: 'No', value: 'No' }
                                    : { label: 'Yes', value: 'Yes' };
                                const location = {
                                  label: tempData.location_name,
                                  value: {
                                    locationId: tempData.location_id,
                                    franchiseName: tempData.franchise_name,
                                    franchiseId: tempData.franchise_id,
                                    locationGroup: tempData.location_group,
                                    l_battery_size: tempData.l_battery_size,
                                    r_battery_size: tempData.r_battery_size
                                  }
                                };
                                _this.setSelectedSubscriptionDataForEdit({
                                  chargable,
                                  location,
                                  l_battery_size: {
                                    label: tempData.l_battery_size,
                                    value: tempData.l_battery_size
                                  },
                                  r_battery_size: {
                                    label: tempData.r_battery_size,
                                    value: tempData.r_battery_size
                                  },
                                  right_serial: tempData.right_serial || '',
                                  left_serial: tempData.left_serial || '',
                                  id: _this.customerDetails.subscription_groups[
                                    index
                                  ].subscriptions.map((item) => item._id),
                                  customer: _this.customerDetails._id,
                                  newsletter:
                                    _this.customerDetails.subscription_groups[index]
                                      .subscriptions[0].newsletter
                                });

                                _this.setTemporaryEditSubscriptionData((prev) => ({
                                  ...prev,
                                  _id: subscription._id,
                                  l_warranty_expiry:
                                    subscription.l_warranty_expiry == 'NA' ||
                                    subscription.l_warranty_expiry == null
                                      ? ''
                                      : moment(subscription.l_warranty_expiry).toDate(),
                                  r_warranty_expiry:
                                    subscription.r_warranty_expiry == 'NA' ||
                                    subscription.r_warranty_expiry == null
                                      ? ''
                                      : moment(subscription.r_warranty_expiry).toDate(),
                                  next_invoice_date:
                                    subscription.next_invoice_date == null ||
                                    subscription.next_invoice_date == 'NA'
                                      ? ''
                                      : moment(subscription.next_invoice_date).toDate(),
                                  expiry_date:
                                    subscription.expiry_date == 'NA' ||
                                    subscription.expiry_date == null
                                      ? ''
                                      : moment(subscription.expiry_date).toDate(),
                                  status: {
                                    label: subscription.status,
                                    value: subscription.status
                                  }
                                }));
                              }}
                              className="text-gray-700 hover:text-gray-800 text-xs cursor-pointer"
                            >
                              Edit
                            </h6>
                          </div>

                          {subscription.is_migrated_from_fusebill == true && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-bottom ">
                                  <p>--Migrated--</p>
                                  <p>Fusebill ID: {subscription.fusebill_id}</p>
                                </Tooltip>
                              }
                            >
                              <div className="px-2 border-gray-400 border-r">
                                <LiaShippingFastSolid
                                  size={18}
                                  className="cursor-pointer text-green-800"
                                />
                              </div>
                            </OverlayTrigger>
                          )}

                          {subscription.status != 'Expired' &&
                            subscription.status != 'Cancelled' && (
                              <PermissionWrapper permission={'pay_extra'}>
                                <div className="px-2 border-gray-400 border-r">
                                  <LiaPaypal
                                    onClick={() => {
                                      _this.setPayModal(true);
                                      _this.setPayAdvanceSubscriptionData((prev) => ({
                                        ...prev,
                                        _id: subscription?._id,
                                        plan_name: subscription?.plan_name,
                                        expiry_date: subscription?.expiry_date,
                                        active_invoice_date: subscription?.active_invoice_date,
                                        next_invoice_date: subscription?.next_invoice_date,
                                        plan_interval: subscription?.plan_interval,
                                        amount: subscription?.amount
                                      }));
                                    }}
                                    size={18}
                                    className="cursor-pointer text-green-800"
                                  />
                                </div>
                              </PermissionWrapper>
                            )}

                          <PermissionWrapper permission={'delete_subscription'}>
                            <div className="pl-2">
                              <RiDeleteBin6Line
                                onClick={() => {
                                  _this.setDeleteSubscriptionModalOpen(true);

                                  _this.setDeleteSubscriptionData((prev) => ({
                                    ...prev,
                                    _id: subscription._id,
                                    plan_name: subscription.plan_name
                                  }));
                                }}
                                fill="red"
                                size={18}
                                className="cursor-pointer"
                              />
                            </div>
                          </PermissionWrapper>
                        </div>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
      </div>
    </Card>
  );
};

export default Subscription;
