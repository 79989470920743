import React from 'react';
import { Card, Button, Input, NewButton, Checkbox } from 'components';
import { Tooltip, OverlayTrigger, ProgressBar } from 'react-bootstrap';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import { PermissionWrapper, HasPermission } from 'components';
import { GrNotes } from 'react-icons/gr';
import { FaRecycle, FaMapMarkerAlt } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';
const UserDetails = ({ _this }) => {
  const customer = _this.customerDetails;

  const getShortName = (name) => {
    const parts = name.split(' ');
    const firstLetter = parts[0].charAt(0); // First letter of the first part
    const secondLetter = parts[1].charAt(0); // First letter of the second part
    return firstLetter + secondLetter; // Concatenate letters
  };

  const excludeZeros = (customer_id) => {
    // Convert the number to a string and remove leading zeros
    const result = customer_id.toString().replace(/^0+/, '');
    return result === '' ? '0' : result;
  };
  return (
    <div className="flex flex-col gap-3 w-full md:w-[33%]">
      <Card
        className={`rounded-xl border-2 shadow-md`}
        paddingClass="p-2"
        borderColor="border-green-500"
      >
        <div className="flex flex-row justify-between items-center">
          <div className="flex w-full">
            <div className="flex justify-start items-center p-2 py-8 px-2 gap-2">
              {/* <div className="w-14 h-14 rounded-full bg-theme-dull-white flex justify-center items-center">
                <p className=" text-2xl text-body-color">
                  {getShortName(`${customer?.first_name} ${customer?.last_name}`)}
                </p>
              </div> */}

              <div className="flex flex-col pr-3">
                <p className="text-base sm:text-lg font-bold whitespace-nowrap">{`${customer?.first_name} ${customer?.last_name}`}</p>
                {/* <p className="text-sm sm:text-base">Patient</p> */}
              </div>
            </div>
            {_this.isUploading ? (
              <div className="w-1/4 ml-2 text-center">
                <h2>Uploading Documents</h2>
                <ProgressBar now={100} animated />
              </div>
            ) : null}
          </div>
          {customer?.status && customer?.status != 'Active' ? (
            <div className="flex flex-row items-center justify-between">
              <span
                className={`${renderStatusColorClass(
                  customer?.status === 'Active' ? 'Active' : 'Cancelled'
                )} font-semibold px-3 py-1 rounded text-xs`}
              >
                <span className="font-bold text-sm uppercase">Inactive</span>
              </span>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <span
                className={`${renderStatusColorClass(
                  customer?.status === 'Active' ? 'Active' : 'Cancelled'
                )} font-semibold px-3 py-1 rounded text-xs`}
              >
                <span className="font-bold text-sm uppercase">Active</span>
              </span>
            </div>
          )}
        </div>
      </Card>

      <Card className="rounded-xl" paddingClass="p-2">
        <div className="flex gap-4 justify-between p-2">
          <p className="text-base sm:text-lg font-extrabold">User Details</p>
          {customer?.email?.length > 0 && (
            <PermissionWrapper permission="send_customer_password">
              <NewButton
                onClick={() => {
                  _this.sendCustomerPassword(customer?.user);
                }}
              >
                Send Password
              </NewButton>
            </PermissionWrapper>
          )}
        </div>

        <Checkbox
          className="text-sm m-2"
          label="Opt out from monthly newsletter emails."
          type="checkbox"
          id={'newsletter'}
          checked={customer.opt_out_from_newsletter_emails}
          onChange={(e) => {
            _this.optOutFromMonthlyNewsletterEmail(customer._id, e.target.checked);
          }}
        />

        <div className="flex flex-row justify-between p-2 gap-2">
          <div className="flex flex-col gap-3">
            <div>
              <p className="text-sm">HSPP ID</p>
              <p className="font-bold text-sm">{customer.hspp_id}</p>
            </div>

            <div>
              <p className="text-sm">Name</p>
              <p className="font-bold text-sm">{`${customer.first_name} ${customer.last_name}`}</p>
            </div>

            <div>
              <p className="text-sm">Email</p>
              <p className="font-bold text-sm">{customer.email}</p>
            </div>

            <div>
              <p className="text-sm">Enabled Email Updates</p>
              <p className="font-bold text-sm">{customer?.enableEmails ? 'Yes' : 'No'}</p>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div>
              <p className="text-sm">Patient ID</p>
              <div className="flex gap-2 justify-center items-center">
                <a
                  href={`https://www.mymiracle-ear.com/freecvs/patient_summary.php?simple_close=1&patient_id=${excludeZeros(
                    customer?.customer_id
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaRecycle />
                </a>
                <p className="font-bold text-sm">{customer.customer_id}</p>
              </div>
            </div>

            <div>
              <p className="text-sm">Phone</p>
              <p className="font-bold text-sm">{customer.phone}</p>
            </div>

            <PermissionWrapper permission={'manage_customer_document'}>
              <div>
                <p className="text-sm">Documents</p>
                <button
                  className="border-[1px] border-red-700 text-red-700 text-xs px-2 py-1 rounded-md flex gap-2 justify-center items-center"
                  onClick={() => {
                    _this.setChatModalOpen(true);
                  }}
                  disabled={!_this.documents}
                >
                  {!_this.documents && <Spinner animation="border" size="sm" />}
                  <GrNotes />
                  Manage
                </button>
              </div>
            </PermissionWrapper>

            <div>
              <p className="text-sm">Signature</p>
              <p className="font-extralight text-sm signature">{customer?.signature?.signature}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-4 justify-between p-2 mt-4">
          <p className="text-base sm:text-lg font-bold">Shipping & Billing</p>
            <PermissionWrapper permission="edit_customer_details">
              <NewButton
                onClick={() => {
                  _this.setEditCustomerModalOpen(true);
                }}
              >
                Edit
              </NewButton>
            </PermissionWrapper>
        </div>
        <div className="p-2 flex flex-col gap-2 mb-2">
          <p className="text-sm">Shipping Address</p>
          <div className="flex gap-2">
            <div className="text-slate-500 p-1">
              <FaMapMarkerAlt size={25} />
            </div>
            <div className="text-sm text-slate-500 w-[250px]">
              {customer?.shipping_address.contact}
              <br />
              {customer?.shipping_address.address1 +
                ', ' +
                (customer?.shipping_address.address2
                  ? customer.shipping_address.address2 + ', '
                  : '') +
                customer?.shipping_address.city +
                ', ' +
                customer?.shipping_address.state +
                ' ' +
                customer?.shipping_address.zipcode +
                ', ' +
                customer?.shipping_address.country}
            </div>
          </div>

          <div className="mt-4 w-full flex justify-between items-center">
            <p className="text-sm">Billing Address</p>
            <PermissionWrapper permission={'edit_billing_address'}>
              <p
                className="text-xs text-body-color hover:text-black hover:underline cursor-pointer"
                onClick={(e) => {
                  _this.setEditPaymentModalOpen(true);
                }}
              >
                Edit
              </p>
            </PermissionWrapper>
          </div>
          <div className="flex gap-2">
            <div className="text-slate-500 p-1">
              <FaMapMarkerAlt size={25} />
            </div>
            <div className="text-sm text-slate-500 w-[250px]">
              {customer?.billing_address.address1 +
                ', ' +
                (customer?.billing_address.address2
                  ? customer.billing_address.address2 + ', '
                  : '') +
                customer?.billing_address.city +
                ', ' +
                customer?.billing_address.state +
                ' ' +
                customer?.billing_address.zipcode +
                ', ' +
                customer?.billing_address.country}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UserDetails;
