import React from 'react';
import { Card, Button } from 'components';
import Form from 'react-bootstrap/Form';
import ButtonBTN from 'react-bootstrap/Button';
import { BsSearch } from 'react-icons/bs';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import EditEmployeeModal from './EditEmployeeModal';
import DeleteEmployeeModal from './DeleteEmployeeModal';
const Section = ({ _this }) => {
  const columns = [
    // {
    //   accessor: '_id',
    //   Header: 'ID',
    //   fixed: 'left',
    //   headerStyle: { backgroundColor: '#474747' },
    //   style: { backgroundColor: '#C6C6C6' },
    //   Cell: (row) => row.original._id
    //   // <Link
    //   //   to={'/customer-info'}
    //   //   state={{ hspp_id: row.original._id }}
    //   //   className="text-blue-500 font-medium"
    //   // >
    //   // #{row.original.hspp_id}
    //   // </Link>
    // },
    {
      accessor: 'action',
      Header: 'Action',
      Cell: (row) => (
        <div>
          <span
            onClick={() => {
              _this.setEditEmployeeModalVisibility(true);
              _this.setEditEmployeeData((prev) => ({
                ...prev,
                user_id: row.original?.user_id,
                name: row.original?.name,
                email: row.original?.email,
                location: _this.editLocationField(row.original.location?.split(','))
              }));
            }}
            className="text-button-primary hover:text-button-primaryHover hover:underline tracking-wider cursor-pointer"
          >
            Edit
          </span>{' '}
          /{' '}
          <span
            onClick={() => {
              _this.setDeleteEmployeeModalVisibility(true);
              _this.setDeleteEmployeeData((prev) => ({
                ...prev,
                user_id: row.original?.user_id,
                name: row.original?.name
              }));
            }}
            className="text-button-danger hover:text-button-dangerHover hover:underline tracking-wider cursor-pointer"
          >
            Delete
          </span>
        </div>
      )
    },
    {
      accessor: 'name',
      Header: 'Name'
    },
    {
      accessor: 'email',
      Header: 'Email'
    },
    {
      accessor: 'role',
      Header: 'Role',
      maxWidth: 120
    },
    {
      accessor: 'location',
      Header: 'location',
      maxWidth: 120
    },
    {
      Cell: (row) => (
        <BootstrapSwitchButton
          size="xs"
          width={75}
          onstyle="success"
          offstyle="secondary"
          checked={row.original.status == 'Enabled' ? true : false}
          onlabel="Enabled"
          offlabel="Disabled"
          onChange={(e) => {
            _this.changeEmployeeStatus({ status: e, user_id: row.original.user_id });
          }}
        />
      ),
      Header: 'Status',
      maxWidth: 120
    },
    {
      Cell: (row) => <div>{moment(row.original.createdAt).format('YYYY-MM-DD')}</div>,
      Header: 'Created On',
      maxWidth: 120
    },
    {
      Cell: (row) => (
        <div className="w-full flex justify-center items-center">
          <button
            className="bg-button-primary hover:bg-button-primaryHover text-white font-medium px-2 py-1.5 rounded text-xs flex flex-row self-center w-24 justify-center items-center"
            onClick={() => {
              _this.handelEmailSend(row);
            }}
          >
            Send Password
          </button>
        </div>
      ),
      Header: 'Send Login Credentials',
      maxWidth: 220
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Employee List</h3>
          <div className="">
            <Link to="/create-employee" className="text-body-color">
              <Button>Create Employee</Button>
            </Link>
          </div>
        </div>
        <form onSubmit={_this.handelSearch}>
          <InputGroup>
            <div className="flex justify-center items-center w-full gap-1 mt-2">
              <Form.Control
                placeholder="...Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                type="search"
                className="rounded-md w-96"
                value={_this.searchInput}
                onChange={_this.handelSearchInput}
              />
              {/* <ButtonBTN
                // onClick={() => _this.handelSearch()}
                className={`bg-button-primary`}
                variant="primary"
                type="submit"
                id="button-addon3"
              >
                <BsSearch size={24} />
              </ButtonBTN> */}
            </div>
          </InputGroup>
        </form>
      </Card>
      <Card className="flex mt-2" paddingClass="p-2">
        <div className="rounded">
          <Table keyField="id" columns={columns} data={_this.tableData} headerComponent={null} />
        </div>
      </Card>
      <EditEmployeeModal _this={_this} />
      <DeleteEmployeeModal _this={_this} />
    </>
  );
};

export default Section;
