import React from 'react';
import { Card, Button } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import { DatePicker } from 'components';
import { parseHsppId } from 'services/helpers';
import { BsSearch } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonBTN from 'react-bootstrap/Button';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Alert from 'react-bootstrap/Alert';
import { toast } from 'react-toastify';
import CreateRoleModal from './Roles/CreateRoleModal';
import CreatePermissionModal from './Permissions/CreatePermissionModal';
import EditPermissionModal from './Permissions/EditPermissionModal';
import DeletePermissionModal from './Permissions/DeletePermissionModal';
import EditRoleModal from './Roles/EditRoleModal';
import DeleteRoleModal from './Roles/DeleteRoleModal';
import { PermissionWrapper, HasPermission } from 'components';

const Section = ({ _this }) => {
  const permissionColumns = [
    {
      accessor: 'permission_group',
      Header: 'Permission Group'
    },
    {
      accessor: 'permission_name',
      Header: 'Permission Name'
    }
  ];

  if (HasPermission({ permission: ['edit_permission', 'delete_permission'], require_all: false }))
    permissionColumns.unshift({
      accessor: 'action',
      Header: 'Action',
      Cell: (row) => (
        <div>
          <PermissionWrapper permission="edit_permission">
            <span
              onClick={() => {
                _this.setEditPermissionModalVisibility(true);
                _this.setEditPermissionData((prev) => ({
                  ...prev,
                  permission_id: row.original._id,
                  permission_name: row.original?.permission_name,
                  permission_group: _this.filterPermissionGroup(row.original.permission_group)
                }));
              }}
              className="text-button-primary hover:text-button-primaryHover hover:underline tracking-wider cursor-pointer"
            >
              Edit
            </span>
          </PermissionWrapper>
          <PermissionWrapper permission={['edit_permission', 'delete_permission']}>
            <span> / </span>
          </PermissionWrapper>
          <PermissionWrapper permission="delete_permission">
            <span
              onClick={() => {
                _this.setDeletePermissionModalVisibility(true);
                _this.setDeletePermissionData((prev) => ({
                  ...prev,
                  permission_id: row.original?._id,
                  permission_name: row.original?.permission_name
                }));
              }}
              className="text-button-danger hover:text-button-dangerHover hover:underline tracking-wider cursor-pointer"
            >
              Delete
            </span>
          </PermissionWrapper>
        </div>
      )
    });

  const roleColumns = [
    {
      accessor: 'role_name',
      Header: 'Role Name'
    },
    {
      accessor: 'permissions',
      Header: 'Permissions',
      Cell: (row) => <div>{_this.fixPermissionsWithCommas(row.original.permissions)}</div>
    }
  ];

  if (HasPermission({ permission: ['edit_role', 'delete_role'], require_all: false }))
    roleColumns.unshift({
      accessor: 'action',
      Header: 'Action',
      Cell: (row) => (
        <div>
          <PermissionWrapper permission="edit_role">
            <span
              onClick={() => {
                _this.setEditRoleModalVisibility(true);
                _this.setEditRoleData((prev) => ({
                  ...prev,
                  role_id: row.original._id,
                  role_name: row.original?.role_name,
                  permissions: _this.filterRolePermissions(row.original.permissions)
                }));
              }}
              className="text-button-primary hover:text-button-primaryHover hover:underline tracking-wider cursor-pointer"
            >
              Edit
            </span>
          </PermissionWrapper>
          <PermissionWrapper permission={['delete_role', 'edit_role']}>
            <span> / </span>
          </PermissionWrapper>
          <PermissionWrapper permission="delete_role">
            <span
              onClick={() => {
                _this.setDeleteRoleModalVisibility(true);
                _this.setDeleteRoleData((prev) => ({
                  ...prev,
                  role_id: row.original?._id,
                  role_name: row.original?.role_name
                }));
              }}
              className="text-button-danger hover:text-button-dangerHover hover:underline tracking-wider cursor-pointer"
            >
              Delete
            </span>
          </PermissionWrapper>
        </div>
      )
    });

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-4">
        <PermissionWrapper permission="view_manage_roles">
          <div className="w-full">
            <Card background={'bg-cardbg1'}>
              <div className="flex flex-col lg:flex-row justify-between gap-10 lg:gap-0">
                <h3 className="text-body-color text-2xl">Manage Roles</h3>
                <div className="">
                  <PermissionWrapper permission="create_role">
                    <Button
                      onClick={() => {
                        _this.setCreateRoleModalVisibility(true);
                      }}
                    >
                      Create Role
                    </Button>
                  </PermissionWrapper>
                </div>
              </div>
            </Card>
            <Card className="flex mt-2" paddingClass="px-0 py-0">
              <div className="">
                <Table
                  keyField="id"
                  columns={roleColumns}
                  data={_this.roleTableData}
                  headerComponent={null}
                />
              </div>
            </Card>
          </div>
        </PermissionWrapper>
        <PermissionWrapper permission="view_permission">
          <div className="w-full">
            <Card background={'bg-cardbg1'}>
              <div className="flex lg:flex-row flex-col justify-between gap-10 lg:gap-0">
                <h3 className="text-body-color text-2xl">Manage Permissions</h3>
                <div className="">
                  <PermissionWrapper permission="create_permission">
                    <Button
                      onClick={() => {
                        _this.setCreatePermissionModalVisibility(true);
                      }}
                    >
                      Create Permission
                    </Button>
                  </PermissionWrapper>
                </div>
              </div>
            </Card>
            <Card className="flex mt-2" paddingClass="px-0 py-0">
              <div className="">
                <Table
                  keyField="id"
                  columns={permissionColumns}
                  data={_this.permissionTableData}
                  headerComponent={null}
                />
              </div>
            </Card>
          </div>
        </PermissionWrapper>
      </div>
      <CreateRoleModal _this={_this} />
      <EditRoleModal _this={_this} />
      <DeleteRoleModal _this={_this} />
      <CreatePermissionModal _this={_this} />
      <EditPermissionModal _this={_this} />
      <DeletePermissionModal _this={_this} />
    </>
  );
};

export default Section;
