import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { Header, Footer } from 'components';
import { loadingStart, loadingStop, login } from 'redux/action';
import { Pdf } from 'components';
import ReactPDF, { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import API from 'services/api';
import { BsDownload } from 'react-icons/bs';
import { AiOutlineLoading } from 'react-icons/ai';
const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    getInvoiceData();
  }, [id]);

  const getInvoiceData = async () => {
    dispatch(loadingStart());
    await API.getInvoiceData(id)
      .then((response) => {
        if (response) {
          setInvoiceData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <div className="relative flex justify-center items-center h-screen md:h-auto">
      <PDFViewer className="hidden md:block" style={{ width: '100vw', height: '100vh' }}>
        <Pdf data={invoiceData} />
      </PDFViewer>
      <div className="relative md:absolute  md:bottom-1 left-1 max-w-sm p-2 bg-primary text-white rounded-md">
        {invoiceData && (
          <PDFDownloadLink document={<Pdf data={invoiceData} />} fileName="invoice.pdf">
            {({ blob, url, loading, error }) =>
              loading ? (
                <div className="flex justify-center items-center flex-col hover:text-white gap-2">
                  <AiOutlineLoading className="animate-spin" />
                  <div>Loading document...</div>
                </div>
              ) : (
                <div className="flex justify-center items-center flex-col hover:text-white gap-2">
                  <BsDownload />
                  <p>Download Invoice</p>
                </div>
              )
            }
          </PDFDownloadLink>
        )}
      </div>
    </div>
  );
};

export default Index;

{
  /*
<PDFViewer style={{ width: '100vw', height: '100vh' }}>
      <Pdf data={invoiceData} />
    </PDFViewer>
*/
}
