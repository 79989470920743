import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [inSync, setInSync] = useState(false);
  const [editExpiryDateModal, setEditExpiryDateModal] = useState(false);
  const [editExpiryDateData, setEditExpiryDateData] = useState(null);
  const [planCode, setPlanCode] = useState({ label: 'Loss & Damage', value: 'loss&damage' });

  useEffect(() => {
    init();
  }, [planCode]);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(customerData);
    } else {
      const filteredResults = customerData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const reloadData = async () => {
    try {
      const response = await API.getLossAndDamageReport(planCode.value);
      if (response) {
        setCustomerData(response);
        setTableData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = () => {
    toast.promise(reloadData, {
      pending: 'Generating patients report...',
      success: 'Report is generated',
      error: 'Failed to generate report'
    });
  };

  const handleExpiryDateEdit = (
    subscription_id,
    l_warranty_expiry,
    r_warranty_expiry,
    expiry_date
  ) => {
    setEditExpiryDateModal(true);

    setEditExpiryDateData((prev) => ({
      ...prev,
      subscription_id: subscription_id,
      l_warranty_expiry_date: l_warranty_expiry && moment(l_warranty_expiry).toDate(),
      r_warranty_expiry_date: r_warranty_expiry && moment(r_warranty_expiry).toDate(),
      expiry_date: expiry_date && moment(expiry_date).toDate()
    }));
  };

  const handleEditExpiryDateSubmit = () => {
    dispatch(loadingStart());
    API.EditExpiryDate(editExpiryDateData)
      .then(async (response) => {
        if (response) {
          setEditExpiryDateModal(false);
          toast.success('Updated successfully.');
          const res = await API.getLossAndDamageReport(planCode.value);
          if (res) {
            setCustomerData(res);
            if (searchInput == '') {
              setTableData(res);
            } else {
              const filteredResults = res.filter((data) =>
                Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
              );
              setTableData(filteredResults);
            }
          }
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData,
          searchInput,
          setSearchInput,
          handleExpiryDateEdit,
          editExpiryDateModal,
          setEditExpiryDateModal,
          setEditExpiryDateData,
          editExpiryDateData,
          handleEditExpiryDateSubmit,
          planCode,
          setPlanCode
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
