import React from 'react';
import { Card, Button, Stepper } from 'components';
import Step1 from './Step1';
import Step2 from './Step2';
import CheckCustomerExistModal from './CheckCustomerExistModal';

const Section = ({ _this }) => {
  const steps = ['Customer Details', 'Payment Details'];

  return (
    <>
      <Card className="mb-2" background={'bg-cardbg1'} paddingClass="px-4 py-2">
        <div className="flex md:flex-row justify-between flex-col gap-y-4">
          <div className="flex flex-col gap-2">
            <h3 className="text-body-color text-xl md:text-2xl">New Customer</h3>
            {_this.activeStep === 1 ? (
              <Button
                onClick={() => {
                  _this.setActiveStep(0);
                }}
              >
                Back
              </Button>
            ) : (
              <></>
            )}
          </div>
          <Stepper steps={steps} activeStep={_this.activeStep} />
          <div>
            <div className="text-base text-slate-600 font-bold tracking-wide">
              Total Amount: ${_this.calculateTotalAmount().toFixed(2)}
            </div>
            <div className="mt-1">
              <Button
                className=""
                onClick={() => _this.submitNext()}
                disabled={_this.activeStep == 1 && !_this.isTnCChecked ? true : false}
              >
                {_this.activeStep == 1 ? 'Submit' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </Card>
      {_this.activeStep == 0 && <Step1 _this={_this} />}
      {_this.activeStep == 1 && <Step2 _this={_this} />}
      <Card className="mb-2" background={'bg-cardbg1'} paddingClass="px-4 py-2">
        <div className="flex flex-col-reverse md:flex-row justify-between items-center gap-3">
          <div className="text-base text-slate-600 font-bold tracking-wide">
            Total Amount: ${_this.calculateTotalAmount().toFixed(2)}
          </div>
          <div>
            <div className="mt-1 flex justify-between gap-3">
              {_this.activeStep === 1 ? (
                <Button
                  onClick={() => {
                    _this.setActiveStep(0);
                  }}
                >
                  Back
                </Button>
              ) : (
                <></>
              )}

              <Button
                className=""
                onClick={() => _this.submitNext()}
                disabled={_this.activeStep == 1 && !_this.isTnCChecked ? true : false}
              >
                {_this.activeStep == 1 ? 'Submit' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <div className="mt-60"></div>
      <CheckCustomerExistModal _this={_this} />
    </>
  );
};

export default Section;
