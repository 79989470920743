import React from 'react';
import { Card, Table } from 'components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Section = ({ _this }) => {
  const exportReport = () => {
    const tableArray = [];

    for (const data of _this.tableData) {
      const dataObject = {
        'Patient Id': data?.customer_id,
        'Patient Name': data?.customer_name,
        'Location name': data?.location_name,
        'Phone No': data?.customer_phone,
        'Location Id': data?.location_id,
        'Current Plan Name': data?.plan_name,
        'Current Amount': '$' + data?.amount,
        'Upgrade Date': moment(data?.expiry_date).format('YYYY-MM-DD'),
        'Next Plan Name': data?.next_plan_name,
        'Next Plan Monthly Amount': '$' + data?.next_plan_monthly,
        'Next Plan Annually Amount': '$' + data?.next_plan_annually
      };

      tableArray.push(dataObject);
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(tableArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'FuturePlansReport' + fileExtension);
  };

  const columns = [
    {
      accessor: 'customer_id',
      Header: 'Patient Id',
      Cell: (row) => (
        <Link
          to={`/customer-info/${row.original?.customer_mongo_id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 font-medium"
        >
          {row.original?.customer_id}
        </Link>
      )
    },
    {
      accessor: 'customer_name',
      Header: 'Patient Name',
      width: 200,
      Cell: (row) => <div>{row.original?.customer_name}</div>
    },
    {
      accessor: 'location_name',
      Header: 'Location Name',
      minWidth: 200
    },
    {
      accessor: 'customer_phone',
      Header: 'Phone No',
      minWidth: 150
    },
    {
      accessor: 'location_id',
      Header: 'Location Id'
    },
    {
      accessor: 'plan_name',
      Header: 'Current Plan Name',
      minWidth: 300
    },
    {
      accessor: 'amount',
      Header: 'Current Amount'
    },
    {
      accessor: 'expiry_date',
      Header: 'Upgrade Date',
      minWidth: 150,
      Cell: (row) => <div>{moment(row.original?.expiry_date)?.format('YYYY-MM-DD')}</div>
    },
    {
      accessor: 'next_plan_name',
      Header: 'Next Plan Name',
      minWidth: 300
    },
    {
      accessor: 'next_plan_annually',
      Header: 'Next Plan Amount',
      Cell: (row) => (
        <div>
          {row?.original?.plan_interval == 'Monthly'
            ? row.original?.next_plan_monthly
            : row.original?.next_plan_annually}
        </div>
      )
    }
  ];

  return (
    <>
      <Card className="flex mr-2 mt-2">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between mb-4">
          <h3 className="text-body-color text-xl font-semibold pb-2">
            Future Plan Reports | {_this?.tableData?.length}
          </h3>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default Section;
