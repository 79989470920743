import React, { useEffect, useState } from 'react';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';
import { useSelector } from 'react-redux';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import ButtonBTN from 'react-bootstrap/Button';

const Body = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const patient_id = dataObject.Patient;
      const patient_name = dataObject.PatientName;
      const patient_email = dataObject.Email;
      const patient_mobile = dataObject.Cell_Phone;
      const location_name = dataObject.location_name;
      const franchise_name = dataObject.franchise_name;
      const created_date = dataObject.InvoiceCreationDate;

      const extractedData = {
        'Patient Id': patient_id,
        'Patient Name': patient_name,
        'Patient Email': patient_email,
        'Patient Mobile': patient_mobile,
        Location: location_name,
        Franchise: franchise_name,
        'Created Date': created_date
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'NonHsppPatientReport' + fileExtension);
  };

  const columns = [
    {
      accessor: '',
      Header: 'Action',
      fixed: 'left',
      sortable: false,
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <button
          className="bg-button-primary hover:bg-button-primaryHover text-white rounded px-3 py-1"
          onClick={() => _this.navigateToCreateCustomer(row?.original?.Patient)}
        >
          Activate
        </button>
      )
    },
    {
      accessor: 'Patient',
      Header: 'Patient Id'
    },
    {
      accessor: 'PatientName',
      Header: 'Patient Name'
    },
    {
      accessor: 'location_name',
      Header: 'Location Name'
    },
    {
      accessor: 'InvoiceCreationDate',
      Header: 'Last Invoice Date'
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="relative flex flex-col lg:flex-row gap-2 justify-between items-center mb-2">
          <h3 className="text-body-color text-xl font-semibold pb-2">
            Non HSPP Patients {`(${_this.tableData?.length > 0 ? _this.tableData?.length : 0})`}
          </h3>
          <DatePicker
            label="Start Date"
            required
            value={_this.nonHsppPatientsDetails?.start_date || ''}
            onChange={(e) => {
              _this.setNonHsppPatientsDetails((prev) => ({
                ...prev,
                start_date: e
              }));
            }}
          />

          <DatePicker
            label="End Date"
            required
            value={_this.nonHsppPatientsDetails?.end_date || ''}
            onChange={(e) => {
              _this.setNonHsppPatientsDetails((prev) => ({
                ...prev,
                end_date: e
              }));
            }}
          />
          <Dropdown
            className="w-48"
            label="Location"
            placeholder="@location"
            required
            type="text"
            options={_this.locationList?.slice().sort((a, b) => a.label.localeCompare(b.label))}
            aria-label="Default select example"
            value={_this?.nonHsppPatientsDetails?.location}
            onChange={(e) => {
              _this.setNonHsppPatientsDetails((prev) => ({
                ...prev,
                location: e
              }));
            }}
            isMulti
          />

          <button
            className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
            onClick={_this.init}
          >
            Get Patients
          </button>
        </div>
        <div className="flex justify-between md:w-1/2 mx-auto">
          {/* <button
            className="bg-button-primary mt-1 hover:bg-button-primaryHover text-white px-1 py-1 rounded text-base flex flex-row self-center w-24 justify-center items-center"
            onClick={_this.sortData}
          >
            Sort
          </button> */}
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
          <ButtonBTN
            onClick={() => exportReport()}
            className={`bg-button-primary`}
            variant="primary"
            id="export"
          >
            Export
          </ButtonBTN>
        </div>
      </Card>

      <div className="flex justify-around sm:justify-between gap-1.5 flex-wrap">
        {/* Changed to the table now 
         {_this?.tableData?.length > 0 ? (
          _this.tableData.map((item) => {
            return (
              <div
                className="flex flex-col justify-center items-center bg-white cardShadow rounded-md w-48 sm:w-44 mt-2"
                key={item.patient}
              >
                <div className="flex flex-row justify-center gap-2 bg-slate-400 w-full rounded-t-md items-center border-b-[1px] pb-1 tracking-wider">
                  <h5 className="text-white text-base">{item.PatientName}</h5>
                </div>
                <div className="mt-1 flex flex-row justify-between gap-2 items-center border-b-[1px] pb-1">
                  <h5 className="text-slate-500 text-sm">Patient ID:</h5>
                  <div className="flex gap-2">
                    <h5 className="text-slate-500 text-sm">{item.Patient}</h5>
                  </div>
                </div>
                <div className="mt-1 flex flex-row justify-between gap-2 items-center border-b-[1px] pb-1">
                  <h5 className="text-slate-500 text-sm">location:</h5>
                  <div className="flex gap-2">
                    <h5 className="text-slate-500 text-sm">{item.location_name}</h5>
                  </div>
                </div>
                <div className="mt-1 flex flex-row justify-between gap-2 items-center border-b-[1px] pb-1">
                  <h5 className="text-slate-500 text-sm">Invoice Date:</h5>
                  <div className="flex gap-2">
                    <h5 className="text-slate-500 text-sm">
                      {moment(item.InvoiceCreationDate).format('YYYY-MM-DD')}
                    </h5>
                  </div>
                </div>
                <button
                  className="bg-button-primary hover:bg-button-primaryHover text-white w-full px-2 py-1 rounded-b-md text-sm flex flex-row self-center justify-center items-center"
                  onClick={() => _this.navigateToCreateCustomer(item.Patient)}
                >
                  Activate
                </button>
              </div>
            );
          })
        ) : (
          <Card className="mt-1"> No datas Found</Card>
        )} */}
        <Table columns={columns} data={_this?.tableData} />
      </div>
    </>
  );
};

export default Body;
