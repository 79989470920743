import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import { FaSyncAlt } from 'react-icons/fa';

const LocationName = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const locationName = dataObject.location_name;
      const locationGroup = dataObject.location_group;
      const startSyclePatients = dataObject.startSyclePatients;
      const currentSyclePatients = dataObject.currentSyclePatients;
      const startActivePatients = dataObject.startActivePatients;
      const currentActivePatients = dataObject.currentActivePatients;
      const startActiveSubscriptions = dataObject.startActiveSubscriptions;
      const currentActiveSubscriptions = dataObject.currentActiveSubscriptions;
      const percHspp = dataObject.percHspp;
      const patientAdded = dataObject.patientAdded;
      const subscriptionAdded = dataObject.subscriptionAdded;

      const extractedData = {
        'Location Name': locationName,
        'Location Group': locationGroup,
        'Start Sycle Patients': startSyclePatients,
        'Current Sycle Patients': currentSyclePatients,
        'Start Active Patients': startActivePatients,
        'Current Active Patients': currentActivePatients,
        '% Hspp': percHspp,
        'Start Active Subscriptions': startActiveSubscriptions,
        'Current Active Subscriptions': currentActiveSubscriptions,
        'Patient Added': patientAdded,
        'Subscription Added': subscriptionAdded
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'ActiveHsppPatientReport' + fileExtension);
  };

  const columns = [
    {
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747', fontSize: '90%' },
      accessor: 'location_name',
      style: { backgroundColor: '#C6C6C6' },
      Header: 'Location Name',
      Cell: (row) => <div>{row.original.location_name}</div>
    },
    // {
    //   accessor: 'location_group',
    //   headerStyle: { fontSize: '90%' },
    //   Header: 'Location Group',
    //   Cell: (row) => <div>{row.original.location_group}</div>
    // },
    {
      accessor: 'startSyclePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Sycle Patients',
      Cell: (row) => <div>{row.original.startSyclePatients}</div>
    },
    {
      accessor: 'currentSyclePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Sycle Patinets',
      Cell: (row) => <div>{row.original.currentSyclePatients}</div>
    },
    {
      accessor: 'startActivePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Active Patients',
      Cell: (row) => <div>{row.original.startActivePatients}</div>
    },
    {
      accessor: 'currentActivePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Active Patients',
      Cell: (row) => <div>{row.original.currentActivePatients}</div>
    },
    {
      accessor: 'percHspp',
      headerStyle: { fontSize: '90%' },
      Header: '% HSPP',
      Cell: (row) => <div>{row.original.percHspp}</div>
    },
    {
      accessor: 'startActiveSubscriptions',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Active Subscriptions',
      Cell: (row) => <div>{row.original.startActiveSubscriptions}</div>
    },
    {
      accessor: 'currentActiveSubscriptions',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Active Subscriptions',
      Cell: (row) => <div>{row.original.currentActiveSubscriptions}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Patient Added',
      accessor: 'patientAdded',
      Cell: (row) => <div>{row.original.patientAdded}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Subscription Added',
      accessor: 'subscriptionAdded',
      Cell: (row) => <div>{row.original.subscriptionAdded}</div>
    }
  ];

  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card className="flex mr-2 mt-2" paddingClass="px-1 py-2">
        <div className="flex justify-between items-center p-2">
          <div className="flex gap-2">
            <ButtonBTN
              onClick={() => exportReport()}
              className={`bg-button-primary  mb-2`}
              variant="primary"
              id="export"
            >
              Export
            </ButtonBTN>
            <ButtonBTN
              onClick={() => _this.syncReport()}
              className={`bg-button-primary mb-2 ml-2 flex justify-between items-center`}
              variant="primary"
              id="export"
              disabled={_this.inSync}
            >
              Sync <FaSyncAlt className={`ml-1 ${_this.inSync ? 'animate-spin' : ''}`} />
            </ButtonBTN>
          </div>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
        </div>

        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
    </>
  );
};

export default LocationName;
