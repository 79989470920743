// DoughnutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { currencyFormatter } from 'services/helpers';

const DoughnutChart = ({ data, onClick }) => {
  const options = {
    responsive: true,
    onClick: onClick,
    maintainAspectRatio: false,
    cutout: '80%',
    plugins: {
      legend: {
        display: false // Hide default legend
      }
    }
  };

  const calculateTotal = () => {
    let sum = 0;
    const value = data.datasets.map((item, index) => (sum += item.data[index]));

    return value;
  };

  return (
    <div className="h-56">
      <div className="w-40 h-40 sm:w-[11rem] sm:h-52 relative flex justify-center">
        {calculateTotal() > 0 ? (
          <Doughnut data={data} options={options} />
        ) : (
          <p>No Payments till now</p>
        )}

        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <p className="text-center text-body-color text-sm">Total</p>
          <p className="font-bold text-lg"> {currencyFormatter.format(calculateTotal())}</p>
        </div>
      </div>
      <div className="w-full h-6 flex justify-between mt-2">
        {data &&
          data?.labels?.map((label, index) => (
            <div key={index} className="w-full text-center mr-3">
              <div className="text-body-color text-sm whitespace-nowrap">{label}</div>

              <div className="flex gap-1 justify-center items-center">
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    backgroundColor: data.datasets[0].backgroundColor[index],
                    borderRadius: '50%'
                    //   marginRight: '8px'
                  }}
                />
                <p className="font-bold">
                  {currencyFormatter.format(data.datasets[0].data[index])}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DoughnutChart;
