import { getToken, handleResponse, API } from './utils';

const Public = {
  getHSPPIdOfPatient: async (patient_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/public/get-patient-hspp-id?patient_id=' + patient_id, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default Public;
