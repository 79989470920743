import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJs.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const BarGraph = ({ data }) => {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Customers And Revenue Data'
      }
    }
  };

  const percentagePlugin = {
    id: 'percentagePlugin',
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
      ctx.save();
      data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const value = dataset.data[index];
          //differentiate btw total - this value
          const difference = dataset.data[0] - value;
          if (difference == 0) return;
          const result = value != 0 ? (difference / value) * 100 : 0;
          let percentage = '';
          let textColor = '';
          if (result < 0) {
            percentage = `↓ ${result.toFixed(2)}%`;
            textColor = '#A52A2A';
          } else if (isNaN(result)) {
            percentage = '';
          } else {
            percentage = `↑ ${result.toFixed(2)}%`;
            textColor = '#000';
          }
          // console.log('value', value);
          ctx.fillStyle = textColor;
          ctx.font = 'bold 12px sans-serif';
          ctx.textAlign = 'center';
          // console.log(ctx);
          // ctx.textBaseline = 'middle';
          const position = bar.tooltipPosition();
          // console.log(bar);
          // const centerY = (bar.y + bar.base) / 2;
          // const topY = bar.height + 2;
          ctx.fillText(percentage, position.x, position.y - 10);
        });
      });
    }
  };

  return (
    <Bar
      options={options}
      data={data}
      plugins={window.innerWidth > 1000 ? [percentagePlugin] : null}
    />
  );
};

export default BarGraph;
