import React, { useEffect } from 'react';
import { Checkbox, Table } from 'components';
import { Card, Input, Dropdown } from 'components';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Body = ({ _this }) => {
  const columns = [
    {
      fixed: 'left',
      accessor: 'patient_id',
      Header: 'Patient Id',
      width: 200,
      // Cell: (row) => <div>{row?.original?.patient_id}</div>
      Cell: (row) => (
        <Link
          to={`/customer-info/${row?.original?.customer_id}`}
          className="text-blue-500 font-medium"
        >
          {row?.original?.patient_id || ''}
        </Link>
      )
    },
    {
      accessor: 'customer_name',
      Header: 'Patient Name',
      Cell: (row) => <div>{row?.original?.customer_name}</div>
    },
    {
      accessor: 'location',
      Header: 'Location',
      width: 150,
      Cell: (row) => <div>{row?.original?.location}</div>
    },
    // {
    //   accessor: 'email',
    //   Header: 'Email date',
    //   Cell: (row) => <div>{row?.original?.sentOn}</div>
    // },
    {
      accessor: 'email',
      Header: 'Customer Email',
      minWidth: 150,
      Cell: (row) => <div>{row?.original?.email}</div>
    },
    {
      accessor: 'newsletter',
      Header: 'Newsletter',
      minWidth: 120,
      Cell: (row) => <div>{row?.original?.newsletter ? 'Yes' : 'No'}</div>
    },
    {
      accessor: 'do_not_mail',
      Header: 'Do Not Mail',
      width: 140,
      Cell: (row) => <div>{row?.original?.do_not_mail ? 'Yes' : 'No'}</div>
    },
    {
      Header: 'Opt Out',
      width: 80,
      Cell: (row) => (
        <div className="flex justify-center items-center">
          <Checkbox
            id="opt_out"
            type="checkbox"
            checked={row.original.opt_out || false}
            onChange={(e) => {
              const updatedData = [..._this.tableData];
              updatedData[row.viewIndex] = {
                ...updatedData[row.viewIndex],
                opt_out: e.target.checked // Toggle the opt_out value
              };

              _this.setTableData(updatedData);
            }}
          />
        </div>
      )
    }
  ];
  return (
    <div className="flex flex-col gap-1">
      <Card background={'bg-cardbg1'} className="">
        <div className="relative flex flex-col sm:flex-row justify-between items-center">
          <div>
            <h3 className="text-body-color text-xl md:text-2xl">Monthly Newsletter Report</h3>
            <h3 className="text-body-color text-xl md:text-xl mt-2">
              Total record : {_this.tableData?.length}
            </h3>
            <input
              className="w-64 rounded-md"
              type="search"
              placeholder="Search.."
              value={_this.searchInput}
              onChange={(e) => _this.setSearchInput(e.target.value)}
            />
          </div>
          <Form className="flex flex-col sm:flex-row gap-3 w-[70%] items-center justify-end">
            <Dropdown
              label="Select Month"
              className="w-48 z-10"
              placeholder="@month"
              required
              type="text"
              options={_this.months}
              aria-label="Default select example"
              value={_this.month.month}
              onChange={(e) => {
                _this.setMonth((prev) => ({
                  ...prev,
                  month: e
                }));
              }}
            />
            <Input
              type="number"
              label="Select year"
              placeholder="year"
              value={_this.month.year}
              className="text-center text-xl p-1"
              onChange={(e) =>
                _this.setMonth((prev) => ({
                  ...prev,
                  year: e.target.value
                }))
              }
            ></Input>
            <Button variant="primary" className="bg-blue-500 " onClick={_this.init}>
              Generate Report
            </Button>
            <Button variant="primary" className="bg-blue-500 " onClick={_this.sendNewsLetterMail}>
              Send emails
            </Button>
          </Form>
        </div>
      </Card>

      <Table columns={columns} data={_this.tableData} keyField="_id" minRows={20} />
    </div>
  );
};

export default Body;
