import { handleResponse, API, getToken } from './utils';

export default {
  fetchRevenueReport: async (locations, start_date, end_date) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        `/v1/accounting/get-revenue-report`,
        {
          locations,
          start_date,
          end_date
        },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  fetchRevenuePatientsReport: async (locations, start_date, end_date) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        `/v1/accounting/get-revenue-patients-report`,
        {
          locations,
          start_date,
          end_date
        },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};
