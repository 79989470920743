import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.session).userSession;
  const [locationList, setLocationList] = useState(null);
  const [revenueParams, setRevenueParams] = useState({
    start_date: moment().startOf('month').toDate(),
    end_date: moment().endOf('month').toDate(),
    locations: []
  });

  const [revenuePatientsReportParams, setRevenuePatientsReportParams] = useState({
    start_date: moment().startOf('month').toDate(),
    end_date: moment().endOf('month').toDate(),
    locations: []
  });

  useEffect(() => {
    loadLocationOptions();
  }, []);

  const loadLocationOptions = async () => {
    dispatch(loadingStart());
    await API.getFranchiseLocation()
      .then((response) => {
        if (response) {
          const list = response.map((item) => ({
            label: item.locationName,
            value: item
          }));

          let userLocationMatch = [];

          loggedInUser.location.forEach((elem) => {
            userLocationMatch.push(...list.filter((item) => item.value.locationName == elem));
          });

          if (loggedInUser.role !== 'Employee') {
            setLocationList(list);
            setRevenueParams((prev) => ({
              ...prev,
              locations: userLocationMatch
            }));
            setRevenuePatientsReportParams((prev) => ({
              ...prev,
              locations: userLocationMatch
            }));
          } else {
            setLocationList(userLocationMatch);
            setRevenueParams((prev) => ({
              ...prev,
              locations: userLocationMatch
            }));
            setRevenuePatientsReportParams((prev) => ({
              ...prev,
              locations: userLocationMatch
            }));
          }
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const openRevenueReport = () => {
    navigate('/accounting/revenue-report', {
      state: {
        revenueParams
      }
    });
  };

  const handleRevenuePatientsReport = () => {
    navigate('/accounting/revenue-patients-report', {
      state: {
        revenuePatientsReportParams
      }
    });
  };
  return (
    <DashboardContainer>
      <Body
        _this={{
          setRevenueParams,
          locationList,
          openRevenueReport,
          revenueParams,
          revenuePatientsReportParams,
          setRevenuePatientsReportParams,
          handleRevenuePatientsReport
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
