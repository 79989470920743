import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox, Table } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ButtonBTN from 'react-bootstrap/Button';
import { BiEditAlt } from 'react-icons/bi';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import AddContestPrizeModal from './AddContestPrizeModal';
import EditContestPrizeModal from './EditContestPrizeModal';

const Section = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const location_group = dataObject.location_group.toString();
      const category = dataObject.category;
      const hurdle_name = dataObject.hurdle_name;
      const hurdle = dataObject.hurdle;
      const multiple = dataObject.multiple;
      const actual_prize = dataObject.actual_prize;

      // const address = dataObject.billing_address;
      // const formattedAddress = `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
      const extractedData = {
        Category: category,
        'Location Group': location_group,
        'Hurdle Name': hurdle_name,
        Hurdle: hurdle,
        Multiple: multiple,
        'Actual Prize': actual_prize
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'ContestPrizeReport' + fileExtension);
  };

  const columns = [
    {
      accessor: 'category',
      Header: 'Category',
      Cell: (row) => (
        <div className="flex gap-2">
          {' '}
          <BiEditAlt
            size={20}
            className="cursor-pointer"
            onClick={() => {
              _this.setEditContestPrizeModal(true);
              _this.setEditContestPrizeData((prev) => ({
                ...prev,
                id: row?.original?._id,
                location_group: {
                  label: row?.original?.location_group,
                  value: row?.original?.location_group
                },
                category: { label: row?.original?.category, value: row?.original?.category },
                hurdle_value: row?.original?.hurdle,
                multiple: row?.original?.multiple
              }));
            }}
          />
          <p>{row?.original?.category}</p>
        </div>
      )
    },
    {
      accessor: 'location_group',
      Header: 'Location Group',
      Cell: (row) => <div>{row?.original?.location_group}</div>
    },
    {
      accessor: 'hurdle_name',
      Header: 'Hurdle Name',
      Cell: (row) => <div>{row?.original?.hurdle_name}</div>
    },
    {
      accessor: 'hurdle',
      Header: 'Hurdle',
      Cell: (row) => <div>{row?.original?.hurdle}</div>
    },
    {
      accessor: 'multiple',
      Header: 'Multiple',
      Cell: (row) => <div>{row?.original?.multiple}</div>
    },
    {
      accessor: 'actual_prize',
      Header: 'Actual Prize',
      Cell: (row) => <div>{row?.original?.actual_prize}</div>
    }
  ];

  return (
    <>
      {/* <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Dropped Patients Reports</h3>
        </div>
      </Card> */}
      <Card className="flex mr-2 mt-2">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between mb-2">
          <h3 className="text-body-color text-xl font-semibold pb-2">Contest Prize</h3>
          {/* <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          /> */}
          <div>
            <h3 className="text-gray-500 text-lg font-semibold pb-1">Tier 1 : 0 - 199 Patients</h3>
            <h3 className="text-gray-500 text-lg font-semibold pb-1">Tier 2 : 200 + Patients</h3>
          </div>
          <Dropdown
            label="Location"
            className="w-48 z-10"
            placeholder="@location"
            required
            type="text"
            options={_this.locationList}
            aria-label="Default select example"
            value={_this.selectLocationGroup}
            onChange={(e) => {
              _this.setSelectLocationGroup(e);
            }}
          />
          <Dropdown
            label="Category"
            className="w-48 z-10"
            placeholder="@tier"
            required
            type="text"
            options={[
              { label: 'Tier 1', value: 'tier_1' },
              { label: 'Tier 2', value: 'tier_2' }
            ]}
            aria-label="Default select example"
            value={_this.selectCategory}
            onChange={(e) => {
              _this.setSelectCategory(e);
            }}
          />

          <div className="flex flex-col gap-2 items-end">
            <ButtonBTN
              onClick={() => _this.setAddContestPrizeModal(true)}
              className={`bg-button-primary`}
              variant="primary"
              id="export"
            >
              Add
            </ButtonBTN>

            <ButtonBTN
              onClick={() => exportReport()}
              className={`bg-button-primary h-10`}
              variant="primary"
              id="export"
            >
              Export
            </ButtonBTN>
          </div>
        </div>
        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>
      <AddContestPrizeModal _this={_this} />
      <EditContestPrizeModal _this={_this} />
    </>
  );
};

export default Section;
