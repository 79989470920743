import { handleResponse, API, getMiracleAppURL, getToken } from './utils';
import states from '../helpers/states.json';

const manageRoles = {
  createPermission: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('v1/manage-roles/create-permission', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getPermissionList: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/manage-roles/get-permission-list', {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  editPermission: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('v1/manage-roles/edit-permission', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  deletePermission: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/manage-roles/delete-permission/' + id, {
        //params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createRole: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('v1/manage-roles/create-role', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getRoleList: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/manage-roles/get-role-list', {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  editRole: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('v1/manage-roles/edit-role', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  deleteRole: async (id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/manage-roles/delete-role/' + id, {
        //params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default manageRoles;
