import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';

const Index = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [franchiseList, setFranchiseList] = useState([]);
  const [searchFieldOption, setSearchFieldOption] = useState({
    All: 'all',
    'Location Name': 'Location Name',
    'Franchise  Name': 'Franchise  Name'
  });
  const [search, setSearch] = useState({ searchField: 'all' });
  const [selectedLocations, setSelectedLocations] = useState({
    location: []
  });
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  const batterySizeOptions = [
    'None',
    '675',
    '312',
    '13',
    '10',
    '312 - Rechargable',
    '13 - Rechargable',
    'Lithium Batteries'
  ];

  useEffect(() => {
    init();
    loadLocationOptions();
  }, []);

  const loadLocationOptions = async () => {
    let response = await API.getFranchiseLocation();

    if (response) {
      const locationMap = {};

      response.forEach((item) => {
        const { franchiseName, locationId } = item;

        if (!locationMap[franchiseName]) {
          locationMap[franchiseName] = {
            label: franchiseName,
            value: [locationId]
          };
        } else {
          locationMap[franchiseName].value.push(locationId);
        }
      });

      const list = response.map((item) => ({
        label: item.locationName,
        value: item.locationId
      }));

      setLocationList(list);

      const franchiseList = Object.values(locationMap);
      setFranchiseList(franchiseList);
    }
  };

  const init = async (current_page = 0, limit_per_page = 20, sort_col = null, sort_dir = null) => {
    dispatch(loadingStart());
    let data;
    if (sort_col) {
      data = {
        page: current_page,
        limit: limit_per_page,
        column: sort_col,
        direction: sort_dir ? 1 : -1,
        locations: selectedLocationsArray
      };
    } else {
      data = {
        page: current_page,
        limit: limit_per_page,
        column: sortColumn,
        direction: sortDirection ? 1 : -1,
        locations: selectedLocationsArray
      };
    }
    API.getInvalidBatteryReport(data)
      .then((result) => {
        setData(result.data);
        if (current_page < 1) {
          setTotalPage(result.total_pages);
          setTotalData(result?.total_data);
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const handelExport = async () => {
    setIsLoading(true);
    let data;

    data = {
      page: 0,
      limit: totalPage * limitPerPage,
      column: sortColumn,
      direction: sortDirection ? 1 : -1,
      locations: selectedLocationsArray
    };

    API.getInvalidBatteryReport(data).then((result) => {
      const desiredOrder = [
        'patient_id',
        'patient_name',
        'email',
        'phone',
        'location_name',
        'do_not_mail',
        'l_battery_size',
        'r_battery_size',
        'createdAt'
      ];

      const orderedDataArray = result?.data?.map((item) => {
        const orderedItem = {};

        for (const key of desiredOrder) {
          if (key === 'do_not_mail') {
            orderedItem[key] = item[key] ? 'Yes' : 'No';
          } else if (item[key] !== undefined) {
            orderedItem[key] = item[key];
          }
        }

        return orderedItem;
      });

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(orderedDataArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'data');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileServer.saveAs(data, 'Invalid Battery List' + fileExtension);

      setIsLoading(false);
    });
  };
  const searchData = async () => {
    setCurrentPage(0);
    let locations = [];
    if (search.searchField == 'Franchise  Name') {
      locations = selectedLocations?.location?.value;
    } else if (search.searchField == 'Location Name') {
      locations = selectedLocations?.location?.map((item) => item.value);
    }
    setSelectedLocationsArray(locations);
    dispatch(loadingStart());
    API.getInvalidBatteryReport({
      page: 0,
      limit: limitPerPage,
      column: sortColumn,
      direction: sortDirection ? 1 : -1,
      locations: locations
    })
      .then((result) => {
        setData(result.data);
        setTotalPage(result.total_pages);
        setTotalData(result?.total_data);
      })
      .finally(() => dispatch(loadingStop()));
  };

  const submitEditBatteriesData = () => {
    // const filteredData = data
    //   .filter((item) => {
    //     return (
    //       typeof item.l_battery_size == 'object' || typeof item.r_battery_size == 'object'
    //       // item.l_battery_size !== '' ||
    //       // item.r_battery_size !== '' ||
    //       // item.l_battery_size ||
    //       // item.r_battery_size
    //     );
    //   })
    //   .map((item) => ({
    //     _id: item._id,
    //     r_battery_size: item?.r_battery_size?.value,
    //     l_battery_size: item?.l_battery_size?.value
    //   }));

    const filteredData = [];
    updatedData.map((item) => {
      filteredData.push({
        _id: data[item]._id,
        r_battery_size: data[item].r_battery_size,
        l_battery_size: data[item].l_battery_size
      });
    });

    dispatch(loadingStart());
    API.editInvalidBatteryReport(filteredData)
      .then((res) => {
        if (res) {
          toast.success('Data edited successfully');
          init();
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          data,
          setData,
          totalPage,
          init,
          currentPage,
          setCurrentPage,
          limitPerPage,
          setLimitPerPage,
          handelExport,
          isLoading,
          totalData,
          sortColumn,
          setSortColumn,
          sortDirection,
          setSortDirection,
          locationList,
          searchFieldOption,
          setSearch,
          search,
          selectedLocations,
          setSelectedLocations,
          searchData,
          setLocationList,
          franchiseList,
          setSelectedLocationsArray,
          batterySizeOptions,
          submitEditBatteriesData,
          updatedData,
          setUpdatedData
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
