import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';

const emailTemplates = {
  fetchEmailErrorLog: async () => {
    let response;
    const token = await getToken();
    try {
      response = await API.get(`/v1/email-error-log/fetch_email`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (error) {
      response = error;
    }
    return handleResponse(response);
  }
};

export default emailTemplates;
