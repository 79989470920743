import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Row, Checkbox, Input } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';
import Cards from 'react-credit-cards-2';

const AddNewPayment = ({ _this }) => {
  const [cardExpiry, setCardExpiry] = useState('');
  useEffect(() => {
    let expiry = cardExpiry.toString();
    if (cardExpiry.includes('/') == false) {
      if (expiry.length == 2) expiry = expiry + '/';
    }
    _this.updateCardDetails('expiry', expiry);
  }, [cardExpiry]);
  return (
    <Modal
      style={{ zIndex: 9999 }}
      className="mt-10"
      show={_this.addPaymentModalOpen}
      onHide={() => _this.setAddPaymentModalOpen(false)}
      // fullscreen="lg-down"
      size="lg"
      aria-labeledBy="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Payment Method</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setAddPaymentModalOpen(false)} />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          <div className="flex justify-between items-center">
            <h3 className="text-body-color text-xl">Select Payment Method</h3>
            <Row className="w-6/12 flex justify-center mt-2">
              <Checkbox
                className="mt-2 text-sm"
                id="do_not_mail"
                label={<p className="text-base tracking-tight">ACH</p>}
                type="checkbox"
                checked={_this.achPaymentVisibility}
                onChange={(e) => {
                  if (e.target.checked) {
                    _this.setACHPaymentVisibility(true);
                    _this.updateCardDetails('cvc', '');
                    _this.updateCardDetails('expiry', '');
                    _this.updateCardDetails('focus', '');
                    _this.updateCardDetails('name', '');
                    _this.updateCardDetails('number', '');
                    _this.updateCardDetails('focused', '');
                  }
                }}
              />
              <Checkbox
                className="mt-2 text-sm"
                id="do_not_mail"
                label={<p className="text-base tracking-tight">Credit Card</p>}
                type="checkbox"
                checked={!_this.achPaymentVisibility}
                onChange={(e) => {
                  if (e.target.checked) {
                    _this.setACHPaymentVisibility(false);
                    _this.updateACHDetails('name', '');
                    _this.updateACHDetails('routing_number', '');
                    _this.updateACHDetails('account_number', '');
                  }
                }}
              />
            </Row>
          </div>

          <div className="bg-slate-100 mt-2 p-2 pt-3 rounded">
            {_this.achPaymentVisibility === false ? (
              <>
                <Cards
                  cvc={_this.cardDetails && _this.cardDetails.cvc}
                  expiry={_this.cardDetails && _this.cardDetails.expiry}
                  focused={_this.cardDetails && _this.cardDetails.focused}
                  name={_this.cardDetails && _this.cardDetails.name}
                  number={_this.cardDetails && _this.cardDetails.number}
                />
                <Form className="mt-4">
                  <Input
                    label="Card Number"
                    placeholder=""
                    required
                    type="number"
                    value={(_this.cardDetails && _this.cardDetails.number) || ''}
                    onChange={(e) => _this.updateCardDetails('number', e.target.value)}
                  />
                  <Input
                    label="Card Name"
                    placeholder=""
                    type="text"
                    required
                    value={(_this.cardDetails && _this.cardDetails.name) || ''}
                    onChange={(e) => _this.updateCardDetails('name', e.target.value)}
                  />
                  <Row>
                    <Input
                      label="Card Expiry (MM/YY)"
                      placeholder=""
                      type="text"
                      required
                      value={(_this.cardDetails && _this.cardDetails.expiry) || ''}
                      onChange={(e) => setCardExpiry(e.target.value)}
                    />
                    <Input
                      label="CVV"
                      placeholder=""
                      required
                      type="text"
                      value={(_this.cardDetails && _this.cardDetails.cvc) || ''}
                      onChange={(e) => _this.updateCardDetails('cvc', e.target.value)}
                    />
                  </Row>
                </Form>
              </>
            ) : (
              <>
                <Form className="mt-4">
                  <Input
                    label="Account Number"
                    placeholder=""
                    required
                    type="number"
                    value={_this.achDetails?.account_number || ''}
                    onChange={(e) => _this.updateACHDetails('account_number', e.target.value)}
                  />
                  <Input
                    label="Bank Routing Number"
                    placeholder=""
                    type="text"
                    required
                    value={_this.achDetails?.routing_number || ''}
                    onChange={(e) => _this.updateACHDetails('routing_number', e.target.value)}
                  />
                  <Input
                    label="Name"
                    placeholder=""
                    type="text"
                    required
                    value={_this.achDetails?.name || ''}
                    onChange={(e) => _this.updateACHDetails('name', e.target.value)}
                  />
                </Form>
              </>
            )}
          </div>
        </Card>
        <Modal.Footer>
          <Button onClick={() => _this.finalDataSubmitforAddNewPayment()}>
            Add New Payment Method
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewPayment;
