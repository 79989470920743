import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import { useNavigate, useLocation } from 'react-router-dom';

const Index = () => {
  const months = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ];

  const dispatch = useDispatch();
  const { state } = useLocation();
  const [searchInput, setSearchInput] = useState('');

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [month, setMonth] = useState(state.newsLetterMonth);

  const init = () => {
    dispatch(loadingStart());
    API.getNewsLetterReport({ year: month.year, month: month.month.value })
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const sendNewsLetterMail = () => {
    const filteredData = tableData.filter((entry) => !entry.opt_out);

    dispatch(loadingStart());
    API.sendNewsLetter(filteredData)
      .then((response) => {
        if (response) toast.success(`Newsletter sent to ${response.count} customers`);
      })
      .catch(() => {
        toast.error('Failed to send newsletter email.');
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData,
          setTableData,
          month,
          setMonth,
          months,
          init,
          sendNewsLetterMail,
          setSearchInput
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
