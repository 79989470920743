import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const [planDetails, setPlanDetails] = useState(params.state);

  const updatePlanDetails = (key, value) => {
    setPlanDetails((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  };

  const editPlan = async () => {
    dispatch(loadingStart());
    // let planCode = (editPlanDetails.name || '').replace(/[^a-z0-9]/gi, '');
    await API.editPlan(planDetails)
      .then((response) => {
        if (response) {
          toast.success('Plan Updated Successfully!');
          navigate('/plan-list');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          planDetails,
          updatePlanDetails,
          editPlan
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
