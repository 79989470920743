import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { Header, Footer } from 'components';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import Body from './Body';
import API from 'services/api';
import { useOnKeyPress } from 'services/helpers';

const Index = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [forgetPasswordModalVisibility, setForgetPasswordModalVisibility] = useState(false);
  const [forgetPasswordEmail, setForgetPasswordEmail] = useState('');
  const [changePasswordModalVisibility, setChangePasswordModalVisibility] = useState(false);
  const [otp, setOTP] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const navigate = useNavigate();

  const onLogin = async () => {
    if (!email) return toast.error('Please provide Email');
    if (!password) return toast.error('Please enter Password');
    dispatch(loadingStart());
    await API.login(email, password)
      .then((response) => {
        if (response) {
          response.token_expiry = new Date().getTime() + 23 * 60 * 60 * 1000;
          dispatch(login(response));
          toast.success('Hellooo! Welcome to MyHspp Dashboard');
          response.role == 'Customer' ? navigate('/customer-dashboard') : navigate('/dashboard');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const handelOTPSend = async () => {
    if (forgetPasswordEmail == '') return toast.error('Email cannot be blank.');
    dispatch(loadingStart());
    await API.sendOTP(forgetPasswordEmail)
      .then((response) => {
        if (response) {
          if (!isButtonDisabled) setButtonDisabled(true);
          toast.success('OTP Send !');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const handleForgetPasswordSubmit = async () => {
    if (forgetPasswordEmail == '' && otp == '') return toast.error('Email or OTP cannot be blank.');
    dispatch(loadingStart());
    await API.verifyOTP(forgetPasswordEmail, otp)
      .then((response) => {
        if (response) {
          toast.success('OTP Verified');
          setChangePasswordModalVisibility(true);
        }
      })
      .finally(() => {
        setForgetPasswordModalVisibility(false);
        // setForgetPasswordEmail('');
        setOTP('');
        dispatch(loadingStop());
      });
  };
  const handelChangePasswordSubmit = async () => {
    if (forgetPasswordEmail == '' || newPassword == '' || confirmNewPassword == '')
      return toast.error('Fill all the mandatory fields.');
    if (newPassword !== confirmNewPassword) return toast.error('Password Do Not Match.');
    dispatch(loadingStart());
    await API.resetPassword(forgetPasswordEmail, newPassword, confirmNewPassword)
      .then((response) => {
        if (response) {
          toast.success('Password Reset Successfully.');
        }
      })
      .finally(() => {
        setChangePasswordModalVisibility(false);
        setForgetPasswordEmail('');
        setOTP('');
        setNewPassword('');
        setConfirmNewPassword('');
        dispatch(loadingStop());
      });
  };

  useOnKeyPress(forgetPasswordModalVisibility ? handleForgetPasswordSubmit : onLogin, 'Enter');
  return (
    <>
      <Body
        _this={{
          email,
          setEmail,
          password,
          setPassword,
          rememberMe,
          setRememberMe,
          onLogin,
          forgetPasswordModalVisibility,
          setForgetPasswordModalVisibility,
          changePasswordModalVisibility,
          setChangePasswordModalVisibility,
          forgetPasswordEmail,
          setForgetPasswordEmail,
          handleForgetPasswordSubmit,
          otp,
          setOTP,
          handelOTPSend,
          newPassword,
          setNewPassword,
          confirmNewPassword,
          setConfirmNewPassword,
          handelChangePasswordSubmit,
          isButtonDisabled,
          countdown,
          setButtonDisabled
        }}
      />
    </>
  );
};

export default Index;
