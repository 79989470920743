import React from 'react';
import './style.scss';

const Stepper = ({ steps, activeStep, labelDisabled, ...props }) => {
  return (
    <React.Fragment>
      <div id="stepperContainer">
        <div className="stepper">
          {steps.map((label, index) => (
            <span key={index} className="flex flex-row justify-center">
              <Step
                label={label}
                index={index}
                activeStep={activeStep}
                completed={index < activeStep}
                ongoing={index <= activeStep}
                labelDisabled={labelDisabled}
              />
              {steps.length != index + 1 && (
                <div
                  className={`stepConnector + ${index < activeStep ? 'stepConnector-active' : ''}`}
                />
              )}
            </span>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

const Step = ({ label, index, activeStep, completed, ongoing, labelDisabled }) => {
  return (
    <div className={'stepWrapper'}>
      <div className={`step-border ${completed ? 'step-active' : ongoing ? 'step-going' : ''}`}>
        {index + 1}
      </div>
      {!labelDisabled && <div className="label">{label}</div>}
    </div>
  );
};

export default Stepper;
