import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import { DashboardContainer, Table } from 'components';
import Body from './Body';
import API from 'services/api';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

const Index = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [activeHsppPatientDetails, setActiveHsppPatientDetails] = useState(
    state.activeHsppPatientDetails
  );
  const [searchInput, setSearchInput] = useState('');

  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [inSync, setInSync] = useState(false);

  const activeHsppPatientOptions = [
    { label: 'Location Name', value: 'location_name' },
    { label: 'Franchise Name', value: 'franchise_name' },
    { label: 'Location Group', value: 'location_group' },
    { label: 'State', value: 'state' },
    { label: 'Total', value: 'total' }
  ];

  useEffect(() => {
    init();
    // getActiveHsppStateLocationReport();
  }, [activeHsppPatientDetails.search_by]);
  useEffect(() => {
    if (searchInput == '') {
      setTableData(originalData);
    } else {
      const filteredResults = originalData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  const syncReport = () => {
    setInSync(true);
    toast
      .promise(
        async () => {
          await API.SyncAmplifonPatientReport();
          init();
        },
        {
          pending: 'Report sync is in progress',
          success: 'Data is up to date now',
          error: 'Failed to sync report'
        }
      )
      .then(() => setInSync(false));
  };

  const init = () => {
    dispatch(loadingStart());
    API.getActiveHsppPatientReport(activeHsppPatientDetails)
      .then((response) => {
        if (response) {
          setTableData(response);
          setOriginalData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const onRowExpanded = async (row) => {
    dispatch(loadingStart());
    await API.getActiveHsppStateLocationReport({
      start_date: activeHsppPatientDetails.start_date,
      current_date: activeHsppPatientDetails.current_date,
      location_name: row.locations
    })
      .then((response) => {
        if (response) {
          setTableData((prev) => {
            const items = [...prev];
            const index = items.findIndex((x) => row.state === x.state);
            items[index]['expanded_data'] = response;
            items[index]['child_component'] = ChildComponent;
            return items;
          });
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  return (
    <DashboardContainer>
      <Body
        _this={{
          setTableData,
          tableData,
          activeHsppPatientDetails,
          activeHsppPatientOptions,
          setActiveHsppPatientDetails,
          init,
          searchInput,
          setSearchInput,
          inSync,
          syncReport,
          onRowExpanded
        }}
      />
    </DashboardContainer>
  );
};

export default Index;

const HelloWorld = () => {
  return <div>Hello World</div>;
};

const ChildComponent = ({ tableData = [] }) => {
  const columns = [
    {
      accessor: 'location_name',
      Header: 'Location Name',
      headerStyle: { fontSize: '90%' },
      Cell: (row) => <div>{row.original.location_name}</div>
      // width: 200
    },
    {
      accessor: 'startSyclePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Sycle Patients',
      Cell: (row) => <div>{row.original.startSyclePatients}</div>
    },
    {
      accessor: 'currentSyclePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Sycle Patinets',
      Cell: (row) => <div>{row.original.currentSyclePatients}</div>
    },
    {
      accessor: 'startActivePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Active Patients',
      Cell: (row) => <div>{row.original.startActivePatients}</div>
    },
    {
      accessor: 'currentActivePatients',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Active Patients',
      Cell: (row) => <div>{row.original.currentActivePatients}</div>
    },
    {
      accessor: 'percHspp',
      headerStyle: { fontSize: '90%' },
      Header: '% HSPP',
      Cell: (row) => <div>{row.original.percHspp}</div>
    },
    {
      accessor: 'startActiveSubscriptions',
      headerStyle: { fontSize: '90%' },
      Header: 'Start Active Subscriptions',
      Cell: (row) => <div>{row.original.startActiveSubscriptions}</div>
    },
    {
      accessor: 'currentActiveSubscriptions',
      headerStyle: { fontSize: '90%' },
      Header: 'Current Active Subscriptions',
      Cell: (row) => <div>{row.original.currentActiveSubscriptions}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Patient Added',
      accessor: 'patientAdded',
      Cell: (row) => <div>{row.original.patientAdded}</div>
    },
    {
      headerStyle: { fontSize: '90%' },
      Header: 'Subscription Added',
      accessor: 'subscriptionAdded',
      Cell: (row) => <div>{row.original.subscriptionAdded}</div>
    }
  ];
  return (
    <Table
      keyField="_id"
      columns={columns}
      data={tableData}
      headerComponent={null}
      maxRows={20}
      minRows={1}
    />
  );
};
