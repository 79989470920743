import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop } from 'redux/action';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    chargebackDashboardReport();
  }, []);

  const chargebackDashboardReport = () => {
    dispatch(loadingStart());
    API.getChargebackDashboardReport()
      .then((result) => {
        setData(result);
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const navigateToChargebackList = (state) => {
    navigate('/chargeback-list', { state: state });
  };
  return (
    <DashboardContainer>
      <Body _this={{ data, navigateToChargebackList }} />
    </DashboardContainer>
  );
};

export default Index;
