import React from 'react';
import { Table, Button, Card, Input, Dropdown } from 'components';
import ButtonBTN from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FcShipped } from 'react-icons/fc';
import { BiSearch } from 'react-icons/bi';
import { MdToggleOn, MdToggleOff, MdAddBox } from 'react-icons/md';
const Body = ({ _this }) => {
  const columns = [
    {
      accessor: 'customer_id',
      Header: 'Patient ID',
      Cell: (row) => (
        <div className="flex justify-center items-center gap-2">
          {row.original.is_migrated_from_fusebill &&
            row.original.is_migrated_from_fusebill == true && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-bottom ">
                    <p>--Migrated--</p>
                    <p>Fusebill ID: {row.original.fusebill_id}</p>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">
                  <FcShipped size={20} />
                </span>
              </OverlayTrigger>
            )}
          <Link
            to={`/customer-info/${row.original.customer_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 font-medium"
          >
            {row.original.patient_id}
          </Link>
        </div>
      )
    },
    {
      accessor: 'patient_name',
      minWidth: 150,
      Header: 'Patient Name',
      Cell: (row) => <div>{row.original.patient_name}</div>
    },
    {
      accessor: 'location_name',
      minWidth: 150,
      Header: 'Location Name',
      Cell: (row) => <div>{row.original.location_name}</div>
    },
    {
      accessor: 'chargeable',
      Header: 'Chargeable',
      Cell: (row) => <div>{row.original.chargeable}</div>
    },
    {
      accessor: 'l_battery_size',
      Header: 'L Battery Size',
      minWidth: 150,
      Cell: (row) => <div>{row.original.l_battery_size}</div>
    },
    {
      accessor: 'r_battery_size',
      Header: 'R Battery Size',
      minWidth: 150,
      Cell: (row) => <div>{row.original.r_battery_size}</div>
    },
    {
      accessor: 'shipping_address_1',
      Header: 'Shipping Address 1',
      minWidth: 250,
      Cell: (row) => <div>{row.original.shipping_address_1}</div>
    },
    {
      accessor: 'shipping_address_2',
      Header: 'Shipping Address 2',
      minWidth: 250,
      Cell: (row) => <div>{row.original.shipping_address_2}</div>
    },
    {
      accessor: 'city',
      Header: 'City',
      Cell: (row) => <div>{row.original.city}</div>
    },
    {
      accessor: 'state',
      Header: 'State',
      Cell: (row) => <div>{row.original.state}</div>
    },
    {
      accessor: 'country',
      Header: 'Country',
      Cell: (row) => <div>{row.original.country}</div>
    },
    {
      accessor: 'zipcode',
      Header: 'Zipcode',
      Cell: (row) => <div>{row.original.zipcode}</div>
    },
    {
      accessor: 'do_not_mail',
      minWidth: 120,
      Header: 'Do Not Mail',
      Cell: (row) => <div>{row.original.do_not_mail ? 'Yes' : 'No'}</div>
    },
    {
      accessor: 'full_tech_mailer',
      sortable: false,
      minWidth: 250,
      Header: 'Fulltech Mailer',
      Cell: (row) => {
        const momentCreatedAt = moment(row.original.createdAt);
        let diff = moment().diff(momentCreatedAt);
        diff = diff / (24 * 3600 * 1000);

        let batterySize;
        let displayText = '';
        if (row.original.l_battery_size && row.original.l_battery_size !== 'None') {
          batterySize = row.original.l_battery_size;
        } else if (row.original.r_battery_size && row.original.r_battery_size !== 'None') {
          batterySize = row.original.r_battery_size;
        } else {
          batterySize = 'None';
        }

        switch (batterySize) {
          case '675':
            displayText = '675-NB';
            break;
          case '312':
            displayText = '312-NB';
            break;
          case '13':
            displayText = '13-NB';
            break;
          case '10':
            displayText = '10-NB';
            break;
          case '312-Rechargeable':
            displayText = '312R-NB';
            break;
          case '13-Rechargeable':
            displayText = '13R-NB';
            break;
          default:
            displayText = 'N-N';
            break;
        }
        if (diff < 31) displayText += 'W';

        return <div className="">{displayText}</div>;

        //   if (row.original.l_battery_size && row.original.l_battery_size !== 'None') {
        //     if (isPreviousMonth) {
        //       return <div className="text-left pl-2">{row.original.l_battery_size} - NBW</div>;
        //     } else {
        //       return <div className="text-left pl-2">{row.original.l_battery_size} - NB</div>;
        //     }
        //   } else if (row.original.r_battery_size && row.original.r_battery_size !== 'None') {
        //     if (isPreviousMonth) {
        //       return <div className="text-left pl-2">{row.original.r_battery_size} - NBW</div>;
        //     } else {
        //       return <div className="text-left pl-2">{row.original.r_battery_size} - NB</div>;
        //     }
        //   } else if (
        //     row.original.l_battery_size &&
        //     row.original.l_battery_size == 'None' &&
        //     row.original.r_battery_size &&
        //     row.original.r_battery_size == 'None'
        //   ) {
        //     if (isPreviousMonth) {
        //       return <div className="text-left pl-2">No - NW</div>;
        //     } else {
        //       return <div className="text-left pl-2">No - N</div>;
        //     }
        //   } else {
        //     return <div className="text-left pl-2">-</div>;
        //   }
      }
    },
    {
      accessor: 'createdAt',
      // sortable: false,
      minWidth: 150,
      Header: 'Created At',
      Cell: (row) => <div>{moment(row.original.createdAt).format('YYYY-MM-DD')}</div>
    }
  ];
  return (
    <div className="flex flex-col gap-1">
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between items-center">
          <div className="flex gap-4 mb-2 items-center">
            <h3 className="text-body-color text-xl md:text-2xl">
              Protection Plan Battery Mailer List | {_this?.totalData}
            </h3>
          </div>
          <div>
            <Button onClick={_this.handelExport}>Export</Button>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex justify-center items-center">
            <Form.Select
              aria-label="Default select example"
              className="mb-2"
              value={_this.search?.searchField}
              onChange={(e) => {
                _this.setSearch((prev) => ({
                  ...prev,
                  searchField: e.target.value
                }));
                _this.setSelectedLocations([]);
                _this.setSelectedLocationsArray([]);
              }}
            >
              {Object.keys(_this.searchFieldOption).map((item, index) => (
                <option key={index} value={_this.searchFieldOption[item]} className="text-lg">
                  {item}
                </option>
              ))}
            </Form.Select>
            {_this.search.searchField === 'Location Name' && (
              <div className="flex justify-center items-center">
                <Dropdown
                  className="w-48 z-20"
                  placeholder="@location"
                  type="text"
                  options={_this.locationList
                    ?.slice()
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  value={_this.selectedLocations.location}
                  onChange={(e) => {
                    _this.setSelectedLocations((prev) => ({
                      ...prev,
                      location: e
                    }));
                  }}
                  aria-label="Location List"
                  isMulti
                />
              </div>
            )}
            {_this.search.searchField === 'Franchise  Name' && (
              <Dropdown
                className="w-48 z-20 !mb-0"
                placeholder="@location"
                type="text"
                options={_this.franchiseList}
                onChange={(e) => {
                  _this.setSelectedLocations((prev) => ({
                    ...prev,
                    location: e
                  }));
                }}
                aria-label="Franchise List"
              />
            )}
            <ButtonBTN
              onClick={() => _this.searchData()}
              className={`bg-button-primary mb-2 md:!mt-0`}
              variant="primary"
              id="button-addon3"
            >
              <BiSearch size={24} />
            </ButtonBTN>
          </div>

          <div className="flex justify-between text-lg items-center text-body-color gap-2">
            {_this?.shippingAddressIndicator ? (
              <MdToggleOff
                onClick={() => {
                  _this.setShippingAddressIndicator((prev) => !prev);
                }}
                size={45}
                className="cursor-pointer text-blue-500"
              />
            ) : (
              <MdToggleOn
                onClick={() => {
                  _this.setShippingAddressIndicator((prev) => !prev);
                }}
                size={45}
                className="cursor-pointer text-blue-500"
              />
            )}
            Blank Shipping Address
          </div>
        </div>
      </Card>
      <Card paddingClass="p-1">
        <Table
          className="h-[35rem]"
          keyField="id"
          columns={columns}
          data={_this.data}
          headerComponent={null}
          showPageJump={false}
          showPageSizeOptions={true}
          pages={_this.totalPage}
          page={_this.currentPage}
          onPageChange={(e) => {
            _this.setCurrentPage(e);
            _this.init(e, _this.limitPerPage);
          }}
          onPageSizeChange={(e) => {
            _this.setCurrentPage(0);
            _this.setLimitPerPage(e);
            _this.init(_this.currentPage, e);
          }}
          onSortedChange={(e) => {
            _this.setSortColumn(e[0].id);
            _this.setSortDirection(e[0].desc);
            _this.init(_this.currentPage, _this.limitPerPage, e[0].id, e[0].desc);
          }}
          manual={true}
        />
      </Card>
      <Loading _this={_this} />
    </div>
  );
};

export default Body;
