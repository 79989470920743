import React, { useEffect, useRef } from 'react';
import { Card, Button } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import { DatePicker } from 'components';
import { parseHsppId } from 'services/helpers';
import Form from 'react-bootstrap/Form';
import { BsSearch } from 'react-icons/bs';
import { FcShipped } from 'react-icons/fc';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonBTN from 'react-bootstrap/Button';
import { PermissionWrapper, HasPermission } from 'components';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import ExportCustomers from './ExportCustomers';
import Loading from 'components/Loading';

const Section = ({ _this }) => {
  const columns = [
    {
      accessor: 'hspp_id',
      Header: 'HSPP ID',
      sortable: false,
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <div className="flex gap-2 px-2 justify-center items-center">
          {row.original.is_migrated_from_fusebill == true && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-bottom ">
                  <p>--Migrated--</p>
                  <p>Fusebill ID: {row.original.fusebill_id}</p>
                </Tooltip>
              }
            >
              <span className="cursor-pointer">
                <FcShipped size={20} />
              </span>
            </OverlayTrigger>
          )}
          <Link to={`/customer-info/${row.original._id}`} className="text-blue-500 font-medium">
            {row.original.hspp_id}
          </Link>
        </div>
      )
    },
    {
      accessor: 'customer_id',
      Header: 'Patient ID'
    },
    {
      accessor: 'first_name',
      Header: 'Patient Name',
      Cell: (row) => <div>{row.original.first_name + ' ' + row.original.last_name}</div>
    },
    {
      accessor: 'email',
      Header: 'Patient Email',
      width: 280,
      Cell: (row) => <div className="break-words">{row.original.email}</div>
    },
    {
      accessor: 'active_plans',
      Header: 'Active Plans',
      sortable: true
    },
    {
      Header: 'Store Location',
      accessor: 'store_location',
      Cell: (row) => <div>{row.original.store_location.toString()}</div>
    },
    {
      accessor: 'createdAt',
      Cell: (row) => <div>{moment(row.original.createdAt).format('YYYY-MM-DD')}</div>,
      Header: 'Created On'
    }
  ];

  if (HasPermission({ permission: ['delete_customer'], require_all: false }))
    columns.push({
      Cell: (row) => (
        <div className="p-1 w-full flex justify-center items-center">
          <button
            className="bg-rose-700 hover:bg-rose-800 text-white font-medium px-2 py-1.5 rounded text-xs flex flex-row self-center w-24 justify-center items-center"
            onClick={() => _this.deleteCustomer(row.original._id)}
          >
            Delete
          </button>
        </div>
      ),
      Header: 'Action'
    });

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-2xl">Customer List</h3>
          <div>
            {_this?.isIdaho ? (
              <p className="text-red-400 w-[25rem]">
                Until further notice, we have to pause the selling, solicitation, or negotiation of
                any protection in the state of Idaho
              </p>
            ) : (
              <PermissionWrapper permission="create_customer">
                <Link to="/create-customer" className="text-body-color">
                  <Button onClick={() => {}}>Create Customer</Button>
                </Link>
              </PermissionWrapper>
            )}
          </div>
        </div>
        <div className="flex justify-between flex-col md:flex-row gap-3 md:items-center mt-4">
          <div className="">
            <InputGroup className="rounded-md">
              <Form.Select
                value={_this.currentSelectedCountOption}
                onChange={_this.handelActiveCount}
              >
                <option value={''} className="text-md">
                  Select Parameters
                </option>
                {Object.keys(_this.countFieldOption).map((item, index) => (
                  <option key={index} value={item} className="text-md">
                    {item}
                  </option>
                ))}
              </Form.Select>
              <label className="border-[2px] border-gray-500 px-3 text-center text-3xl font-bold">
                {_this.count}
              </label>
            </InputGroup>
          </div>
          <form onSubmit={_this.searchData}>
            <InputGroup
              className={`${
                _this.search.searchField === 'createdAt'
                  ? 'flex flex-col md:flex-row md:items-start justify-center w-full md:gap-2 gap-0'
                  : 'w-84 sm:w-96 flex gap-2 md:!gap-0'
              }  focus:border-button-primary`}
            >
              <Form.Select
                aria-label="Default select example"
                value={_this.search?.searchField}
                className="!w-full md:!w-2/12"
                onChange={(e) => {
                  _this.setSearch((prev) => ({
                    ...prev,
                    searchField: e.target.value
                  }));
                  if (_this.searchFieldOption[e.target.value] != 'Created At') {
                    _this.setSearchFromDate('');
                    _this.setSearchToDate('');
                    _this.setSearch((prev) => ({
                      ...prev,
                      searchInput: ''
                    }));
                  }
                }}
              >
                <option value={''}>Select Parameters</option>
                {Object.keys(_this.searchFieldOption).map((item, index) => (
                  <option key={index} value={_this.searchFieldOption[item]} className="text-lg">
                    {item}
                  </option>
                ))}
              </Form.Select>
              {_this.search.searchField === 'createdAt' ? (
                <div className="flex mt-2 md:!mt-0 sm:flex-row">
                  <div className="flex-1">
                    <DatePicker
                      // label="From"
                      value={_this.searchFromDate || ''}
                      onChange={(e) => _this.setSearchFromDate(e)}
                      onCalendarOpen={null}
                    />
                  </div>
                  <div className="flex-1">
                    <DatePicker
                      // label="To"
                      value={_this.searchToDate || ''}
                      onChange={(e) => _this.setSearchToDate(e)}
                      onCalendarOpen={null}
                    />
                  </div>
                </div>
              ) : (
                <Form.Control
                  placeholder="...Search"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  type="search"
                  className="rounded-md"
                  value={_this.search.searchInput}
                  onChange={(e) =>
                    _this.setSearch((prev) => ({
                      ...prev,
                      searchInput: e.target.value
                    }))
                  }
                />
              )}
              <ButtonBTN
                onClick={() => _this.searchData()}
                className={`bg-button-primary mt-2 md:!mt-0`}
                variant="primary"
                id="button-addon3"
              >
                <BsSearch size={24} />
              </ButtonBTN>
            </InputGroup>
          </form>
          <div>
            <PermissionWrapper permission="export_customer_list">
              <ButtonBTN
                // onClick={() => _this.exportCustomer()}
                onClick={() => _this.setExportModalVisible(true)}
                className={`bg-button-primary`}
                variant="primary"
                id="export"
              >
                Export
              </ButtonBTN>
            </PermissionWrapper>
          </div>
        </div>
      </Card>
      <Card className="flex mt-2 " paddingClass="p-2">
        <div className="rounded-lg">
          <Table
            keyField="id"
            columns={columns}
            data={_this.tableData}
            headerComponent={null}
            showPageJump={false}
            showPageSizeOptions={true}
            pages={_this.totalPage}
            page={_this.currentPage}
            onPageChange={(e) => {
              _this.setCurrentPage(e);
              _this.fetchData(e, _this.limitPerPage, _this.payload);
            }}
            onPageSizeChange={(e) => {
              _this.setCurrentPage(0);
              _this.setLimitPerPage(e);
              _this.fetchData(0, e, _this.payload);
            }}
            manual={true}
            onSortedChange={(e) => {
              const data = e[0];
              _this.fetchData(
                _this.currentPage,
                _this.limitPerPage,
                _this.payload,
                false,
                data.id,
                data.desc
              );
            }}
          />
        </div>
      </Card>
      <ExportCustomers _this={_this} />
      <Loading _this={_this} />
    </>
  );
};

export default Section;
