import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop, login } from 'redux/action';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import { toast } from 'react-toastify';

const Index = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (searchInput == '') {
      setTableData(customerData);
    } else {
      const filteredResults = customerData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  useEffect(() => {
    toast.promise(init(), {
      pending: 'Report is loading',
      success: 'Report generated successfully',
      error: 'Something went wrong.'
    });
  }, []);

  const init = async () => {
    await API.getFuturePlansReport().then((res) => {
      if (res) {
        setTableData(res);
        setCustomerData(res);
      }
    });
  };

  return (
    <DashboardContainer>
      <Body _this={{ tableData, searchInput, setSearchInput }} />
    </DashboardContainer>
  );
};

export default Index;
