import React, { useState, useEffect } from 'react';
import {
  Card,
  Button,
  Input,
  Dropdown,
  DatePicker,
  Row,
  Checkbox,
  AutSuggestInput,
  PermissionWrapper
} from 'components';
import { Form, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import './style.scss';
import { toast } from 'react-toastify';

const SubscriptionDetails = ({ _this, i }) => {
  const [filteredPlanList, setfilteredPlanList] = useState([]);
  const productInfo = _this.productInformation[i];

  useEffect(() => {
    if (productInfo) {
      filterPlanList(i);
    }
  }, [productInfo?.l_warranty_expiry, productInfo?.r_warranty_expiry, productInfo?.plan_interval]);

  useEffect(() => {
    _this.updateProductInformation(
      'plan_interval',
      { label: 'Full Amount', value: 'Full Amount' },
      i
    );
  }, []);

  useEffect(() => {
    if (productInfo?.discount == 0) {
      _this.updateProductInformation('plan_override_amount', null, i);
    } else if (productInfo?.plan && productInfo?.discount > 0) {
      const plan_override_amount = calculatePlanOverrideAmount();
      if (plan_override_amount < 0) {
        toast.error('Discount cannot be greater than actual price.');
        _this.updateProductInformation('discount', 0, i);
      } else {
        _this.updateProductInformation('plan_override_amount', Number(plan_override_amount), i);
      }
    }
    // }
  }, [productInfo?.discount, productInfo?.plan]);

  const filterPlanList = (index) => {
    const productInfo = _this.productInformation[index];

    // Adding price to the dropdown label
    const interval = productInfo?.plan_interval?.value;
    const planList = JSON.parse(JSON.stringify(_this.planList)).map((item) => {
      let amount = 0;
      if (interval == 'Monthly') amount += item.value.monthly;
      else if (interval == 'Annually') amount += item.value.annually;
      else if (interval == 'Full Amount') {
        amount += item.value.frequency * item.value.annually;
      }
      item.label = item.label + ' | $' + Math.abs(amount).toFixed(2);
      return item;
    });

    let newPlanList = planList.filter((item) => [].includes(item.value.plan_code));
    let defaultSelectedValue = null;

    //checking if double aid
    if (productInfo && productInfo.l_warranty_expiry && productInfo.r_warranty_expiry) {
      //future date
      if (
        moment(productInfo.l_warranty_expiry).diff(moment(), 'days') >= 1 &&
        moment(productInfo.r_warranty_expiry).diff(moment(), 'days') >= 1
      ) {
        newPlanList = planList.filter((item) =>
          ['batteryplan', '2022protectionsupplementallossdamage'].includes(item.value.plan_code)
        );
        defaultSelectedValue = planList.filter((item) =>
          ['2022protectionsupplementallossdamage'].includes(item.value.plan_code)
        )[0];
      } else if (
        // past date more than 2 years
        moment(productInfo.l_warranty_expiry).diff(moment(), 'days') < -730 &&
        moment(productInfo.r_warranty_expiry).diff(moment(), 'days') < -730
      ) {
        newPlanList = planList.filter((item) =>
          ['batteryplan', 'secureplusplansupplementalrepairwarranty'].includes(item.value.plan_code)
        );
        defaultSelectedValue = planList.filter((item) =>
          ['secureplusplansupplementalrepairwarranty'].includes(item.value.plan_code)
        )[0];
      } else {
        //past date within 2 years
        newPlanList = planList.filter((item) =>
          ['batteryplan', '2022secureplan'].includes(item.value.plan_code)
        );
        defaultSelectedValue = planList.filter((item) =>
          ['2022secureplan'].includes(item.value.plan_code)
        )[0];
      }
    } else if (
      //if Single aid
      (productInfo && productInfo.l_warranty_expiry && productInfo.r_warranty_expiry == '') ||
      (productInfo && productInfo.l_warranty_expiry == '' && productInfo.r_warranty_expiry)
    ) {
      //future date
      if (
        moment(productInfo.l_warranty_expiry).diff(moment(), 'days') >= 1 ||
        moment(productInfo.r_warranty_expiry).diff(moment(), 'days') >= 1
      ) {
        newPlanList = planList.filter((item) =>
          ['batteryplan', 'protectionsupplementallossdamagesingleaid'].includes(
            item.value.plan_code
          )
        );
        defaultSelectedValue = planList.filter((item) =>
          ['protectionsupplementallossdamagesingleaid'].includes(item.value.plan_code)
        )[0];
      } else if (
        // past date more than 2 years
        moment(productInfo.l_warranty_expiry).diff(moment(), 'days') < -730 ||
        moment(productInfo.r_warranty_expiry).diff(moment(), 'days') < -730
      ) {
        newPlanList = planList.filter((item) =>
          ['batteryplan', 'secureplusplansupplementalrepairwarranty'].includes(item.value.plan_code)
        );
        defaultSelectedValue = planList.filter((item) =>
          ['secureplusplansupplementalrepairwarranty'].includes(item.value.plan_code)
        )[0];
      } else {
        //past date within 2 years
        newPlanList = planList.filter((item) =>
          ['batteryplan', '2022secureplansupplementalrepairwarrantysingleaid'].includes(
            item.value.plan_code
          )
        );
        defaultSelectedValue = planList.filter((item) =>
          ['2022secureplansupplementalrepairwarrantysingleaid'].includes(item.value.plan_code)
        )[0];
      }
    }

    // Update plan list and set default selected plan
    setfilteredPlanList(newPlanList);
    _this.updateProductInformation('plan', defaultSelectedValue, i);
    // _this.updateProductInformation('payment_date', moment(), i);
  };

  const calculatePlanOverrideAmount = () => {
    const interval = productInfo.plan_interval.value;

    if (interval == 'Monthly')
      // plan_override_amount = productInfo.plan.value.monthly - productInfo.discount;
      return (productInfo.plan.value.monthly - productInfo.discount).toFixed(2);
    else if (interval == 'Annually')
      // plan_override_amount = productInfo.plan.value.monthly - productInfo.discount;
      return (productInfo.plan.value.annually - productInfo.discount).toFixed(2);
    else if (interval == 'Full Amount')
      // plan_override_amount = productInfo.plan.value.monthly - productInfo.discount;
      return (
        productInfo.plan.value.annually * productInfo.plan.value.frequency -
        productInfo.discount
      ).toFixed(2);
  };

  return (
    <div>
      <Card className="mb-2" background={'bg-cardbg1'} paddingClass="px-4 py-2">
        <center className="">
          <h3 className="text-body-color text-2xl">Subscription {i + 1} Details</h3>
        </center>
      </Card>
      <div className="flex md:flex-row mb-2 flex-col gap-y-5 gap-x-4">
        <Card className="flex basis-1/2">
          <h3 className="text-body-color text-xl">Plan Details</h3>
          <Form className="bg-slate-100 mt-2 p-2 rounded">
            <Row>
              <div>
                <DatePicker
                  label="L Warranty Expiry Date"
                  value={_this.productInformation[i]?.l_warranty_expiry || ''}
                  onChange={(e) => {
                    _this.updateProductInformation('l_warranty_expiry', e || '', i);
                  }}
                  onCalendarOpen={() => {
                    if (!_this.productInformation[i]?.l_warranty_expiry)
                      _this.updateProductInformation(
                        'l_warranty_expiry',
                        moment().add(3, 'years').toDate(),
                        i
                      );
                  }}
                />
              </div>
              <div>
                <DatePicker
                  label="R Warranty Expiry Date"
                  value={_this.productInformation[i]?.r_warranty_expiry || ''}
                  onChange={(e) => {
                    _this.updateProductInformation('r_warranty_expiry', e || '', i);
                  }}
                  onCalendarOpen={() => {
                    if (!_this.productInformation[i]?.r_warranty_expiry)
                      _this.updateProductInformation(
                        'r_warranty_expiry',
                        moment().add(3, 'years').toDate(),
                        i
                      );
                  }}
                />
              </div>
            </Row>
            <Row>
              <Dropdown
                label="Plan Interval"
                placeholder="Select"
                required
                type="text"
                options={[
                  { label: 'Monthly', value: 'Monthly' },
                  { label: 'Annually', value: 'Annually' },
                  { label: 'Full Amount', value: 'Full Amount' }
                ]}
                value={_this.productInformation[i]?.plan_interval}
                onChange={(e) => _this.updateProductInformation('plan_interval', e, i)}
              />
              <Input
                label={
                  _this.productInformation[i]?.plan_interval?.value === 'Monthly'
                    ? 'Months'
                    : _this.productInformation[i]?.plan_interval?.value === 'Annually'
                    ? 'Years'
                    : 'Months/Years'
                }
                placeholder=""
                type="text"
                value={_this.productInformation[i]?.plan_frequency || ''}
                disabled
              />
            </Row>
            <Dropdown
              label="Select Plan"
              required
              type="text"
              options={filteredPlanList}
              isClearable
              value={_this.productInformation[i]?.plan}
              onChange={(e) => {
                _this.updateProductInformation('plan', e, i);
              }}
            />
            <DatePicker
              label="Set Payment Date"
              value={
                _this.productInformation[i]?.payment_date &&
                _this.productInformation[i]?.payment_date
                // : new Date(moment().format('YYYY-MM-DD'))
              }
              onChange={(e) => {
                _this.updateProductInformation('payment_date', e || '', i);
              }}
              maxDate={new Date(moment().add(1, 'M'))}
              required
              // onCalendarOpen={() => {
              //   if (!_this.productInformation[i]?.payment_date)
              //     _this.updateProductInformation('payment_date', moment().toDate(), i);
              // }}
            />
          </Form>

          {/*Promo discount only for admins */}
          <PermissionWrapper permission="add_promo">
            <h3 className="text-body-color text-xl mt-4">Promo Discount(Optional)</h3>
            <Form className="bg-slate-100 mt-2 p-2 rounded">
              <Row>
                <Input
                  label="Discount"
                  controlId="Discount"
                  placeholder="$"
                  type="number"
                  pattern="$"
                  value={_this.productInformation[i]?.discount || ''}
                  onChange={(e) => {
                    _this.updateProductInformation('discount', Number(e.target.value), i);
                    calculatePlanOverrideAmount();
                  }}
                />

                <DatePicker
                  label="Add discount till date"
                  controlId="plan_override_date"
                  placeholder=""
                  type="date"
                  value={_this.productInformation[i]?.plan_override_date || ''}
                  onChange={(e) =>
                    _this.updateProductInformation('plan_override_date', e || null, i)
                  }
                />
              </Row>
              <h3 className="text-body-color text-sm mt-4">
                To give discount for the entire plan, keep the Add discount till date empty.
              </h3>
            </Form>
          </PermissionWrapper>
        </Card>
        <Card className="flex basis-1/2">
          <h3 className="text-body-color text-xl">Product Information</h3>
          <Form className="bg-slate-100 mt-2 p-2 rounded">
            <Row>
              <Dropdown
                label="L Battery Size"
                placeholder="Select"
                required
                type="text"
                options={_this.batterySizeOptions}
                value={_this.productInformation[i]?.l_battery_size}
                onChange={(e) => {
                  _this.updateProductInformation('l_battery_size', e, i);
                }}
              />
              <Dropdown
                label="R Battery Size"
                placeholder="Select"
                required
                type="text"
                options={_this.batterySizeOptions}
                value={_this.productInformation[i]?.r_battery_size}
                onChange={(e) => _this.updateProductInformation('r_battery_size', e, i)}
              />
            </Row>
            <Row>
              <Dropdown
                label="Chargeable"
                placeholder="Select"
                required
                type="text"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]}
                value={_this.productInformation[i]?.chargable}
                onChange={(e) => _this.updateProductInformation('chargable', e, i)}
              />
              <Dropdown
                label="Select Location"
                required
                type="text"
                options={_this.locationList}
                isClearable
                value={_this.productInformation[i]?.location}
                onChange={(e) => {
                  _this.updateProductInformation('location', e, i);
                }}
              />
            </Row>
            <Row>
              <Input
                label="Serial # Left"
                controlId="left_serial"
                placeholder=""
                type="text"
                value={_this.productInformation[i]?.left_serial || ''}
                onChange={(e) => _this.updateProductInformation('left_serial', e.target.value, i)}
              />
              <Input
                label="Serial # Right"
                controlId="right_serial"
                placeholder=""
                type="text"
                value={_this.productInformation[i]?.right_serial || ''}
                onChange={(e) => _this.updateProductInformation('right_serial', e.target.value, i)}
              />
            </Row>
            <Row>
              <Input
                label="Employee Name"
                controlId="employee_name"
                placeholder=""
                type="text"
                value={_this.loggedInUser.name}
                disabled
              />
              <Dropdown
                label="Employee Location"
                required
                type="text"
                options={_this.locationList}
                isClearable
                value={_this.productInformation[i]?.created_at_location}
                onChange={(e) => {
                  _this.updateProductInformation('created_at_location', e, i);
                }}
              />
            </Row>
          </Form>

          <Checkbox
            className="mt-2 text-sm"
            label="Customer will receive monthly newsletter via email unless batteries are included."
            type="checkbox"
            id={'newsletter'}
            checked={
              typeof _this.productInformation[i]?.newsletter == 'undefined'
                ? true
                : _this.productInformation[i]?.newsletter
            }
            onChange={(e) => {
              _this.updateProductInformation('newsletter', e.target.checked, i);
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
