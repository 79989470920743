import React, { useEffect, useState } from 'react';
import { Card, Button, Input } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Alert, Form, InputGroup, ToggleButton } from 'react-bootstrap';
import { MdToggleOn, MdToggleOff, MdAddBox } from 'react-icons/md';
import Editor from 'jodit-react';
import htmlParser from 'html-react-parser';
import { AiFillEdit } from 'react-icons/ai';
import ButtonBTN from 'react-bootstrap/Button';
import LogDataModal from './LogDataModel';

const Section = ({ _this }) => {
  const columns = [
    {
      accessor: 'customer_id',
      Header: 'Patient ID',
      Cell: (row) => <div>{row?.original?.customer_id}</div>
    },
    {
      accessor: 'customer_name',
      Header: 'Patient Name',
      Cell: (row) => <div>{row?.original?.customer_name}</div>
    },
    {
      accessor: 'email',
      Header: 'Patient Email',
      Cell: (row) => <div>{row?.original?.email}</div>
    },
    {
      accessor: 'createdAt',
      Cell: (row) => <div>{moment(row.original.createdAt).format('YYYY-MM-DD')}</div>,
      Header: 'Created On'
    },
    {
      accessor: 'email_type',
      Header: 'Email Type',
      Cell: (row) => <div>{row?.original?.email_type}</div>
    },
    {
      accessor: 'error_message',
      Header: 'View Error',
      Cell: (row) => (
        <div className="flex justify-center">
          <button
            className="bg-button-primary hover:bg-button-primaryHover text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
            onClick={() => {
              _this.setSeeLogDataModalOpen(true);
              _this.setLogDataToShow({
                error: row?.original?.error,
                message: row?.original?.error_message,
                action: row?.original?.email_type
              });
            }}
          >
            View
          </button>
        </div>
      )
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-2xl">Email Error Logs</h3>
        </div>
      </Card>

      <Card className="flex mt-2" paddingClass="p-2">
        <Table keyField="_id" columns={columns} data={_this.tableData} headerComponent={null} />
      </Card>
      <LogDataModal _this={_this} />
    </>
  );
};

export default Section;
