import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import './style.scss';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Table = ({ columns, data, minRows = 10, ...props }) => {
  const renderLoading = () => ({
    ...(props.loading ? (
      <div className="uc-new-table-loading-container">
        <div className="uc-new-table-loading-wrapper">
          <span>Loading...</span>
        </div>
      </div>
    ) : (
      <div />
    ))
  });

  return (
    <ReactTableFixedColumns
      data={data}
      columns={columns}
      sortable
      multiSort
      minRows={minRows}
      // filterable
      // defaultFilterMethod={(filter, row, column) => {
      //   const id = filter.pivotId || filter.id;
      //   return row[id] !== undefined
      //     ? String(row[id]).toLowerCase().startsWith(String(filter.value).toLowerCase())
      //     : true;
      // }}
      LoadingComponent={renderLoading}
      className="react_table_custom"
      {...props}
    />
  );
};

export default Table;
