import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';
import states from '../helpers/states.json';

const chargebackDashboard = {
  getChargebackDashboardReport: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(
        '/v1/chargeback/get-dashboard-data',

        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  getChargebackInformationByIds: async (_ids) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/chargeback/get-chargeback-information-by-ids',
        { _ids: _ids },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default chargebackDashboard;
