import React, { useEffect, useState } from 'react';
import { DashboardContainer } from 'components';
import Body from './Body';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadingStart, loadingStop } from 'redux/action';
import API from 'services/api';
import RetryPayments from './RetryPayments';

const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const invoicesData = location.state || 0;
  const [searchInput, setSearchInput] = useState('');
  const [retrySearchInput, setRetrySearchInput] = useState('');
  const [customerData, setCustomerData] = useState();
  const [tableData, setTableData] = useState([]);
  const [retryTableData, setRetryTableData] = useState([]);
  const [retryTableDataToShow, setRetryTableDataToShow] = useState([]);
  const [isRetryInvoice, setIsRetryInvoice] = useState(false);
  const [retryCounts, setRetryCounts] = useState({
    processing: 0,
    succeded: 0,
    failed: 0,
    total: 0
  });
  const [isRetryInProgress, setIsRetryInProgress] = useState(false);
  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  //  for warning before reloading the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isRetryInProgress) {
        // Cancel the event
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = '';
        // Display a confirmation message
        return 'Are you sure you want to leave this page? The process will be cancelled.';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isRetryInProgress]);

  useEffect(() => {
    getRevenueInvoicesData();
  }, []);

  useEffect(() => {
    setRetryCounts({
      processing: tableData?.length,
      total: tableData?.length,
      succeded: 0,
      failed: 0
    });
  }, [tableData]);

  useEffect(() => {
    if (searchInput == '') {
      setTableData(customerData);
    } else {
      const filteredResults = customerData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(searchInput.toLowerCase())
      );
      setTableData(filteredResults);
    }
  }, [searchInput]);

  useEffect(() => {
    if (retrySearchInput == '') {
      setRetryTableDataToShow(retryTableData);
    } else {
      const filteredResults = retryTableData.filter((data) =>
        Object.values(data).join(', ').toLowerCase().includes(retrySearchInput.toLowerCase())
      );
      setRetryTableDataToShow(filteredResults);
    }
  }, [retrySearchInput]);

  useEffect(() => {
    setRetryTableDataToShow(retryTableData);
  }, [retryTableData]);

  const getRevenueInvoicesData = () => {
    let invoices_array = [];
    if (invoicesData.invoices_ids == 0) {
      [...invoices_array];
    } else {
      invoices_array = [...invoicesData.invoices_ids];
    }
    dispatch(loadingStart());
    API.getRevenueInvoicesData(invoices_array)
      .then((res) => {
        if (res) {
          setCustomerData(res);
          setTableData(res);
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const retryPayments = async () => {
    setIsRetryInvoice(true);
    setIsRetryInProgress(true);

    for (let item of tableData) {
      let newItem = { ...item, retry_status: 'processing' };
      setRetryTableData((state) => {
        let newState = [...state];
        newState.unshift(newItem);
        return newState;
      });

      let response = await API?.retryFailedInvoice(item?._id);
      if (response?.status) {
        setRetryCounts((prev) => {
          return {
            ...prev,
            processing: prev.processing - 1,
            succeded: prev.succeded + 1
          };
        });
        newItem.retry_status = 'succeeded';
        newItem = { ...newItem, message: response?.message };
      } else {
        setRetryCounts((prev) => {
          return {
            ...prev,
            processing: prev.processing - 1,
            failed: prev.failed + 1
          };
        });
        newItem.retry_status = 'failed';
        newItem = { ...newItem, message: response?.message };
      }

      setRetryTableData((state) => {
        let newState = [...state];
        newState[0] = newItem;
        return newState;
      });
    }

    setIsRetryInProgress(false);
  };

  const goBack = () => {
    getRevenueInvoicesData();
    setIsRetryInvoice(false);
    setRetryTableData([]);
    setRetryCounts({ total: 0, processing: 0, succeded: 0, failed: 0 });
  };

  const onSummaryOpen = () => {
    const result = {};

    // Iterate over the array of objects
    retryTableData.forEach((obj) => {
      const message = obj.message;

      // If the message exists in the result object, increment count and push data
      if (result[message]) {
        result[message].count++;
        result[message].data.push(obj);
      } else {
        // Otherwise, initialize count to 1 and data array with the object
        result[message] = { count: 1, data: [obj] };
      }
    });

    setSummaryData(result);
  };

  return (
    <DashboardContainer>
      {isRetryInvoice ? (
        <RetryPayments
          _this={{
            isRetryInvoice,
            setIsRetryInvoice,
            retryTableDataToShow,
            retryPayments,
            retryCounts,
            goBack,
            isRetryInProgress,
            retrySearchInput,
            setRetrySearchInput,
            summaryModalOpen,
            setSummaryModalOpen,
            onSummaryOpen,
            summaryData
          }}
        />
      ) : (
        <Body
          _this={{
            invoicesData,
            customerData,
            tableData,
            setSearchInput,
            isRetryInvoice,
            setIsRetryInvoice,
            retryPayments
          }}
        />
      )}
    </DashboardContainer>
  );
};

export default Index;
