import { Modal } from 'react-bootstrap';
import { Card, Button, Input, DatePicker, Dropdown, Row } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const EditContestPrizeModal = ({ _this }) => {
  return (
    <Modal
      show={_this.editContestPrizeModal}
      onHide={() => _this.setEditContestPrizeModal(false)}
      fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Edit Contest Prize</Modal.Title>
        <AiFillCloseCircle size={30} onClick={() => _this.setEditContestPrizeModal(false)} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Dropdown
            label="Location"
            className="w-48 z-10"
            placeholder="@location"
            required
            type="text"
            options={_this.locationList}
            aria-label="Default select example"
            value={_this.editContestPrizeData?.location_group}
            onChange={(e) => {
              _this.setEditContestPrizeData((prev) => ({
                ...prev,
                location_group: e
              }));
            }}
          />
          <Dropdown
            label="Category"
            className="w-48 z-8"
            placeholder="@tier"
            required
            type="text"
            options={[
              { label: 'Tier 1', value: 'tier_1' },
              { label: 'Tier 2', value: 'tier_2' }
            ]}
            aria-label="Default select example"
            value={_this.editContestPrizeData?.category}
            onChange={(e) => {
              _this.setEditContestPrizeData((prev) => ({
                ...prev,
                category: e
              }));
            }}
          />
        </Row>
        <Row>
          <Input
            type="text"
            placeholder="Enter hurdle value..."
            label="Hurdle Value"
            required
            value={_this.editContestPrizeData?.hurdle_value}
            onChange={(e) => {
              _this.setEditContestPrizeData((prev) => ({
                ...prev,
                hurdle_value: e.target.value
              }));
            }}
          />
          <Input
            type="text"
            placeholder="Enter Multiple..."
            label="Multiple"
            required
            value={_this.editContestPrizeData?.multiple}
            onChange={(e) => {
              _this.setEditContestPrizeData((prev) => ({
                ...prev,
                multiple: e.target.value
              }));
            }}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.editContestPrize();
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditContestPrizeModal;
