import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { PermissionWrapper } from 'components';
import moment from 'moment';

const Section = ({ _this }) => {
  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Reports</h3>
        </div>
      </Card>
      <div className="mt-3 mb-3 flex flex-col lg:flex-row flex-wrap gap-3 justify-between">
        {/* Dropped Patients Report */}
        <PermissionWrapper permission="view_dropped_patients_report">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">Dropped Patients Report</h3>
            <div className="mt-3 mb-3 flex flex-col">
              <Form className="flex flex-col gap-4">
                <div>
                  <h3>Invoice Due Date Range</h3>

                  <DatePicker
                    label="Start Date"
                    required
                    value={_this.droppedPatientsDetails?.start_due_date || ''}
                    onChange={(e) => {
                      _this.setDroppedPatientsDetails((prev) => ({
                        ...prev,
                        start_due_date: e
                      }));
                    }}
                  />

                  <DatePicker
                    label="End Date"
                    required
                    value={_this.droppedPatientsDetails?.end_due_date || ''}
                    onChange={(e) => {
                      _this.setDroppedPatientsDetails((prev) => ({
                        ...prev,
                        end_due_date: e
                      }));
                    }}
                  />
                </div>
                <Dropdown
                  label="Location"
                  placeholder="@location"
                  required
                  type="text"
                  options={_this.locationList
                    ?.slice()
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  aria-label="Default select example"
                  value={_this.droppedPatientsDetails.location}
                  onChange={(e) => {
                    _this.setDroppedPatientsDetails((prev) => ({
                      ...prev,
                      location: e
                    }));
                  }}
                  isMulti
                />
              </Form>
            </div>

            <Button onClick={_this.handleGenerateDroppedPatientsReport}>Generate Report</Button>
          </Card>
        </PermissionWrapper>

        {/* Active Hspp Patients Report */}
        <PermissionWrapper permission="view_active_hspp_patient_report">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">Active HSPP Patients Report</h3>
            <div className="mt-3 mb-3 flex flex-col">
              <Form className="flex flex-col gap-4">
                <DatePicker
                  label="Start Date"
                  required
                  value={_this.activeHsppPatientDetails?.start_date || ''}
                  onChange={(e) => {
                    _this.setActiveHsppPatientDetails((prev) => ({
                      ...prev,
                      start_date: e
                    }));
                  }}
                />

                <DatePicker
                  label="Current Date"
                  required
                  value={_this.activeHsppPatientDetails?.current_date || ''}
                  onChange={(e) => {
                    _this.setActiveHsppPatientDetails((prev) => ({
                      ...prev,
                      current_date: e
                    }));
                  }}
                />

                <Dropdown
                  label="Search By"
                  placeholder="@location"
                  required
                  type="text"
                  options={_this.activeHsppPatientOptions}
                  aria-label="Default select example"
                  value={_this.activeHsppPatientDetails.search_by}
                  onChange={(e) => {
                    _this.setActiveHsppPatientDetails((prev) => ({
                      ...prev,
                      search_by: e
                    }));
                  }}
                />
              </Form>
            </div>
            <Button onClick={_this.handleGenerateActiveHsppPatientReport}>Generate Report</Button>
          </Card>
        </PermissionWrapper>

        <PermissionWrapper permission="view_active_hspp_patient_history_report">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">
              Active HSPP Patients History Report
            </h3>
            <div className="mt-3 mb-3 flex flex-col">
              <Form className="flex flex-col gap-4">
                <DatePicker
                  label="Start Date"
                  required
                  value={_this.activeHsppPatientHistoryDetails?.start_date || ''}
                  onChange={(e) => {
                    _this.setActiveHsppPatientHistoryDetails((prev) => ({
                      ...prev,
                      start_date: e
                    }));
                  }}
                />

                <DatePicker
                  label="Current Date"
                  required
                  value={_this.activeHsppPatientHistoryDetails?.current_date || ''}
                  onChange={(e) => {
                    _this.setActiveHsppPatientHistoryDetails((prev) => ({
                      ...prev,
                      current_date: e
                    }));
                  }}
                />

                <Dropdown
                  aria-label="Default select example"
                  className="z-30"
                  label="Search By"
                  placeholder="@location"
                  required
                  type="text"
                  value={_this.activeHsppPatientHistoryDetails?.search_field}
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Location Name', value: 'location_name' },
                    { label: 'Franchise Name', value: 'franchise_name' }
                  ]}
                  onChange={(e) => {
                    _this.setActiveHsppPatientHistoryDetails((prev) => ({
                      ...prev,
                      search_field: e,
                      search_value: []
                    }));
                  }}
                />

                {_this.activeHsppPatientHistoryDetails.search_field.value === 'location_name' && (
                  <Dropdown
                    className="z-20"
                    placeholder="@select location name"
                    type="text"
                    options={_this.locationListForActiveHsppHistoryReport
                      ?.slice()
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    value={
                      _this.activeHsppPatientHistoryDetails.search_value.length > 0 &&
                      _this.activeHsppPatientHistoryDetails.search_value
                    }
                    onChange={(e) => {
                      _this.setActiveHsppPatientHistoryDetails((prev) => ({
                        ...prev,
                        search_value: e
                      }));
                    }}
                    aria-label="Location List"
                    isMulti
                  />
                )}
                {_this.activeHsppPatientHistoryDetails.search_field.value === 'franchise_name' && (
                  <Dropdown
                    className="z-20"
                    placeholder="@select franchise name"
                    type="text"
                    options={_this.franchiseListForActiveHsppHistoryReport
                      ?.slice()
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    value={
                      _this.activeHsppPatientHistoryDetails.search_value.length > 0 &&
                      _this.activeHsppPatientHistoryDetails.search_value
                    }
                    onChange={(e) => {
                      _this.setActiveHsppPatientHistoryDetails((prev) => ({
                        ...prev,
                        search_value: e
                      }));
                    }}
                    aria-label="Franchise List"
                    isMulti
                  />
                )}
              </Form>
            </div>
            <Button onClick={_this.handleGenerateActiveHsppPatientHistoryReport}>
              Generate Report
            </Button>
          </Card>
        </PermissionWrapper>

        <PermissionWrapper permission="view_active_hspp_patient_report_based_on_plan_name">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">
              Active HSPP Patients Report Based On Plan Name
            </h3>
            <div className="mt-3 mb-3 flex flex-col">
              <Form className="flex flex-col gap-4">
                <DatePicker
                  label="Start Date"
                  required
                  value={_this.activeHsppPatientReportBasedOnPlanName?.start_date || ''}
                  onChange={(e) => {
                    _this.setActiveHsppPatientBasedOnPlanName((prev) => ({
                      ...prev,
                      start_date: e
                    }));
                  }}
                />

                <DatePicker
                  label="Current Date"
                  required
                  value={_this.activeHsppPatientReportBasedOnPlanName?.current_date || ''}
                  onChange={(e) => {
                    _this.setActiveHsppPatientBasedOnPlanName((prev) => ({
                      ...prev,
                      current_date: e
                    }));
                  }}
                />

                <Dropdown
                  label="Search By"
                  placeholder="@location"
                  required
                  type="text"
                  options={_this.activeHsppPatientOptions}
                  aria-label="Default select example"
                  value={_this.activeHsppPatientReportBasedOnPlanName.search_by}
                  onChange={(e) => {
                    _this.setActiveHsppPatientBasedOnPlanName((prev) => ({
                      ...prev,
                      search_by: e
                    }));
                  }}
                />
              </Form>
            </div>
            <Button onClick={_this.handleGenerateActiveHsppPatientReportBasedOnPlanName}>
              Generate Report
            </Button>
          </Card>
        </PermissionWrapper>

        {/* HSPP attachment Report */}
        <PermissionWrapper permission="view_hspp_attatchment_report">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">HSPP Attachment Report</h3>
            <div className="mt-3 mb-3 flex flex-col">
              <Form className="flex flex-col gap-4">
                <Dropdown
                  label="Search By"
                  placeholder="@location"
                  required
                  type="text"
                  options={[
                    { label: 'Location Name', value: 'location_name' },
                    { label: 'Franchise Name', value: 'franchise_name' },
                    { label: 'Location Group', value: 'location_group' },
                    { label: 'State', value: 'state' }
                  ]}
                  aria-label="Default select example"
                  value={_this.hsppAttatchmentDetails?.search_by}
                  onChange={(e) => {
                    _this.setHsppAttatchmentDetails((prev) => ({
                      ...prev,
                      search_by: e
                    }));
                  }}
                />
                <DatePicker
                  label="Start Date"
                  required
                  value={_this.hsppAttatchmentDetails?.start_date || ''}
                  onChange={(e) => {
                    _this.setHsppAttatchmentDetails((prev) => ({
                      ...prev,
                      start_date: e
                    }));
                  }}
                />

                <DatePicker
                  label="End Date"
                  required
                  value={_this.hsppAttatchmentDetails?.end_date || ''}
                  onChange={(e) => {
                    _this.setHsppAttatchmentDetails((prev) => ({
                      ...prev,
                      end_date: e
                    }));
                  }}
                />
              </Form>
            </div>
            <Button onClick={_this.handelGenerateHsppAttachmentReport}>Generate Report</Button>
          </Card>
        </PermissionWrapper>

        {/* Protection Plan Battery Mailer List */}
        <PermissionWrapper permission="view_protection_plan_battery_report">
          <Card className="w-full lg:w-[32%]">
            <div className="flex gap-3 flex-col">
              <h3 className="text-body-color text-xl md:text-xl">
                Protection Plan Battery Mailer List
              </h3>
              <Button onClick={_this.handleGenerateProtectionPlanBatteryReport}>
                Generate Report
              </Button>
            </div>
          </Card>
        </PermissionWrapper>

        {/* Amplifon patients report */}
        <PermissionWrapper permission="view_amplifon_patients_report">
          <Card className="w-full lg:w-[32%]">
            <div className="flex gap-3 flex-col">
              <h3 className="text-body-color text-xl md:text-xl">Amplifon Patients Report</h3>
              <Button onClick={_this.handleGenerateAmplifonPatientsReport}>Generate Report</Button>
            </div>
          </Card>
        </PermissionWrapper>

        {/* Invalid Battery List */}
        <PermissionWrapper permission="view_protection_plan_battery_report">
          <Card className="w-full lg:w-[32%]">
            <div className="flex gap-3 flex-col">
              <h3 className="text-body-color text-xl md:text-xl">Invalid Battery List</h3>
              <Button onClick={_this.handleGenerateInvalidBatteryReport}>Generate Report</Button>
            </div>
          </Card>
        </PermissionWrapper>

        {/* Loss & Damage Report */}
        <PermissionWrapper permission="view_loss_and_damage_report">
          <Card className="w-full lg:w-[32%]">
            <div className="flex gap-3 flex-col">
              <h3 className="text-body-color text-xl md:text-xl">L&D Report</h3>

              <Button onClick={_this.handleLossAndDamageReport}>Generate Report</Button>
            </div>
          </Card>
        </PermissionWrapper>

        {/* Future Plans Report */}
        <PermissionWrapper permission="view_future_plans_report">
          <Card className="w-full lg:w-[32%]">
            <div className="flex gap-3 flex-col">
              <h3 className="text-body-color text-xl md:text-xl">Future HSPP Plans Report</h3>

              <Button onClick={_this.handleFuturePlansReport}>Generate Report</Button>
            </div>
          </Card>
        </PermissionWrapper>

        {/* Card Udpate report */}
        <PermissionWrapper permission="view_card_update_report">
          <Card className="w-full lg:w-[32%]">
            <div className="flex gap-3 flex-col">
              <h3 className="text-body-color text-xl md:text-xl">Updated Cards Report</h3>
              <Button onClick={_this.handleCardUpdateReport}>Generate Report</Button>
            </div>
          </Card>
        </PermissionWrapper>

        {/* Monthly NewsLetter Report */}
        <PermissionWrapper permission="view_newsletter_report">
          <Card className="w-full lg:w-[32%]">
            <div className="flex gap-3 flex-col">
              <h3 className="text-body-color text-xl md:text-xl">Monthly NewsLetter Report</h3>
              <Form className="flex gap-5">
                <Dropdown
                  label="Select Month"
                  className="w-48 z-10"
                  placeholder="@month"
                  required
                  type="text"
                  options={_this.months}
                  aria-label="Default select example"
                  value={_this.newsLetterMonth.month}
                  onChange={(e) => {
                    _this.setNewsLetterMonth((prev) => ({
                      ...prev,
                      month: e
                    }));
                  }}
                />
                <Input
                  type="number"
                  label="Select year"
                  placeholder="year"
                  value={_this.newsLetterMonth.year}
                  className="text-center text-xl p-1"
                  onChange={(e) => {
                    _this.setNewsLetterMonth((prev) => ({
                      ...prev,
                      year: moment(e.target.value).year()
                    }));
                  }}
                ></Input>
              </Form>
              <Button onClick={_this.handleNewsLetterReport}>Generate Report</Button>
            </div>
          </Card>
        </PermissionWrapper>

        {/* View Chargeback Report */}
        <PermissionWrapper permission="view_chargeback_report">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">Chargeback Report</h3>
            <div className="mt-3 mb-3 flex flex-col">
              <Form className="flex flex-col gap-4">
                <DatePicker
                  label="Start Date"
                  required
                  value={_this.chargebackDetails?.start_date || ''}
                  onChange={(e) => {
                    _this.setChargebackDetails((prev) => ({
                      ...prev,
                      start_date: e
                    }));
                  }}
                />

                <DatePicker
                  label="End Date"
                  required
                  value={_this.chargebackDetails?.end_date || ''}
                  onChange={(e) => {
                    _this.setChargebackDetails((prev) => ({
                      ...prev,
                      end_date: e
                    }));
                  }}
                />
              </Form>
            </div>
            <Button onClick={_this.handelGenerateChargebackReport}>Generate Report</Button>
          </Card>
        </PermissionWrapper>

        {/* Subscription Plan Change report */}
        <PermissionWrapper permission="subscription_plan_change_report">
          <Card className="w-full lg:w-[32%]">
            <div className="flex gap-3 flex-col">
              <h3 className="text-body-color text-xl md:text-xl">
                Subscription Plan Change Report
              </h3>
              <Button onClick={_this.handleSubscriptionPlanChangeReport}>Generate Report</Button>
            </div>
          </Card>
        </PermissionWrapper>
      </div>
    </>
  );
};

export default Section;
