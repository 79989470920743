import React from 'react';
import { Table } from 'components';
import moment from 'moment';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { Modal, Form } from 'react-bootstrap';
import ButtonBTN from 'react-bootstrap/Button';
import * as FileServer from 'file-saver';
import * as XLSX from 'xlsx';
import { FaSyncAlt } from 'react-icons/fa';
const Body = ({ _this }) => {
  const exportReport = () => {
    const extractedDataArray = [];
    for (const dataObject of _this.tableData) {
      const extractedData = {
        Location: dataObject.location ? dataObject.location : 'N/A',
        'Monthly Battery Service': dataObject.monthly_battery_service,
        'Secure Plan Supplemental Repair Warranty (Single Aid)':
          dataObject.secure_plan_supplemental_repair_warranty_single_aid,
        '2022 Loss and Damage (single aid)': dataObject['2022_loss_and_damage_single_aid'],
        'Protection + Supplemental Loss and Damage':
          dataObject['protection_plus_supplemental_loss_and_damage'],
        'Protection + Supplemental Loss & Damage':
          dataObject['protection_plus_supplemental_loss_&_damage'],
        '2023 ACH - Protection + Supplemental Loss & Damage':
          dataObject['2023_ach_protection_plus_supplemental_loss_and_damage'],
        'Secure Plan Supplemental Repair Warranty':
          dataObject['secure_plan_supplemental_repair_warranty'],
        'Secure+ Plan Supplemental Repair Warranty':
          dataObject['secure_plus_plan_supplemental_repair_warranty'],
        'Protection+ Supplemental Loss and Damage (Single Aid)':
          dataObject['protection_plus_suplemental_loss_and_damage_single_aid'],
        'Total Active Subscriptions': dataObject['total_active_subscriptions']
      };

      extractedDataArray.push(extractedData);
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(extractedDataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob and save the Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileServer.saveAs(data, 'ActiveHsppPatientReportBasedOnPlanName' + fileExtension);
  };

  const columns = [
    {
      fixed: 'left',
      headerStyle: { backgroundColor: '#474747', fontSize: '75%' },
      accessor: 'location',
      style: { backgroundColor: '#C6C6C6' },
      Header: 'Location Name',
      Cell: (row) => <div>{row.original.location ? row.original.location : 'N/A'}</div>
    },
    // {
    //   accessor: 'location_group',
    //   headerStyle: { fontSize: '75%' },
    //   Header: 'Location Group',
    //   Cell: (row) => <div>{row.original.location_group}</div>
    // },
    {
      accessor: 'monthly_battery_service',
      headerStyle: { fontSize: '75%' },
      Header: 'Monthly Battery Service',
      Cell: (row) => <div>{row.original.monthly_battery_service}</div>
    },

    {
      accessor: 'secure_plan_supplemental_repair_warranty_single_aid',
      headerStyle: { fontSize: '75%' },
      Header: 'Secure Plan Supplemental Repair Warranty (Single Aid)',
      Cell: (row) => <div>{row.original.secure_plan_supplemental_repair_warranty_single_aid}</div>
    },

    {
      accessor: '2022_loss_and_damage_single_aid',
      headerStyle: { fontSize: '75%' },
      Header: '2022 Loss and Damage (single aid)',
      Cell: (row) => <div>{row.original['2022_loss_and_damage_single_aid']}</div>
    },

    {
      accessor: 'protection_plus_supplemental_loss_and_damage',
      headerStyle: { fontSize: '75%' },
      Header: 'Protection + Supplemental Loss and Damage',
      Cell: (row) => <div>{row.original.protection_plus_supplemental_loss_and_damage}</div>
    },

    {
      accessor: 'protection_plus_supplemental_loss_&_damage',
      headerStyle: { fontSize: '75%' },
      Header: 'Protection + Supplemental Loss & Damage',
      Cell: (row) => <div>{row.original['protection_plus_supplemental_loss_&_damage']}</div>
    },

    {
      accessor: '2023_ach_protection_plus_supplemental_loss_and_damage',
      headerStyle: { fontSize: '75%' },
      Header: '2023 ACH - Protection + Supplemental Loss & Damage',
      Cell: (row) => (
        <div>{row.original['2023_ach_protection_plus_supplemental_loss_and_damage']}</div>
      )
    },

    {
      accessor: 'secure_plan_supplemental_repair_warranty',
      headerStyle: { fontSize: '75%' },
      Header: 'Secure Plan Supplemental Repair Warranty',
      Cell: (row) => <div>{row.original['secure_plan_supplemental_repair_warranty']}</div>
    },

    {
      accessor: 'secure_plus_plan_supplemental_repair_warranty',
      headerStyle: { fontSize: '75%' },
      Header: 'Secure+ Plan Supplemental Repair Warranty',
      Cell: (row) => <div>{row.original['secure_plus_plan_supplemental_repair_warranty']}</div>
    },

    {
      accessor: 'protection_plus_suplemental_loss_and_damage_single_aid',
      headerStyle: { fontSize: '75%' },
      Header: 'Protection+ Supplemental Loss and Damage (Single Aid)',
      Cell: (row) => (
        <div>{row.original['protection_plus_suplemental_loss_and_damage_single_aid']}</div>
      )
    },
    {
      accessor: 'total_active_subscriptions',
      headerStyle: { fontSize: '75%' },
      Header: 'Total Active Subscriptions',
      Cell: (row) => <div>{row.original['total_active_subscriptions']}</div>
    }
  ];
  return (
    <div className="flex flex-col gap-1">
      <Card background={'bg-cardbg1'} className="">
        <div className="relative flex flex-col sm:flex-row gap-2 justify-between items-center">
          <h3 className="text-body-color text-xl md:text-2xl">
            Active Hspp Patient Report Based On Plan Name
          </h3>

          <DatePicker
            label="Start Date"
            required
            value={_this.activeHsppPatientReportBasedOnPlanName?.start_date || ''}
            onChange={(e) => {
              _this.setActiveHsppPatientReportBasedOnPlanName((prev) => ({
                ...prev,
                start_date: e
              }));
            }}
          />

          <DatePicker
            label="Current Date"
            required
            value={_this.activeHsppPatientReportBasedOnPlanName?.current_date || ''}
            onChange={(e) => {
              _this.setActiveHsppPatientReportBasedOnPlanName((prev) => ({
                ...prev,
                current_date: e
              }));
            }}
          />

          <Dropdown
            label="Search By"
            className="w-48 z-10"
            placeholder="@location"
            required
            type="text"
            options={_this.activeHsppPatientOptions}
            aria-label="Default select example"
            value={_this.activeHsppPatientReportBasedOnPlanName.search_by}
            onChange={(e) => {
              _this.setActiveHsppPatientReportBasedOnPlanName((prev) => ({
                ...prev,
                search_by: e
              }));
            }}
          />

          <button
            className="bg-button-primary mt-3 hover:bg-button-primaryHover text-white px-2 py-2 rounded text-base flex flex-row self-center w-48 justify-center items-center"
            onClick={_this.init}
          >
            Generate Report
          </button>
        </div>
      </Card>

      <Card className="flex mr-2 mt-2" paddingClass="px-1 py-2">
        <div className="flex justify-between items-center p-2">
          <div className="flex gap-2">
            <ButtonBTN
              onClick={() => exportReport()}
              className={`bg-button-primary  mb-2`}
              variant="primary"
              id="export"
            >
              Export
            </ButtonBTN>
          </div>
          <input
            type="search"
            placeholder="Search.."
            value={_this.searchInput}
            onChange={(e) => _this.setSearchInput(e.target.value)}
          />
        </div>

        <Table
          keyField="_id"
          columns={columns}
          data={_this.tableData}
          headerComponent={null}
          minRows={20}
        />
      </Card>

      {/* {_this.activeHsppPatientReportBasedOnPlanName.search_by.value == 'location_name' && (
        <LocationName _this={_this} />
      )} */}
      {/* {_this.activeHsppPatientDetails.search_by.value == 'location_group' && (
        <LocationGroup _this={_this} />
      )}
      {_this.activeHsppPatientDetails.search_by.value == 'franchise_name' && (
        <FranchiseName _this={_this} />
      )}
      {_this.activeHsppPatientDetails.search_by.value == 'state' && <State _this={_this} />}
      {_this.activeHsppPatientDetails.search_by.value == 'total' && <Total _this={_this} />}  */}
    </div>
  );
};

export default Body;
