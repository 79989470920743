import React, { useEffect, useState } from 'react';
import { Button, Card, Table } from 'components';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Body = ({ _this }) => {
  const [columns, setColumns] = useState([
    {
      Header: 'Patient ID',
      fixed: 'left',
      accessor: 'patient_id',
      Cell: (row) => (
        <Link
          to={`/customer-info/${row?.original?.customer_id}`}
          className="text-blue-500 font-medium"
        >
          {row?.original?.patient_id || ''}
        </Link>
      )
    },
    {
      Header: 'Customer Name',
      accessor: 'customer_name',
      Cell: (row) => <div>{row?.original?.customer_name}</div>
    },
    {
      Header: 'Location',
      accessor: 'data.location',
      Cell: (row) => <div>{row?.original?.data?.location}</div>
    },
    {
      Header: 'Employee Name',
      minWidth: 220,
      accessor: 'employee_name',
      Cell: (row) => <div className="font-medium">{row?.original?.employee_name}</div>
    },
    {
      Header: 'Franchise Name',
      accessor: 'franchise_name',
      Cell: (row) => <div>{row?.original?.franchise_name}</div>
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (row) => <div>{row?.original?.action}</div>
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (row) => (
        <div
          className={
            (row.original.status == 'pending'
              ? 'bg-amber-200 text-amber-700'
              : row.original.status == 'approved'
              ? 'bg-green-200 text-green-700'
              : 'bg-red-200 text-red-700') + ' py-1 rounded'
          }
        >
          {row.original.status}
        </div>
      )
    }
  ]);

  const permissions = useSelector((state) => state.session)?.userSession?.permissions;
  useEffect(() => {
    let ind = permissions?.indexOf('resolve_ticket');

    if (ind != -1)
      setColumns([
        ...columns,
        {
          Header: 'View',
          accessor: '_id',
          sortable: false,
          width: 160,
          Cell: (row) => (
            <Button
              style="w-20"
              onClick={() => {
                _this.setModalTicket(row?.original);
                _this.setOpenModal(true);
              }}
              className="font-medium"
            >
              view
            </Button>
          )
        }
      ]);
  }, []);

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Ticket List</h3>
          <div></div>
        </div>
      </Card>

      <Card className="flex mt-2" paddingClass="px-0 py-0">
        <div className="">
          <Table keyField="_id" columns={columns} data={_this.tableData} minRows={20} />
        </div>
      </Card>

      <Modal
        style={{ zIndex: 9999 }}
        show={_this?.openModal}
        centered
        onHide={() => _this?.setOpenModal(false)}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Ticket Details for {_this?.modalTicket?.action}</Modal.Title>
          <AiFillCloseCircle size={30} onClick={() => _this.setOpenModal(false)} />
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-3 flex  mr-2">
            <h3 className="text-font-label text-lg">
              Patient Name : {_this?.modalTicket?.customer_name}
            </h3>
            <h3 className="text-font-label text-lg">
              Subscription Name : {_this?.modalTicket?.data?.plan_name}
            </h3>
            <h3 className="text-font-label text-lg">
              Subscription Amount : ${_this?.modalTicket?.data?.amount}
            </h3>
            <h3 className="text-font-label text-lg">
              Last Invoice Date :{' '}
              {moment(_this?.modalTicket?.data?.last_invoice_date).format('YYYY-MM-DD')}
            </h3>
            <h3 className="text-font-label text-lg">
              Next Invoice Date :{' '}
              {moment(_this?.modalTicket?.data?.next_invoice_date).format('YYYY-MM-DD')}
            </h3>

            {_this?.modalTicket?.status == 'pending' ? (
              <div className="flex w-2/3 gap-20 mx-auto mt-5">
                <Button color="info" onClick={() => _this.resolveTicket(true)}>
                  Approve
                </Button>
                <Button color="danger" onClick={() => _this.resolveTicket(false)}>
                  Deny
                </Button>
              </div>
            ) : (
              <div
                className={
                  (_this?.modalTicket?.status == 'approved' ? 'bg-green-400' : 'bg-red-400') +
                  ' py-2 w-1/5 rounded-md mx-auto text-2xl text-center mt-3'
                }
              >
                {_this?.modalTicket?.status == 'approved' ? 'Approved' : 'Rejected'}
              </div>
            )}
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Body;
