import React from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';

const SetPrimaryPaymentModal = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this.selectPrimaryPaymentModalOpen}
      onHide={() => _this.setSelectPrimaryPaymentModalOpen(false)}
      fullscreen="lg-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Alert</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setSelectPrimaryPaymentModalOpen(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 flex  mr-2">
          <h3 className="text-font-label text-lg">
            Are You sure you want to set{' '}
            <span className="text-black">
              XXXX XXXX XXXX {_this.selectedPaymentDetails?.card_number}{' '}
              {_this.selectedPaymentDetails?.payment_method == 'creditcard'
                ? 'Credit Card'
                : 'ACH Account'}{' '}
            </span>
            as your primary payment method?
          </h3>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex justify-end gap-2 items-center">
          <Button color="danger" onClick={() => _this.setSelectPrimaryPaymentModalOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => _this.selectPrimaryPaymentMethod()}>Yes</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SetPrimaryPaymentModal;
