import React from 'react';
import { Card, Button } from 'components';
import { Link } from 'react-router-dom';
import { Table } from 'components';
import moment from 'moment';
import { parseHsppId } from 'services/helpers';

const Section = ({ _this }) => {
  const columns = [
    {
      Header: 'Patient ID',
      fixed: 'left',
      accessor: 'customer_data.customer_id',
      headerStyle: { backgroundColor: '#474747' },
      style: { backgroundColor: '#C6C6C6' },
      Cell: (row) => (
        <Link
          to={`/customer-info/${row?.original?.customer}`}
          className="text-blue-500 font-medium"
        >
          {row?.original?.patient_id}
        </Link>
      )
    },
    {
      accessor: 'customer_data.first_name',
      Header: 'Customer Name',
      Cell: (row) => <div>{row?.original?.patient_name}</div>
    },
    {
      accessor: 'plan_name',
      Header: 'Plan Name',
      width: 350
    },
    {
      accessor: 'plan_interval',
      Header: 'Plan Interval'
    },
    {
      accessor: 'status',
      Header: 'Status'
    }
  ];

  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-2xl">Subscriptions List</h3>
          <div className=""></div>
        </div>
      </Card>
      <Card className="flex mt-2" paddingClass="px-0 py-0">
        <div className="">
          <Table
            keyField="id"
            columns={columns}
            data={_this.tableData}
            headerComponent={null}
            pageSize={100}
            showPageSizeOptions={false}
            pages={_this.totalPage}
            onFetchData={(state, instance) => {
              _this.fetchData(
                state.page,
                state.pageSize,
                state?.sorted[0]?.id,
                state?.sorted[0]?.desc
              );
            }}
            manual={true}
          />
        </div>
      </Card>
    </>
  );
};

export default Section;
