import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Card, Button, Input, Dropdown, DatePicker, Row, Checkbox } from 'components';
import { PermissionWrapper } from 'components';
import moment from 'moment';

const Section = ({ _this }) => {
  return (
    <>
      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Accounting</h3>
        </div>
      </Card>
      <div className="mt-3 mb-3 flex flex-col lg:flex-row flex-wrap gap-3 justify-between">
        {/* Accouning Revenue Report */}
        <PermissionWrapper permission="view_revenue_report">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">Revenue Report</h3>
            <div className="mt-3 mb-3 flex flex-col">
              <Form className="flex flex-col gap-4">
                <div>
                  <h3>Select range in months</h3>

                  <DatePicker
                    label="Start Month"
                    isMonth={true}
                    required
                    value={_this.revenueParams?.start_date || ''}
                    onChange={(e) => {
                      _this.setRevenueParams((prev) => ({
                        ...prev,
                        start_date: moment(e).startOf('month').toDate()
                      }));
                    }}
                  />

                  <DatePicker
                    label="End Month"
                    isMonth={true}
                    required
                    value={_this.revenueParams?.end_date || ''}
                    onChange={(e) => {
                      _this.setRevenueParams((prev) => ({
                        ...prev,
                        end_date: moment(e).endOf('month').toDate()
                      }));
                    }}
                  />
                </div>
                <Dropdown
                  label="Location"
                  placeholder="@location"
                  required
                  type="text"
                  options={_this.locationList
                    ?.slice()
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  aria-label="Default select example"
                  value={_this.revenueParams?.locations}
                  onChange={(e) => {
                    _this.setRevenueParams((prev) => ({
                      ...prev,
                      locations: e
                    }));
                  }}
                  isMulti
                />
              </Form>
            </div>

            <Button onClick={_this.openRevenueReport}>Generate Report</Button>
          </Card>
        </PermissionWrapper>

        <PermissionWrapper permission="view_revenue_patients_report">
          <Card className="w-full lg:w-[32%]">
            <h3 className="text-body-color text-xl md:text-xl">Revenue Patients Report</h3>
            <div className="mt-3 mb-3 flex flex-col">
              <Form className="flex flex-col gap-4">
                <div>
                  <h3>Select range in months</h3>

                  <DatePicker
                    label="Start Month"
                    isMonth={true}
                    required
                    value={_this.revenuePatientsReportParams?.start_date || ''}
                    onChange={(e) => {
                      _this.setRevenuePatientsReportParams((prev) => ({
                        ...prev,
                        start_date: moment(e).startOf('month').toDate()
                      }));
                    }}
                  />

                  <DatePicker
                    label="End Month"
                    isMonth={true}
                    required
                    value={_this.revenuePatientsReportParams?.end_date || ''}
                    onChange={(e) => {
                      _this.setRevenuePatientsReportParams((prev) => ({
                        ...prev,
                        end_date: moment(e).endOf('month').toDate()
                      }));
                    }}
                  />
                </div>
                <Dropdown
                  label="Location"
                  placeholder="@location"
                  required
                  type="text"
                  options={_this.locationList
                    ?.slice()
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  aria-label="Default select example"
                  value={_this.revenuePatientsReportParams?.locations}
                  onChange={(e) => {
                    _this.setRevenuePatientsReportParams((prev) => ({
                      ...prev,
                      locations: e
                    }));
                  }}
                  isMulti
                />
              </Form>
            </div>

            <Button onClick={_this.handleRevenuePatientsReport}>Generate Report</Button>
          </Card>
        </PermissionWrapper>
      </div>
    </>
  );
};

export default Section;
