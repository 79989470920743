import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingStart, loadingStop } from 'redux/action';
import { DashboardContainer } from 'components';
import Body from './Body';
import API from 'services/api';
import { toast } from 'react-toastify';

const Index = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [modalTicket, setModalTicket] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const init = () => {
    dispatch(loadingStart());

    API.getTickets()
      .then((response) => {
        if (response) {
          setTableData(response);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const resolveTicket = (approve) => {
    dispatch(loadingStart());
    API.resolveTicket({ ticket_id: modalTicket?._id, approve }).then((response) => {
      setOpenModal(false);
      if (response) toast.success('Ticket resolved successfully.');
      init();
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <DashboardContainer>
      <Body
        _this={{
          tableData,
          openModal,
          setOpenModal,
          modalTicket,
          setModalTicket,
          resolveTicket
        }}
      />
    </DashboardContainer>
  );
};

export default Index;
