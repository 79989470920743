import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardContainer } from 'components';
import Body from './Body';

const Index = () => {
  return (
    <DashboardContainer>
      <Body _this={{}} />
    </DashboardContainer>
  );
};

export default Index;
