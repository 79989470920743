import { handleResponse, API, getToken } from './utils';

const invoice = {
  getSubscriptionList: async (page, limit, sortingField, sortingDirection) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(
        `/v1/subscription/get-subscriptions?page=${page || 0}&limit=${limit || 1}&sortingField=${
          sortingField || 'createdAt'
        }&sortingDirection=${sortingDirection ? 1 : -1}`,
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  submitSignature: async (id, signature) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/subscription/submit-signature',
        { subscription_id: id, signature: signature },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createSubscription: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/subscription/add-subscription', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  temporaryEditSubscription: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/subscription/temporary-edit-subscription', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  deleteSubscription: async (_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/subscription/delete-subscription?_id=${_id}`, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  EditExpiryDate: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/subscription/edit-expiry-date', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default invoice;
